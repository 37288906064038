import palette from 'theme/palette'

const MuiButton = {
  borderRadius: '40px',
  root: {
    borderRadius: '40px',
    fontWeight: '700',
  },
  contained: {
    backgroundColor: palette.primary.main,
    color: '#FFF',
    borderRadius: '40px',
    boxShadow: '0px',
    fontWeight: '700',
    '&:hover': {
      backgroundColor: palette.primary.main,
    },
  },
  containedPrimary: {
    fontWeight: '700',
  },
  outlined: {
    borderColor: palette.primary.main,
    borderRadius: '40px',
    color: palette.primary.main,
    fontWeight: '700',
    '&:hover': {
      borderColor: palette.primary.main,
      // color: 'red',
    },
  },
}

export default MuiButton
