import { Container as ContainerMaterial } from '@material-ui/core'
import styles from './styles'

const Container = ({
  children,
  spacingXl = 18,
  spacingLg = 16,
  spacingMd = 4,
  spacingSm = 2,
  spacingXs = 2,
  ...rest
}) => {
  const useStyles = styles(
    spacingXl,
    spacingLg,
    spacingMd,
    spacingSm,
    spacingXs,
  )
  const classes = useStyles()

  return (
    <ContainerMaterial {...rest} className={classes.container}>
      {children}
    </ContainerMaterial>
  )
}

export default Container
