const ITEMS = {
  common: {
    black: '#000',
    white: '#fff',
  },
  type: 'light',
  primary: {
    contrastText: '#FFFFFF',
    main: '#DD235B',
    light: 'rgb(227, 79, 123)',
    dark: 'rgb(154, 24, 63)',
  },
  secondary: {
    contrastText: '#FFFFFF',
    main: '#9651b8',
    light: 'rgb(171, 115, 198)',
    dark: 'rgb(105, 56, 128)',
  },
  error: {
    contrastText: '#FFFFFF',
    dark: '#b71c1c',
    main: '#e53935',
    light: '#ef5350',
  },
  warning: {
    contrastText: '#FFFFFF',
    dark: '#e65100',
    main: '#fb8c00',
    light: '#ffa726',
  },
  info: {
    contrastText: '#FFFFFF',
    main: '#1084AC',
    light: 'rgb(63, 156, 188)',
    dark: 'rgb(11, 92, 120)',
  },
  success: {
    contrastText: '#FFFFFF',
    main: '#00A900',
    light: '#9FF89F',
    dark: 'rgb(0, 118, 0)',
  },
  grey: {
    50: '#fafafa',
    100: '#f5f5f5',
    200: '#eeeeee',
    300: '#e0e0e0',
    400: '#bdbdbd',
    500: '#9e9e9e',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
    A100: '#d5d5d5',
    A200: '#aaaaaa',
    A400: '#303030',
    A700: '#616161',
  },
  contrastThreshold: 3,
  tonalOffset: 0.2,
  text: {
    primary: '#1f1f1f',
    secondary: '#676767',
    disabled: 'rgba(0, 0, 0, 0.38)',
    hint: 'rgba(0, 0, 0, 0.38)',
    link: '#1e88e5',
  },
  divider: '#e0e0e0',
  background: {
    paper: '#FFF',
    default: '#fbfbfb',
  },
  action: {
    active: '#676767',
    hover: 'rgba(0, 0, 0, 0.04)',
    hoverOpacity: 0.04,
    selected: 'rgba(0, 0, 0, 0.08)',
    selectedOpacity: 0.08,
    disabled: 'rgba(0, 0, 0, 0.26)',
    disabledBackground: 'rgba(0, 0, 0, 0.12)',
    disabledOpacity: 0.38,
    focus: 'rgba(0, 0, 0, 0.12)',
    focusOpacity: 0.12,
    activatedOpacity: 0.12,
  },
  black: '#000000',
  white: '#FFFFFF',
  teriary: {
    contrastText: '#FFFFFF',
    main: '#FFAC2F',
  },
  newInfo: {
    infoOne: '#1084AC',
  },
  newWarning: {
    warningOne: '#B71C1C',
  },
  card: {
    default: '#052846',
  },
  icon: '#546e7a',
}

const themeLead = {
  ITEMS,
}

export default themeLead
