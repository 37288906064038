function hexToHSV(hexColor) {
  // Remover o caractere '#' se estiver presente
  const hex = hexColor.replace(/^#/, '')

  // Converter os componentes R, G, B para a faixa [0, 1]
  const r = parseInt(hex.substr(0, 2), 16) / 255
  const g = parseInt(hex.substr(2, 2), 16) / 255
  const b = parseInt(hex.substr(4, 2), 16) / 255

  // Encontrar o valor máximo e mínimo entre os componentes RGB
  const max = Math.max(r, g, b)
  const min = Math.min(r, g, b)

  // Calcular a luminância (Valor)
  const v = max

  // Calcular a saturação
  const s = max === 0 ? 0 : 1 - min / max

  // Calcular a matiz
  let h
  if (max === min) {
    h = 0 // Matiz indefinida para tons de cinza
  } else {
    if (max === r) {
      h = ((g - b) / (max - min) + (g < b ? 6 : 0)) / 6
    } else if (max === g) {
      h = ((b - r) / (max - min) + 2) / 6
    } else {
      h = ((r - g) / (max - min) + 4) / 6
    }
  }

  // Converter a matiz para graus
  h *= 360

  // Arredondar os valores para inteiros
  const roundedH = Math.round(h)
  const roundedS = Math.round(s * 100)
  const roundedV = Math.round(v * 100)

  // Retornar a cor no formato HSV como uma string
  return `HSV(${roundedH}, ${roundedS}%, ${roundedV}%)`
}

export default hexToHSV
