import PropTypes from 'prop-types'
import SnackbarProvider from 'providers/SnackbarProvider'

const PreviewCheckout = ({ children }) => {
  return <SnackbarProvider>{children}</SnackbarProvider>
}

PreviewCheckout.propTypes = {
  children: PropTypes.any,
}

export default PreviewCheckout
