import React, { useState } from 'react'
import {
  Box,
  makeStyles,
  Grid,
  Typography,
  Paper,
  Button,
} from '@material-ui/core'

import { DollarSign as DollarSignIcon } from 'react-feather'

import styles from './styles'
import clsx from 'clsx'
import helpers from 'helpers'

const useStyles = makeStyles(styles)

const OrderPayments = ({ order }) => {
  const classes = useStyles()
  const [startIndex, setStartIndex] = useState(0)

  const handlePrev = () => {
    setStartIndex((prevStartIndex) => Math.max(0, prevStartIndex - 5))
  }

  const handleNext = () => {
    setStartIndex((prevStartIndex) =>
      Math.min(order?.payments.length - 1, prevStartIndex + 5),
    )
  }

  const visiblePayments = order?.payments.slice(startIndex, startIndex + 5)

  // const paymentPaid = visiblePayments?.filter((pay) => pay.status === 'PAID')[0]

  // const getPaymentMethod = (order) => {
  //   if (order?.payments === undefined) {
  //     return 'Indefinido'
  //   }

  //   return (
  //     paymentPaid?.paymentMethod?.description ||
  //     visiblePayments[0]?.paymentMethod?.description ||
  //     'N/A'
  //   )
  // }

  // const getTotalReceived = (payments) => {
  //   let total = 0
  //   payments?.forEach((payment) => {
  //     if (payment.status === constants.payments.STATUSES.PAID_STATUS) {
  //       total += payment.amount
  //     }
  //   })

  //   return helpers.formatters.floatToMoney(total, true)
  // }

  // const pendingPayments = (payments) => {
  //   let pending = 0
  //   payments?.forEach((payment) => {
  //     if (constants.payments.PENDING_STATUSES.includes(payment.status)) {
  //       pending++
  //     }
  //   })

  //   return pending
  // }

  // const approvedPayments = (payments) => {
  //   let approved = 0
  //   payments?.forEach((payment) => {
  //     if (payment.status === constants.payments.STATUSES.PAID_STATUS) {
  //       approved++
  //     }
  //   })

  //   return approved
  // }

  return (
    <Grid container spacing={8}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Box className={classes.plansBanner} mb={2}>
          <Box className={classes.boxDescription}>
            <Typography variant="h6" className={classes.text}>
              Link de pagamento
            </Typography>
            <Typography variant="body2" className={classes.text}>
              Veja a página de pagamento e compartilhe com seus clientes.
            </Typography>
            <Button
              style={{ borderRadius: 40, marginTop: 12 }}
              size="small"
              variant="contained"
              color="primary"
              onClick={() => {
                window.open(
                  `${window.location.origin}/checkout/order/${order?.token}`,
                  '_blank',
                )
              }}
            >
              Acessar página
            </Button>
          </Box>
          <Box className={classes.bgColor}></Box>
        </Box>
      </Grid>
      <Paper
        elevation={0}
        p={2}
        style={{ borderRadius: '20px', width: '100%', padding: '8px' }}
      >
        <Box p={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography color="textPrimary" variant="h6">
              Cobranças
            </Typography>
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            {visiblePayments &&
              visiblePayments.map((payment, index) => (
                <div
                  className={clsx(classes.row, {
                    [classes.selectedRow]: index === startIndex,
                  })}
                  key={startIndex + index}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    style={{ width: '100%' }}
                  >
                    <Box display="flex" flexDirection="row">
                      <Box
                        mr={2}
                        className={classes.circle}
                        style={{ backgroundColor: '#EECDFF' }}
                      >
                        <DollarSignIcon size={20} color="#a804bc" />
                      </Box>
                      <Box>
                        <Typography
                          color="textSecondary"
                          variant="body2"
                          style={{ fontSize: '12px' }}
                        >
                          {payment.paymentMethod.description}
                        </Typography>
                        <Typography
                          color="textPrimary"
                          variant="body1"
                          style={{ fontSize: '12px' }}
                        >
                          {helpers.formatters.date(payment.createdAt)}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      alignItems="flex-end"
                    >
                      <Typography color="textSecondary" variant="body2">
                        Total
                      </Typography>
                      <Typography color="textPrimary" variant="body2">
                        R$ {helpers.formatters.floatToMoney(payment.amount)}
                      </Typography>
                    </Box>
                  </Box>
                </div>
              ))}
          </Grid>
          <Grid>
            <Box
              display="flex"
              alignItems="flex-end"
              justifyContent="space-between"
              style={{ width: '100%' }}
              mt={2}
            >
              {startIndex !== 0 && (
                <Button onClick={handlePrev} disabled={startIndex === 0}>
                  Voltar
                </Button>
              )}
              <Button
                onClick={handleNext}
                disabled={startIndex >= order?.payments.length - 5}
              >
                Próximo
              </Button>
            </Box>
          </Grid>
        </Box>
      </Paper>
    </Grid>
  )
}

export default OrderPayments
