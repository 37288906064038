import {
  CheckoutBillet,
  CheckoutCard,
  CheckoutPix,
  CustomerForm,
} from './components'

const CheckoutPayment = ({
  type,
  token,
  order,
  customer,
  setCustomer,
  step,
  setStep,
  loading,
}) => {
  return (
    <>
      {!loading &&
        (step === 1 ? (
          <CustomerForm setStep={setStep} setCustomer={setCustomer} />
        ) : step === 2 ? (
          <CheckoutBillet
            type={type}
            token={token}
            setStep={setStep}
            setCustomer={setCustomer}
            customer={customer}
            order={order}
          />
        ) : step === 3 ? (
          <CheckoutCard
            type={type}
            token={token}
            setStep={setStep}
            setCustomer={setCustomer}
            customer={customer}
            order={order}
          />
        ) : (
          step === 4 && (
            <CheckoutPix
              type={type}
              token={token}
              setStep={setStep}
              setCustomer={setCustomer}
              customer={customer}
              order={order}
            />
          )
        ))}
    </>
  )
}

export default CheckoutPayment
