import { useEffect, useState } from 'react'
import { useLocation, matchPath } from 'react-router'
import { Link as RouterLink } from 'react-router-dom'
import { makeStyles, Box, Typography, ButtonBase } from '@material-ui/core'
import styles from './styles'
import { ArrowLeft, ArrowRight } from 'react-feather'
import constants from 'constants/index'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'

import logoSmallSuperfin from 'images/logosmallsuperfin.png'
import logoLargeSuperfin from 'images/logolargesuperfin.png'

import logoLargeLovers from 'images/leadlovers-marca.svg'
import logoSmallLovers from 'images/logosmalllovers.png'

import { routes } from 'Routes'

const useStyles = makeStyles(styles)

const NavBar = ({ openMobile, onMobileClose, logo }) => {
  const classes = useStyles()
  const location = useLocation()
  const history = useHistory()

  const [active, setActive] = useState(false)

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose()
    }
  }, [location.pathname, active, openMobile, onMobileClose])

  const handleActive = (status) => {
    setActive(status)
  }

  const navigateTo = (route) => {
    history.push(route)
  }

  const selected = (href) =>
    matchPath(location.pathname, {
      path: href,
      exact: true,
    })

  return (
    <Box
      display="flex"
      flexDirection="column"
      className={clsx({
        [classes.activeMenu]: active,
        [classes.menu]: !active,
      })}
    >
      <RouterLink to={routes.home}>
        {logo === 'leadlovers' ? (
          <img
            alt="Logo"
            src={!active ? logoSmallLovers : logoLargeLovers}
            className={classes.logo}
            style={{ width: !active ? '40px' : '140px' }}
            width="100%"
          />
        ) : (
          <img
            alt="Logo"
            src={!active ? logoSmallSuperfin : logoLargeSuperfin}
            className={classes.logo}
            style={{ width: !active ? '40px' : '140px' }}
            width="100%"
          />
        )}
      </RouterLink>
      <Box width="100%">
        {constants.menu.ITEMS &&
          constants.menu.ITEMS.map((item) => (
            <Box key={item.title} display="flex" alignItems="center">
              <ButtonBase
                className={clsx(classes.button, {
                  [classes.buttonExtended]: active,
                  [classes.active]: selected(item.href),
                })}
                classes={{
                  label: clsx({
                    [classes.buttonLabel]: active,
                  }),
                }}
                fullWidth={active}
                onClick={() => navigateTo(item.href)}
              >
                {item.icon}
                {active && (
                  <Typography variant="body2" className={classes.title}>
                    {item.title}
                  </Typography>
                )}
              </ButtonBase>
            </Box>
          ))}
      </Box>
      <ButtonBase
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        className={clsx({
          [classes.buttonExtended]: active,
          [classes.menuButton]: active,
          [classes.button]: !active,
        })}
        onClick={() => {
          handleActive(!active)
        }}
        fullWidth
      >
        {!active ? <ArrowRight size={16} /> : <ArrowLeft size={16} />}
      </ButtonBase>
    </Box>
  )
}

export default NavBar
