import { useState } from 'react'

import {
  Box,
  makeStyles,
  Grid,
  Typography,
  Card,
  CardContent,
  Collapse,
  Paper,
  IconButton,
} from '@material-ui/core'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { StepperOrder } from '../../components'

import {
  User as UserIcon,
  Loader as LoaderIcon,
  DollarSign as DollarSignIcon,
  MapPin as MapPinIcon,
} from 'react-feather'

import { LoadingFeedback, LabelView } from 'components'

import styles from './styles'
import clsx from 'clsx'
import helpers from 'helpers'
import constants from 'constants/index'

const useStyles = makeStyles(styles)

const OrderInfos = ({ isLoading, order }) => {
  const classes = useStyles()
  const [expandedCard, setExpandedCard] = useState(null)

  const handleExpandClick = (index) => {
    setExpandedCard(expandedCard === index ? null : index)
  }

  const paymentPaid = order?.payments?.filter((pay) => pay.status === 'PAID')[0]
  const sumProductValues = (products) => {
    if (!products || products.length === 0) {
      return 0
    }

    const totalValue = products.reduce((acc, product) => {
      if (product.name !== 'Desconto') {
        const price = parseFloat(product.price)
        const quantity = parseInt(product.quantity, 10)

        if (!isNaN(price) && !isNaN(quantity)) {
          acc += price * quantity
        }
      }

      return acc
    }, 0)

    return totalValue
  }

  const getPaymentMethod = (order) => {
    if (order?.payments === undefined || order?.payments?.length === 0) {
      return 'Indefinido'
    }

    return (
      paymentPaid?.paymentMethod?.description ||
      order?.payments[0]?.paymentMethod?.description
    )
  }

  function creditInfo() {
    if (
      paymentPaid &&
      constants.paymentMethods.CARD_METHODS.includes(
        paymentPaid?.paymentMethod?.method,
      )
    ) {
      if (paymentPaid?.splitNumber === 1) {
        return (
          <Box mt={1} mb={1}>
            <LabelView
              label="Data de creditação"
              value={helpers.formatters.date(order?.firstCreditDate)}
            />
          </Box>
        )
      } else {
        return (
          <>
            <Box mt={1} mb={1}>
              <LabelView
                label="Data de creditação 1ª parcela"
                value={helpers.formatters.date(order?.firstCreditDate)}
              />
            </Box>
            <Box mt={1} mb={1}>
              <LabelView
                label="Data de creditação última parcela"
                value={helpers.formatters.date(order?.lastCreditDate)}
              />
            </Box>
          </>
        )
      }
    }
  }

  const pendingPayments = (payments) => {
    let pending = 0
    payments?.forEach((payment) => {
      if (constants.payments.PENDING_STATUSES.includes(payment.status)) {
        pending++
      }
    })

    return pending
  }

  // const getTotalReceived = (payments) => {
  //   let total = 0
  //   payments?.forEach((payment) => {
  //     if (payment.status === constants.payments.STATUSES.PAID_STATUS) {
  //       total += payment.amount
  //     }
  //   })

  //   return helpers.formatters.floatToMoney(total, true)
  // }

  const approvedPayments = (payments) => {
    let approved = 0
    payments?.forEach((payment) => {
      if (payment.status === constants.payments.STATUSES.PAID_STATUS) {
        approved++
      }
    })

    return approved
  }

  const truncateText = (text, lenghtText) => {
    let result = null

    if (text.length > lenghtText) {
      result = text.substring(0, lenghtText) + '...'
    }
    return result ? result : text
  }

  return (
    <>
      <LoadingFeedback open={isLoading} />
      <Grid container spacing={8}>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Paper elevation={0} style={{ borderRadius: '20px', padding: '8px' }}>
            <Box p={2} display="flex" flexDirection="row" alignItems="center">
              <Box
                mr={2}
                className={classes.circle}
                style={{ backgroundColor: '#EECDFF' }}
              >
                <DollarSignIcon size={20} color="#a804bc" />
              </Box>
              <Box>
                <Typography color="textSecondary" variant="body2">
                  Total
                </Typography>
                <Typography color="textPrimary" variant="body1">
                  R$
                  {helpers.formatters.floatToMoney(
                    sumProductValues(order?.products),
                  )}
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Paper elevation={0} style={{ borderRadius: '20px', padding: '8px' }}>
            <Box p={2} display="flex" flexDirection="row" alignItems="center">
              <Box
                mr={2}
                className={classes.circle}
                style={{ backgroundColor: '#e2f4f9' }}
              >
                <LoaderIcon size={25} color="#235078" />
              </Box>
              <Box>
                <Typography color="textSecondary" variant="body2">
                  Pag. pendentes
                </Typography>
                <Typography color="textPrimary" variant="body1">
                  {pendingPayments(order?.payments)}
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Paper elevation={0} style={{ borderRadius: '20px', padding: '8px' }}>
            <Box p={2} display="flex" flexDirection="row" alignItems="center">
              <Box
                mr={2}
                className={classes.circle}
                style={{ backgroundColor: '#FFE6BF' }}
              >
                <DollarSignIcon size={20} color="#FFAC2F" />
              </Box>
              <Box>
                <Typography color="textSecondary" variant="body2">
                  Pago
                </Typography>
                <Typography color="textPrimary" variant="body1">
                  {approvedPayments(order?.payments)}
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Grid>
        {/* <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box mt={2}>
            <Typography color="textPrimary" variant="body2">
              Valor cobrado:
              {order?.amount &&
                helpers.formatters.floatToMoney(order?.amount, true)}
            </Typography>
            <Typography color="textPrimary" variant="body2">
              Taxa de desconto na Transação:
              {order?.amountReceivable &&
                helpers.formatters.floatToMoney(
                  order?.amount - order?.amountReceivable,
                  true,
                )}
            </Typography>
          </Box>
          {order?.amountReceivable ? (
            <Box display="flex" alignItems="flex-end">
              <Typography color="textPrimary" variant="h5">
                Valor com desconto da taxa:
              </Typography>
              <Typography
                className={clsx(classes.infoText, classes.textSuccess)}
                color="textPrimary"
              >
                +{' '}
                {helpers.formatters.floatToMoney(order?.amountReceivable, true)}
              </Typography>
              <Box ml={1}></Box>
            </Box>
          ) : (
            <Box display="flex" alignItems="flex-end" mb={2}>
              <Typography
                color="textPrimary"
                className={clsx(classes.infoText)}
              >
                Valor com desconto da taxa:{' '}
                {helpers.formatters.floatToMoney(order?.amount, true)}
              </Typography>
            </Box>
          )}
        </Grid> */}
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography color="textPrimary" variant="h6">
            Informações de pagamento
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box mb={2}>
            <Paper elevation={0} style={{ borderRadius: '20px' }}>
              <StepperOrder
                orderStatus={order?.status}
                alternativeLabel={false}
              />
            </Paper>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5}>
          <Paper elevation={0} style={{ borderRadius: '20px', padding: '8px' }}>
            <Box p={2}>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box
                      className={classes.circle}
                      style={{ backgroundColor: '#a804bc' }}
                      p={2}
                    >
                      <UserIcon size={32} color="#FFF" />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography
                    color="textPrimary"
                    variant="body1"
                    align="center"
                  >
                    {order?.buyer?.name ? order?.buyer?.name : 'pendente'}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography
                    color="textPrimary"
                    variant="body1"
                    align="center"
                  >
                    {order?.buyer?.document
                      ? helpers.formatters.document(order?.buyer?.document)
                      : 'pendente'}
                  </Typography>
                </Grid>
                {order?.buyer?.address?.city && (
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="center"
                      p={1}
                      my={2}
                      style={{
                        backgroundColor: '#EECDFF',
                        borderRadius: '40px',
                      }}
                    >
                      <Box mr={1}>
                        <MapPinIcon size={20} color="#a804bc" />
                      </Box>

                      <Typography variant="body1" style={{ color: '#a804bc' }}>
                        {`${order?.buyer?.address?.city},
                                        ${order?.buyer?.address?.state}`}
                      </Typography>
                    </Box>
                  </Grid>
                )}

                {order?.buyer && (
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <LabelView label="E-mail" value={order?.buyer?.email} />
                  </Grid>
                )}
              </Grid>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={7} lg={7}>
          <Paper elevation={0} style={{ borderRadius: '20px', padding: '8px' }}>
            <Box p={2}>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <LabelView
                    label="Token de transação"
                    value={order?.token ? order?.token : 'pendente'}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <LabelView
                    label="Código de cobrança"
                    value={order?.code ? order?.code : 'pendente'}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <LabelView
                    label="Método de pagamento"
                    value={
                      order?.payments ? getPaymentMethod(order) : 'pendente'
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <LabelView
                    label="Data criação"
                    value={helpers.formatters.date(order?.createdAt)}
                  />
                </Grid>
                {paymentPaid?.approvalDate && (
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <LabelView
                      label="Data de aprovação"
                      value={helpers.formatters.date(paymentPaid?.approvalDate)}
                    />
                  </Grid>
                )}
                {paymentPaid?.splitNumber && (
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <LabelView
                      label="Quantidade de parcelas"
                      value={paymentPaid?.splitNumber}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  {creditInfo()}
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography color="textPrimary" variant="h6">
            Endereço
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper elevation={0} style={{ borderRadius: '20px', padding: '8px' }}>
            <Box p={2}>
              <Grid container>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <LabelView
                    label="CEP"
                    value={
                      order?.buyer?.address?.postalCode
                        ? order?.buyer?.address?.postalCode
                        : 'pendente'
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <LabelView
                    label="Estado"
                    value={
                      order?.buyer?.address?.state
                        ? order?.buyer?.address?.state
                        : 'pendente'
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  <LabelView
                    label="Cidade"
                    value={
                      order?.buyer?.address?.city
                        ? order?.buyer?.address?.city
                        : 'pendente'
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <LabelView
                    label="Bairro"
                    value={
                      order?.buyer?.address?.neighborhood
                        ? order?.buyer?.address?.neighborhood
                        : 'pendente'
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <LabelView
                    label="Rua"
                    value={
                      order?.buyer?.address?.street
                        ? order?.buyer?.address?.street
                        : 'pendente'
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <LabelView
                    label="Número"
                    value={
                      order?.buyer?.address?.number
                        ? order?.buyer?.address?.number
                        : 'pendente'
                    }
                  />
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography color="textPrimary" variant="h6">
            Produto/Serviço
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {order?.products && (
            <Grid container spacing={2}>
              {order.products.map((value, index) => (
                <Grid item lg={4} md={4} sm={12} xs={12} key={index}>
                  <Card style={{ borderRadius: '20px' }} elevation={0}>
                    <Box
                      style={{
                        backgroundColor: '#FFAC2F',
                        height: '120px',
                        width: '100%',
                      }}
                    ></Box>
                    <CardContent>
                      <Typography variant="body1" color="textPrimary">
                        {value.name}
                      </Typography>
                      <Typography variant="body1" color="textSecondary">
                        {truncateText(value.description, 40)}
                      </Typography>
                      <Typography variant="body1" color="textPrimary">
                        R$ {value.price}
                      </Typography>
                      <Typography variant="body1" color="textPrimary">
                        Quantidade ({value.quantity})
                      </Typography>
                      <IconButton
                        className={clsx(classes.expand, {
                          [classes.expandOpen]: expandedCard === index,
                        })}
                        onClick={() => handleExpandClick(index)}
                        aria-expanded={expandedCard === index}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                    </CardContent>
                    <Collapse
                      in={expandedCard === index}
                      timeout="auto"
                      unmountOnExit
                    >
                      <CardContent>
                        <Typography paragraph>{value.name}</Typography>
                        <Typography paragraph>{value.description}</Typography>
                      </CardContent>
                    </Collapse>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default OrderInfos
