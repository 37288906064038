import {
  Briefcase as BriefcaseIcon,
  Check as CheckIcon,
  DollarSign as DollarSignIcon,
  Send as SendIcon,
  FileText as FileTextIcon,
  Loader as LoaderIcon,
  Link as LinkIcon,
  Layers as LayersIcon,
  ArrowDownCircle as ArrowDownCircleIcon,
  PieChart as PieChartIcon,
  Plus as PlusIcon,
  Settings as SettingsIcon,
  RefreshCcw as RefreshCcwIcon,
  Play as PlayIcon,
} from 'react-feather'

const ITEMS = {
  charges: {
    title: 'Destaque',
    chargesMain: {
      name: 'Cobranças',
      icon: <DollarSignIcon size={16} color="#005534" />,
      color: '#005534',
      background: '#dff4ee',
      route: '/charges',
      view: true,
    },
    chargesNew: {
      name: 'C. Avulsa',
      icon: <LinkIcon size={16} color="#342182" />,
      color: '#342182',
      background: '#f0e5f4',
      route: '/charges/new',
      view: true,
    },
    subscriptionNew: {
      name: 'C. Recorrente',
      icon: <RefreshCcwIcon size={16} color="#342182" />,
      color: '#342182',
      background: '#f0e5f4',
      route: '/subscriptions/new',
      view: true,
    },
    QuickGuide: {
      name: 'Guia Rápido',
      icon: <PlayIcon size={16} color="#342182" />,
      color: '#342182',
      background: '#f0e5f4',
      route: '/quickGuide',
      view: true,
    },
  },
  orders: {
    title: 'Transações',
    orderMain: {
      name: 'Transações',
      icon: <FileTextIcon size={16} color="#103556" />,
      color: '#103556',
      background: '#e2f4f9',
      route: '/orders',
      view: true,
    },
    orderR: {
      name: 'Recebido',
      icon: <CheckIcon size={16} color="#00A900" />,
      color: '#00A900',
      background: '#9FF89F',
      route: '/orders?status=PAID',
      view: true,
    },
    orderP: {
      name: 'Pendentes',
      icon: <SendIcon size={16} color="#342182" />,
      color: '#342182',
      background: '#f0e5f4',
      route: '/orders?status=NOT_PAID',
      view: true,
    },
    balanceControls: {
      name: 'Recebíveis',
      icon: <LoaderIcon size={16} color="#005534" />,
      color: '#005534',
      background: '#dff4ee',
      route: '/balance-controls',
      view: true,
    },
  },
  business: {
    title: 'Meu negócio',
    orderMain: {
      name: 'Relatório de C. Avulsa',
      icon: <PieChartIcon size={16} color="#005534" />,
      color: '#005534',
      background: '#dff4ee',
      route: '/report',
      view: true,
    },
  },
  subscriptions: {
    title: 'Assinatura',
    subscriptionsMain: {
      name: 'Assinaturas',
      icon: <DollarSignIcon size={16} color="#103556" />,
      color: '#103556',
      background: '#e2f4f9',
      route: '/subscriptions',
      view: true,
    },
    subscriptionsNew: {
      name: 'Nova Assinatura',
      icon: <DollarSignIcon size={16} color="#103556" />,
      color: '#103556',
      background: '#e2f4f9',
      route: '/subscriptions/new',
      view: true,
    },
  },
  plans: {
    title: 'Planos',
    plansMain: {
      name: 'Planos',
      icon: <LayersIcon size={16} color="#103556" />,
      color: '#103556',
      background: '#e2f4f9',
      route: '/plans',
      view: true,
    },
    plansNew: {
      name: 'Novo Plano',
      icon: <PlusIcon size={16} color="#103556" />,
      color: '#103556',
      background: '#e2f4f9',
      route: '/plans/new',
      view: true,
    },
  },
  others: {
    title: 'Outros',
    withDraws: {
      name: 'Sacar',
      icon: <ArrowDownCircleIcon size={16} color="#103556" />,
      color: '#103556',
      background: '#e2f4f9',
      route: '/withdraws/new',
      view: true,
    },
    bankAccounts: {
      name: 'Bancos',
      icon: <BriefcaseIcon size={16} color="#103556" />,
      color: '#103556',
      background: '#e2f4f9',
      route: '/settings/bank-accounts',
      view: true,
    },
    settings: {
      name: 'Configurações',
      icon: <SettingsIcon size={16} color="#103556" />,
      color: '#103556',
      background: '#e2f4f9',
      route: '/settings',
      view: true,
    },
  },
}

const functionalities = {
  ITEMS,
}

export default functionalities
