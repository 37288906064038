const styles = (theme) => ({
  boxButton: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '20px',
    width: '100%',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  boxRoot: {
    borderRadius: '20px',
    width: '100%',
  },
  boxFullHeight: {
    height: '100%',
  },
  iconBox: {
    backgroundColor: '#e2f4f9',
    borderRadius: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
  monthSalesIconBox: {
    borderRadius: '20px',
    backgroundColor: '#e2f4f9',
  },
  backgroundColorBlue: {
    backgroundColor: '#e2f4f9',
  },
})

export default styles
