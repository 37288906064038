const styles = (theme) => ({
  table: {
    borderCollapse: 'separate',
    // borderSpacing: '0px 8px',
  },
  menuText: {
    marginBottom: '8px',
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  menuItem: {
    // fontSize: '12px',
    color: '#2b76b9',
    '& .MuiTreeItem-label': {
      fontSize: '14px',
    },
    '&.MuiTreeItem-root': {
      marginBottom: '8px',
    },
    '&.MuiTreeItem-root > .MuiTreeItem-content:hover': {
      background: 'transparent',
      borderLeft: '2px solid #00A900',
    },
    '&.MuiTreeItem-root > .MuiTreeItem-content:hover > .MuiTreeItem-label': {
      background: 'transparent',
    },
    '@media (hover: none)': {
      backgroundColor: 'transparent',
    },
  },
})

export default styles
