import { Box, makeStyles, Typography, Button } from '@material-ui/core'
import { Copy as CopyIcon } from 'react-feather'
import styles from './styles'
import { QRCodeSVG } from 'qrcode.react'

import checkoutTheme from 'theme/checkoutTheme'

const useStyles = makeStyles(styles)

const PixInfo = ({ pix }) => {
  const classes = useStyles()

  return (
    <Box>
      <Box display="flex" justifyContent="center">
        <Box className={classes.qrCodeBox}>
          <QRCodeSVG value={pix?.qrCode} size="180" />
        </Box>
      </Box>
      <Box>
        <Box
          display="flex"
          flexDirection="column"
          mt={checkoutTheme.spacing(8)}
        >
          <Typography variant="h6" color="textPrimary">
            Método de pagamento
          </Typography>
          <Typography variant="body1" color="textPrimary">
            PIX
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          className={classes.pixCodeBox}
          py={checkoutTheme.spacing(4)}
          px={checkoutTheme.spacing(4)}
          mt={checkoutTheme.spacing(8)}
        >
          <Typography
            variant="body1"
            color="textPrimary"
            className={classes.pixCode}
          >
            {pix?.qrCode}
          </Typography>
          <Button
            px={checkoutTheme.spacing(8)}
            variant="contained"
            color="primary"
            className={classes.buttonCopy}
            onClick={() => {
              navigator.clipboard.writeText(pix?.qrCode)
            }}
          >
            <Box display="flex">
              <CopyIcon
                size={checkoutTheme.spacing(8)}
                ml={checkoutTheme.spacing(4)}
                onClick={() => {
                  navigator.clipboard.writeText(pix?.qrCode)
                }}
              />
            </Box>
            Copiar
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default PixInfo
