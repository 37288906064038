import { Box } from '@material-ui/core'
import { LoadingButton } from 'theme/checkoutTheme/components'
import { DialogPix, PixInfo } from './components'

import { useState } from 'react'
import PaymentMethodSelect from '../PaymentMethodSelect'
import ComplianceText from '../ComplianceText/ComplianceText'

import checkoutTheme from 'theme/checkoutTheme'

const CheckoutPix = ({ type, token, setStep, customer, order }) => {
  const [pix, setPix] = useState({})
  const [open, setOpen] = useState(false)
  const [openPix, setOpenPix] = useState(false)

  return (
    <>
      <PaymentMethodSelect paymentMethodId={2} setStep={setStep} />
      {openPix ? (
        <>
          <PixInfo pix={pix} order={order} />
        </>
      ) : (
        <>
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            mt={checkoutTheme.spacing(10)}
          >
            <LoadingButton
              text="Visualizar o PIX"
              onClick={() => {
                setOpen(true)
              }}
            />
          </Box>
        </>
      )}
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        my={checkoutTheme.spacing(10)}
      >
        <LoadingButton
          text="VOLTAR"
          onClick={() => {
            setStep(1)
          }}
          variant="outlined"
        />
      </Box>
      <ComplianceText buttonTitle="Gerar Pix" />
      <DialogPix
        open={open}
        setOpen={setOpen}
        type={type}
        token={token}
        order={order}
        customer={customer}
        setPix={setPix}
        setOpenPix={setOpenPix}
      />
    </>
  )
}

export default CheckoutPix
