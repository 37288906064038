const styles = (theme) => ({
  root: {
    width: '100%',
    backgroundColor: 'transparent',
  },
  media: {
    height: 140,
    backgroundColor: '#FFF',
    borderRadius: '20px',
  },
})

export default styles
