import { Box, Typography } from '@material-ui/core'

import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

import TreeView from '@material-ui/lab/TreeView'
import TreeItem from '@material-ui/lab/TreeItem'

import { routes } from 'Routes'

import { useHistory } from 'react-router-dom'

import styles from './styles'

const useStyles = makeStyles(styles)

const Menu = () => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
      }}
    >
      <Typography
        variant="body2"
        color="textPrimary"
        style={{
          fontWeight: 'bold',
          marginLeft: '22px',
          marginBottom: '8px',
          textTransform: 'uppercase',
        }}
      >
        Começando
      </Typography>
      <TreeView
        // className={classes.root}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
      >
        <TreeItem
          nodeId="1"
          label="Introdução"
          className={classes.menuItem}
          onClick={() => history.push(routes.documentation.introduction)}
        />
        <TreeItem nodeId="2" label="Ambientes" className={classes.menuItem}>
          <TreeItem
            nodeId="3"
            label="Sandbox"
            className={classes.menuItem}
            onClick={() => history.push(routes.documentation.sandbox)}
          />
          <TreeItem
            nodeId="4"
            label="Produção"
            className={classes.menuItem}
            onClick={() => history.push(routes.documentation.production)}
          />
        </TreeItem>
        <TreeItem
          nodeId="5"
          label="Resposta HTTP"
          className={classes.menuItem}
          onClick={() => history.push(routes.documentation.httpCode)}
        />
        <TreeItem nodeId="6" label="APIs" className={classes.menuItem}>
          <TreeItem
            nodeId="7"
            label="Autenticação"
            className={classes.menuItem}
            onClick={() => history.push(routes.documentation.autentication)}
          />
          <TreeItem
            nodeId="8"
            label="Transações"
            className={classes.menuItem}
            onClick={() => history.push(routes.documentation.order)}
          />
          <TreeItem
            nodeId="9"
            label="Cobrança avulsa"
            className={classes.menuItem}
            onClick={() => history.push(routes.documentation.oneTimeCharge)}
          />
          <TreeItem
            nodeId="10"
            label="Pagamentos"
            className={classes.menuItem}
            onClick={() => history.push(routes.documentation.payments)}
          />
          <TreeItem
            nodeId="11"
            label="Minhas informações"
            className={classes.menuItem}
            onClick={() => history.push(routes.documentation.myInformation)}
          />
          <TreeItem
            nodeId="12"
            label="Cobrança Recorrente"
            className={classes.menuItem}
            onClick={() =>
              history.push(routes.documentation.recurringBillingSummary)
            }
          />
        </TreeItem>
      </TreeView>
      <Typography
        variant="body2"
        color="textPrimary"
        style={{
          fontWeight: 'bold',
          marginLeft: '22px',
          marginTop: '20px',
          marginBottom: '8px',
          textTransform: 'uppercase',
        }}
      >
        Recorrência
      </Typography>
      <TreeView
        // className={classes.root}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
      >
        <TreeItem
          nodeId="14"
          label="Introdução"
          className={classes.menuItem}
          onClick={() =>
            history.push(routes.documentation.introductionSubscriptions)
          }
        />
        <TreeItem
          nodeId="15"
          label="Planos"
          className={classes.menuItem}
          onClick={() => history.push(routes.documentation.plans)}
        />
        <TreeItem
          nodeId="16"
          label="Assinaturas"
          className={classes.menuItem}
          onClick={() => history.push(routes.documentation.subscriptions)}
        />
        <TreeItem
          nodeId="17"
          label="Clientes"
          className={classes.menuItem}
          onClick={() => history.push(routes.documentation.customers)}
        />
        <TreeItem
          nodeId="18"
          label="Faturas"
          className={classes.menuItem}
          onClick={() => history.push(routes.documentation.invoice)}
        />
      </TreeView>
    </Box>
  )
}

export default Menu
