import PropTypes from 'prop-types'

import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  makeStyles,
} from '@material-ui/core'

import useSnackbar from 'hooks/useSnackbar'
import * as service from 'service'

import styles from './styles'

const useStyles = makeStyles(styles)

const DialogBillet = ({
  open,
  setOpen,
  type,
  token,
  order,
  customer,
  setBillet,
  setOpenBillet,
}) => {
  const classes = useStyles()
  const snackbar = useSnackbar()

  const onSubmit = async () => {
    try {
      let response = null
      switch (type) {
        case 'plan':
          response = await service.intermediador.payments.full({
            order: orderParams(order?.payment, customer, token, type),
            seller_auth_token: order?.seller?.token,
          })
          break
        case 'product':
          response = await service.intermediador.payments.create({
            order: orderParams(order?.payment, customer, token, type),
            seller_auth_token: order?.seller?.token,
          })
          break
        default:
          response = await service.intermediador.payments.pay({
            order: orderParams(order?.payment, customer, token, type),
            seller_auth_token: order?.seller?.token,
          })
          break
      }

      setBillet(
        response?.data?.payment?.billet || response?.data?.order?.billet,
      )
      setOpenBillet(true)
      setOpen(false)

      snackbar.open({
        message: `${`Boleto gerado com sucesso!`}`,
        variant: 'success',
      })
    } catch (error) {
      setOpen(false)
      snackbar.open({
        message:
          error?.response?.data?.error?.message ||
          `Ocorreu um erro ao criar ao criar o boleto`,
        variant: 'error',
      })
    }
  }

  const orderParams = (order, customer, token, type) => {
    const params = {
      orderType: type,
      token: token,
      code: order?.code || generateCode(order, type),
      payment: {
        method: 6,
        billet: {
          description: order?.description || 'n/a',
          expiration: order?.expirationDate,
        },
      },
      products: formatProducts(order?.products),
      customer: formatCustomer(customer),
    }

    return params
  }

  const generateCode = (order, type) => {
    if (type === 'product') {
      let productToken = order?.products?.[0]?.['productToken']
      let randomNumber = Math.floor(Date.now() * Math.random())
      return `P#${productToken}#${randomNumber}`
    }
  }

  const formatProducts = (products) => {
    products?.forEach((prod) => {
      delete prod['startsAt']
      delete prod['endsAt']
      delete prod['type']
    })

    return products
  }

  const formatCustomer = (customer) => {
    const formattedCustomer = {
      birthDate: customer?.birthDate,
      document: customer?.document
        .split('-')
        .join('')
        .split('.')
        .join('')
        .split('/')
        .join(''),
      documentType: customer?.documentType,
      email: customer?.email,
      name: customer?.name,
      address: {
        city: customer?.address?.city,
        neighborhood: customer?.address?.neighborhood,
        number: customer?.address?.number,
        postalCode: customer?.address?.postalCode?.replace('-', ''),
        state: customer?.address?.state,
        street: customer?.address?.street,
      },
    }

    return formattedCustomer
  }

  return (
    <Dialog
      className={classes.modal}
      maxWidth="lg"
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogTitle>Você deseja pagar pelo boleto?</DialogTitle>
      <DialogActions>
        <Button type="button" variant="outlined" onClick={() => setOpen(false)}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          type="submit"
          color="primary"
          onClick={onSubmit}
        >
          Sim
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DialogBillet.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  order: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  setBillet: PropTypes.func.isRequired,
  setOpenBillet: PropTypes.func.isRequired,
}

export default DialogBillet
