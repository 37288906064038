import { Grid, Box, Typography, TextField, Button } from '@material-ui/core'
import { useForm, Controller } from 'react-hook-form'

import useSnackbar from 'hooks/useSnackbar'
import { useHistory } from 'react-router-dom'

import { routes } from 'Routes'
import schema from './schema'
import constants from 'constants/index'
import * as service from 'service'

const Notifications = ({ accountUrlPaid }) => {
  const history = useHistory()
  const snackbar = useSnackbar()

  const { control, handleSubmit, errors } = useForm({
    validationSchema: schema,
    defaultValues: {
      endpoint: accountUrlPaid?.url || '',
    },
  })

  const onSubmit = async (data) => {
    try {
      await service.intermediador.accountUrls.create({
        accountUrls: [
          {
            url: data.endpoint,
            action: 'PAID',
          },
        ],
      })

      snackbar.open({
        message: `Notificação adicionada com sucesso`,
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message: 'Erro ao salvar notificação',
        variant: 'error',
      })
    }
  }

  return (
    <>
      <form id="notificationForm" onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item sm={12} xs={12}>
            <Typography color="textPrimary" variant="body1">
              Adicione o endpoint para receber as notificações
            </Typography>
          </Grid>
          <Grid item sm={12} xs={12}>
            <Controller
              as={
                <TextField
                  label="Endpoint"
                  type="text"
                  color="textPrimary"
                  error={!!errors.endpoint}
                  helperText={errors?.endpoint?.message}
                  fullWidth
                />
              }
              control={control}
              name="endpoint"
              mode="onBlur"
              size="small"
            />
          </Grid>
          <Grid item sm={12} xs={12}>
            <Typography color="textPrimary" variant="body1">
              O que vou receber ao adicionar este endpoint
            </Typography>
            <Box mt={1}>
              <Typography color="textPrimary" variant="body2">
                Ao cadastrar o seu endpoint. Você receberá todas as notificações
                sobre as solicitações de pagamento e os andamentos.
              </Typography>
            </Box>
          </Grid>
          <Grid item sm={12} xs={12}>
            <Typography color="textPrimary" variant="body1">
              Como é a estrutura do json?
            </Typography>
            <Box mt={1}>
              <TextField
                type="text"
                color="textPrimary"
                multiline
                fullWidth
                disabled
                maxRows={24}
                value={JSON.stringify(
                  constants.notifications.JSON_EXAMPLE,
                  null,
                  2,
                )}
              />
            </Box>
          </Grid>
        </Grid>
      </form>
      <Box mt={3} display="flex" justifyContent="flex-start">
        <Box pr={1}>
          <Button
            type="button"
            onClick={() => history.push(routes.settings.main)}
          >
            Voltar
          </Button>
        </Box>
        <Box>
          <Button
            form="notificationForm"
            color="primary"
            variant="contained"
            type="submit"
          >
            Salvar
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default Notifications
