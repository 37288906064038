const styles = (theme) => ({
  cardBottom: {
    backgroundColor: '#272c3a',
    color: theme.palette.white,
    marginTop: theme.spacing(7),
  },
  payText: {
    fontWeight: 300,
  },
})

export default styles
