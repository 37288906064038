import Image from 'images/backgroundplans.png'

const styles = (theme) => ({
  infoBox: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(2),
    padding: theme.spacing(2),
  },
  iconBox: {
    width: '60px',
    height: '60px',
    backgroundColor: '#235078',
    borderRadius: theme.spacing(2),
    color: theme.palette.white,
  },
  transaction: {
    backgroundColor: theme.palette.success.main,
  },
  infoText: {
    fontSize: '17px',
    fontWeight: '500',
  },
  textSuccess: {
    color: theme.palette.success.main,
  },
  text: {
    color: 'rgba(255, 255, 255, 1)',
    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'grayscale',
    zIndex: 3,
  },
  marginRight: {
    marginRight: theme.spacing(2),
  },
  colorPendents: {
    backgroundColor: theme.palette.info.main,
  },
  table: {
    borderCollapse: 'separate',
    borderSpacing: '0px 8px',
  },
  row: {
    display: 'flex',
    height: '60px',
  },
  plansBanner: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    backgroundImage: `url(${Image})`,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 20,
    backgroundSize: 'cover',
    width: '100%',
    height: '200px',
  },
  circle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    width: 50,
    height: 50,
    [theme.breakpoints.between('xs', 'sm')]: {
      width: 40,
      height: 40,
    },
    zIndex: 1,
  },
  bgColor: {
    position: 'absolute',
    top: 0,
    left: 0,
    background:
      'linear-gradient(to bottom right, rgba(51, 159, 255, 0.5), rgba(140, 0, 226, 0.5))',
    width: '100%',
    height: '100%',
    borderRadius: 20,
    zIndex: 1,
  },
  boxDescription: {
    backgroundColor: 'rgba(217, 217, 217, 0.4)',
    width: '100%',
    padding: 12,
    backdropFilter: 'blur(20px)',
    zIndex: 2,
    borderRadius: '20px 20px 20px 20px',
  },
})

export default styles
