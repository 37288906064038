import { useState, Children } from 'react'
import { useForm } from 'react-hook-form'

import {
  Box,
  Button,
  Drawer,
  Typography,
  makeStyles,
  TextField,
} from '@material-ui/core'

import { DatePicker } from 'components'

import { Calendar } from 'react-feather'

import moment from 'moment'
import humps from 'humps'

import styles from './styles'
import clsx from 'clsx'

import theme from 'theme'

const useStyles = makeStyles(styles)

const Filters = ({ filter, children }) => {
  const classes = useStyles()
  const [values, setValues] = useState(filter.filters)
  const { register, handleSubmit } = useForm()
  const [minDates, setMinDates] = useState({})
  const [maxDates, setMaxDates] = useState({})

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  const onSubmit = () => {
    filter.setFilters(values)
    filter.setDrawerOpen(false)
  }

  const clearFilters = () => {
    children.map((field) => {
      return setValues({ [field.props.name]: '' })
    })
    filter.setFilters('')
    filter.setDrawerOpen(false)
  }

  const setDateInterval = (date, props) => {
    const formattedProps = humps.camelizeKeys(props)
    const formattedDate = moment(date).format('YYYY-MM-DD')

    if (!formattedProps.dataMin && !formattedProps.dataMax) return

    if (formattedProps.dataMin) {
      return setMinDates({
        ...minDates,
        [formattedProps.dataTarget]: formattedDate,
      })
    }

    if (formattedProps.dataMax) {
      return setMaxDates({
        ...maxDates,
        [formattedProps.dataTarget]: formattedDate,
      })
    }
  }

  return (
    <Drawer
      anchor="right"
      open={filter.drawerOpen}
      onClose={() => filter.setDrawerOpen(false)}
    >
      <Box className={classes.root}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography variant="h6" gutterBottom>
            Filtros
          </Typography>
          {Children.toArray(children).map(
            (field, index) =>
              (field &&
                field.props.textfieldinput &&
                field.props.skip !== 'true' && (
                  <div key={index}>
                    <TextField
                      SelectProps={{ native: true }}
                      margin="dense"
                      className={classes.filterField}
                      fullWidth
                      value={values[field.props.name] || ''}
                      select={field.type === 'select'}
                      onChange={handleChange}
                      {...field.props}
                      inputProps={{
                        ref: register,
                        ...field.props.inputProps,
                      }}
                      key={index}
                    />
                  </div>
                )) ||
              (field && field.props.datepickerinput && (
                <div key={index}>
                  <DatePicker
                    margin="dense"
                    className={classes.filterField}
                    fullWidth
                    format="DD/MM/yyyy"
                    keyboardIcon={
                      <Calendar
                        size={20}
                        style={{ color: theme.palette.text.primary }}
                      />
                    }
                    minDate={minDates[field.props.name]}
                    minDateMessage="A data inicial não poser ser maior que a data final"
                    maxDate={maxDates[field.props.name]}
                    maxDateMessage="A data final não pode ser menor que a data inicial"
                    value={values[field.props.name] || null}
                    onChange={(e) => {
                      setDateInterval(e, field.props)
                      setValues({
                        ...values,
                        [field.props.name]: moment(e).utc(false).format(),
                      })
                    }}
                    {...field.props}
                    inputProps={{
                      ref: register,
                      ...field.props.inputProps,
                    }}
                    key={index}
                  />
                </div>
              )),
          )}
          <Button
            className={classes.filterField}
            color="default"
            fullWidth
            type="button"
            onClick={clearFilters}
          >
            Limpar Filtros
          </Button>

          <Button
            variant="contained"
            color="primary"
            type="submit"
            className={clsx(classes.filterButton, classes.filterField)}
            fullWidth
          >
            Filtrar
          </Button>
        </form>
      </Box>
    </Drawer>
  )
}

export default Filters
