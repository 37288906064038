import React, { useState } from 'react'
import {
  Box,
  Typography,
  Modal,
  Backdrop,
  Fade,
  makeStyles,
  Paper,
  ButtonBase,
} from '@material-ui/core'

import { Play } from 'react-feather'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))

const Video = ({ title, link, route = null, width, height }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Box
        style={{
          width: '220px',
        }}
        onClick={handleOpen}
      >
        <ButtonBase
          style={{
            width: '100%',
            backgroundColor: '#000930',
            height: '390px',
            borderRadius: '12px',
          }}
        >
          <Play color="#FFF" />
        </ButtonBase>
        <Typography variant="body1">{title}</Typography>
      </Box>
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <Paper>
              <iframe
                width={width}
                height={height}
                src={link}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </Paper>
          </Fade>
        </Modal>
      </div>
    </>
  )
}

export default Video
