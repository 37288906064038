import palette from 'theme/palette'

const MuiTableRow = {
  root: {
    backgroundColor: '#FFF',
  },
  head: {
    backgroundColor: palette.background.default,
  },
}

export default MuiTableRow
