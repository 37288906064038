const typography = () => ({
  typography: {
    htmlFontSize: 16,
    fontFamily:
      'Montserrat,Roboto,-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontFamily:
        'Montserrat,Roboto,-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
      fontWeight: 600,
      fontSize: '5.25rem',
      lineHeight: 1.167,
      letterSpacing: '-0.24px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      zIndex: 2,
    },
    h2: {
      fontFamily:
        'Montserrat,Roboto,-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
      fontWeight: 600,
      fontSize: '4rem',
      lineHeight: 1.2,
      letterSpacing: '-0.9px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      zIndex: 2,
    },
    h3: {
      fontFamily:
        'Montserrat,Roboto,-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
      fontWeight: 600,
      fontSize: '3rem',
      lineHeight: 1.167,
      letterSpacing: '-0.06px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      zIndex: 2,
    },
    h4: {
      fontFamily:
        'Montserrat,Roboto,-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
      fontWeight: 600,
      fontSize: '2.5rem',
      lineHeight: 1.235,
      letterSpacing: '-0.06px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      zIndex: 2,
    },
    h5: {
      fontFamily:
        'Montserrat,Roboto,-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
      fontWeight: 600,
      fontSize: '2rem',
      lineHeight: 1.334,
      letterSpacing: '-0.05px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      zIndex: 2,
    },
    h6: {
      fontFamily:
        'Montserrat,Roboto,-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
      fontWeight: 600,
      fontSize: '1.5rem',
      lineHeight: 1.6,
      letterSpacing: '-0.05px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      zIndex: 2,
    },
    subtitle1: {
      fontFamily: 'Montserrat',
      fontWeight: 400,
      fontSize: '1.125rem',
      lineHeight: 1.75,
      letterSpacing: '-0.05px',
      zIndex: 2,
    },
    subtitle2: {
      fontFamily: 'Montserrat',
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: 1.57,
      letterSpacing: '-0.05px',
      zIndex: 2,
    },
    body1: {
      fontFamily: 'Montserrat',
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 1.5,
      letterSpacing: '-0.04px',
      zIndex: 2,
    },
    body2: {
      fontFamily: 'Montserrat',
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: 1.43,
      letterSpacing: '0.2px',
      zIndex: 2,
    },
    button: {
      fontFamily: 'Montserrat',
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: 1.75,
      textTransform: 'uppercase',
      letterSpacing: '0.02857em',
      zIndex: 2,
    },
    caption: {
      fontFamily: 'Montserrat',
      fontWeight: 500,
      fontSize: '0.75rem',
      lineHeight: 0.9,
      zIndex: 2,
    },
    overline: {
      fontFamily: 'Montserrat',
      fontWeight: 500,
      fontSize: '0.625rem',
      lineHeight: 0.7,
      textTransform: 'uppercase',
      zIndex: 2,
    },
    fontStyle: 'normal',
  },
})

export default typography
