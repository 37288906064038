import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import LaptopIcon from '@material-ui/icons/Laptop'
import TvIcon from '@material-ui/icons/Tv'
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid'
import Grid from '@material-ui/core/Grid'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'

import checkoutTheme from 'theme/checkoutTheme'

const useStyles = makeStyles((checkoutTheme) => ({
  toggleContainer: {
    margin: checkoutTheme.spacing(2, 0),
  },
}))

const Responsive = ({ onClickTypeView }) => {
  const [formats, setFormats] = useState('md')

  const handleFormat = (event, newFormats) => {
    if (newFormats !== null) {
      onClickTypeView(newFormats)
      setFormats(newFormats)
    }
  }

  const classes = useStyles()

  return (
    <Grid container spacing={2}>
      <Grid item sm={12} md={6}>
        <div className={classes.toggleContainer}>
          <ToggleButtonGroup
            value={formats}
            exclusive
            onChange={handleFormat}
            aria-label="device"
          >
            <ToggleButton
              style={{
                color: checkoutTheme.palette.text.primary,
                borderColor: checkoutTheme.palette.text.secondary,
              }}
              value="md"
              aria-label="laptop"
            >
              <LaptopIcon />
            </ToggleButton>
            <ToggleButton
              style={{
                color: checkoutTheme.palette.text.primary,
                borderColor: checkoutTheme.palette.text.secondary,
              }}
              value="lg"
              aria-label="tv"
            >
              <TvIcon />
            </ToggleButton>
            <ToggleButton
              style={{
                color: checkoutTheme.palette.text.primary,
                borderColor: checkoutTheme.palette.text.secondary,
              }}
              value="xs"
              aria-label="phone"
            >
              <PhoneAndroidIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </Grid>
    </Grid>
  )
}

export default Responsive
