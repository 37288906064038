import { makeStyles, Typography, Box, Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import styles from './styles'

const useStyles = makeStyles(styles)

const BoxButton = ({
  iconName,
  nameButton,
  sizeIcon,
  textIconColor,
  backgroundColor,
  routes,
}) => {
  const classes = useStyles()
  const history = useHistory()

  const CircleComponent = ({ children, color }) => {
    return (
      <Box
        mb={2}
        className={classes.circle}
        style={{
          backgroundColor: color,
        }}
      >
        {children}
      </Box>
    )
  }

  const handleButtonClick = () => {
    history.push(routes)
  }

  return (
    <Button
      onClick={handleButtonClick}
      className={classes.boxButton}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'start',
        '&:hover': {
          cursor: 'pointer',
        },
      }}
    >
      <CircleComponent color={backgroundColor}>{iconName}</CircleComponent>
      <Typography
        variant="body2"
        className={classes.textButton}
        style={{ color: textIconColor, marginTop: '12px' }}
      >
        {nameButton}
      </Typography>
    </Button>
  )
}

export default BoxButton
