import React from 'react'
import { Controller } from 'react-hook-form'
import {
  Box,
  Typography,
  Grid,
  makeStyles,
  TextField,
  MenuItem,
  Select as SelectMain,
  FormControl,
  InputAdornment,
} from '@material-ui/core'
import { Select } from 'components'
import { DatePicker, LoadingButton } from 'theme/checkoutTheme/components'

import { useForm } from 'react-hook-form'
import schema from './schema'
import helpers from 'helpers'
import styles from './styles'
import constants from 'constants/index'

import { Info as InfoIcon } from 'react-feather'

import checkoutTheme from 'theme/checkoutTheme'

const useStyles = makeStyles(styles)

const estados = [
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MT',
  'MS',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO',
]

const CustomerForm = ({ setStep, setCustomer }) => {
  const classes = useStyles()
  const { control, handleSubmit, errors, setValue } = useForm({
    validationSchema: schema,
    defaultValues: {
      paymentMethod: '',
      name: '',
      email: '',
      document: '',
      documentType: '',
      birthDate: null,
      address: {
        street: '',
        number: '',
        neighborhood: '',
        city: '',
        state: '',
        postalCode: '',
      },
    },
  })

  const formatCustomerParams = (data) => {
    return {
      name: data?.name,
      email: data?.email,
      document: data?.document,
      documentType: data?.documentType,
      birthDate: data?.birthDate?.toString(),
      address: {
        street: data?.address?.street,
        number: data?.address?.number,
        neighborhood: data?.address?.neighborhood,
        city: data?.address?.city,
        state: data?.address?.state,
        postalCode: data?.address?.postalCode,
      },
    }
  }

  const onSubmit = async (data) => {
    setStep(data.paymentMethod)
    setCustomer(formatCustomerParams(data))
  }
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box alignSelf="start" mb={checkoutTheme.spacing(6)}>
          <Typography variant="h6" color="textPrimary">
            Informações de cobrança
          </Typography>
        </Box>
        <Box>
          <Typography variant="body1" color="textSecondary">
            Pague com
          </Typography>
          <Controller
            name="paymentMethod"
            as={
              <Select items={constants.paymentMethods.METHODS} size="small" />
            }
            control={control}
            mode="onBlur"
          />
        </Box>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={7} lg={7}>
              <Box>
                <Typography variant="body1" color="textSecondary">
                  Nome completo*
                </Typography>
              </Box>
              <Controller
                name="name"
                as={
                  <TextField
                    type="tel"
                    color="primary"
                    variant="outlined"
                    fullWidth
                    error={!!errors.name}
                    helperText={errors?.name?.message}
                    InputLabelProps={{
                      shrink: false,
                      startAdornment: (
                        <InputAdornment position="start">
                          <InfoIcon />
                        </InputAdornment>
                      ),
                    }}
                    className={classes.blueText}
                    size="small"
                  />
                }
                control={control}
                mode="onBlur"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <Box>
                <Typography variant="body1" color="textSecondary">
                  Nascimento*
                </Typography>
              </Box>
              <Controller
                name="birthDate"
                as={
                  <DatePicker
                    fullWidth
                    InputLabelProps={{ shrink: false }}
                    format="DD/MM/yyyy"
                    helperText={errors?.birthDate?.message}
                    error={!!errors.birthDate}
                    inputVariant="outlined"
                    size="small"
                    minDate={`${
                      new Date(Date.now()).getFullYear() - 100
                    }-01-01`}
                    maxDate={`${new Date(Date.now()).getFullYear() - 18}-${
                      new Date(Date.now()).getMonth() + 1
                    }-${new Date(Date.now()).getDate()}`}
                  />
                }
                control={control}
                mode="onBlur"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box>
                <Typography variant="body1" color="textSecondary">
                  E-mail*
                </Typography>
              </Box>
              <Controller
                name="email"
                as={
                  <TextField
                    InputLabelProps={{ shrink: false }}
                    type="email"
                    color="primary"
                    variant="outlined"
                    fullWidth
                    error={!!errors.email}
                    helperText={errors?.email?.message}
                    size="small"
                  />
                }
                control={control}
                mode="onBlur"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box>
                <Typography variant="body1" color="textSecondary">
                  CPF/CNPJ*
                </Typography>
              </Box>
              <Controller
                name="document"
                as={
                  <TextField
                    InputLabelProps={{ shrink: false }}
                    type="text"
                    color="primary"
                    variant="outlined"
                    fullWidth
                    error={!!errors.document}
                    helperText={errors?.document?.message}
                    size="small"
                  />
                }
                onChange={([event]) => {
                  let doc = helpers.formatters.document(event.target.value)
                  if (doc.length === 14) {
                    setValue('documentType', 'CPF')
                  } else if (doc.length > 14) {
                    setValue('documentType', 'CNPJ')
                  }
                  return doc
                }}
                control={control}
                mode="onBlur"
              />
              <Controller
                name="documentType"
                as={<input type="hidden" />}
                control={control}
                mode="onBlur"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <Box>
                <Typography variant="body1" color="textSecondary">
                  CEP*
                </Typography>
              </Box>
              <Controller
                name="address.postalCode"
                as={
                  <TextField
                    InputLabelProps={{ shrink: false }}
                    type="text"
                    color="primary"
                    variant="outlined"
                    fullWidth
                    error={!!errors?.address?.postalCode}
                    helperText={errors?.address?.postalCode?.message}
                    inputProps={{ maxLength: 9 }}
                    size="small"
                  />
                }
                onChange={([event]) => {
                  return event.target.value.replace(
                    /^(\d{5})(\d{1,4}).*/,
                    '$1-$2',
                  )
                }}
                control={control}
                mode="onBlur"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Box>
                <Typography variant="body1" color="textSecondary">
                  Estado*
                </Typography>
              </Box>
              <FormControl variant="outlined" size="small">
                <Controller
                  name="address.state"
                  as={
                    <SelectMain
                      variant="outlined"
                      fullWidth
                      error={!!errors?.address?.state}
                      helperText={errors?.address?.state?.message}
                    >
                      {estados.map((estado) => (
                        <MenuItem key={estado} value={estado}>
                          {estado}
                        </MenuItem>
                      ))}
                    </SelectMain>
                  }
                  control={control}
                  mode="onBlur"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box>
                <Typography variant="body1" color="textSecondary">
                  Cidade*
                </Typography>
              </Box>
              <Controller
                name="address.city"
                as={
                  <TextField
                    InputLabelProps={{ shrink: false }}
                    type="text"
                    color="primary"
                    variant="outlined"
                    fullWidth
                    error={!!errors?.address?.city}
                    helperText={errors?.address?.city?.message}
                    size="small"
                  />
                }
                control={control}
                mode="onBlur"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box>
                <Typography variant="body1" color="textSecondary">
                  Bairro*
                </Typography>
              </Box>
              <Controller
                name="address.neighborhood"
                as={
                  <TextField
                    InputLabelProps={{ shrink: false }}
                    type="text"
                    color="primary"
                    variant="outlined"
                    fullWidth
                    error={!!errors?.address?.neighborhood}
                    helperText={errors?.address?.neighborhood?.message}
                    size="small"
                  />
                }
                control={control}
                mode="onBlur"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <Box>
                <Typography variant="body1" color="textSecondary">
                  Rua*
                </Typography>
              </Box>
              <Controller
                name="address.street"
                as={
                  <TextField
                    InputLabelProps={{ shrink: false }}
                    type="tel"
                    color="primary"
                    variant="outlined"
                    fullWidth
                    error={!!errors?.address?.street}
                    helperText={errors?.address?.street?.message}
                    size="small"
                  />
                }
                control={control}
                mode="onBlur"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Box>
                <Typography variant="body1" color="textSecondary">
                  Número*
                </Typography>
              </Box>
              <Controller
                name="address.number"
                as={
                  <TextField
                    InputLabelProps={{ shrink: false }}
                    type="text"
                    color="primary"
                    variant="outlined"
                    fullWidth
                    error={!!errors?.address?.number}
                    helperText={errors?.address?.number?.message}
                    size="small"
                  />
                }
                control={control}
                mode="onBlur"
              />
            </Grid>
          </Grid>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          my={checkoutTheme.spacing(10)}
        >
          <LoadingButton text="CONTINUAR O PAGAMENTO" type="submit" />
        </Box>
      </form>
    </>
  )
}

export default CustomerForm
