import {
  Breadcrumbs as BreadcrumbsMaterial,
  Box,
  Typography,
  makeStyles,
} from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import styles from './styles'

import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(styles)

const Breadcrumbs = ({ mainPage, backPage, page, href, ...rest }) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <BreadcrumbsMaterial
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
      {...rest}
    >
      <Typography>{mainPage}</Typography>
      {backPage ? (
        <Box>
          <Typography
            color="textPrimary"
            className={classes.menuNavigation}
            onClick={() => history.push(href)}
          >
            {backPage}
          </Typography>
        </Box>
      ) : (
        ''
      )}
      <Typography color="textPrimary">{page}</Typography>
    </BreadcrumbsMaterial>
  )
}

export default Breadcrumbs
