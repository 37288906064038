import { Box, Typography } from '@material-ui/core'

// import { ArrowLeft as ArrowLeftIcon } from 'react-feather'

import theme from 'theme'

const Header = ({ name, route = null }) => {
  return (
    <Box
      mt={theme.spacing(10)}
      mb={theme.spacing(10)}
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      {/* <Button
        style={{
          width: '64px',
          height: '64px',
          borderRadius: '40px',
          marginRight: '20px',
        }}
      >
        <ArrowLeftIcon
          component="span"
          style={{
            cursor: 'pointer',
          }}
          onClick={() => {
            // history.push(route)
            history.goBack()
          }}
          size={32}
          color={theme.palette.text.primary}
        />
      </Button> */}
      <Typography variant="h6" color="textPrimary">
        {name}
      </Typography>
    </Box>
  )
}

export default Header
