const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(3),
  },
  orderButton: {
    borderRadius: theme.spacing(3),
    backgroundColor: theme.palette.white,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  boxButton: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'start',
    backgroundColor: '#FFF',
    borderRadius: '20px',
    width: '100%',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  iconBox: {
    backgroundColor: '#e3e9ec',
    borderRadius: '50%',
  },
  image: {
    width: '180px',
    height: '100%',
    float: 'right',
  },
})

export default styles
