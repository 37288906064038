import { Divider, Grid, Box } from '@material-ui/core'

import {
  Menu,
  OnPage,
  ButtonBaseComponent,
  CodeBox,
  Title,
  Paragraph,
  ParamsTable,
  UrlBox,
  Section,
} from '../components'

import { Page, Container } from 'components'

import { routes } from 'Routes'

const Autentication = () => {
  const onPage = [
    ['#d-autenticacao', 'Autenticação'],
    ['#d-login', 'Login'],
  ]

  const loginCode = `
{
    "user_email": "rafael.couto@gmail.com",
    "seller_key": "2e3ee2089c77a6acd5417ddc79a63176",
    "seller_token": "9a9686926364ce90a8808d8d6177c1cb",
    "expire_last_token": false
}
  `
  return (
    <Page title="Superfin">
      <Container maxWidth="md">
        <Box style={{ marginBottom: '40px' }}>
          <Grid container spacing={8}>
            <Grid item xs={12} sm={12} md={12} lg={2}>
              <Menu />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8}>
              <Box style={{ marginBottom: '40px' }}>
                <Title variant="h5" id="d-autenticacao" subtitle="Começando">
                  Autenticação
                </Title>
                <Paragraph>
                  Para realizar o login via API, o usuário deve fornecer alguns
                  dados específicos da conta. Esses dados incluem:
                  <br />
                  <br />
                  <ul style={{ marginLeft: '20px' }}>
                    <li>
                      <strong>Email do usuário:</strong>O endereço de email
                      associado à conta.
                    </li>
                    <li>
                      <strong>Token da conta (ID): </strong>Um identificador
                      único da conta.
                    </li>
                    <li>
                      <strong>Seller key da conta:</strong>Um token gerado pelo
                      nosso sistema, que funciona como a chave principal de
                      autenticação.
                    </li>
                  </ul>
                  <br />
                  <br />
                  Todos esses dados estão disponíveis no portal do cliente. Para
                  acessá-los, siga estes passos:
                  <br />
                  <br />
                  <ol style={{ marginLeft: '20px' }}>
                    <li>Acesse o portal do cliente.</li>
                    <li>
                      Navegue até o menu <strong>Configurações.</strong>
                    </li>
                    <li>
                      Clique no botão <strong>Implementação.</strong>
                    </li>
                  </ol>
                  <br />
                  Nesta tela, você encontrará o Seller token (ID da conta) e a
                  Seller key (Chave Principal). Também é possível redefinir a
                  Seller key nesta tela. Lembre-se de que, ao redefinir a Seller
                  key, o último token gerado será invalidado. <br />
                  <br />
                  Os detalhes de todos os parâmetros que o sistema aceita, bem
                  como suas respectivas descrições, estão presentes na tabela
                  abaixo.
                </Paragraph>
              </Box>
              <Divider />
              <Section>
                <Title variant="h6" id="d-login">
                  Login
                </Title>
                <UrlBox
                  type="POST"
                  url="https://api.superfin.com.br/authentication_api"
                  link="https://documenter.getpostman.com/view/11000226/TzRPhoDe#e4f50aed-3497-4ddf-9840-086999f605c0"
                />
                <Paragraph>
                  O endpoint de listagem de transações é essencial para obter
                  uma visão geral das transações realizadas. Ele permite
                  recuperar uma lista de transações com diversos parâmetros de
                  filtragem para facilitar a busca e análise.
                </Paragraph>
                <CodeBox
                  title="Body"
                  code={loginCode}
                  link="https://documenter.getpostman.com/view/11000226/TzRPhoDe#e4f50aed-3497-4ddf-9840-086999f605c0"
                />
                <ParamsTable
                  rows={[
                    [
                      'user_email',
                      'Email do usuário cadastrado na nossa plataforma',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'seller_key',
                      'Token criptografado.',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'seller_token',
                      'Id criptografado',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'expire_last_token',
                      'Caso seja enviado true, o antigo token de autenticação gerado será inválidado e será gerado um novo token de autentição.',
                      'Booleano',
                      'Body',
                      'Não',
                    ],
                  ]}
                />
              </Section>

              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ButtonBaseComponent
                    nextName="Transações"
                    previousName="Códigos de resposta"
                    previousRoute={routes.documentation.httpCode}
                    nextRoute={routes.documentation.order}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={2}>
              <OnPage page={onPage} />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  )
}

export default Autentication
