import { Box, makeStyles, Grid, Button, TextField } from '@material-ui/core'

import { useForm, Controller } from 'react-hook-form'

import { FilterButton } from 'components'
import { Search as SearchIcon } from 'react-feather'

import theme from 'theme'
import styles from './styles'

const useStyles = makeStyles(styles)

const FilterRow = ({
  filter,
  searchPlaceholder = 'Pesquisar',
  searchField = 'buyerName',
  search = true,
}) => {
  const classes = useStyles()

  const { control, handleSubmit } = useForm()

  const onSubmit = (data) => {
    filter.setFilters({ ...filter.filters, ...data })
  }

  return (
    <Grid container spacing={2} className={classes.root}>
      {search === true ? (
        <>
          <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
            <Box className={classes.searchRow}>
              <form
                onSubmit={handleSubmit(onSubmit)}
                id="search-form"
                style={{ width: '100%' }}
              >
                <Box className={classes.rootBox}>
                  <Controller
                    as={
                      <TextField
                        fullWidth
                        label={searchPlaceholder}
                        placeholder={searchPlaceholder}
                        type="text"
                        variant="outlined"
                        size="small"
                        InputLabelProps={{
                          focused: true,
                        }}
                      />
                    }
                    control={control}
                    name={searchField}
                    mode="onBlur"
                    defaultValue=""
                  />
                </Box>
              </form>
              <Button
                variant="contained"
                type="submit"
                form="search-form"
                fullWidth
                className={classes.searchButton}
              >
                <SearchIcon color={theme.palette.white} size={16} />
              </Button>
            </Box>
          </Grid>

          {/* <Grid item xl={3} lg={3} md={3} sm={6} xs={6}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          className={classes.rootBox}
        >
          <Typography variant="h6">Ordenar por</Typography>
          <FormControl>
            <Select
              id="order-select"
              value={orderValue}
              onChange={handleChangeOrder}
              input={<SorterInput />}
            >
              <MenuItem value="newly_created">
                <Typography variant="body2" color="secondary">
                  Criados recentemente
                </Typography>
              </MenuItem>
              <MenuItem value="older_created">
                <Typography variant="body2" color="secondary">
                  Mais antigo
                </Typography>
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Grid> */}
          <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
            <FilterButton setDrawerOpen={filter.setDrawerOpen} />
          </Grid>
        </>
      ) : (
        <FilterButton setDrawerOpen={filter.setDrawerOpen} />
      )}
    </Grid>
  )
}

export default FilterRow
