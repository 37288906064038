import { Grid, Box, Divider } from '@material-ui/core'

import { Page, Container } from 'components'

import {
  Menu,
  OnPage,
  ButtonBaseComponent,
  CodeBox,
  Title,
  Paragraph,
  Section,
  UrlBox,
  ParamsTable,
} from '../components'

import { routes } from 'Routes'

const Production = () => {
  const jsonCode = `{
    "user_email": "rafael.couto@gmail.com",
    "seller_key": "2e3ee2089c77a6acd5417ddc79a63176",
    "seller_token": "9a9686926364ce90a8808d8d6177c1cb",
    "expire_last_token": false
}`

  const onPage = [
    ['#d-producao', 'Produção'],
    ['#d-endereco', 'Endereço'],
  ]

  return (
    <Page title="Produção">
      <Container maxWidth="md">
        <Box style={{ marginBottom: '40px' }}>
          <Grid container spacing={8}>
            <Grid item xs={12} sm={12} md={12} lg={2}>
              <Menu />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8}>
              <Box style={{ marginBottom: '20px' }}>
                <Title variant="h5" id="d-producao" subtitle="Começando">
                  Produção
                </Title>
                <Paragraph>
                  O ambiente de produção é o cenário onde o software ou
                  aplicativo está totalmente operacional e acessível aos
                  usuários finais. Este ambiente é crítico, pois é onde os dados
                  reais são processados e as operações comerciais ocorrem
                </Paragraph>
              </Box>
              <Divider />
              <Section>
                <Title variant="h6" id="d-endereco">
                  Endereço
                </Title>
                <Paragraph>
                  O endpoint abaixo é essencial para realizar todas as
                  requisições à API. No ambiente produção, todas as requisições
                  devem ser direcionadas a este endpoint.
                </Paragraph>
                <UrlBox
                  url="https://api.superfin.com.br"
                  link="https://documenter.getpostman.com/view/11000226/TzRPhoDe#intro"
                />
                <Paragraph>
                  Agora você pode autenticar-se na Superfin para obter o token
                  que será utilizado em todas as requisições subsequentes.
                </Paragraph>
              </Section>
              <Section>
                <CodeBox
                  type="POST"
                  title="/authentication_api"
                  code={jsonCode}
                  link="https://documenter.getpostman.com/view/11000226/TzRPhoDe#e4f50aed-3497-4ddf-9840-086999f605c0"
                />
                <ParamsTable
                  rows={[
                    [
                      'user_email',
                      'E-mail do usuário cadastrado na nossa plataforma',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'seller_key',
                      'Token criptografado',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'seller_token',
                      'Id criptografado',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'expire_last_token',
                      'Caso seja enviado true, o antigo token de autenticação gerado será inválidado e será gerado um novo token de autentição.',
                      'booleano',
                      'Body',
                      'Não',
                    ],
                  ]}
                />
              </Section>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ButtonBaseComponent
                    nextName="Respostas HTTPs"
                    previousName="Sandbox"
                    previousRoute={routes.documentation.sandbox}
                    nextRoute={routes.documentation.httpCode}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={2}>
              <OnPage page={onPage} />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  )
}

export default Production
