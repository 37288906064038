import api from 'service/api'

const intermediadorAPI = api.create('intermediador')

const get = async ({ ...params }) => {
  return await intermediadorAPI.get(`/reports/order`, { params })
}
const generateReport = {
  get,
}

export default generateReport
