import { Grid, Box, Divider } from '@material-ui/core'

import { Page, Container } from 'components'

import {
  Menu,
  OnPage,
  ButtonBaseComponent,
  Title,
  Paragraph,
  ParamsTable,
  UrlBox,
  Section,
  CodeBox,
} from '../components'

import { routes } from 'Routes'

const Subscription = () => {
  const onPage = [
    ['#d-planos', 'Assinaturas'],
    ['#d-listar', 'Listar'],
    ['#d-consultar', 'Consultar'],
    ['#d-criar', 'Criar'],
    ['#d-atualizar', 'Atualizar'],
  ]

  const createSub = `
{
    "subscription": {
        "customer":{
            "name": "aaa",
            "email": "asfasf",
            "document": "123456"
        },
        "code": "1A2S3D",
        "plans": [
            {"id": 1, "price": 10}
        ],
        "items": [
            {"description": "teste", "price": 11}   
        ]
    }
}
  `

  return (
    <Page title="Planos">
      <Container maxWidth="md">
        <Box style={{ marginBottom: '40px' }}>
          <Grid container spacing={8}>
            <Grid item xs={12} sm={12} md={12} lg={2}>
              <Menu />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8}>
              <Box style={{ marginBottom: '40px' }}>
                <Title variant="h5" id="d-planos" subtitle="Recorrência">
                  Assinaturas
                </Title>
                <Paragraph>
                  Assinaturas são um modelo de pagamento recorrente onde os
                  clientes pagam periodicamente por um serviço ou produto. Este
                  endpoint gerencia as assinaturas dos clientes, permitindo a
                  criação, consulta, atualização e cancelamento de assinaturas.
                  Para criar uma assinatura, é necessário fornecer o ID do plano
                  desejado, os dados do cliente, e os parâmetros de
                  autenticação. A consulta de uma assinatura específica pode ser
                  feita através do envio do ID da assinatura na URL, permitindo
                  obter detalhes como status, data de início, e próximas
                  cobranças.
                </Paragraph>
              </Box>
              <Divider />
              <Section>
                <Title variant="h6" id="d-listar">
                  Listar
                </Title>
                <UrlBox
                  type="GET"
                  url="https://api.sandbox.superfin.com.br/subscription/subscriptions"
                  link="https://documenter.getpostman.com/view/11000226/TzRPhoDe#79f11c6f-b21e-449b-b67f-bc777660e106"
                />
                <Paragraph>
                  A listagem de assinaturas desempenha um papel crucial no
                  gerenciamento e monitoramento das assinaturas dos clientes.
                  Este endpoint permite visualizar todas as assinaturas
                  registradas, oferecendo informações detalhadas sobre cada uma.
                  Se nenhum parâmetro for enviado, serão listadas todas as
                  assinaturas disponíveis. Caso sejam enviados parâmetros, a
                  filtragem será realizada com base nos critérios especificados
                  na URL (por exemplo, valores de ID do cliente, status da
                  assinatura, data de início). É fundamental enviar os
                  parâmetros de autenticação para garantir a segurança e o
                  acesso autorizado aos dados.
                </Paragraph>
                <ParamsTable
                  rows={[
                    [
                      'Authorization',
                      'Token de identificação referente a autenticação',
                      'String',
                      'Headers',
                      'Sim',
                    ],
                  ]}
                />
              </Section>
              <Divider />
              <Section>
                <Title variant="h6" id="d-consultar">
                  Consultar
                </Title>
                <UrlBox
                  type="GET"
                  url="https://api.sandbox.superfin.com.br/subscription/subscriptions/:id"
                  link="https://documenter.getpostman.com/view/11000226/TzRPhoDe#8ca8ca5f-db51-4de7-9fd0-fb31bb9e4a1c"
                />
                <Paragraph>
                  Realiza a consulta de assinaturas. É obrigatório enviar um ID
                  para retornar a assinatura correspondente, desde que ela
                  pertença à conta autenticada.
                </Paragraph>
                <ParamsTable
                  rows={[
                    [
                      'Authorization',
                      'Token de identificação referente a autenticação',
                      'String',
                      'Headers',
                      'Sim',
                    ],
                    [
                      'id',
                      'Identificação da assinatura',
                      'String',
                      'Headers',
                      'Sim',
                    ],
                  ]}
                />
              </Section>
              <Divider />
              <Section>
                <Title variant="h6" id="d-criar">
                  Criar
                </Title>
                <UrlBox
                  type="POST"
                  url="https://api.sandbox.superfin.com.br/subscription/subscriptions"
                  link="https://documenter.getpostman.com/view/11000226/TzRPhoDe#387e51cb-48ea-47f9-9cad-f485d31553ac"
                />
                <Paragraph>
                  Endpoint para criar assinaturas, os parâmetros enviados no
                  corpo (body) são todos os que é possível enviar. Parâmetros
                  obrigatórios: 'customer' (com ID, caso queira vincular a um
                  cliente já existente, ou com 'name', 'email', 'document' para
                  criar um novo cliente) e 'plans' (com ID e 'price', caso seja
                  um preço diferente do pré-estabelecido no plano), além dos
                  parâmetros de autenticação. Agora, vamos vincular um usuário a
                  um plano criado. Para isso, é necessário fazer um POST na
                  seguinte rota 'subscription/subscriptions'. Para criar uma
                  assinatura vinculada ao usuário, é necessário construir a
                  seguinte estrutura.
                </Paragraph>
                <CodeBox
                  title="Body"
                  code={createSub}
                  link="https://documenter.getpostman.com/view/11000226/TzRPhoDe#81863d9c-0cdd-4c1c-bb4a-503573b6397d"
                />
                <ParamsTable
                  rows={[
                    [
                      'Authorization',
                      'Token de identificação referente a autenticação',
                      'String',
                      'Headers',
                      'Sim',
                    ],
                  ]}
                />
              </Section>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ButtonBaseComponent
                    previousName="Planos"
                    previousRoute={routes.documentation.plans}
                    nextName="Clientes"
                    nextRoute={routes.documentation.customers}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={2}>
              <OnPage page={onPage} />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  )
}

export default Subscription
