import {
  Box,
  makeStyles,
  Typography,
  Grid,
  Tooltip,
  Button,
} from '@material-ui/core'
import helpers from 'helpers'

import styles from './styles'
import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'
import { routes } from 'Routes'
import checkoutTheme from 'theme/checkoutTheme'

const useStyles = makeStyles(styles)

const CheckoutPlansList = ({ plans, accountToken }) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Grid container spacing={2}>
      {plans.map((plan) => (
        <Grid item md={4} sm={6} xs={12} key={plan.id}>
          <Box
            className={classes.infoBox}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Box
              width="100%"
              display="flex"
              justifyContent="center"
              style={{ marginBottom: checkoutTheme.spacing(10) }}
            >
              <Tooltip title={plan.name} enterTouchDelay={0}>
                <Typography
                  color="textPrimary"
                  variant="h6"
                  className={classes.overflow}
                >
                  {plan.name}
                </Typography>
              </Tooltip>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              <Box>
                <Typography variant="body1" color="textPrimary">
                  R$
                </Typography>
              </Box>
              <Typography variant="h5" color="textPrimary">
                {helpers.formatters.floatToMoney(parseFloat(plan?.price))}
              </Typography>
              <Box>
                <Typography color="textPrimary" variant="body1">
                  {helpers.plans.periodicity(plan?.periodicity)}
                </Typography>
              </Box>
            </Box>
            <Button
              color="primary"
              type="submit"
              variant="contained"
              style={{ marginTop: checkoutTheme.spacing(10) }}
              disableElevation
              onClick={() =>
                history.push(
                  reverse(routes.checkout.main, {
                    type: 'plan',
                    token: plan.id,
                  }),
                  {
                    accountToken: accountToken,
                  },
                )
              }
            >
              Quero este plano
            </Button>
          </Box>
        </Grid>
      ))}
    </Grid>
  )
}

export default CheckoutPlansList
