const typography = () => ({
  h1: {
    fontWeight: 600,
    fontSize: '5.25rem',
    letterSpacing: '-0.24px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    zIndex: 2,
    // [theme.breakpoints.between('xs', 'sm')]: {
    //   fontSize: '2rem',
    // },
  },
  fontFamily: [
    'Comfortaa',
    'Roboto',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  fontStyle: 'normal',
  h2: {
    fontWeight: 600,
    fontSize: '4rem',
    letterSpacing: '-0.9px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    zIndex: 2,
    // [theme.breakpoints.between('xs', 'sm')]: {
    //   fontSize: '1.55rem',
    // },
  },
  h3: {
    fontWeight: 600,
    fontSize: '3rem',
    letterSpacing: '-0.06px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    zIndex: 2,
    // [theme.breakpoints.between('xs', 'sm')]: {
    //   fontSize: '1.25rem',
    // },
  },
  h4: {
    fontWeight: 600,
    fontSize: '2.5rem',
    letterSpacing: '-0.06px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    zIndex: 2,
    // [theme.breakpoints.between('xs', 'sm')]: {
    //   fontSize: '1rem',
    // },
  },
  h5: {
    fontWeight: 600,
    fontSize: '2rem',
    letterSpacing: '-0.05px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    zIndex: 2,
    // [theme.breakpoints.between('xs', 'sm')]: {
    //   fontSize: '0.75rem',
    // },
  },
  h6: {
    fontWeight: 600,
    fontSize: '1.5rem',
    letterSpacing: '-0.05px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    zIndex: 2,
    // [theme.breakpoints.between('xs', 'sm')]: {
    //   fontSize: '0.75rem',
    // },
  },
  subtitle1: {
    fontSize: '1.125rem',
    fontFamily: 'Poppins',
    fontWeight: 400,
    letterSpacing: '-0.05px',
    zIndex: 2,
  },
  subtitle2: {
    fontSize: '0.875rem',
    fontFamily: 'Poppins',
    fontWeight: 400,
    letterSpacing: '-0.05px',
    zIndex: 2,
  },
  body1: {
    fontSize: '1rem',
    fontWeight: 400,
    letterSpacing: '-0.04px',
    fontFamily: 'Poppins',
    zIndex: 2,
    // [theme.breakpoints.between('xs', 'sm')]: {
    //   fontSize: '0.75rem',
    // },
  },
  body2: {
    fontSize: '0.875rem',
    fontWeight: 400,
    letterSpacing: '0.2px',
    fontFamily: 'Poppins',
    zIndex: 2,
    // [theme.breakpoints.between('xs', 'sm')]: {
    //   fontSize: '0.60rem',
    // },
  },
  button: {
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: 1.75,
    letterSpacing: '0.02857em',
    textTransform: 'uppercase',
    zIndex: 2,
  },
  caption: {
    fontFamily: 'Poppins',
    fontWeight: 500,
    lineHeight: 0.9,
    fontSize: '0.75rem',
    zIndex: 2,
  },
  overline: {
    fontWeight: 500,
    fontSize: '0.625rem',
    fontFamily: 'Poppins',
    lineHeight: 0.7,
    zIndex: 2,
  },
})

export default typography
