const styles = (theme) => ({
  menu: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px',
    height: '100%',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
  },
  activeMenu: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px',
    height: '100%',
    width: 220,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  buttonExtended: {
    display: 'flex',
    justifyContent: 'flex-start',
    textTransform: 'capitalize',
    width: '100%',
  },
  button: {
    display: 'flex',
    padding: '20px',
    height: '40px',
    minWidth: '40px',
    borderRadius: '40px',
    color: theme.palette.text.secondary,
  },
  title: {
    paddingLeft: '12px',
    textAlign: 'left',
  },
  buttonLabel: {
    justifyContent: 'flex-start',
  },
  menuButton: {
    height: '40px',
    width: '100%',
  },
  active: {
    color: theme.palette.primary.main,
    // backgroundColor: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.black,
    },
  },
})

export default styles
