import moment from 'moment'
import 'moment/locale/pt-br'

moment.locale('pt-br')

const dateTime = (date) => {
  if (!date) {
    return null
  }

  return moment(date).locale('pt-BR').format('DD/MM/YYYY - HH:mm')
}

export default dateTime
