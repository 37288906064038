const styles = (theme) => ({
  boxButton: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    height: '150px',
    borderRadius: '20px',
    transition: 'transform 0.5s',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  },

  iconBox: {
    backgroundColor: '#e2f4f9',
    borderRadius: theme.spacing(20),
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
})

export default styles
