import MuiTableCell from './MuiTableCell'
import MuiButton from './MuiButton'
import MuiFormLabel from './MuiFormLabel'
import MuiDialogActions from './MuiDialogActions'
import MuiPopover from './MuiPopover'
import MuiTableRow from './MuiTableRow'
import MuiTableBody from './MuiTableBody'
import MuiTextField from './MuiTextField'
import MuiOutlinedInput from './MuiOutlinedInput'
import MuiSelect from './MuiSelect'
import MuiFormControl from './MuiFormControl'
import MuiPaper from './MuiPaper'

const overrides = {
  MuiTableCell,
  MuiButton,
  MuiFormLabel,
  MuiDialogActions,
  MuiPopover,
  MuiTableRow,
  MuiTableBody,
  MuiTextField,
  MuiOutlinedInput,
  MuiSelect,
  MuiFormControl,
  MuiPaper,
}

export default overrides
