import { Grid, Box } from '@material-ui/core'

import { Page, Container } from 'components'

import {
  Menu,
  OnPage,
  ButtonBaseComponent,
  Title,
  Paragraph,
} from '../components'

import { routes } from 'Routes'

const RecurringBillingSummary = () => {
  const onPage = [['#d-cobrançarecorrente', 'Cobrança recorrente']]

  return (
    <Page title="Cobrança Recorrente">
      <Container maxWidth="md">
        <Box style={{ marginBottom: '40px' }}>
          <Grid container spacing={8}>
            <Grid item xs={12} sm={12} md={12} lg={2}>
              <Menu />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8}>
              <Box style={{ marginBottom: '40px' }}>
                <Title variant="h5" id="d-cobrançarecorrente">
                  Cobrança recorrente
                </Title>
                <Paragraph>
                  O Superfin oferece a funcionalidade de cobrança recorrente.
                  Dedicamos uma sessão exclusiva para explicar detalhadamente a
                  cobrança recorrente. Confira no próximo tópico!
                </Paragraph>
              </Box>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ButtonBaseComponent
                    previousName="Minhas informações"
                    previousRoute={routes.documentation.myInformation}
                    nextName="Cobrança recorrente"
                    nextRoute={routes.documentation.introductionSubscriptions}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={2}>
              <OnPage page={onPage} />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  )
}

export default RecurringBillingSummary
