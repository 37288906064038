import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import {
  Grid,
  Box,
  Typography,
  makeStyles,
  Link,
  Drawer,
  Button,
  Hidden,
} from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'

import theme from 'theme'

import JssProvider from 'react-jss/lib/JssProvider'
import { createGenerateClassName } from '@material-ui/core/styles'
import logoLargeSuperfin from 'images/logolargesuperfin.png'
import logoLargeSuperfinWhite from 'images/logo-white.svg'

import creditCardImg from 'images/credito_sf.png'
import billetImg from 'images/boleto_sf.png'
import pixImg from 'images/pix_sf.png'
import chargeImg from 'images/cobranca_sf.png'
import subsImg from 'images/recorrente_sf.png'
import transfImg from 'images/transferencia_sf.png'

import { Menu as MenuIcon } from 'react-feather'

import SnackbarProvider from 'providers/SnackbarProvider'

import { Container } from 'components'

import history from 'service/history'
import { routes } from 'Routes'

import styles from './styles'

const defaultGenerateClassName = createGenerateClassName({
  disableGlobal: true,
  productionPrefix: 'c',
})

const useStyles = makeStyles(styles)

const Documentation = ({ children }) => {
  const classes = useStyles()
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setState({ ...state, [anchor]: open })
  }

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      style={{ padding: '40px' }}
    >
      <Typography
        variant="h6"
        className={classes.menu}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <Link
          onClick={() => {
            history.push(routes.documentation.main)
          }}
          color="textPrimary"
          style={{ marginRight: '12px' }}
        >
          Inicio
        </Link>
        <Link
          onClick={() => {
            history.push(routes.documentation.introduction)
          }}
          color="textPrimary"
          style={{ marginRight: '12px' }}
        >
          Documentação
        </Link>
        <Link
          onClick={() => {
            history.push(routes.root)
          }}
          color="textPrimary"
        >
          Acessar
        </Link>
      </Typography>
    </div>
  )

  return (
    <ThemeProvider theme={theme}>
      <JssProvider generateClassName={defaultGenerateClassName}>
        <SnackbarProvider>
          <Container maxWidth="md">
            <Grid container spacing={8}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    height: '64px',
                  }}
                >
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      alt="Logo"
                      src={logoLargeSuperfin}
                      style={{ width: '120px' }}
                      width="100%"
                    />

                    <Typography
                      variant="body2"
                      color="primary"
                      style={{
                        backgroundColor: '#B8FFB8',
                        borderRadius: '40px',
                        marginLeft: '12px',
                        paddingRight: '8px',
                        paddingLeft: '8px',
                      }}
                    >
                      V1.0.0
                    </Typography>
                  </Box>

                  <Box>
                    <Hidden lgUp>
                      <div>
                        {['left'].map((anchor) => (
                          <React.Fragment key={anchor}>
                            <Button onClick={toggleDrawer(anchor, true)}>
                              <MenuIcon size={16} />
                            </Button>
                            <Drawer
                              anchor={anchor}
                              open={state[anchor]}
                              onClose={toggleDrawer(anchor, false)}
                            >
                              {list(anchor)}
                            </Drawer>
                          </React.Fragment>
                        ))}
                      </div>
                    </Hidden>
                    <Hidden mdDown>
                      <Typography variant="h6" className={classes.menu}>
                        <Link
                          onClick={() => {
                            history.push(routes.documentation.main)
                          }}
                          color="textPrimary"
                          style={{ marginRight: '12px' }}
                        >
                          Inicio
                        </Link>
                        <Link
                          onClick={() => {
                            history.push(routes.documentation.introduction)
                          }}
                          color="textPrimary"
                          style={{ marginRight: '12px' }}
                        >
                          Documentação
                        </Link>
                        <Link
                          onClick={() => {
                            history.push(routes.root)
                          }}
                          color="textPrimary"
                        >
                          Acessar
                        </Link>
                      </Typography>
                    </Hidden>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Container>
          <Box style={{ marginTop: '20px' }}>{children}</Box>
          <Box
            style={{
              paddingTop: '40px',
              paddingBottom: '40px',
              backgroundColor: '#00A900',
            }}
          >
            <Container maxWidth="md">
              <Grid container spacing={8}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box>
                    <img
                      alt="Logo"
                      src={logoLargeSuperfinWhite}
                      style={{ width: '120px' }}
                      width="100%"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Container>
            <Container maxWidth="md">
              <Grid container spacing={8}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box
                    style={{
                      paddingBottom: '40px',
                      paddingTop: '40px',
                    }}
                  >
                    <Typography
                      variant="h6"
                      color="textPrimary"
                      className={classes.title}
                    >
                      Tarifas transparentes
                    </Typography>
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      className={classes.paragraph}
                      style={{ textAlign: 'center' }}
                    >
                      Aqui você aproveita nossas vantagens sem custo adicionais
                      ou tarifas ocultas, como abertura de contas, saques,
                      mensalidades, entre outras.Conheça nossas tarifas
                      simplificadas e compare!
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexWrap: 'wrap',
                    }}
                  >
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <img
                        alt="Cartão de crédito"
                        src={creditCardImg}
                        style={{ width: '60px' }}
                        width="100%"
                      />
                      <Typography
                        variant="body2"
                        color="textPrimary"
                        className={classes.imgDescription}
                        style={{ textAlign: 'center' }}
                      >
                        Cartão de crédito
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <img
                        alt="Boleto Bancário"
                        src={billetImg}
                        style={{ width: '60px' }}
                        width="100%"
                      />
                      <Typography
                        variant="body2"
                        color="textPrimary"
                        className={classes.imgDescription}
                        style={{ textAlign: 'center' }}
                      >
                        Boleto Bancário
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <img
                        alt="PIX"
                        src={pixImg}
                        style={{ width: '60px' }}
                        width="100%"
                      />
                      <Typography
                        variant="body2"
                        color="textPrimary"
                        className={classes.imgDescription}
                        style={{ textAlign: 'center' }}
                      >
                        PIX
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <img
                        alt="Cobrança avulsa"
                        src={chargeImg}
                        style={{ width: '60px' }}
                        width="100%"
                      />
                      <Typography
                        variant="body2"
                        color="textPrimary"
                        className={classes.imgDescription}
                        style={{ textAlign: 'center' }}
                      >
                        Cobrança avulsa
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <img
                        alt="Cobrança recorrente"
                        src={subsImg}
                        style={{ width: '60px' }}
                        width="100%"
                      />
                      <Typography
                        variant="body2"
                        color="textPrimary"
                        className={classes.imgDescription}
                        style={{ textAlign: 'center' }}
                      >
                        Cobrança recorrente
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <img
                        alt="Transferência entre contas Superfin"
                        src={transfImg}
                        style={{ width: '60px' }}
                        width="100%"
                      />
                      <Typography
                        variant="body2"
                        color="textPrimary"
                        className={classes.imgDescription}
                        style={{ textAlign: 'center' }}
                      >
                        Transferência entre contas Superfin
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Container>
            <Container maxWidth="md">
              <Grid container spacing={8}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box
                    style={{
                      paddingBottom: '40px',
                      paddingTop: '40px',
                    }}
                  >
                    <Typography
                      variant="h6"
                      color="textPrimary"
                      className={classes.title}
                    >
                      Solução para empresas de todos os tamanhos
                    </Typography>

                    <Typography
                      variant="body2"
                      color="textPrimary"
                      className={classes.paragraph}
                      style={{ textAlign: 'center' }}
                    >
                      A Superfin tem a solução ideal para que seu negócio, seja
                      para quem já vende online ou está começando.Aqui você
                      encontra as melhores condições para alavancar o seu
                      negócio.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Container>
            <Container maxWidth="md">
              <Grid container spacing={8}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box
                    style={{
                      paddingTop: '40px',
                    }}
                  >
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      className={classes.paragraph}
                    >
                      Superfin Intermediacao De Pagamentos Ltda
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      className={classes.paragraph}
                    >
                      © Todos os direitos reservados. CNPJ: 49.777.920/0001-00
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      className={classes.paragraph}
                    >
                      A Superfin é uma plataforma completa para a gestão de
                      pagamentos online.
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      className={classes.paragraph}
                    >
                      Nosso serviço permite o envio e recebimento de pagamentos
                      online, envio de cobranças, planos de assinaturas,
                      recorrência, entre outras funcionalidades.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </SnackbarProvider>
      </JssProvider>
    </ThemeProvider>
  )
}

Documentation.propTypes = {
  children: PropTypes.any,
}

export default Documentation
