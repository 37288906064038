import * as yup from 'yup'

export const schema = yup.object().shape({
  bank: yup.object().required().typeError('Escolha a entidade financeira'),
  agency: yup.string().required(),
  number: yup.string().required(),
  titularName: yup.string().required(),
  document: yup.string().max(18).min(14).required(),
})

export default schema
