const JSON_EXAMPLE = {
  order: {
    id: '01asfasf978b68b80ee3e6fe24741cadae8',
    seller_token: 'asfa1f13f48c24adcf220089923783',
    code: '2543161183332742073',
    status: 'PAID',
    products: [
      {
        name: 'Limpa vidro',
        description: 'Limpa vidro',
        price: '70,00',
        quantity: '2',
      },
      {
        name: 'Aspirador',
        description: 'Limpeza',
        price: '30,00',
        quantity: '3',
      },
    ],
    created_at: '2022-08-24',
    updated_at: '2022-09-27',
    gateway_transaction_id: null,
    payments: [
      {
        amount: 230.0,
        status: 'PAID',
        payment_data: {
          method: 6,
          billet: {
            description: 'Descrição no boleto',
            expiration: '11/11/2021',
          },
        },
        gateway_payment_id: null,
        payment_method: 'Boleto',
      },
    ],
    buyer: {
      email: 'cliente@testes.com',
      name: 'Teste jucelino',
      document_type: 'CPF',
      document: '71475176015',
      address: {
        street: 'Rua teste',
        number: '777',
        neighborhood: 'Bairro teste',
        city: 'Testelândia',
        state: 'SP',
        postal_code: '17580000',
      },
      birth_date: '2000-01-01',
    },
  },
  status: 'PAID',
}

const notifications = {
  JSON_EXAMPLE,
}

export default notifications
