import { useState } from 'react'

import OrderContext from 'contexts/OrderContext'

const OrderProvider = ({ children }) => {
  const [data, setData] = useState([])

  const loadData = async (order) => {
    setData(order)
  }

  return (
    <OrderContext.Provider
      value={{
        data,
        setData,
        loadData,
      }}
    >
      {children}
    </OrderContext.Provider>
  )
}

export default OrderProvider
