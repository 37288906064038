const styles = (theme) => ({
  planBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 20,
    padding: 8,
    width: '100%',
    height: '124.04px',
    transition: 'all 500ms ease',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
  createPlan: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '156.55px',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 20,
    padding: 8,
  },
  planName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 'fit-content',
    maxWidth: '100%',
    textAlign: 'left',
    marginBottom: 8,
  },
  iconButton: {
    display: 'flex',
    alignSelf: 'flex-start',
    padding: '0px',
    width: 50,
    height: 50,
  },
  descriptionText: {
    display: '-webkit-box',
    '-webkit-line-clamp': '3' /** número de linhas que você quer exibir */,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    overflowWrap: 'break-word',
    textAlign: 'left',
  },
  notPublicBox: {
    backgroundColor: '#e8ecec',
  },
})

export default styles
