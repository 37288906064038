import {
  Box,
  makeStyles,
  Typography,
  Card,
  CardContent,
  Divider,
  Grid,
} from '@material-ui/core'

import styles from './styles'
import helpers from 'helpers'

const useStyles = makeStyles(styles)

const ProductDetails = ({ order, notPaid = true }) => {
  const classes = useStyles()
  return (
    <Box mt={4}>
      <Card>
        <Box p={2}>
          <Typography variant="h5">Produto(s)</Typography>
        </Box>
        <Divider />
        <CardContent>
          <Box>
            {order?.products?.map((product) => (
              <Grid container spacing={3}>
                <Grid item sm={12} xs={12}>
                  <Box display="flex" flexDirection="column" m={2}>
                    <Typography variant="h5">{product?.name}</Typography>
                    <Typography variant="h5">
                      R${' '}
                      {helpers.formatters.floatToMoney(
                        parseFloat(product?.price),
                      )}
                    </Typography>
                    {product?.quantity ? (
                      <Typography variant="body1">
                        {product?.quantity} Unidade
                      </Typography>
                    ) : product?.startsAt ? (
                      <Typography variant="body1">
                        Vigência: {product?.startsAt} até {product?.endsAt}
                      </Typography>
                    ) : (
                      ''
                    )}
                  </Box>
                  <Divider />
                </Grid>
              </Grid>
            ))}
          </Box>
        </CardContent>
        {notPaid ? (
          <Box p={5} className={classes.cardBottom}>
            <Typography variant="h3" className={classes.payText}>
              Valor total
            </Typography>
            <Typography variant="h1">
              R$ {helpers.formatters.floatToMoney(order?.value)}
            </Typography>
          </Box>
        ) : null}
      </Card>
    </Box>
  )
}

export default ProductDetails
