const translateStatus = (status) => {
  switch (status) {
    case 'NOT_PAID':
      return 'Não pago'
    case 'WAITING_PAYMENT':
      return 'Aguardando pagamento'
    case 'WAITING_CONFIRM':
      return 'Aguardando confirmação'
    case 'PAID':
      return 'Pago'
    default:
      return 'Cancelado'
  }
}

export default translateStatus
