import { Box, Typography } from '@material-ui/core'
import { Select } from 'components'

import constants from 'constants/index'

import checkoutTheme from 'theme/checkoutTheme'

const PaymentMethodSelect = ({ paymentMethodId, setStep }) => {
  const handleChangePaymentMethod = (event, newValue) => {
    setStep(event.target.value)
  }

  return (
    <Box mb={checkoutTheme.spacing(6)}>
      <Typography variant="body1" color="textSecondary">
        Pague com
      </Typography>
      <Select
        items={constants.paymentMethods.METHODS}
        defaultValue={constants.paymentMethods.METHODS[paymentMethodId].id}
        onChange={handleChangePaymentMethod}
        size="small"
      />
    </Box>
  )
}

export default PaymentMethodSelect
