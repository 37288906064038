import helpers from 'helpers'
import * as yup from 'yup'

export const schema = yup.object().shape({
  number: yup
    .string()
    .required()
    .test(
      'validate-card-number',
      'Necessário informar um número de cartão válido.',
      function (number) {
        const brand = helpers.formatters.getMethodIdByBrand(number)
        const numberAjusted = number.replace(/ /g, '')
        var mastercardRegEx = /^(5[1-5]\d{4}|677189)\d{10}$/
        var visaRegEx = /^4\d{12}(\d{3})?$/
        var amexpRegEx = /^3[47]\d{13}$/
        var eloRegEx = new RegExp(
          /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})$/,
        )
        var hipercardRegEx = /^(606282\d{10}(\d{3})?)|(3841\d{15})$/

        switch (brand) {
          case 1:
            return numberAjusted.match(mastercardRegEx)
          case 2:
            return numberAjusted.match(visaRegEx)
          case 3:
            return numberAjusted.match(amexpRegEx)
          case 4:
            return numberAjusted.match(eloRegEx)
          case 5:
            return numberAjusted.match(hipercardRegEx)
          default:
            return null
        }
      },
    )
    .min(16)
    .max(19),
  name: yup.string().required(),
  expiry: yup
    .string()
    .test(
      'expiry-validate',
      'Cartão expirado, informe um valido, ou arrume a data de vencimento!',
      function (value) {
        const expiryMonth = value.split('/')[0]
        const expiryYear = '20' + value.split('/')[1]
        const today = new Date()
        const someday = new Date()
        someday.setFullYear(expiryYear, expiryMonth - 1, 0)

        return someday > today
      },
    )
    .required(),
  cvv: yup.string().required(),
  splitNumber: yup.string().required(),
})

export default schema
