import api from 'service/api'

const intermediadorAPI = api.create('intermediador')

const getPublic = async ({ accountToken = '', ...params }) => {
  return await intermediadorAPI.get(
    `/show_public_plans/${accountToken}`,
    params,
  )
}

const get = async ({ ...params }) => {
  return await intermediadorAPI.get('/subscription/plans', { params })
}

const update = async ({ planId = '', ...params }) => {
  return await intermediadorAPI.put(`/subscription/plans/${planId}`, params)
}

const show = async ({ planId = '' }) => {
  return await intermediadorAPI.get(`/subscription/plans/${planId}`)
}

const create = async ({ ...data }) => {
  return await intermediadorAPI.post('/subscription/plans', data)
}

const plans = {
  getPublic,
  get,
  update,
  show,
  create,
}

export default plans
