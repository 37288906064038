import { Grid, Box, Divider } from '@material-ui/core'

import { Page, Container } from 'components'

import {
  Menu,
  OnPage,
  ButtonBaseComponent,
  CodeBox,
  Title,
  Paragraph,
  ParamsTable,
  UrlBox,
  Section,
} from '../components'

import { routes } from 'Routes'

const OneTimeCharge = () => {
  const onPage = [
    ['#d-cobrancaavulsa', 'Cobrança avulsa'],
    ['#d-listar', 'Listar'],
    ['#d-consultar', 'Consultar'],
    ['#d-criar', 'Criar'],
  ]

  const createCharge = `
{
    "charge" : {
        "payer_name": "Junior",
        "payer_email": "Junior.em@teste.com",
        "expiration_date": "08/08/2023",
        "code": "1a2b3c",
        "description": "Teste",
        "products": [
            {
                "name": "Produto A",
                "price": "1000",
                "quantity": "1"
            },
            {
                "name": "Produto B",
                "price": "20.5",
                "quantity": "3"
            }
        ]
    }
}
`

  return (
    <Page title="Cobrança avulsa">
      <Container maxWidth="md">
        <Box style={{ marginBottom: '40px' }}>
          <Grid container spacing={8}>
            <Grid item xs={12} sm={12} md={12} lg={2}>
              <Menu />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8}>
              <Box style={{ marginBottom: '40px' }}>
                <Title variant="h5" id="d-cobrancaavulsa" subtitle="Começando">
                  Cobrança avulsa
                </Title>
                <Paragraph>
                  A requisição para a criação de uma cobrança é um pedido que o
                  cliente (geralmente uma aplicação ou serviço) envia ao
                  servidor para gerar uma nova cobrança com base nos produtos
                  especificados.
                </Paragraph>
              </Box>
              <Divider />
              <Section>
                <Title variant="h6" id="d-listar">
                  Listar
                </Title>
                <UrlBox
                  type="GET"
                  url="https://api.sandbox.superfin.com.br/charges"
                  link="https://documenter.getpostman.com/view/11000226/TzRPhoDe#dc28d227-9e53-4106-b60a-81fa4ffc850a"
                />
                <Paragraph>
                  Para consultar uma cobrança em nosso sistema, o usuário deve
                  fornecer apenas o token de autorização.
                </Paragraph>
                <ParamsTable
                  rows={[
                    [
                      'Authorization',
                      'Token de identificação referente a autenticação',
                      'String',
                      'Headers',
                      'Sim',
                    ],
                  ]}
                />
              </Section>
              <Divider />
              <Section>
                <Title variant="h6" id="d-consultar">
                  Consultar
                </Title>
                <UrlBox
                  type="GET"
                  url="https://api.sandbox.superfin.com.br/charges/:token"
                  link="https://documenter.getpostman.com/view/11000226/TzRPhoDe#b450ffe6-8e85-44dd-bff5-06d783ea1252"
                />

                <ParamsTable
                  rows={[
                    [
                      'Authorization',
                      'Token de identificação referente a autenticação',
                      'String',
                      'Headers',
                      'Sim',
                    ],
                    [
                      'token',
                      'Token de identificação referente a cobrança',
                      'String',
                      'Route',
                      'Sim',
                    ],
                  ]}
                />
              </Section>
              <Divider />
              <Section>
                <Title variant="h6" id="d-criar">
                  Criar
                </Title>
                <UrlBox
                  type="POST"
                  url="https://api.sandbox.superfin.com.br/charges"
                  link="https://documenter.getpostman.com/view/11000226/TzRPhoDe#6713c43f-57f8-4908-b868-82d717038d6f"
                />
                <Paragraph>
                  O valor da cobrança será composto pelo cálculo do valor
                  unitário dos produtos multiplicado pela quantidade de cada
                  produto, e pela soma de todos os produtos presentes no array.
                </Paragraph>
                <CodeBox
                  title="Body"
                  code={createCharge}
                  link="https://documenter.getpostman.com/view/11000226/TzRPhoDe#6713c43f-57f8-4908-b868-82d717038d6f"
                />
                <ParamsTable
                  rows={[
                    [
                      'Authorization',
                      'Token de identificação referente ao usuário',
                      'String',
                      'Headers',
                      'Sim',
                    ],
                    [
                      'charge[payer_name]',
                      'Nome da pessoa que vai receber a cobrança',
                      'String',
                      'Body',
                      'Sim',
                    ],
                    [
                      'charge[payer_email]',
                      'E-mail da pessoa que vai receber a cobrança',
                      'String',
                      'Body',
                      'Sim',
                    ],
                    [
                      'charge[expiration_date]',
                      'Data de expiração da cobrança(caso não informada, irá ser gerada como padrão para 2 dias após a criação)',
                      'String',
                      'Body',
                      'Não',
                    ],
                    [
                      'charge[code]',
                      'Código da cobrança',
                      'String',
                      'Body',
                      'Sim',
                    ],
                    [
                      'charge[description]',
                      'Descrição da cobrança',
                      'String',
                      'Body',
                      'Sim',
                    ],
                    [
                      'charge[products]',
                      'Array de produtos da cobrança',
                      'Array',
                      'Body',
                      'Sim',
                    ],
                    [
                      'charge[products][name]',
                      'Nome do produto',
                      'String',
                      'Body',
                      'Sim',
                    ],
                    [
                      'charge[products][price]',
                      'Preço do produto',
                      'String',
                      'Body',
                      'Sim',
                    ],
                    [
                      'charge[products][quantity]',
                      'Quantidade do produto',
                      'String',
                      'Body',
                      'Sim',
                    ],
                  ]}
                />
              </Section>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ButtonBaseComponent
                    nextName="Pagamentos"
                    previousName="Transações"
                    previousRoute={routes.documentation.order}
                    nextRoute={routes.documentation.payments}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={2}>
              <OnPage page={onPage} />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  )
}

export default OneTimeCharge
