import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core'

import logo from 'images/logo-superfin.png'
import styles from './styles'

import LogoLovers from 'images/logosmalllovers.png'
import useAuth from 'hooks/useAuth'

const useStyles = makeStyles(styles)

const ScreenLoading = ({ loaded, children, bgColor }) => {
  const classes = useStyles()
  const auth = useAuth()

  return (
    <>
      {loaded && (
        <div className={`${classes.root} ${loaded && classes.rootLoaded}`}>
          <div
            style={{ backgroundColor: bgColor }}
            className={`${classes.bg} ${loaded && classes.bgLoaded}`}
          />
          <figure className={`${classes.logo} ${loaded && classes.logoLoaded}`}>
            <img
              alt="Logo"
              src={
                auth?.account?.partnerGroup === 'leadlovers' ? LogoLovers : logo
              }
              width="100%"
            />
          </figure>
        </div>
      )}
      {loaded && children}
    </>
  )
}

ScreenLoading.propTypes = {
  loaded: PropTypes.bool.isRequired,
  children: PropTypes.any,
}

export default ScreenLoading
