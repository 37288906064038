import constants from 'constants/index'

const balanceControlStatus = (status) => {
  switch (status) {
    case constants.balanceControls.STATUSES.WAITING_PAYMENT_STATUS:
      return constants.balanceControls.STATUSES_LABELS.WAITING_PAYMENT_LABEL
    case constants.balanceControls.STATUSES.PAID_STATUS:
      return constants.balanceControls.STATUSES_LABELS.PAID_LABEL
    default:
      return ''
  }
}

export default balanceControlStatus
