const WAITING_ACTIVATION_STATUS = '1'
const ACTIVE_STATUS = '2'
const OVERDUED_STATUS = '3'
const CANCELED_STATUS = '4'

const WAITING_ACTIVATION_LABEL = 'AGUARDANDO ATIVAÇÃO'
const ACTIVE_LABEL = 'ATIVO'
const OVERDUED_LABEL = 'ATRASADO'
const CANCELED_LABEL = 'CANCELADO'

const STATUSES = {
  WAITING_ACTIVATION_STATUS,
  ACTIVE_STATUS,
  OVERDUED_STATUS,
  CANCELED_STATUS,
}

const STATUSES_LABELS = {
  WAITING_ACTIVATION_LABEL,
  ACTIVE_LABEL,
  OVERDUED_LABEL,
  CANCELED_LABEL,
}

const subscriptions = {
  STATUSES,
  STATUSES_LABELS,
}

export default subscriptions
