import { Container, Page, ContentHeader } from 'components'
import {
  Box,
  makeStyles,
  Grid,
  Typography,
  Button,
  IconButton,
} from '@material-ui/core'
import {
  Copy as CopyIcon,
  User as UserIcon,
  Key as KeyIcon,
} from 'react-feather'

import { useHistory } from 'react-router-dom'
import useAuth from 'hooks/useAuth'
import useSnackbar from 'hooks/useSnackbar'
import { useState, useEffect } from 'react'

import styles from './styles'
import { routes } from 'Routes'
import * as service from 'service'

const useStyles = makeStyles(styles)

const SettingsImplementation = () => {
  const classes = useStyles()
  const history = useHistory()
  const snackbar = useSnackbar()
  const { user } = useAuth()
  const [sellerKey, setSellerKey] = useState('')

  useEffect(() => {
    const loadSellerKey = async () => {
      const response = await service.intermediador.accounts.showSellerKey()
      setSellerKey(response?.data?.sellerKey)
    }
    loadSellerKey()
  }, [])

  const resetSellerKey = async () => {
    try {
      let response = await service.intermediador.accounts.resetSellerKey()

      snackbar.open({
        message: `Chave principal alterada!`,
        variant: 'success',
      })

      setSellerKey(response?.data?.sellerKey)
    } catch (error) {
      snackbar.open({
        message: 'Erro ao alterar a chave principal',
        variant: 'error',
      })
    }
  }

  return (
    <Page className={classes.root} title="Informações pessoais">
      <Container maxWidth="sm">
        <Grid container spacing={8}>
          <ContentHeader subtitle="Implementação" />
          <Box mb={8} mt={8} width="100%">
            <Typography variant="h6" color="textPrimary">
              Código de autenticação!
            </Typography>
            <Typography variant="body1" color="textSecondary">
              Utilize este código para fazer login na API da Superfin
            </Typography>
          </Box>
          <Grid item xs={12} className={classes.card}>
            <Box mb={3} display="flex">
              <Box heigth="100%" mr={2}>
                <Box
                  p={8}
                  display="flex"
                  alignItems="center"
                  className={classes.iconBox}
                >
                  <UserIcon size={22} color="#63bbdb" />
                </Box>
              </Box>
              <Box width="100%" overflow="hidden">
                <Typography variant="body1" color="textPrimary">
                  ID da conta (seller_token)
                </Typography>
                <Box
                  className={classes.tokenBox}
                  pl={2}
                  mt={1}
                  mb={1}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box width="100%" overflow="hidden" textOverflow="ellipsis">
                    <Typography variant="body2" color="textPrimary">
                      {user?.account?.id}
                    </Typography>
                  </Box>
                  <IconButton
                    color="secondary"
                    component="span"
                    onClick={() => {
                      navigator.clipboard.writeText(user?.account?.id)
                      snackbar.open({
                        message: `ID da conta copiado!`,
                        variant: 'success',
                      })
                    }}
                  >
                    <CopyIcon size={22} color="#63bbdb" />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} className={classes.card}>
            <Box mb={8} display="flex">
              <Box heigth="100%" mr={2}>
                <Box
                  p={8}
                  display="flex"
                  alignItems="center"
                  className={classes.iconBox}
                >
                  <KeyIcon size={22} color="#63bbdb" />
                </Box>
              </Box>
              <Box width="100%" overflow="hidden">
                <Typography variant="body1" color="textPrimary">
                  Chave Principal (seller_key)
                </Typography>
                <Box
                  className={classes.tokenBox}
                  pl={2}
                  mt={1}
                  mb={1}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box width="100%" overflow="hidden" textOverflow="ellipsis">
                    <Typography variant="body2" color="textPrimary">
                      {sellerKey}
                    </Typography>
                  </Box>
                  <IconButton
                    color="#63bbdb"
                    component="span"
                    onClick={() => {
                      navigator.clipboard.writeText(sellerKey)
                      snackbar.open({
                        message: `Chave principal copiada!`,
                        variant: 'success',
                      })
                    }}
                  >
                    <CopyIcon size={22} color="#63bbdb" />
                  </IconButton>
                </Box>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    resetSellerKey()
                  }}
                >
                  Nova chave
                </Button>
              </Box>
            </Box>
          </Grid>
          <Button onClick={() => history.push(routes.settings.main)}>
            Voltar
          </Button>
        </Grid>
      </Container>
    </Page>
  )
}

export default SettingsImplementation
