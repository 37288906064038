export const getBaseURL = (project) => envsBaseUrl[project][env]

const env = process.env.REACT_APP_API || 'development'

const companyToken = {
  development: '85081a949e63b592cc511566374365b2',
  test: '85081a949e63b592cc511566374365b2',
  sandbox: '85081a949e63b592cc511566374365b2',
  production: '34990fb9004ad5c64ca3d94ca9ce0348',
}

export const COMPANY_TOKEN_LOGIN_WITH_ID = companyToken[env]

const envsBaseUrl = {
  intermediador: {
    development: 'http://localhost:3009/',
    test: 'https://api.test.superfin.com.br/',
    sandbox: 'https://api.sandbox.superfin.com.br/',
    production: 'https://api.superfin.com.br/',
  },
  gateway: {
    development: 'https://gw.sandbox.leavening.com/',
    test: 'https://api.gw.test.superfin.com.br/',
    sandbox: 'https://api.gw.sandbox.superfin.com.br/',
  },
  auth: {
    development: 'https://auth.sandbox.oneid.com.br/auth.js',
    test: 'https://auth.sandbox.oneid.com.br/auth.js',
    sandbox: 'https://auth.sandbox.oneid.com.br/auth.js',
    production: 'https://auth.oneid.com.br/auth.js',
  },
  /*webSocket: {
    development: 'ws://localhost:3009/',
    sandbox: 'wss://api.sandbox.superfin.com.br/',
    test: 'wss://api.test.superfin.com.br/',
    production: 'wss://api.superfin.com.br/',
  },*/
}
