import { Container, Page, LabelView, Header } from 'components'
import { Box, makeStyles, Grid, Button } from '@material-ui/core'

import { useHistory } from 'react-router-dom'
import useAuth from 'hooks/useAuth'

import styles from './styles'
import helpers from 'helpers'
import { routes } from 'Routes'

const useStyles = makeStyles(styles)

const ProfilesDetail = () => {
  const classes = useStyles()
  const history = useHistory()
  const { user } = useAuth()

  return (
    <Page title="Informações pessoais">
      <Container maxWidth="sm">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Header name="Perfil" route={routes.settings.main} />
          </Grid>
          <Grid item lg={12} sm={12} xs={12}>
            <Box className={classes.card}>
              <LabelView label="Nome" value={user?.name} />
              <LabelView
                label={user?.document?.type}
                value={
                  user?.document?.type === 'CPF'
                    ? helpers.formatters.cpf(user?.document?.value)
                    : helpers.formatters.cnpj(user?.document?.value)
                }
              />
              <LabelView label="E-mail" value={user?.email} />
              <LabelView
                label="Data de nascimento"
                value={helpers.formatters.date(user?.birthDate)}
              />
            </Box>
            <Box mt={4}>
              <Button onClick={() => history.push(routes.settings.main)}>
                Voltar
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}

export default ProfilesDetail
