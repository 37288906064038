import api from 'service/api'

const intermediadorAPI = api.create('intermediador')

const get = async ({ ...params }) => {
  return await intermediadorAPI.get('/balance_controls', { params })
}

const balanceControls = {
  get,
}

export default balanceControls
