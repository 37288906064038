import React, { useState } from 'react'

import {
  Box,
  makeStyles,
  Typography,
  Grid,
  Tooltip,
  withStyles,
  TextField,
} from '@material-ui/core'
import styles from './styles'
import helpers from 'helpers'
import LogoMastercard from 'images/image-mastercard.svg'
import LogoVisa from 'images/image-visa.svg'
import LogoAmec from 'images/image-amec.svg'
import LogoElo from 'images/image-elo.svg'
import LogoHipercard from 'images/image-hipercard.svg'
import LogoBoleto from 'images/image-boleto.svg'
import LogoPix from 'images/image-pix.svg'
import LogoDiners from 'images/image-diners.svg'
import LogoBanes from 'images/image-banes.svg'
import LogoCabal from 'images/image-cabal.svg'
import LogoJcb from 'images/image-jcb.svg'

import checkoutTheme from 'theme/checkoutTheme'

const useStyles = makeStyles(styles)

const ProductDetails = ({ order, notPaid = true, type, onOrderUpdate }) => {
  const classes = useStyles()
  const [orders, setOrders] = useState(order)

  const images = [
    { name: 'mastercard', src: LogoMastercard },
    { name: 'visa', src: LogoVisa },
    { name: 'amec', src: LogoAmec },
    { name: 'elo', src: LogoElo },
    { name: 'hipercard', src: LogoHipercard },
    { name: 'boleto', src: LogoBoleto },
    { name: 'pix', src: LogoPix },
    { name: 'diners', src: LogoDiners },
    { name: 'banes', src: LogoBanes },
    { name: 'cabal', src: LogoCabal },
    { name: 'jcb', src: LogoJcb },
  ]

  const truncateText = (text, lenghtText) => {
    let result = null

    if (text.length > lenghtText) {
      result = text.substring(0, lenghtText) + '...'
    }
    return result ? result : text
  }

  const applyDiscount = (originalValue) => {
    const numericValue = parseFloat(originalValue)

    if (!isNaN(numericValue)) {
      const discount = (numericValue * 0.1).toFixed(2)
      const finalValue = numericValue - discount

      return finalValue
    }
  }

  const LightTooltip = withStyles((checkoutTheme) => ({
    tooltip: {
      backgroundColor: checkoutTheme.palette.white,
      fontSize: 14,
    },
  }))(Tooltip)

  const sumProductValues = (products) => {
    if (!products || products.length === 0) {
      return 0
    }

    const totalValue = products.reduce((acc, product) => {
      if (product.name !== 'Desconto') {
        const price = parseFloat(product.price)
        const quantity = parseInt(product.quantity, 10)

        if (!isNaN(price) && !isNaN(quantity)) {
          acc += price * quantity
        }
      }

      return acc
    }, 0)

    return totalValue
  }

  const handleNumberChange = (event, index) => {
    const newValue = event.target.value

    if (/^\d+$/.test(newValue) || newValue === '') {
      const updatedProducts = [...orders.products]

      updatedProducts[index] = {
        ...updatedProducts[index],
        quantity: newValue === '' ? '' : parseInt(newValue, 10),
      }

      const updatedOrder = {
        ...orders,
        // value: sumProductValues(updatedProducts),
        products: updatedProducts,
      }

      setOrders(updatedOrder)
      onOrderUpdate(updatedOrder)
    }
  }

  const BoxProduct = () => {
    return (
      <Grid container spacing={2}>
        <Grid item lg={12} sm={12} xs={12}>
          <Typography variant="body1" className={classes.lightBlueText}>
            Você está comprando
          </Typography>
        </Grid>
        <Grid item lg={12} sm={12} xs={12}>
          {orders?.products.map((product, index) => (
            <Grid container spacing={2} key={index}>
              {product?.name !== 'Desconto' && (
                <Grid item lg={12} sm={12} xs={12}>
                  <Grid item lg={12} sm={12} xs={12}>
                    <Box display="flex" flexDirection="row" alignItems="start">
                      <LightTooltip
                        title={
                          <Typography variant="body2">
                            {product?.name}
                          </Typography>
                        }
                        enterTouchDelay={0}
                      >
                        <Typography
                          variant="body1"
                          style={{ maxWidth: '100%' }}
                          className={classes.blueText}
                        >
                          {product?.name}
                        </Typography>
                      </LightTooltip>
                      {/* <Typography variant="h6" className={classes.blueText}>
                            ({product?.quantity})
                          </Typography> */}
                    </Box>
                  </Grid>
                  <Grid item lg={12} sm={12} xs={12}>
                    {product?.code && (
                      <LightTooltip
                        title={
                          <Typography variant="body2">
                            {product?.code}
                          </Typography>
                        }
                        enterTouchDelay={0}
                      >
                        <Typography className={classes.blueTextCode}>
                          Código: {product?.code} 33333
                        </Typography>
                      </LightTooltip>
                    )}
                  </Grid>
                  <Grid item lg={12} sm={12} xs={12}>
                    <LightTooltip
                      title={
                        <Typography variant="body2">
                          {product?.description}
                        </Typography>
                      }
                      enterTouchDelay={0}
                      style={{ color: '#000' }}
                    >
                      <Typography
                        variant="body2"
                        className={classes.blueDescription}
                      >
                        {truncateText(product?.description, 40)}
                      </Typography>
                    </LightTooltip>
                  </Grid>
                  <Grid item lg={12} sm={12} xs={12}>
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="start"
                      mt={1}
                    >
                      <Typography variant="body2" className={classes.price}>
                        R${' '}
                        {helpers.formatters.floatToMoney(
                          parseFloat(product?.price) * product?.quantity,
                        )}
                      </Typography>
                      <Box ml={2}>
                        <form noValidate autoComplete="off">
                          <TextField
                            type="number"
                            color="primary"
                            variant="outlined"
                            label="Quant."
                            size="small"
                            value={product?.quantity}
                            onChange={(e) => handleNumberChange(e, index)}
                            style={{ width: '80px' }}
                            inputProps={{
                              min: '1',
                              inputMode: 'numeric',
                              style: {
                                padding: '8px',
                                fontSize: '12px',
                                // width: '80px',
                              },
                            }}
                          />
                        </form>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              )}
            </Grid>
          ))}
        </Grid>
        <Grid item lg={12} sm={12} xs={12}>
          <Box display="flex" flexDirection="column" alignItems="start">
            <Typography variant="body1" className={classes.blueText}>
              Total
            </Typography>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="start"
              alignItems="center"
              style={{ width: '100%' }}
            >
              <Typography variant="body1" className={classes.blueTextSecondary}>
                R$
              </Typography>
              <Typography variant="h6" className={classes.blueTextMain}>
                {helpers.formatters.floatToMoney(
                  Number(applyDiscount(sumProductValues(orders?.products))),
                )}
              </Typography>
              <Typography
                variant="body2"
                className={classes.blueDescriptionSecondary}
              >
                no Pix/Boleto
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="body2"
                className={classes.blueDescriptionSecondary}
                style={{ color: checkoutTheme.palette.success.main }}
              >
                (10% de desconto)
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="body2"
                className={classes.blueDescriptionSecondary}
              >
                ou R${' '}
                {helpers.formatters.floatToMoney(
                  Number(sumProductValues(orders?.products)),
                )}{' '}
                no cartão sem juros
              </Typography>
            </Box>
            <Box style={{ marginTop: '20px' }}>
              <Typography variant="body2" color="textSecondary">
                Aceitamos:
                <br />
              </Typography>
            </Box>
            <Box
              display="flex"
              alignItems="flex-start"
              flexWrap="wrap"
              justifyContent="space-around"
            >
              {images?.map((image, index) => (
                <img
                  key={index}
                  alt={image.name}
                  src={image.src}
                  style={{
                    flexBasis: 'calc(18% - 10px)',
                    marginBottom: checkoutTheme.spacing(6),
                  }}
                  height="28px"
                />
              ))}
            </Box>
          </Box>
        </Grid>
      </Grid>
    )
  }

  const BoxOrder = () => {
    return (
      <Grid container spacing={2}>
        <Grid item lg={12} sm={12} xs={12}>
          <Typography variant="body1" className={classes.lightBlueText}>
            Você está comprando
          </Typography>
        </Grid>
        <Grid item lg={12} sm={12} xs={12}>
          {orders?.products.map((product, index) => (
            <Grid container spacing={2} key={index}>
              {product?.name !== 'Desconto' && (
                <Grid item lg={12} sm={12} xs={12}>
                  <Grid item lg={12} sm={12} xs={12}>
                    <Box display="flex" flexDirection="row" alignItems="start">
                      <LightTooltip
                        title={
                          <Typography variant="body2">
                            {product?.name}
                          </Typography>
                        }
                        enterTouchDelay={0}
                      >
                        <Typography
                          variant="body1"
                          style={{ maxWidth: '90%' }}
                          className={classes.blueText}
                        >
                          {product?.name}
                        </Typography>
                      </LightTooltip>
                      <Typography variant="body1" className={classes.blueText}>
                        ({product?.quantity})
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={12} sm={12} xs={12}>
                    <LightTooltip
                      title={
                        <Typography variant="body2">
                          {product?.description}
                        </Typography>
                      }
                      enterTouchDelay={0}
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: 'red',
                            '& .MuiTooltip-arrow': {
                              color: 'common.black',
                            },
                          },
                        },
                      }}
                    >
                      <Typography
                        className={classes.blueDescription}
                        variant="body1"
                      >
                        {truncateText(product?.description, 40)}
                      </Typography>
                    </LightTooltip>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography variant="body1" className={classes.price}>
                      R${' '}
                      {helpers.formatters.floatToMoney(
                        parseFloat(product?.price) * product?.quantity,
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          ))}
        </Grid>
        <Grid item lg={12} sm={12} xs={12}>
          <Box mt={checkoutTheme.spacing(4)}>
            <Typography variant="body1" className={classes.blueText}>
              Total
            </Typography>
          </Box>
          <Box display="flex" direction="row" alignItems="center">
            <Typography variant="body2" className={classes.blueTextSecondary}>
              R$
            </Typography>
            <Typography variant="h6" className={classes.blueTextMain}>
              {helpers.formatters.floatToMoney(Number(order?.value))}
            </Typography>
          </Box>
          <Box style={{ marginTop: '20px' }}>
            <Typography variant="body2" color="textSecondary">
              Aceitamos:
              <br />
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="flex-start"
            flexWrap="wrap"
            justifyContent="space-around"
          >
            {images?.map((image, index) => (
              <img
                key={index}
                alt={image.name}
                src={image.src}
                style={{
                  flexBasis: 'calc(18% - 10px)',
                  marginBottom: checkoutTheme.spacing(6),
                }}
                height="28px"
              />
            ))}
          </Box>
        </Grid>
      </Grid>
    )
  }

  return (
    <Box>
      {type === 'order' || type === 'plan' ? <BoxOrder /> : <BoxProduct />}
    </Box>
  )
}

export default ProductDetails
