const styles = (checkoutTheme) => ({
  pixCodeBox: {
    backgroundColor: checkoutTheme.palette.background.paper,
    borderRadius: checkoutTheme.spacing(20),
  },
  pixCode: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '95%',
    fontSize: '12px',
  },
  buttonCopy: {
    borderRadius: checkoutTheme.spacing(20),
  },
  qrCodeBox: {
    backgroundColor: checkoutTheme.palette.background.paper,
    padding: checkoutTheme.spacing(2),
    borderRadius: checkoutTheme.spacing(2),
  },
})

export default styles
