const creditCardNumber = (cardNumber) => {
  if (cardNumber === null || cardNumber === undefined) return ''

  let l = cardNumber.length

  if (l <= 3) return cardNumber

  cardNumber = cardNumber.split('-').join('').split(' ').join('')

  l = cardNumber.length

  if (l <= 4) return cardNumber.replace(/^(\d{4})*/, '$1')

  if (l <= 8 && l > 4) return cardNumber.replace(/^(\d{4})(\d{1,4})*/, '$1 $2')

  if (l <= 12 && l > 8)
    return cardNumber.replace(/^(\d{4})(\d{1,4})(\d{1,4})*/, '$1 $2 $3')

  return cardNumber.replace(
    /^(\d{4})(\d{1,4})(\d{1,4})(\d{1,4})*/,
    '$1 $2 $3 $4',
  )
}

export default creditCardNumber
