const translateStatus = (status) => {
  switch (status) {
    case 'paid':
      return 'Pago'
    case 'waiting_payment':
      return 'Aguardando pagamento'
    case 'waiting_confirm':
      return 'Aguardando confirmação'
    default:
      return 'Cancelado'
  }
}

export default translateStatus
