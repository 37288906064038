import {
  Box,
  makeStyles,
  Typography,
  Button,
  Container,
} from '@material-ui/core'
import { ContentHeader, Page, Breadcrumbs } from 'components'
import styles from './styles'
import { routes } from 'Routes'
import { useLocation } from 'react-router-dom'

const useStyles = makeStyles(styles)

const ChargeBilletInfo = () => {
  const location = useLocation()
  const billet = location.state?.billet
  const classes = useStyles()

  return (
    <Page title="Nova cobrança">
      <Container maxWidth={false} className={classes.rootNew}>
        <Breadcrumbs
          mainPage="Cobrança"
          backPage="Listagem"
          page="Boleto"
          href={routes.charges.main}
        />
        <Box mt={1}>
          <ContentHeader subtitle="Boleto" />
        </Box>
        <Box mt={4}>
          <Box p={2}>
            <Typography variant="h5">Código de barras</Typography>
          </Box>
          <Box maxWidth p={3}>
            <Box
              display="flex"
              justifyContent="center"
              className={classes.barCode}
              p={3}
            >
              <Typography variant="h5">{billet?.digitableLine}</Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            p={1}
            width="100%"
          >
            <Button
              color="primary"
              variant="contained"
              className={classes.roundedButton}
              onClick={() => {
                navigator.clipboard.writeText(billet?.digitableLine)
              }}
            >
              Copiar código de barras
            </Button>
            <Box ml={4}>
              <Button
                color="primary"
                variant="contained"
                className={classes.roundedButton}
                onClick={() => {
                  window.location.href = billet.url
                }}
              >
                Baixar Boleto
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </Page>
  )
}

export default ChargeBilletInfo
