import { Box, makeStyles, Typography } from '@material-ui/core'

import { Check } from 'react-feather'
import styles from './styles'

const useStyles = makeStyles(styles)

const SuccessPayment = () => {
  const classes = useStyles()

  return (
    <Box mt={1} p={2} className={classes.successBox}>
      <Box mr={1}>
        <Check className={classes.image} />
      </Box>
      <Typography>Pagamento efetuado com sucesso!</Typography>
    </Box>
  )
}

export default SuccessPayment
