import upperText from './upperText'
import capitalizeText from './capitalizeText'
import reduceRoutesToArray from './reduceRoutesToArray'
import isEmpty from './isEmpty'
import mountTitle from './mountTitle'
import orderStatusColor from './orderStatusColor'
import orderStatusIcon from './orderStatusIcon'

const functions = {
  upperText,
  capitalizeText,
  reduceRoutesToArray,
  isEmpty,
  mountTitle,
  orderStatusColor,
  orderStatusIcon,
}

export default functions
