const translateStatus = (status) => {
  switch (status) {
    case 1:
      return 'Aguardando Ativação'
    case 2:
      return 'Ativa'
    case 3:
      return 'Atrasada'
    default:
      return 'Cancelada'
  }
}

export default translateStatus
