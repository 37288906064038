import api from 'service/api'

const intermediadorAPI = api.create('intermediador', false)

const get = async ({ ...params }) => {
  return await intermediadorAPI.get('/account_statements', { params })
}

const accountStatements = {
  get,
}

export default accountStatements
