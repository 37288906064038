import { Grid, makeStyles, Typography } from '@material-ui/core'

import { ContentCard } from './components'

import { Container, Page, Header, Video, AccordionCard } from 'components'

import apiImage from './components/images/API.png'
import pagamentos from './components/images/Pagamentos.png'
import assinatura from './components/images/Assinatura.png'
import avulsa from './components/images/avulsa.png'

// import primeiroAcesso from './components/images/primeiroAcesso.png'
// import cobrancaRecorrente from './components/images/cobrancaRecorrente.png'
// import cobrançaAvulsa from './components/images/cobrançaAvulsa.png'
// import planos from './components/images/planos.png'
// import banco from './components/images/banco.png'
// import saque from './components/images/saque.png'

import styles from './styles'
import { routes } from 'Routes'

const useStyles = makeStyles(styles)

const QuickGuide = () => {
  const classes = useStyles()

  return (
    <Page className={classes.root2} title="Início">
      <Container maxWidth="md">
        <Grid container spacing={8}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Header name="Guia Rápido" route={routes.home} />
          </Grid>
          {/* <Grid item xs={12} sm={12} md={12} lg={12}>
            <AccountStatus />
          </Grid> */}
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <Video
              title="Como criar a primeira cobrança avulsa?"
              link="https://www.youtube.com/embed/1QiYiVbinyY?si=OPWhRTVwuz9Jdnp_"
              width="690"
              height="415"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <Video
              title="Como criar a primeira cobrança recorrente?"
              link="https://www.youtube.com/embed/v6_uABGUzfY?si=s4ZTEWRudmajE2QJ"
              width="690"
              height="415"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <Video
              title="Como criar os planos?"
              link="https://www.youtube.com/embed/_IF4vFIbuDQ?si=Q5k3KAiqpK7IByoC"
              width="690"
              height="415"
            />
          </Grid>
          {/* <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography variant="h6" color="textPrimary">
              Recompensas
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <AchievementCard
              title="Primeira acesso"
              progress={100}
              urlImage={primeiroAcesso}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <AchievementCard
              title="Primeira cobrança avulsa"
              progress={0}
              urlImage={cobrançaAvulsa}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <AchievementCard
              title="Primeira cobrança recorrente"
              progress={0}
              urlImage={cobrancaRecorrente}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <AchievementCard
              title="Primeiro saque"
              progress={0}
              urlImage={saque}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <AchievementCard
              title="Registro de banco"
              progress={0}
              urlImage={banco}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <AchievementCard
              title="Crie o seu primeiro plano"
              progress={0}
              urlImage={planos}
            />
          </Grid> */}
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography variant="h6" color="textPrimary">
              Materiais
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <ContentCard
              title="Cobrança avulsa"
              description="Baixe o arquivo e aprenda a fazer a primeira cobrança avulsa. É fácil e rápido!"
              imageUrl={avulsa}
              link="https://docs.google.com/document/d/1IBRMkliHlbD7Q2ZXf9bsdUpyHUXHRaBvSI840v2WUnA/edit?usp=sharing"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <ContentCard
              title="Cobrança Recorrente"
              description="Aprenda a criar uma assinatura e receber cobranças recorrentes."
              imageUrl={assinatura}
              link="https://docs.google.com/document/d/1RnbJH9f75n34k0YQs4usDdmtOQjyMO_E1y8QEQVfgy8/edit?usp=sharing"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <ContentCard
              title="Personalização do carrinho"
              description="Aprenda a personalizar o seu carrinho de compras para seus clientes."
              imageUrl={pagamentos}
              link="https://docs.google.com/document/d/1IM_IOc6MaUzSNnzw9gWtWDjeWO8WdvVTntMd8KYcm7M/edit?usp=sharing"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <ContentCard
              title="API"
              description="Implemente a API da superfin no seu carrinho de pagamento"
              imageUrl={apiImage}
              link="https://docs.google.com/document/d/1Cv4iU_PaPvYwSpVA9rGI8goHqzbrtguiEdvUoIjeq5k/edit?usp=drive_link"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography variant="h6" color="textPrimary">
              Perguntas frequentes
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <AccordionCard
              title="Como depositar o saldo da conta Superfin na minha conta?"
              description="Para depositar dinheiro em sua conta, você precisa registrar uma conta principal na Superfin, no menu Configurações/Banco."
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <AccordionCard
              title="Posso desenvolver meu próprio carrinho de pagamentos com a API da Superfin? Como faço isso?"
              description="Sim. Caso você queira desenvolver seu próprio carrinho, pode usar a API da Superfin. Disponibilizamos o token e a documentação para implementação."
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}

export default QuickGuide
