import palette from 'theme/palette'

const MuiOutlinedInput = {
  root: {
    '&:hover $notchedOutline': {
      borderColor: palette.primary.main,
      // backgroundColor: 'red',
    },
    borderRadius: '40px',
    '& .MuiOutlinedInput-root': {
      backgroundColor: palette.background.paper,
      '& fieldset': {
        color: palette.text.primary,
      },
      '&:hover fieldset': {
        color: palette.text.primary,
      },
      '&.Mui-focused fieldset': {
        color: palette.text.primary,
      },
    },
  },
  notchedOutline: {
    // borderColor: palette.line.default,
    // color: 'red',
  },
}
export default MuiOutlinedInput
