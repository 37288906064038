import { Box, Typography, makeStyles, Grid } from '@material-ui/core'

import styles from './styles'

const useStyles = makeStyles(styles)

const ContentHeader = ({ menu, title, subtitle, children }) => {
  const classes = useStyles()

  return (
    <Box display="flex" justifyContent="space-between" alignItems="flex-end">
      <Grid container>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          {menu && (
            <Typography
              className={classes.menu}
              variant="body1"
              color="textPrimary"
            >
              {menu}
            </Typography>
          )}
          {title && (
            <Typography
              className={classes.title}
              variant="body1"
              color="textPrimary"
            >
              {title}
            </Typography>
          )}
          {subtitle && (
            <Typography variant="h6" color="textPrimary">
              {subtitle}
            </Typography>
          )}
        </Grid>
        {children && (
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Box display="flex" justifyContent="flex-end">
              {children}
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default ContentHeader
