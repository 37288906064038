import { useState } from 'react'
import { TypographyTheme, ColorsTheme } from './components'
import {
  Button,
  Drawer,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from '@material-ui/core'
import checkoutTheme from 'theme/checkoutTheme'
import { ThemeProvider } from '@material-ui/core'

import theme from 'theme'
import { useHistory } from 'react-router-dom'

import { routes } from 'Routes'
import * as service from 'service'
import useSnackbar from 'hooks/useSnackbar'

import {
  Type as TypeIcon,
  Droplet as DropletIcon,
  Home as HomeIcon,
  RefreshCcw as RefreshCcwIcon,
} from 'react-feather'

const EditPage = ({ changeColor, customPalette }) => {
  const [open, setOpen] = useState(false)
  const [openDialog, setDialogOpen] = useState(false)
  const [type, setType] = useState('')
  const history = useHistory()
  const snackbar = useSnackbar()

  const toggleDrawer = (value) => {
    setOpen(!open)
    setType(value)
  }

  const handleCloseDrawer = () => {
    setOpen(false)
  }

  const handleClickOpen = () => {
    setDialogOpen(true)
  }

  const handleClose = () => {
    setDialogOpen(false)
  }

  const resetTheme = async () => {
    handleClose()
    try {
      await service.intermediador.accountCustomizations.create({
        tag: 'theme',
        data: false,
      })
      snackbar.open({
        variant: 'success',
        message: 'Personalização será alterado para padrão superfin, aguarde!',
      })
      window.location.reload()
    } catch (error) {
      snackbar.open({
        variant: 'error',
        message: 'Erro ao aplicar a personalização',
      })
      return null
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Box
        style={{
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          left: '20px',
          backgroundColor: '#FFF',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: checkoutTheme.spacing(20),
          zIndex: 100,
        }}
      >
        <Tooltip title="Voltar para página inicial">
          <Button
            onClick={() => history.push(routes.home)}
            style={{ width: '64px', height: '64px', borderRadius: '40px' }}
          >
            <HomeIcon size={16} color="#C9691A" />
          </Button>
        </Tooltip>
        <Tooltip title="Design e Cores">
          <Button
            onClick={() => toggleDrawer('colors')}
            style={{ width: '64px', height: '64px', borderRadius: '40px' }}
          >
            <DropletIcon size={16} color="#EB5B9A" />
          </Button>
        </Tooltip>
        <Tooltip title="Tipografia">
          <Button
            onClick={() => toggleDrawer('typography')}
            style={{ width: '64px', height: '64px', borderRadius: '40px' }}
          >
            <TypeIcon size={16} color="#5A71EB" />
          </Button>
        </Tooltip>
        <Tooltip title="Reset">
          <Button
            onClick={handleClickOpen}
            style={{ width: '64px', height: '64px', borderRadius: '40px' }}
          >
            <RefreshCcwIcon size={16} color="#EA4110" />
          </Button>
        </Tooltip>

        <Drawer anchor="left" open={open} onClose={handleCloseDrawer}>
          <Box role="presentation" p={checkoutTheme.spacing(20)}>
            {type === 'colors' && (
              <ColorsTheme
                changeColor={changeColor}
                customPalette={customPalette}
              />
            )}
            {type === 'typography' && (
              <TypographyTheme
                changeColor={changeColor}
                customTypography={customPalette}
              />
            )}
            {/* <Responsive onClickTypeView={onClickTypeView} /> */}
            <Button onClick={handleCloseDrawer} style={{ width: '100%' }}>
              Fechar
            </Button>
          </Box>
        </Drawer>
      </Box>
      {/* <Box
        style={{
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          right: '20px',
          backgroundColor: '#FFF',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: checkoutTheme.spacing(10),
          padding: '20px',
        }}
      >
        <Typography variant="body2">Janelas</Typography>
        <Button
          onClick={() => history.push(routes.previewCheckoutPlans)}
          style={{
            backgroundColor: 'red',
            width: '80px',
            height: '60px',
            borderRadius: checkoutTheme.spacing(10),
          }}
        >
          Planos
        </Button>
        <Button
          onClick={() => history.push(routes.previewCheckout.main)}
          style={{
            backgroundColor: 'red',
            width: '80px',
            height: '60px',
            borderRadius: checkoutTheme.spacing(10),
          }}
        >
          Carrinho
        </Button>
        <Button
          style={{
            backgroundColor: 'red',
            width: '80px',
            height: '60px',
            borderRadius: checkoutTheme.spacing(10),
          }}
          onClick={() => {
            alert('Olá')
          }}
        >
          Aprovado
        </Button>
      </Box> */}
      <Dialog open={openDialog} onClose={handleClose}>
        <DialogTitle>Você deseja resetar o tema para o padrão?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Assim que aceitar, o tema será alterado para o padrão Superfin e
            tudo o que você fez será apagado!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={resetTheme} color="primary" autoFocus>
            Aceitar
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}

export default EditPage
