import clsx from 'clsx'

import { AppBar, Toolbar, Box, makeStyles } from '@material-ui/core'

import styles from './styles'
import Logo from 'images/logo-horizontal-y.png'

const useStyles = makeStyles(styles)

const TopBar = ({ ...rest }) => {
  const classes = useStyles()

  return (
    <AppBar className={clsx(classes.root)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Box mb={1}>
          <img alt="Logo" src={Logo} className={classes.logo} width="100%" />
        </Box>
        <Box ml={2} flexGrow={1} />
      </Toolbar>
    </AppBar>
  )
}

export default TopBar
