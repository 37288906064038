import * as yup from 'yup'

export const schema = yup.object().shape({
  name: yup.string().required(),
  document: yup.string().max(18).min(14).required(),
  email: yup.string().email().required(),
  code: yup.string().nullable(),
  planId: yup.number().required(),
  subscriptionCode: yup.string().required(),
})

export default schema
