import React, { useRef, useState } from 'react'
import {
  Avatar,
  Box,
  ButtonBase,
  Drawer,
  Button,
  Menu,
  MenuItem,
  makeStyles,
  Typography,
  Grid,
} from '@material-ui/core'

import { LogOut, Settings, Play } from 'react-feather'

import ChangeAccount from '../ChangeAccount'

import styles from './styles'

import * as service from 'service'

import useAuth from 'hooks/useAuth'

import { routes } from 'Routes'

import theme from 'theme'

const useStyles = makeStyles(styles)

const Account = () => {
  const classes = useStyles()
  const ref = useRef(null)
  const auth = useAuth()
  const [isOpen, setOpen] = useState(false)
  const [isChangeAccountOpen, setChangeAccountOpen] = useState(false)
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setState({ ...state, [anchor]: open })
  }

  // const list = (anchor) => (
  //   <div
  //     className={clsx(classes.list, {
  //       [classes.fullList]: anchor === 'top' || anchor === 'bottom',
  //     })}
  //     role="presentation"
  //     onClick={toggleDrawer(anchor, false)}
  //     onKeyDown={toggleDrawer(anchor, false)}
  //   >
  //     <List>
  //       {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
  //         <ListItem button key={text}>
  //           <ListItemIcon>
  //             {index % 2 === 0 ? <MessageCircle /> : <MessageCircle />}
  //           </ListItemIcon>
  //           <ListItemText primary={text} />
  //         </ListItem>
  //       ))}
  //     </List>
  //     <Divider />
  //     <List>
  //       {['All mail', 'Trash', 'Spam'].map((text, index) => (
  //         <ListItem button key={text}>
  //           <ListItemIcon>
  //             {index % 2 === 0 ? <MessageCircle /> : <MessageCircle />}
  //           </ListItemIcon>
  //           <ListItemText primary={text} />
  //         </ListItem>
  //       ))}
  //     </List>
  //   </div>
  // )

  // const handleOpen = () => {
  //   setOpen(true)
  // }

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpenChangeAccount = () => {
    setChangeAccountOpen(true)
  }

  const handleLogout = () => {
    service.intermediador.auth.logout()
    auth.setAuth(false)
    service.history.push(routes.root)
  }
  const handleSettings = () => {
    service.history.push(routes.settings.main)
  }

  const handleQuickGuide = () => {
    service.history.push(routes.quickGuide.main)
  }

  const avatarLetters = ((auth.user && auth.user.name) || 'Usuário')
    .split(' ')
    .map((word) => word[0])
    .join('')

  const AvatarIcon = (size) => {
    return (
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={toggleDrawer('right', true)}
        ref={ref}
      >
        <Avatar
          style={{
            height: size,
            width: size,
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
          }}
        >
          {avatarLetters[0]}
          {avatarLetters[avatarLetters.length - 1]}
        </Avatar>
        <Box
          style={{
            position: 'absolute',
            bottom: '0px',
            right: '0px',
            backgroundColor: '#4DF53D',
            border: `3px solid ${theme.palette.background.default}`,
            width: '16px',
            height: '16px',
            borderRadius: '50%',
          }}
        ></Box>
      </Box>
    )
  }

  return (
    <>
      <Box display="flex">
        <AvatarIcon size="45px!important" />
        <div>
          {['right'].map((anchor) => (
            <React.Fragment key={anchor}>
              <Drawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
              >
                <Box
                  style={{
                    width: '400px',
                    height: '100%',
                  }}
                >
                  <Grid container style={{ height: '100%' }}>
                    <Grid item xs={12} sm={12} md={2} lg={2}>
                      <Box
                        style={{
                          backgroundColor: 'rgba(0,169,0,1)',
                          height: '100%',
                        }}
                      >
                        <Button
                          onClick={handleQuickGuide}
                          style={{
                            width: '100%',
                            height: '60px',
                          }}
                        >
                          <Play size={24} color="#FFF" />
                        </Button>
                        <Button
                          onClick={handleSettings}
                          style={{
                            width: '100%',
                            height: '60px',
                          }}
                        >
                          <Settings size={24} color="#FFF" />
                        </Button>
                        <Button
                          color="error"
                          onClick={handleLogout}
                          style={{
                            width: '100%',
                            height: '60px',
                          }}
                        >
                          <LogOut size={24} color="#FFF" />
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={10} lg={10}>
                      <Box
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          background:
                            'radial-gradient(circle,rgba(0,169,0,1) 0%, rgba(0,171,0,1)  75%)',
                          padding: '20px',
                        }}
                      >
                        <AvatarIcon size="60px!important" />
                      </Box>

                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <Typography variant="h6" color="textPrimary">
                          {(auth.user && auth.user?.name) || 'Usuário'}
                        </Typography>
                        <Typography variant="body2" color="textPrimary">
                          Online
                        </Typography>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <Typography variant="body2" color="textPrimary">
                          {(auth.user && auth.user?.account?.name) || 'Usuário'}
                        </Typography>
                        <Typography variant="body2" color="textPrimary">
                          Brasil ● Português
                        </Typography>
                      </Box>
                      <Box></Box>
                    </Grid>
                  </Grid>
                </Box>
              </Drawer>
            </React.Fragment>
          ))}
        </div>
        <Menu
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          keepMounted
          PaperProps={{ className: classes.popover }}
          getContentAnchorEl={null}
          anchorEl={ref.current}
          open={isOpen}
        >
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography variant="body1" color="textPrimary">
              {(auth.user && auth.user?.name) || 'Usuário'}
            </Typography>
            <Typography variant="body2" color="textPrimary">
              {(auth.user && auth.user?.account?.name) || 'Usuário'}
            </Typography>
          </Box>
          {auth?.accounts?.length > 1 && (
            <MenuItem onClick={handleOpenChangeAccount}>
              Mudar de conta
            </MenuItem>
          )}
          <MenuItem onClick={handleSettings}>Configurações</MenuItem>
          <MenuItem onClick={handleLogout}>Sair</MenuItem>
        </Menu>

        <ChangeAccount
          open={isChangeAccountOpen}
          setOpen={setChangeAccountOpen}
        />
      </Box>
    </>
  )
}

export default Account
