import { Container, Page, Header, AccordionCard } from 'components'
import { SubscriptionsForm } from './components'
import { makeStyles, Grid, Typography, Box } from '@material-ui/core'

import styles from './styles'

import { routes } from 'Routes'

import constants from 'constants/index'

const useStyles = makeStyles(styles)

const SubscriptionsNew = () => {
  const classes = useStyles()

  return (
    <Page className={classes.root} title="Nova Assinatura">
      <Container maxWidth="sm">
        <Grid container spacing={8}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Header name="Nova Assinatura" route={routes.subscriptions.main} />
          </Grid>
          <Grid item lg={12} xs={12}>
            <SubscriptionsForm isEdit={false} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box mt={20}>
              <Typography variant="h6" color="textPrimary">
                Perguntas frequentes
              </Typography>
            </Box>
          </Grid>
          {constants?.faqs?.ITEMS?.charges?.subscriptionNew?.content?.map(
            (value) => (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <AccordionCard
                  title={value?.title}
                  description={value?.description}
                />
              </Grid>
            ),
          )}
        </Grid>
      </Container>
    </Page>
  )
}

export default SubscriptionsNew
