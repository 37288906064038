import api from 'service/api'

const intermediadorAPI = api.create('intermediador')

const show = async (tag, token) => {
  return await intermediadorAPI.get(
    `/account_customizations/${tag}?token=${token}`,
  )
}

const create = async ({ ...params }) => {
  return await intermediadorAPI.post('/account_customizations', params)
}

const accountCustomizations = {
  show,
  create,
}

export default accountCustomizations
