import MuiButton from './MuiButton'
import MuiTextField from './MuiTextField'
import MuiOutlinedInput from './MuiOutlinedInput'

const overrides = {
  MuiButton,
  MuiTextField,
  MuiOutlinedInput,
}

export default overrides
