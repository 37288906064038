const styles = () => ({
  menuNavigation: {
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.8,
    },
  },
})

export default styles
