import {
  Box,
  TablePagination,
  makeStyles,
  useMediaQuery,
  Grid,
  Typography,
} from '@material-ui/core'

import {
  Container,
  Header,
  Page,
  Filters,
  FilterRow,
  LoadingFeedback,
} from 'components'
import { BalanceControlsTable } from './components'
import { DollarSign as DollarSignIcon } from 'react-feather'

import useFilter from 'hooks/useFilter'
import useFetch from 'hooks/useFetch'
import usePagination from 'hooks/usePagination'

import theme from 'theme'
import styles from './styles'

import * as service from 'service'
import constants from 'constants/index'
import helpers from 'helpers'

import { routes } from 'Routes'

const useStyles = makeStyles(styles)

const BalanceControlsMain = () => {
  const filter = useFilter({ status: 'WAITING_PAYMENT' })
  const classes = useStyles()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const { page, perPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination(10)

  const { response, isLoading } = useFetch(
    service.intermediador.balanceControls.get,
    { ...{ ...filter.filters }, page, perPage },
    [page, perPage, filter.filters],
  )

  const responseResume = useFetch(service.intermediador.accounts.resume)

  return (
    <Page className={classes.root} title="Agenda de recebíveis">
      <LoadingFeedback open={isLoading} />
      <Container maxWidth="md">
        <Grid container spacing={8}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Header name="Agenda de recebíveis" route={routes.home} />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box mb={10}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                className={classes.iconBox}
              >
                <DollarSignIcon size={25} />
              </Box>
              <Box my={1}>
                <Typography color="textPrimary" variant="body2">
                  Total a receber
                </Typography>
              </Box>
              <Typography className={classes.infoText}>
                {helpers.formatters.floatToMoney(
                  responseResume?.response?.data?.balanceControlToReceive,
                  true,
                )}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <FilterRow
            filter={filter}
            searchField={'orderToken'}
            searchPlaceholder={
              'Busque a transação pelo token dela, para ver seus recebíveis'
            }
          />
        </Grid>
        <Box mt={4}>
          <BalanceControlsTable
            balanceControls={response?.data?.balanceControls}
          />
        </Box>
        <Box
          mt={2}
          display="flex"
          justifyContent="flex-end"
          className={classes.pagination}
        >
          <TablePagination
            component="div"
            count={response?.data?.meta?.totalCount || 0}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={page - 1}
            rowsPerPage={perPage}
            rowsPerPageOptions={[5, 10, 25, 100]}
            labelRowsPerPage={isDesktop ? 'Por página' : ''}
            nextIconButtonProps={{ size: 'small' }}
            backIconButtonProps={{ size: 'small' }}
          />
        </Box>
        <Filters filter={filter}>
          <select textfieldinput="true" label="Status" name="status">
            <option value=""></option>
            <option
              value={constants.balanceControls.STATUSES.WAITING_PAYMENT_STATUS}
            >
              {constants.balanceControls.STATUSES_LABELS.WAITING_PAYMENT_LABEL}
            </option>
            <option value={constants.balanceControls.STATUSES.PAID_STATUS}>
              {constants.balanceControls.STATUSES_LABELS.PAID_LABEL}
            </option>
          </select>
          <input
            textfieldinput="true"
            label="Token da transação"
            name="orderToken"
          />
          <input
            datepickerinput="true"
            label="Data de pagamento inicial"
            name="paymentDateGt"
            data-target="paymentDateLt"
            data-min
          />
          <input
            datepickerinput="true"
            label="Data de pagamento final"
            name="paymentDateLt"
            data-target="paymentDateGt"
            data-max
          />
        </Filters>
      </Container>
    </Page>
  )
}

export default BalanceControlsMain
