import helpers from 'helpers'

const moreDetails = (invoice, subscription) => {
  const data = [
    {
      label: 'Status',
      value: helpers.invoices.translateStatus(invoice?.status),
    },
    {
      label: 'Início da fatura',
      value: helpers.formatters.date(invoice?.startsAt),
    },
    { label: 'Fim da fatura', value: helpers.formatters.date(invoice?.endsAt) },
    {
      label: 'Vencimento da fatura',
      value: helpers.formatters.date(invoice?.expirationDate),
    },
  ]
  if (invoice?.status !== 'paid') {
    return data
  }
  data.push(
    {
      label: 'Data de pagamento',
      value: helpers.formatters.date(invoice?.paidAt),
    },
    { label: 'Cliente', value: '' },
    {
      label: 'Nome:',
      value: subscription?.customer?.name ? subscription.customer.name : '',
    },
    {
      label: 'E-mail:',
      value: subscription?.customer?.email ? subscription.customer.email : '',
    },
    {
      label: 'CPF/CNPJ:',
      value: subscription?.customer?.document
        ? helpers.formatters.document(subscription.customer.document)
        : '',
    },
  )

  return data
}

export default moreDetails
