import constants from 'constants/index'

const paymentStatus = (status) => {
  switch (status) {
    case constants.payments.STATUSES.NOT_PAID_STATUS:
      return constants.payments.STATUSES_LABELS.NOT_PAID_LABEL
    case constants.payments.STATUSES.WAITING_PAYMENT_STATUS:
      return constants.payments.STATUSES_LABELS.WAITING_PAYMENT_LABEL
    case constants.payments.STATUSES.WAITING_CONFIRM_STATUS:
      return constants.payments.STATUSES_LABELS.WAITING_CONFIRM_LABEL
    case constants.payments.STATUSES.PAID_STATUS:
      return constants.payments.STATUSES_LABELS.PAID_LABEL
    case constants.payments.STATUSES.CANCELED_STATUS:
      return constants.payments.STATUSES_LABELS.CANCELED_LABEL
    case constants.payments.STATUSES.FAILURE_STATUS:
      return constants.payments.STATUSES_LABELS.FAILURE_LABEL
    default:
      return ''
  }
}

export default paymentStatus
