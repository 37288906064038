const operationLabel = (status) => {
  switch (status) {
    case 'NOT_PAID':
      return 'Você cobrou'
    case 'WAITING_PAYMENT':
      return 'Em andamento'
    case 'WAITING_CONFIRM':
      return 'Aguardando confirmação'
    case 'PAID':
      return 'Você recebeu'
    default:
      return 'Cancelado'
  }
}

export default operationLabel
