const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(6),
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
})

export default styles
