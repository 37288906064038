import { useState, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'

import { makeStyles, Box, Hidden } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'

import themeLeadLovers from 'theme/themeLeadLovers'
import theme from 'theme'

import JssProvider from 'react-jss/lib/JssProvider'
import { createGenerateClassName } from '@material-ui/core/styles'

import { TopBar, ScreenLoading, NavBar, MenuBar } from './components'
import useAuth from 'hooks/useAuth'

import styles from './styles'

import SnackbarProvider from 'providers/SnackbarProvider'

const useStyles = makeStyles(styles)

const defaultGenerateClassName = createGenerateClassName({
  disableGlobal: true,
  productionPrefix: 'c',
})

const Main = ({ children }) => {
  const classes = useStyles()
  const [isMobileNavOpen, setMobileNavOpen] = useState(false)
  const auth = useAuth()

  const currentTheme = useMemo(() => {
    return auth?.account?.partnerGroup === 'leadlovers'
      ? themeLeadLovers
      : theme
  }, [auth?.account?.partnerGroup])

  const handleMobileClose = useCallback(() => {
    setMobileNavOpen(false)
  }, [])

  if (!auth.loaded) {
    return <ScreenLoading loaded={false} bgColor="#00A900" />
  }

  return (
    <ThemeProvider theme={currentTheme}>
      <JssProvider generateClassName={defaultGenerateClassName}>
        {/* <ScreenLoading
          loaded={auth.loaded}
          bgColor={
            auth?.account?.partnerGroup === 'leadlovers' ? '#FFF' : '#00A900'
          }
        > */}
        <SnackbarProvider>
          <Box className={classes.root}>
            <Hidden mdDown>
              <Box display="flex" alignItems="center">
                <NavBar
                  logo={auth?.account?.partnerGroup}
                  onMobileClose={handleMobileClose}
                  openMobile={isMobileNavOpen}
                />
              </Box>
            </Hidden>
            <Box className={classes.wrapper}>
              <Box className={classes.contentContainer}>
                <Box className={classes.content}>
                  <TopBar
                    logo={auth?.account?.partnerGroup}
                    onMobileNavOpen={() => setMobileNavOpen(true)}
                  />
                  {children}
                  <Hidden lgUp>
                    <Box pb={10}></Box>
                    <Box className={classes.menuBottom}>
                      <MenuBar />
                    </Box>
                  </Hidden>
                </Box>
              </Box>
            </Box>
          </Box>
        </SnackbarProvider>
        {/* </ScreenLoading> */}
      </JssProvider>
    </ThemeProvider>
  )
}

Main.propTypes = {
  children: PropTypes.any,
}

export default Main
