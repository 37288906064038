import { Box } from '@material-ui/core'

const Section = ({ children, ...rest }) => {
  return (
    <Box style={{ marginTop: '40px', marginBottom: '40px' }} {...rest}>
      {children}
    </Box>
  )
}

export default Section
