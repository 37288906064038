const styles = (theme) => ({
  root: {
    width: '100%',
    borderRadius: '20px',
    backgroundColor: '#FFF',
  },
  heading: {
    fontWeight: 'bold',
  },
})

export default styles
