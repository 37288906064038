function getContrastColor(hexColor) {
  // Remove o caractere '#' se estiver presente
  const hex = hexColor.replace(/^#/, '')

  // Converte os componentes R, G, B para inteiros
  const r = parseInt(hex.substr(0, 2), 16)
  const g = parseInt(hex.substr(2, 2), 16)
  const b = parseInt(hex.substr(4, 2), 16)

  // Calcula a média ponderada para obter a tonalidade de cinza
  const grayScale = Math.round(r * 0.299 + g * 0.587 + b * 0.114)

  // Determina se o fundo é claro ou escuro com base na tonalidade de cinza
  const isLight = grayScale > 128

  // Retorna preto (#000000) se o fundo for claro e branco (#FFFFFF) se for escuro
  return isLight ? '#000000' : '#FFFFFF'
}

export default getContrastColor
