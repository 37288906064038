import date from './date'
import dateTime from './dateTime'
import dateFromNow from './dateFromNow'
import errorMessage from './errorMessage'
import hours from './hours'
import convertBoolToBinary from './convertBoolToBinary'
import convertBinaryToBool from './convertBinaryToBool'
import convertBoolToString from './convertBoolToString'
import balanceControlStatus from './balanceControlStatus'
import cpf from './cpf'
import cnpj from './cnpj'
import floatToMoney from './floatToMoney'
import document from './document'
import cnpjInput from './cnpjInput'
import cpfInput from './cpfInput'
import getMethodIdByBrand from './getMethodIdByBrand'
import creditCardNumber from './creditCardNumber'
import paymentStatus from './paymentStatus'
import statementType from './statementType'

const formatters = {
  convertBinaryToBool,
  convertBoolToBinary,
  date,
  dateTime,
  dateFromNow,
  errorMessage,
  hours,
  convertBoolToString,
  balanceControlStatus,
  cpf,
  floatToMoney,
  cnpj,
  document,
  cnpjInput,
  cpfInput,
  getMethodIdByBrand,
  creditCardNumber,
  paymentStatus,
  statementType,
}

export default formatters
