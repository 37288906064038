import { useMemo } from 'react'
import { Box, makeStyles, Grid, Typography } from '@material-ui/core'

import { LoadingFeedback, ListCard } from 'components'

import styles from './styles'
import helpers from 'helpers'

const useStyles = makeStyles(styles)

const SubscriptionPayments = ({ isLoading, subscription, invoices }) => {
  const classes = useStyles()

  const invoicesByDate = useMemo(() => {
    const invoicesMap = new Map()
    invoices.forEach((invoice) => {
      const dataKey = helpers.formatters.date(invoice?.createdAt)
      if (!invoicesMap.has(dataKey)) {
        invoicesMap.set(dataKey, [])
      }
      invoicesMap.get(dataKey).push(invoice)
    })
    return invoicesMap
  }, [invoices])

  return (
    <>
      <LoadingFeedback open={isLoading} />
      <Box mt={4}>
        <Grid container spacing={2}>
          <Grid item lg={4} md={12} sm={12} xs={12}>
            <Box display="flex" flexDirection="column">
              <Typography color="textPrimary" variant="body1">
                Total de faturas
              </Typography>
              <Typography color="textPrimary">{invoices?.length}</Typography>
              <Box ml={1}></Box>
            </Box>
          </Grid>
          <Grid item lg={4} md={12} sm={12} xs={12}>
            <Box display="flex" flexDirection="column">
              <Typography color="textPrimary" variant="body1">
                Pendentes
              </Typography>
              <Typography color="textPrimary">
                {
                  invoices.filter((invoice) => {
                    return (
                      invoice.status === 'waiting_payment' ||
                      invoice.status === 'waiting_confirmation'
                    )
                  }).length
                }
              </Typography>
              <Box ml={1}></Box>
            </Box>
          </Grid>
          <Grid item lg={4} md={12} sm={12} xs={12}>
            <Box display="flex" flexDirection="column">
              <Typography color="textPrimary" variant="body1">
                Pagas
              </Typography>
              <Typography color="textPrimary">
                {
                  invoices.filter((invoice) => {
                    return invoice.status === 'paid'
                  }).length
                }
              </Typography>
              <Box ml={1}></Box>
            </Box>
          </Grid>
          <Grid item lg={12}>
            {Array.from(invoicesByDate).map(([data, invoices]) => (
              <Box key={data} className={classes.container}>
                <Box mt={2} mb={2} className={classes.center}>
                  <Typography variant="body1" color="textPrimary">
                    {data}
                  </Typography>
                </Box>
                {invoices.map((invoice) => (
                  <ListCard
                    key={invoice?.id}
                    iconByStatus={helpers.invoices.iconByStatus(
                      invoice?.status,
                    )}
                    title={helpers.invoices.operationLabel(invoice?.status)}
                    subtitle={subscription?.customer?.name || 'A definir'}
                    hour={helpers.formatters.hours(invoice?.createdAt)}
                    value={helpers.invoices.valueLabel(
                      invoice?.price,
                      invoice?.status,
                    )}
                    route={invoice?.id}
                    moreDetails={helpers.invoices.moreDetails(
                      invoice,
                      subscription,
                    )}
                    sendToDetails={true}
                  />
                ))}
              </Box>
            ))}
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default SubscriptionPayments
