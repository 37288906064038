const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  paragraph: {
    color: '#FFF',
    fontSize: '0.75rem',
  },
  imgDescription: {
    color: '#FFF',
    fontSize: '0.7rem',
  },
  title: {
    color: '#FFF',
    textAlign: 'center',
  },
  menu: {
    fontSize: '0.8rem',
  },
})

export default styles
