import translateStatus from './translateStatus'
import statusColor from './statusColor'
import textStatusColor from './textStatusColor'
import operationLabel from './operationLabel'
import iconByStatus from './iconByStatus'
import valueLabel from './valueLabel'
import moreDetails from './moreDetails'

const orders = {
  translateStatus,
  statusColor,
  textStatusColor,
  operationLabel,
  iconByStatus,
  valueLabel,
  moreDetails,
}

export default orders
