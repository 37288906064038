import { Page } from 'components'
import {
  Grid,
  Box,
  Typography,
  ThemeProvider,
  Container,
  makeStyles,
  createTheme,
} from '@material-ui/core'
import { CheckoutPlansList, EditPage } from './components'
import { useState, useEffect, useCallback } from 'react'
import checkoutTheme from 'theme/checkoutTheme'

import Logo from 'images/logo-horizontal-y.png'
import * as service from 'service'

import styles from './styles'

const useStyles = makeStyles(styles)

const PreviewCheckoutPlans = ({ match }) => {
  const plans = [
    {
      id: 1,
      name: 'Plano A',
      description: 'teste',
      code: 'teste',
      price: 12.5,
      periodicity: 1,
      status: 1,
      created_at: '2023-10-25T16:09:03.353-03:00',
      updated_at: '2023-10-25T16:10:16.465-03:00',
    },
    {
      id: 2,
      name: 'Plano B',
      description: 'teste',
      code: 'teste',
      price: 12.5,
      periodicity: 1,
      status: 1,
      created_at: '2023-10-25T16:09:03.353-03:00',
      updated_at: '2023-10-25T16:10:16.465-03:00',
    },
    {
      id: 3,
      name: 'Plano C',
      description: 'teste',
      code: 'teste',
      price: 12.5,
      periodicity: 1,
      status: 1,
      created_at: '2023-10-25T16:09:03.353-03:00',
      updated_at: '2023-10-25T16:10:16.465-03:00',
    },
  ]
  const [theme, setTheme] = useState(checkoutTheme)
  const { accountToken } = match.params
  const classes = useStyles()

  const changeColor = (value) => {
    setTheme((prevTheme) => {
      const newTheme = { ...prevTheme }

      for (const category in value) {
        if (!newTheme[category]) {
          newTheme[category] = {}
        }
        for (const field in value[category]) {
          newTheme[category][field] = value[category][field]
        }
      }

      return newTheme
    })
  }

  const userMe = async () => {
    try {
      const user = await service.intermediador.users.me()
      return user.data.user.account.id
    } catch (error) {
      return null
    }
  }

  const getTheme = useCallback(async () => {
    try {
      const response = await service.intermediador.accountCustomizations.show(
        'theme',
        await userMe(),
      )
      const themeData = JSON.parse(response?.data.accountCustomization?.data)
      setTheme(createTheme(themeData))
      return null
    } catch (error) {
      setTheme(createTheme(checkoutTheme))
      return null
    }
  }, [])

  useEffect(() => {
    if (!theme) {
      getTheme()
    }
  }, [theme, getTheme])

  return (
    <ThemeProvider theme={theme ? theme : checkoutTheme}>
      <Page
        title="Planos"
        style={{
          padding: '0px!important',
          paddingRight: '0px!important',
          minHeight: '104vh',
        }}
      >
        <Box
          style={{
            position: 'absolute',
            top: '0px',
            width: '100%',
            height: '100%',
          }}
        >
          <EditPage changeColor={changeColor} />
        </Box>
        <Container maxWidth="md">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <img
                alt="Logo"
                src={Logo}
                className={classes.logo}
                width="100%"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography
                color="textPrimary"
                variant="h6"
                align="center"
                style={{
                  marginTop: checkoutTheme.spacing(20),
                  marginBottom: checkoutTheme.spacing(20),
                }}
              >
                Escolha o melhor para você
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <CheckoutPlansList plans={plans} accountToken={accountToken} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography
                color="textPrimary"
                variant="body1"
                align="center"
                style={{
                  marginTop: checkoutTheme.spacing(20),
                }}
              >
                Vendedor
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Page>
    </ThemeProvider>
  )
}

export default PreviewCheckoutPlans
