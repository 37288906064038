import { Container, Page, Header, Filters, FilterRow } from 'components'
import { Grid, Button } from '@material-ui/core'
import { routes } from 'Routes'

import useFilter from 'hooks/useFilter'
import useFetch from 'hooks/useFetch'

import { DetailsTable } from './components'
import constants from 'constants/index'

import * as service from 'service'

const parseCSV = (text) => {
  const lines = text.split('\n')
  return lines.map((line) => {
    const values = []
    let inQuotes = false
    let value = ''

    for (let i = 0; i < line.length; i++) {
      const char = line[i]

      if (char === '"' && (i === 0 || line[i - 1] !== '\\')) {
        inQuotes = !inQuotes
        continue
      }

      if (char === ',' && !inQuotes) {
        values.push(value.trim())
        value = ''
        continue
      }

      value += char
    }

    values.push(value.trim())
    return values
  })
}

const GenerateReport = () => {
  const filter = useFilter()
  const { response } = useFetch(
    service.intermediador.generateReport.get,
    {
      ...{ ...filter.filters },
    },
    [filter.filters],
  )

  const data = response?.data ? parseCSV(response.data) : []
  const headers = data[0]
  const transactions = data.slice(1)

  // const replaceCommas = (str) => {
  //   return str
  //     .replace(/R\$ ([0-9]+),([0-9]+)/g, 'R$ $1.$2')
  //     .replace(/([0-9]+),([0-9]+)%/g, '$1.$2%')
  // }

  const exportCSV = () => {
    const csvContent = `data:text/csv;charset=utf-8,${response.data}`
    const encodedUri = encodeURI(csvContent)
    const link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', 'relatorio_transacao.csv')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <Page title="Relatório de transação">
      <Container maxWidth="md">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Header
              name="Relatório de transação"
              route={routes.settings.main}
            />
          </Grid>
          <Grid item lg={12} sm={12} xs={12}>
            <DetailsTable
              header={headers}
              rows={transactions?.slice(0, 1 + 1)}
              lines={transactions?.length}
            >
              <FilterRow
                filter={filter}
                searchPlaceholder={'Pesquisar pagador'}
                searchField={'buyerName'}
                search={false}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={exportCSV}
                style={{ marginBottom: '20px' }}
                size="small"
              >
                Exportar CSV
              </Button>
            </DetailsTable>
          </Grid>
        </Grid>
      </Container>
      <Filters filter={filter}>
        <input textfieldinput="true" label="Pagador" name="buyerName" />
        <select textfieldinput="true" label="Status" name="status">
          <option value=""></option>
          <option value={constants.payments.STATUSES.NOT_PAID_STATUS}>
            {constants.payments.STATUSES_LABELS.NOT_PAID_LABEL}
          </option>
          <option value={constants.payments.STATUSES.WAITING_PAYMENT_STATUS}>
            {constants.payments.STATUSES_LABELS.WAITING_PAYMENT_LABEL}
          </option>
          <option value={constants.payments.STATUSES.WAITING_CONFIRM_STATUS}>
            {constants.payments.STATUSES_LABELS.WAITING_CONFIRM_LABEL}
          </option>
          <option value={constants.payments.STATUSES.PAID_STATUS}>
            {constants.payments.STATUSES_LABELS.PAID_LABEL}
          </option>
          <option value={constants.payments.STATUSES.CANCELED_STATUS}>
            {constants.payments.STATUSES_LABELS.CANCELED_LABEL}
          </option>
          <option value={constants.payments.STATUSES.FAILURE_STATUS}>
            {constants.payments.STATUSES_LABELS.FAILURE_LABEL}
          </option>
        </select>
        <input
          datepickerinput="true"
          label="Data de Criação (Maior que)"
          name="createdAtGt"
          disableFuture
          data-target="createdAtLt"
          data-min
        />
        <input
          datepickerinput="true"
          label="Data de Criação (Menor que)"
          name="createdAtLt"
          disableFuture
          data-target="createdAtGt"
          data-max
        />
      </Filters>
    </Page>
  )
}

export default GenerateReport
