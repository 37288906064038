import PropTypes from 'prop-types'

import {
  Dialog,
  DialogContent,
  DialogActions,
  Box,
  Divider,
  Typography,
  Button,
  makeStyles,
} from '@material-ui/core'

import useSnackbar from 'hooks/useSnackbar'

import { reverse } from 'named-urls'
import { routes } from 'Routes'
import * as service from 'service'

import styles from './styles'
import helpers from 'helpers'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(styles)

const DialogPayment = ({
  open,
  setOpen,
  values,
  customer,
  order,
  token,
  type,
  setLoading,
}) => {
  const classes = useStyles()
  const snackbar = useSnackbar()
  const history = useHistory()

  const onSubmit = async () => {
    setLoading(true)
    try {
      await service.intermediador.payments.pay({
        order: orderParams(order.payment, values, customer, token, type),
        seller_auth_token: order.seller.token,
      })

      setOpen(false)

      snackbar.open({
        message: `${`Pagamento feito com sucesso!`}`,
        variant: 'success',
      })
      history.push(
        reverse(routes.payments.creditPaid, {
          type: type,
          token: token,
        }),
        {
          customer: customer,
        },
      )
      setLoading(false)
    } catch (error) {
      setOpen(false)
      snackbar.open({
        message:
          error?.response?.data?.error?.message ||
          `Ocorreu um erro ao criar ao realizar o pagamento`,
        variant: 'error',
      })
      setLoading(false)
    }
  }

  const orderParams = (order, values, customer, token, type) => {
    const params = {
      orderType: type,
      token: token,
      code: order.code,
      payment: {
        method: helpers.formatters.getMethodIdByBrand(values.number),
        splitNumber: values.splitNumber,
        card: {
          name: values.name,
          expiration: values.expiry,
          number: values.number.replace(/ /g, ''),
          cvv: values.cvv,
        },
      },
      customer: formatCustomer(customer),
    }
    return params
  }

  const formatCustomer = (customer) => {
    const formattedCustomer = {
      birthDate: customer.birthDate,
      document: customer.document
        .split('-')
        .join('')
        .split('.')
        .join('')
        .split('/')
        .join(''),
      documentType: customer.documentType,
      email: customer.email,
      name: customer.name,
      address: {
        city: customer.address.city,
        neighborhood: customer.address.neighborhood,
        number: customer.address.number,
        postalCode: customer.address.postalCode.replace('-', ''),
        state: customer.address.state,
        street: customer.address.street,
      },
    }

    return formattedCustomer
  }

  return (
    <Dialog
      className={classes.modal}
      maxWidth="lg"
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogContent className={classes.modalContent}>
        <Box my={1}>
          <Typography>Você deseja realizar o pagamento via cartão?</Typography>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions p={2}>
        <Box pl={2} pr={1} display="flex" justifyContent="center">
          <Button
            type="button"
            variant="outlined"
            onClick={() => setOpen(false)}
          >
            Cancelar
          </Button>
        </Box>
        <Button
          variant="contained"
          type="submit"
          color="primary"
          onClick={onSubmit}
        >
          Sim
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DialogPayment.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  values: PropTypes.object,
}

export default DialogPayment
