const styles = (theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  titleBox: {
    borderRadius: '20px',
    width: '100%',
    background:
      'linear-gradient(90deg, rgba(8,109,161,255) 0%, rgba(21,150,164,1) 100%)',
    marginBottom: theme.spacing(4),
  },
  textWhite: {
    color: theme.palette.white,
  },
  card: {
    borderRadius: theme.spacing(4),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(1),
  },
  tokenBox: {
    [`& fieldset`]: {
      borderRadius: theme.spacing(20),
    },
    borderRadius: theme.spacing(20),
    backgroundColor: theme.palette.background.paper,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  iconBox: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(20),
  },
})

export default styles
