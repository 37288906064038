import {
  TableContainer,
  makeStyles,
  IconButton,
  Switch,
  Typography,
} from '@material-ui/core'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'

import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'

import PerfectScrollbar from 'react-perfect-scrollbar'
import { X as XIcon } from 'react-feather'
import helpers from 'helpers'
import theme from 'theme'
import styles from './styles'
import * as service from 'service'

const useStyles = makeStyles(styles)

const BankAccountsTableRow = ({
  bankAccount,
  bankAccountActive,
  setBankAccountActive,
  classes,
}) => {
  const [status, setStatus] = useState(
    bankAccount.status === 'ACTIVE' ? true : false,
  )

  useEffect(() => {
    if (
      bankAccountActive !== undefined &&
      bankAccountActive?.id !== bankAccount?.id
    ) {
      setStatus(false)
    }
  }, [bankAccountActive, bankAccount])

  const updateBankAccountStatus = async (checked, bankAccount) => {
    setStatus(checked)
    if (checked) {
      await service.intermediador.bankAccounts.update({
        bankAccountId: bankAccount.id,
        bankAccount: {
          status: 'ACTIVE',
        },
      })

      setBankAccountActive(bankAccount)
    }
  }

  return (
    <TableRow
      style={{
        backgroundColor: theme.palette.background.paper,
      }}
      key={bankAccount.id}
    >
      <TableCell>
        Banco: {bankAccount.bank}
        <br />
        Agência: {bankAccount.agency}
        <br />
        Número: {bankAccount.number}
      </TableCell>
      <TableCell>
        {helpers.formatters.dateTime(bankAccount?.createdAt)}
      </TableCell>
      <TableCell>
        <Switch
          checked={status}
          onChange={(event) => {
            updateBankAccountStatus(event.target.checked, bankAccount)
          }}
          color="primary"
        />
      </TableCell>
      <TableCell align="center">
        <IconButton>
          <XIcon strokeWidth={3} size={24} color={theme.palette.text.primary} />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

BankAccountsTableRow.propTypes = {
  bankAccount: PropTypes.object,
  bankAccountActive: PropTypes.object,
  setBankAccountActive: PropTypes.func,
  classes: PropTypes.object,
}

const BankAccountsTable = ({
  isLoading,
  bankAccounts = [],
  bankAccountActive,
  setBankAccountActive,
}) => {
  const classes = useStyles()

  return (
    <>
      <PerfectScrollbar>
        <TableContainer>
          <Table
            isLoading={isLoading}
            emptyMessage="Nenhuma conta bancária encontrada"
            size="medium"
            noWrap
            className={classes.table}
          >
            <TableHead>
              <TableRow>
                <TableCell width="20%">
                  <Typography color="textPrimary">Dados Bancários</Typography>
                </TableCell>
                <TableCell width="20%">
                  <Typography color="textPrimary">Data de criação</Typography>
                </TableCell>
                <TableCell width="15%">
                  <Typography color="textPrimary">Conta principal</Typography>
                </TableCell>
                <TableCell align="center" width="10%">
                  <Typography color="textPrimary">Ações</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bankAccounts?.map((bankAccount) => (
                <BankAccountsTableRow
                  key={bankAccount.id}
                  bankAccount={bankAccount}
                  bankAccountActive={bankAccountActive}
                  setBankAccountActive={setBankAccountActive}
                  classes={classes}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </PerfectScrollbar>
    </>
  )
}

BankAccountsTable.propTypes = {
  isLoading: PropTypes.bool,
  bankAccounts: PropTypes.array,
  bankAccountActive: PropTypes.object,
  setBankAccountActive: PropTypes.func,
}

export default BankAccountsTable
