import React, { useState } from 'react'
import { makeStyles, Box, Typography } from '@material-ui/core'

import { useHistory } from 'react-router-dom'
import {
  ChevronRight as ChevronRightIcon,
  Send as SendIcon,
  Check as CheckIcon,
  Loader as LoaderIcon,
  Cpu as CpuIcon,
} from 'react-feather'

import { routes } from 'Routes'
import { reverse } from 'named-urls'

import helpers from 'helpers'
import styles from './styles'
import theme from 'theme'

const useStyles = makeStyles(styles)

const ListCard = ({ status, route, data }) => {
  const classes = useStyles()
  const history = useHistory()
  const [isComponentVisible, setComponentVisible] = useState(false)

  const toggleComponent = () => {
    setComponentVisible(!isComponentVisible)
  }
  //Altera os nomes das operações do card de cada transação
  const paymentOperation = (params) => {
    let result = ''

    switch (params) {
      case 'NOT_PAID':
        result = 'Você cobrou'
        break
      case 'WAITING_PAYMENT':
        result = 'Em andamento'
        break
      case 'WAITING_CONFIRM':
        result = 'Aguardando confirmação'
        break
      case 'PAID':
        result = 'Você recebeu'
        break
      default:
        result = 'Cancelado'
        break
    }

    return result
  }

  //ìcones de cada transação dos circulos
  const paymentAccept = () => {
    return (
      <Box
        style={{ backgroundColor: theme.palette.success.main }}
        className={classes.circle}
        mr={2}
      >
        <CheckIcon className={classes.iconColor} size={16} />
      </Box>
    )
  }

  const awaitingConfirmation = () => {
    return (
      <Box
        style={{ backgroundColor: '#44be9d' }}
        className={classes.circle}
        mr={2}
      >
        <CpuIcon className={classes.iconColor} size={16} />
      </Box>
    )
  }

  const paymentRefused = () => {
    return (
      <Box
        style={{ backgroundColor: theme.palette.secondary.main }}
        className={classes.circle}
        mr={2}
      >
        <SendIcon className={classes.iconColor} size={16} />
      </Box>
    )
  }

  const pendingPayment = () => {
    return (
      <Box
        style={{ backgroundColor: theme.palette.info.main }}
        className={classes.circle}
        mr={2}
      >
        <LoaderIcon className={classes.iconColor} size={16} />
      </Box>
    )
  }

  //Função que determina o tipo de icone da operação
  const paymentStatus = (params) => {
    switch (params) {
      case 'PAID':
        return paymentAccept()
      case 'WAITING_PAYMENT':
        return pendingPayment()
      case 'WAITING_CONFIRM':
        return awaitingConfirmation()
      case 'NOT_PAID':
        return paymentRefused()
      default:
        break
    }
  }

  //Essa função altera as propriedades dos valores das tranções
  const paymentValue = (params) => {
    const value = helpers.formatters.floatToMoney(params?.amount, false)
    let result = ''

    switch (status) {
      case 'NOT_PAID':
        result = (
          <Typography variant="body1" className={classes.price}>
            {value} R$
          </Typography>
        )
        break
      case 'WAITING_PAYMENT':
        result = (
          <Typography variant="body1" className={classes.price}>
            {value} R$
          </Typography>
        )
        break
      case 'WAITING_CONFIRM':
        result = (
          <Typography variant="body1" className={classes.price}>
            {value} R$
          </Typography>
        )
        break
      case 'PAID':
        result = (
          <Typography
            variant="body1"
            style={{ color: theme.palette.success }}
            className={classes.price}
          >
            +{value} R$
          </Typography>
        )
        break

      default:
        break
    }
    return result
  }

  return (
    <>
      <Box className={classes.cardRow} onClick={toggleComponent} mb={2}>
        <Box className={classes.cardCol}>
          {status && paymentStatus(status)}
          <Box>
            <Typography variant="body1" className={classes.operation}>
              {status && paymentOperation(status)}
            </Typography>
            <Typography variant="body2" className={classes.description}>
              {data?.buyer?.name ? data?.buyer?.name : 'A ver'}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.cardCol}>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}
          >
            {data && paymentValue(data)}
            <Typography variant="body2" className={classes.description}>
              {data &&
                (helpers.formatters.dateTime(data?.createdAt).split('-')[1],
                '-',
                helpers.formatters.dateTime(data?.createdAt).split('-')[1])}
            </Typography>
          </Box>
          <Box ml={2}>
            <ChevronRightIcon
              style={{
                color: theme.palette.text.primary,
                cursor: 'pointer',
              }}
              onClick={() =>
                history.push(
                  reverse(routes?.orders?.show, {
                    orderId: route,
                  }),
                )
              }
            />
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default ListCard
