import theme from 'theme'

import {
  X as XIcon,
  Check as CheckIcon,
  Loader as LoaderIcon,
  Cpu as CpuIcon,
} from 'react-feather'

const iconByStatus = (status) => {
  switch (status) {
    case 'paid':
      return {
        icon: <CheckIcon style={{ color: theme.palette.white }} />,
        color: theme.palette.success,
      }
    case 'waiting_payment':
      return {
        icon: <LoaderIcon style={{ color: theme.palette.white }} />,
        color: theme.palette.info.main,
      }
    case 'waiting_confirm':
      return {
        icon: <CpuIcon style={{ color: theme.palette.white }} />,
        color: '#44be9d',
      }
    default:
      return {
        icon: <XIcon style={{ color: theme.palette.white }} />,
        color: theme.palette.warning.dark,
      }
  }
}

export default iconByStatus
