import { colors } from '@material-ui/core'

const palette = {
  black: '#000000',
  white: '#FFFFFF',
  primary: {
    contrastText: '#FFFFFF',
    main: '#00A900',
  },
  secondary: {
    contrastText: '#FFFFFF',
    main: '#9651b8',
  },
  success: {
    contrastText: '#FFFFFF',
    main: colors.green[600],
  },
  info: {
    contrastText: '#FFFFFF',
    main: colors.blue[600],
  },
  warning: {
    contrastText: '#FFFFFF',
    main: colors.orange[600],
  },
  error: {
    contrastText: '#FFFFFF',
    main: colors.red[600],
  },
  text: {
    primary: '#235078',
    secondary: '#598CB9',
    link: colors.blue[600],
  },
  background: {
    default: '#F2F6F8',
    paper: '#FFF',
  },
  divider: {
    default: '#235078',
  },
  line: {
    default: '#598CB9',
  },
  icon: { default: colors.blueGrey[600] },
  action: {
    active: '#598CB9',
  },
}

export default palette
