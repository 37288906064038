const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: '100%',
  },
  boxStyled: {
    position: 'absolute',
    height: '100px',
    width: '100px',
    border: '4px solid #ECF6FF',
    borderRadius: '20px',
    transition: 'transform 3s ease-in-out',
    zIndex: 1,
  },
})

export default styles
