const statusColor = (status, classes) => {
  switch (status) {
    case 'NOT_PAID':
      return classes.notPaid
    case 'WAITING_PAYMENT':
      return classes.waitingPayment
    case 'WAITING_CONFIRM':
      return classes.waitingConfirm
    case 'PAID':
      return classes.paid
    default:
      return classes.canceled
  }
}

export default statusColor
