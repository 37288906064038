const NOT_PAID_STATUS = 'NOT_PAID'
const WAITING_PAYMENT_STATUS = 'WAITING_PAYMENT'
const WAITING_CONFIRM_STATUS = 'WAITING_CONFIRM'
const PAID_STATUS = 'PAID'
const CANCELED_STATUS = 'CANCELED'
const FAILURE_STATUS = 'FAILURE'

const NOT_PAID_LABEL = 'NÃO PAGO'
const WAITING_PAYMENT_LABEL = 'AGUARDANDO PAGAMENTO'
const WAITING_CONFIRM_LABEL = 'AGUARDANDO CONFIRMAÇÃO'
const PAID_LABEL = 'PAGO'
const CANCELED_LABEL = 'CANCELADO'
const FAILURE_LABEL = 'FALHA'

const STATUSES = {
  NOT_PAID_STATUS,
  WAITING_PAYMENT_STATUS,
  WAITING_CONFIRM_STATUS,
  PAID_STATUS,
  CANCELED_STATUS,
  FAILURE_STATUS,
}

const PENDING_STATUSES = [WAITING_CONFIRM_STATUS, WAITING_PAYMENT_STATUS]

const STATUSES_LABELS = {
  NOT_PAID_LABEL,
  WAITING_PAYMENT_LABEL,
  WAITING_CONFIRM_LABEL,
  PAID_LABEL,
  CANCELED_LABEL,
  FAILURE_LABEL,
}

const payments = {
  STATUSES,
  STATUSES_LABELS,
  PENDING_STATUSES,
}

export default payments
