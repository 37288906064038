import api from 'service/api'

const intermediadorAPI = api.create('intermediador')

const show = async (type, token, accountToken = '') => {
  return await intermediadorAPI.get(
    `/payments/${type}/${token}?account_token=${accountToken}`,
  )
}

const pay = async ({ ...params }) => {
  return await intermediadorAPI.post('/payments/pay', params)
}

const full = async ({ ...params }) => {
  return await intermediadorAPI.post('/payments/full', params)
}

const create = async ({ ...params }) => {
  return await intermediadorAPI.post('/payments', params)
}

const payments = {
  show,
  pay,
  full,
  create,
}

export default payments
