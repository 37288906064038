import PropTypes from 'prop-types'

import {
  Button,
  Tooltip,
  Box,
  Typography,
  makeStyles,
  Hidden,
} from '@material-ui/core'

import styles from './styles'

const useStyles = makeStyles(styles)

const FilterButton = ({ setDrawerOpen }) => {
  const classes = useStyles()

  return (
    <Box display="flex" alignItems="center" className={classes.root}>
      <Tooltip title="Filtros">
        <Button
          fullWidth
          onClick={() => setDrawerOpen(true)}
          disableRipple
          className={classes.button}
        >
          <Hidden xsDown>
            <Typography variant="body2" color="textPrimary">
              Filtrar
            </Typography>
          </Hidden>
        </Button>
      </Tooltip>
    </Box>
  )
}

FilterButton.propTypes = {
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
}

FilterButton.propTypes = {
  setDrawerOpen: PropTypes.func,
}

FilterButton.defaultProps = {
  setDrawerOpen: () => {},
}

export default FilterButton
