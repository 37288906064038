const styles = (checkoutTheme) => ({
  '&:hover': {
    border: '1px solid white',
  },
  flexbox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  divider: {
    textAlign: '-webkit-center',
  },
  footer: {
    height: '100%',
  },
  image: {
    display: 'inline-block',
    maxWidth: '100%',
    width: 260,
  },
  logo: {
    fontSize: 0,
    width: '160px',
    marginBottom: '20px',
    [checkoutTheme.breakpoints.down('sm')]: {
      width: '140px',
    },
  },
})

export default styles
