import { Typography } from '@material-ui/core'

const Title = ({ children, subtitle, ...rest }) => {
  return (
    <>
      {subtitle && (
        <Typography
          color="primary"
          variant="body2"
          style={{ fontWeight: 'bold' }}
        >
          {subtitle}
        </Typography>
      )}
      <Typography color="textPrimary" {...rest}>
        {children}
      </Typography>
    </>
  )
}

export default Title
