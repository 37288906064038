import Image from '../../images/backgroundplans.png'

const styles = (theme) => ({
  root: {
    paddingTop: theme.spacing(3),
  },
  pagination: {
    backgroundColor: theme.palette.background.default,
    borderRadius: '20px',
  },
  plansBanner: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    backgroundImage: `url(${Image})`,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 20,
    backgroundSize: 'cover',
    width: '100%',
    height: 200,
  },
  bgColor: {
    position: 'absolute',
    top: 0,
    left: 0,
    background:
      'linear-gradient(to bottom right, rgba(51, 159, 255, 0.5), rgba(140, 0, 226, 0.5))',
    width: '100%',
    height: '100%',
    borderRadius: 20,
    zIndex: 1,
  },
  boxDescription: {
    backgroundColor: 'rgba(217, 217, 217, 0.4)',
    width: '100%',
    padding: 12,
    backdropFilter: 'blur(20px)',
    zIndex: 2,
    borderRadius: '20px 20px 20px 20px',
  },
  text: {
    color: 'rgba(255, 255, 255, 1)',
    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'grayscale',
    zIndex: 3,
  },
  headerBox: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
})

export default styles
