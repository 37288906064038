import { Grid, Container, makeStyles, Box, Typography } from '@material-ui/core'

import { Header, Page } from 'components'

import WithdrawsForm from './components/WithdrawsForm'
import WithdrawsTable from './components/WithdrawsTable'

import styles from './styles'
import helpers from 'helpers'
import * as service from 'service'

import { routes } from 'Routes'

import useFetch from 'hooks/useFetch'

const useStyles = makeStyles(styles)

const WithdrawsNew = () => {
  const classes = useStyles()

  const { response } = useFetch(service.intermediador.accounts.resume)

  return (
    <Page title="Saque">
      <Container maxWidth="sm">
        <Grid container spacing={8}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Header name="Saque" route={routes.home} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box
              display="flex"
              flexDirection="column"
              className={classes.accountBox}
            >
              <Box mt={4}>
                <Typography className={classes.colorWhite} variant="body2">
                  Saldo na conta
                </Typography>
                <Typography className={classes.colorWhite} variant="h6">
                  {helpers.formatters.floatToMoney(
                    response?.data?.balance,
                    true,
                  )}
                </Typography>
              </Box>
              <WithdrawsForm />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <WithdrawsTable />
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}

export default WithdrawsNew
