import { Typography } from '@material-ui/core'

const Paragraph = ({ children, ...rest }) => {
  return (
    <Typography variant="body1" color="textSecondary" {...rest}>
      <br />
      {children}
      <br />
    </Typography>
  )
}

export default Paragraph
