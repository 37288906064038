import { useState } from 'react'

import {
  Grid,
  TextField,
  makeStyles,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'

import { useForm, Controller } from 'react-hook-form'
import { MoneyInput } from 'components'

import styles from './styles'
import * as service from 'service'
import schema from './schema'

import useSnackbar from 'hooks/useSnackbar'

const useStyles = makeStyles(styles)

const WithdrawsForm = () => {
  const [open, setOpen] = useState(false)
  const { control, handleSubmit, errors } = useForm({
    validationSchema: schema,
    defaultValues: {
      amount: '',
    },
  })

  const snackbar = useSnackbar()
  const classes = useStyles()

  const onSubmit = async (data) => {
    try {
      let response = await service.intermediador.withdraws.create({
        amount: data.amount,
      })

      if (response?.data?.withdraw?.status === 'canceled') {
        snackbar.open({
          message: `Falha ao processar pagamento do saque`,
          variant: 'error',
        })
      } else {
        snackbar.open({
          message: `Solicitação de saque criada com sucesso`,
          variant: 'success',
        })
      }

      setTimeout(() => {
        window.location.reload()
      }, 2000)
    } catch (error) {
      snackbar.open({
        message: 'Erro ao criar solicitação de saque',
        variant: 'error',
      })
    }
    handleClose()
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Sacar
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Novo saque</DialogTitle>
        <DialogContent>
          <form id="withdrawForm" onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={8}>
              <Grid item sm={12} xs={12}>
                <Box mt={2}>
                  <Controller
                    as={
                      <TextField
                        label="Valor do saque (R$)"
                        type="text"
                        color="primary"
                        error={!!errors.amount}
                        helperText={errors?.amount?.message}
                        className={classes.roundedTextField}
                        fullWidth
                        InputProps={{
                          inputComponent: MoneyInput,
                        }}
                      />
                    }
                    control={control}
                    name="amount"
                    mode="onBlur"
                    size="small"
                  />
                </Box>
                <Box mt={10} mb={8} display="flex" justifyContent="flex-start">
                  <Box pr={1}>
                    <Button type="button" onClick={handleClose}>
                      Voltar
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      form="withdrawForm"
                      color="primary"
                      variant="contained"
                      type="submit"
                    >
                      Sacar
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default WithdrawsForm
