import { useState } from 'react'

import { Container, Page, Header } from 'components'
import {
  Box,
  Grid,
  Button,
  Paper,
  Typography,
  Avatar,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'

import { User as UserIcon, Trash as TrashIcon } from 'react-feather'

// import { useHistory } from 'react-router-dom'
// import useAuth from 'hooks/useAuth'

// import styles from './styles'
// import helpers from 'helpers'
import { routes } from 'Routes'

// const useStyles = makeStyles(styles)

const currencies = [
  {
    value: 'USD',
    label: 'R$',
  },
  {
    value: 'EUR',
    label: '%',
  },
]

const SplitMain = () => {
  const [open, setOpen] = useState(false)
  const [currency, setCurrency] = useState('EUR')

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleChange = (event) => {
    setCurrency(event.target.value)
  }

  return (
    <Page title="Split de pagamentos">
      <Container maxWidth="md">
        <Grid container spacing={8}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Header name="Split de pagamentos" route={routes.settings.main} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Button>Novo Vendedor</Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleClickOpen}
            >
              Open form dialog
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container spacing={8}>
              <Grid item xs={12} sm={12} md={12} lg={4}>
                <Paper elevation={0} style={{ borderRadius: '20px' }}>
                  <Box
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      flexDirection="row"
                      justifyContent="space-between"
                      style={{
                        padding: '16px',
                        width: '100%',
                      }}
                    >
                      <div></div>
                      <Avatar>
                        <UserIcon />
                      </Avatar>
                      <TrashIcon />
                    </Box>

                    <Box
                      display="flex"
                      alignItems="center"
                      flexDirection="column"
                      style={{ padding: '16px', width: '100%' }}
                    >
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 'bold' }}
                      >
                        João Oliveira
                      </Typography>
                      <Typography variant="subtitle2" color="textSecondary">
                        54.914.981/0001-01
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 'bold' }}
                      >
                        R$ 20,00
                      </Typography>
                      <Typography variant="subtitle2" color="textSecondary">
                        Repasse
                      </Typography>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4}>
                <Paper elevation={0}>
                  <Typography>
                    <Typography>João Oliveira</Typography>
                    <Typography>54.914.981/0001-01</Typography>
                    <Typography>R$ 20,00</Typography>
                    <Typography>Repasse</Typography>
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4}>
                <Paper elevation={0}>
                  <Typography>
                    <Typography>João Oliveira</Typography>
                    <Typography>54.914.981/0001-01</Typography>
                    <Typography>R$ 20,00</Typography>
                    <Typography>Repasse</Typography>
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">Novo vendedor</DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  id="id_split"
                  label="ID Split"
                  type="number"
                  fullWidth
                />
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Email Address"
                  type="email"
                  fullWidth
                />
                <TextField
                  autoFocus
                  margin="dense"
                  id="valor"
                  label="Valor"
                  type="number"
                  fullWidth
                />
                <div>
                  <TextField
                    id="filled-select-currency-native"
                    select
                    label="Native select"
                    value={currency}
                    onChange={handleChange}
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                  >
                    {currencies.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Voltar
                </Button>
                <Button onClick={handleClose} color="primary">
                  Salvar
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
          {/* <Grid item lg={12} sm={12} xs={12}>
            <Box mt={4}>
              <Button onClick={() => history.push(routes.settings.main)}>
                Voltar
              </Button>
            </Box>
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  )
}

export default SplitMain
