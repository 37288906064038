const MuiTableBody = {
  root: {
    '&::before': {
      content: '',
      display: 'block',
      height: '1.5em',
      width: '100%',
      background: '#FFF',
    },
  },
}

export default MuiTableBody
