import {
  Box,
  makeStyles,
  Typography,
  Grid,
  Checkbox,
  FormControlLabel,
  TextField,
} from '@material-ui/core'
import { LoadingFeedback, Select } from 'components'
import { LoadingButton } from 'theme/checkoutTheme/components'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import DialogCard from '../DialogCard'
import schema from './schema'
import styles from './styles'
import helpers from 'helpers'
import CreditCard from 'react-credit-cards'
import 'react-credit-cards/es/styles-compiled.css'

import checkoutTheme from 'theme/checkoutTheme/'

const useStyles = makeStyles(styles)

const CreditCardForm = ({
  type,
  token,
  customer,
  order,
  setStep,
  loading,
  setLoading,
}) => {
  const { control, handleSubmit, errors } = useForm({
    validationSchema: schema,
    defaultValues: {
      number: '',
      name: '',
      expiry: '',
      cvv: '',
      splitNumber: 1,
      recurrency: false,
    },
  })
  const [card, setCard] = useState({
    cvv: '',
    expiry: '',
    focus: '',
    name: '',
    number: '',
  })
  const classes = useStyles()
  const [values, setValues] = useState({})
  const [open, setOpen] = useState(false)
  const [disableButton, setDisableButton] = useState(false)

  const onSubmit = async (data) => {
    setValues(data)
    setOpen(true)
  }

  const handleInputFocus = (e) => {
    setCard((prevState) => ({
      ...prevState,
      focus: e.target.name,
    }))
  }

  const handleInputChange = (e) => {
    const { name, value } = e[0].target

    setCard((prevState) => ({
      ...prevState,
      [name]: value,
    }))
    if (name === 'number') {
      return helpers.formatters.creditCardNumber(value)
    }
    if (name === 'expiry') {
      let expiry = value
      let l = expiry.length

      if (l < 3) return expiry

      if (l > 5) return expiry.slice(0, 5)

      expiry = expiry.replaceAll('/', '')

      l = expiry.length

      if (l <= 5) return expiry.replace(/^(\d{2})(\d{1,2})*/, '$1/$2')
    }
    return value
  }

  return (
    <Box>
      <LoadingFeedback open={loading} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box display="flex" alignItems="center" flexDirection="column">
          <div
            className={`${classes.creditCard}`}
            mb={checkoutTheme.spacing(10)}
          >
            <CreditCard
              cvv={card.cvv}
              cvc={card.cvv}
              expiry={card.expiry}
              focused={card.focus}
              name={card.name}
              number={card.number || ''}
              placeholders={{ name: 'Nome completo', valid: 'Validade' }}
            />
          </div>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box>
                <Typography variant="body1" color="textSecondary">
                  Número do cartão
                </Typography>
              </Box>
              <Controller
                name="number"
                as={
                  <TextField
                    type="tel"
                    color="primary"
                    variant="outlined"
                    fullWidth
                    error={!!errors.number}
                    helperText={errors?.number?.message}
                    onFocus={handleInputFocus}
                    inputProps={{ maxLength: 19 }}
                    size="small"
                  />
                }
                control={control}
                mode="onBlur"
                onChange={handleInputChange}
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <Box>
                <Typography variant="body1" color="textSecondary">
                  Nome
                </Typography>
              </Box>
              <Controller
                name="name"
                as={
                  <TextField
                    type="text"
                    color="primary"
                    variant="outlined"
                    fullWidth
                    error={!!errors.name}
                    helperText={errors?.name?.message}
                    onFocus={handleInputFocus}
                    inputProps={{ maxLength: 30 }}
                    size="small"
                  />
                }
                control={control}
                mode="onBlur"
                onChange={handleInputChange}
                size="small"
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <Box>
                <Typography variant="body1" color="textSecondary">
                  Validade
                </Typography>
              </Box>
              <Controller
                name="expiry"
                as={
                  <TextField
                    type="text"
                    color="primary"
                    variant="outlined"
                    fullWidth
                    error={!!errors.expiry}
                    helperText={errors?.expiry?.message}
                    onFocus={handleInputFocus}
                    inputProps={{ maxLength: 5 }}
                    size="small"
                  />
                }
                control={control}
                mode="onBlur"
                onChange={handleInputChange}
                size="small"
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <Box>
                <Typography variant="body1" color="textSecondary">
                  CVV
                </Typography>
              </Box>
              <Controller
                name="cvv"
                as={
                  <TextField
                    type="text"
                    color="primary"
                    variant="outlined"
                    fullWidth
                    error={!!errors.cvv}
                    helperText={errors?.cvv?.message}
                    onFocus={handleInputFocus}
                    inputProps={{ maxLength: 3 }}
                    size="small"
                  />
                }
                control={control}
                mode="onBlur"
                onChange={handleInputChange}
                size="small"
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <Typography variant="body1" color="textSecondary">
                Parcelas
              </Typography>
              <Controller
                name="splitNumber"
                as={
                  <Select
                    defaultValue={1}
                    items={
                      type === 'product'
                        ? [
                            { id: 1, name: 1 },
                            { id: 2, name: 2 },
                            { id: 3, name: 3 },
                          ]
                        : [
                            { id: 1, name: 1 },
                            { id: 2, name: 2 },
                            { id: 3, name: 3 },
                            { id: 4, name: 4 },
                            { id: 5, name: 5 },
                            { id: 6, name: 6 },
                            { id: 7, name: 7 },
                            { id: 8, name: 8 },
                            { id: 9, name: 9 },
                            { id: 10, name: 10 },
                            { id: 11, name: 11 },
                            { id: 12, name: 12 },
                          ]
                    }
                  />
                }
                control={control}
                mode="onBlur"
                onChange={handleInputChange}
                className={classes.input}
                size="small"
              />
            </Grid>
            {type === 'plan' && (
              <Grid item xs={12}>
                <Box display="flex" alignItems="center">
                  <FormControlLabel
                    label="Utilizar este cartão para realizar os próximos pagamentos dessa assinatura automaticamente"
                    control={
                      <Controller
                        name="recurrency"
                        as={<Checkbox value={true} />}
                        control={control}
                        mode="onBlur"
                      />
                    }
                  />
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          mt={checkoutTheme.spacing(10)}
          width="100%"
        >
          <LoadingButton
            text="Pagar Agora"
            type="submit"
            variant="contained"
            disabled={disableButton}
          />
          <Box
            display="flex"
            flexDirection="column"
            width="inherit"
            my={checkoutTheme.spacing(10)}
          >
            <LoadingButton
              text="Voltar"
              variant="outlined"
              onClick={() => setStep(1)}
            >
              Voltar
            </LoadingButton>
          </Box>
        </Box>
      </form>
      <DialogCard
        open={open}
        setOpen={setOpen}
        {...(open && {
          values: values,
          customer: customer,
          order: order,
          token: token,
          type: type,
        })}
        setLoading={setLoading}
        setDisableButton={setDisableButton}
      />
    </Box>
  )
}

export default CreditCardForm
