import api from 'service/api'

const intermediadorAPI = api.create('intermediador')

const get = async ({ ...params }) => {
  return await intermediadorAPI.get('/withdraws', { params })
}

const create = async ({ ...data }) => {
  return await intermediadorAPI.post('/withdraw', data)
}

const withdraws = {
  get,
  create,
}

export default withdraws
