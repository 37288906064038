import PropTypes from 'prop-types'

import {
  Dialog,
  DialogContent,
  DialogActions,
  Box,
  Divider,
  Typography,
  Button,
  makeStyles,
} from '@material-ui/core'

import useSnackbar from 'hooks/useSnackbar'

import { reverse } from 'named-urls'
import { routes } from 'Routes'
import * as service from 'service'

import styles from './styles'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(styles)

const DialogBillet = ({
  open,
  setOpen,
  type,
  token,
  order,
  seller,
  customer,
}) => {
  const classes = useStyles()
  const snackbar = useSnackbar()
  const history = useHistory()

  const onSubmit = async () => {
    try {
      const response = await service.intermediador.payments.pay({
        order: orderParams(order, customer, token, type),
        seller_auth_token: seller.token,
      })

      setOpen(false)

      snackbar.open({
        message: `${`Boleto gerado com sucesso!`}`,
        variant: 'success',
      })

      history.push(
        reverse(routes.payments.billet, {
          type: type,
          token: token,
        }),
        {
          customer: customer,
          billet: response?.data?.payment?.billet,
        },
      )
    } catch (error) {
      setOpen(false)
      snackbar.open({
        message:
          error?.response?.data?.error?.message ||
          `Ocorreu um erro ao criar ao criar o boleto`,
        variant: 'error',
      })
    }
  }

  const formatProducts = (products) => {
    products.forEach((prod) => {
      delete prod['startsAt']
      delete prod['endsAt']
      delete prod['type']
    })

    return products
  }

  const orderParams = (order, customer, token, type) => {
    const params = {
      orderType: type,
      token: token,
      code: order.code || 'teste',
      payment: {
        method: 6,
        billet: {
          description: order.description || 'n/a',
          expiration: order.expirationDate,
        },
      },
      products: formatProducts(order.products),
      customer: formatCustomer(customer),
    }

    return params
  }

  const formatCustomer = (customer) => {
    const formattedCustomer = {
      birthDate: customer.birthDate,
      document: customer.document
        .split('-')
        .join('')
        .split('.')
        .join('')
        .split('/')
        .join(''),
      documentType: customer.documentType,
      email: customer.email,
      name: customer.name,
      address: {
        city: customer.address.city,
        neighborhood: customer.address.neighborhood,
        number: customer.address.number,
        postalCode: customer.address.postalCode.replace('-', ''),
        state: customer.address.state,
        street: customer.address.street,
      },
    }

    return formattedCustomer
  }

  return (
    <Dialog
      className={classes.modal}
      maxWidth="lg"
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogContent className={classes.modalContent}>
        <Box my={1}>
          <Typography>Você deseja pagar pelo boleto?</Typography>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions p={2}>
        <Box pl={2} pr={1}>
          <Button
            type="button"
            variant="outlined"
            onClick={() => setOpen(false)}
          >
            Cancelar
          </Button>
        </Box>
        <Button
          variant="contained"
          type="submit"
          color="primary"
          onClick={onSubmit}
        >
          Sim
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DialogBillet.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  values: PropTypes.object,
}

export default DialogBillet
