import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react'
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
} from '@material-ui/core'

const SelectColors = forwardRef(({ defaultColor = [], applyColor }, ref) => {
  const [open, setOpen] = useState(false)
  const [color, setColor] = useState(defaultColor)

  useEffect(() => {
    defaultColor && setColor(defaultColor[0])
  }, [defaultColor])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleApplyColor = () => {
    applyColor({ [defaultColor[1]]: { [defaultColor[2]]: color } })
    handleClose()
  }

  useImperativeHandle(ref, () => ({
    openDialog: handleClickOpen,
  }))

  const handleChange = (event) => {
    const newColor = event.target.value
    setColor(newColor)
  }

  return (
    defaultColor && (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <DialogContentText
            style={{
              backgroundColor: color,
              padding: '60px',
              borderRadius: '2px',
              border: '1px solid black',
            }}
          ></DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="HEX"
            type="text"
            value={color}
            fullWidth
            onChange={handleChange}
            inputProps={{ maxLength: 7 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Voltar
          </Button>
          <Button onClick={handleApplyColor} color="primary">
            Aplicar
          </Button>
        </DialogActions>
      </Dialog>
    )
  )
})

export default SelectColors
