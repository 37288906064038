const styles = (checkoutTheme) => ({
  successBox: {
    padding: '16px',
    borderRadius: '60%',
    borderColor: checkoutTheme.palette.success.main,
    color: '#FFF',
    backgroundColor: checkoutTheme.palette.success.main,
    marginBottom: '20px',
  },
})

export default styles
