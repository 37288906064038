import headerPayment from 'images/header_payment.jpg'

const styles = (theme) => ({
  header: {
    backgroundImage: `url(${headerPayment})`,
  },
  image: {
    paddingRight: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  title: {
    fontWeight: 400,
  },
  subtitle: {
    fontWeight: 300,
  },
  button: {
    padding: theme.spacing(3),
    width: '100%',
  },
})

export default styles
