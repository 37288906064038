const styles = () => ({
  table: {
    borderCollapse: 'separate',
    borderSpacing: '0px 8px',
  },
  icon: {
    color: 'white',
    borderRadius: '20px',
  },
  processed: {
    backgroundColor: '#38a434',
  },
  pending: {
    backgroundColor: '#1084ac',
  },
  canceled: {
    backgroundColor: '#F8312F',
  },
  processing: {
    backgroundColor: '#FFAC2F',
  },
  colorBlue: {
    color: '#235078',
  },
  pagination: {
    borderRadius: '20px',
  },
})

export default styles
