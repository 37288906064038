const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  infoText: {
    fontSize: '17px',
    fontWeight: '500',
  },
})

export default styles
