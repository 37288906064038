import constants from 'constants/index'
import {
  Loader as LoaderIcon,
  AlertCircle as AlertCircleIcon,
  ArrowDown as ArrowDownIcon,
  Eye as EyeIcon,
  X as XIcon,
} from 'react-feather'

const orderStatusIcon = (status) => {
  switch (status) {
    case constants.payments.STATUSES.NOT_PAID_STATUS:
      return <AlertCircleIcon size={25} />
    case constants.payments.STATUSES.CANCELED_STATUS:
      return <LoaderIcon size={25} />
    case constants.payments.STATUSES.PAID_STATUS:
      return <ArrowDownIcon size={25} />
    case constants.payments.STATUSES.WAITING_CONFIRM_STATUS:
      return <EyeIcon size={25} />
    default:
      return <XIcon size={25} />
  }
}

export default orderStatusIcon
