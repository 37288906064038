import { Grid, Typography, Box, makeStyles, Button } from '@material-ui/core'
import { DollarSign as DollarSignIcon } from 'react-feather'

import { useHistory } from 'react-router-dom'

import styles from './styles'
import theme from 'theme'
import { routes } from 'Routes'

const useStyles = makeStyles(styles)

const Home = () => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Grid container spacing={8}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography variant="h6" color="textPrimary">
          Como você quer cobrar o seu cliente?
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={4}>
        <Box
          className={classes.boxButton}
          onClick={() => history.push(routes.charges.new)}
        >
          <Box
            p={theme.spacing(6)}
            mr={theme.spacing(10)}
            display="flex"
            alignItems="center"
            className={classes.iconBox}
          >
            <DollarSignIcon size={16} color="#235078" />
          </Box>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{
              textAlign: 'left',
              textTransform: 'capitalize',
            }}
          >
            Cobrança avulsa
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={4}>
        <Box
          className={classes.boxButton}
          style={{}}
          onClick={() => history.push(routes.subscriptions.new)}
        >
          <Box
            p={theme.spacing(6)}
            mr={theme.spacing(10)}
            display="flex"
            alignItems="center"
            className={classes.iconBox}
          >
            <DollarSignIcon size={16} color="#235078" />
          </Box>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{
              textAlign: 'left',
              textTransform: 'capitalize',
            }}
          >
            Cobrança recorrente
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={4}>
        <Button
          className={classes.boxButton}
          style={{}}
          onClick={() => history.push(routes.quickGuide)}
        >
          <Box
            p={theme.spacing(6)}
            mr={theme.spacing(10)}
            display="flex"
            alignItems="center"
            className={classes.iconBox}
          >
            <DollarSignIcon size={16} color="#235078" />
          </Box>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{
              textAlign: 'left',
              textTransform: 'capitalize',
            }}
          >
            Mais detalhes
          </Typography>
        </Button>
      </Grid>
    </Grid>
  )
}

export default Home
