import { useState } from 'react'
import {
  Typography,
  makeStyles,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
} from '@material-ui/core'
import styles from '../../styles'

const useStyles = makeStyles(styles)

const StepperComponent = ({ steps, stepContents }) => {
  const classes = useStyles()
  const [activeStep, setActiveStep] = useState(0)

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  return (
    <Stepper
      activeStep={activeStep}
      orientation="vertical"
      style={{ marginTop: '20px', marginBottom: '20px' }}
    >
      {steps.map((label, index) => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
          <StepContent>
            <Typography variant="body2" color="textSecondary">
              {stepContents[index]}
            </Typography>
            <div className={classes.actionsContainer}>
              <div>
                <Button disabled={activeStep === 0} onClick={handleBack}>
                  Voltar
                </Button>
                {activeStep !== steps.length - 1 && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                  >
                    Próximo
                  </Button>
                )}
              </div>
            </div>
          </StepContent>
        </Step>
      ))}
    </Stepper>
  )
}

export default StepperComponent
