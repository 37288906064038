import { Box, Typography } from '@material-ui/core'

const LabelView = ({ label, value }) => {
  return (
    <Box display="flex" width="100%" flexDirection="column" mb={1}>
      <Box mr={1}>
        <Typography
          variant="body2"
          color="textSecondary"
          style={{
            overflowWrap: 'break-word',
            wordBreak: 'break-word',
          }}
        >
          {label}
        </Typography>
      </Box>
      <Typography
        variant="body2"
        color="textPrimary"
        style={{
          overflowWrap: 'break-word',
          wordBreak: 'break-word',
        }}
      >
        {value}
      </Typography>
    </Box>
  )
}

export default LabelView
