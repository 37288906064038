const styles = (theme) => ({
  unityButton: {
    color: theme.palette.white,
    backgroundColor: theme.palette.text.primary,
    '&:hover': {
      backgroundColor: theme.palette.text.primary,
    },
  },
  trashButton: {
    color: theme.palette.white,
    backgroundColor: theme.palette.error.dark,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  boldText: {
    fontWeight: '600',
  },
  clickableText: {
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.8,
    },
  },
  unityBox: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
})

export default styles
