import api from 'service/api'

const intermediadorAPI = api.create('intermediador')

const get = async ({ ...params }) => {
  return await intermediadorAPI.get('/orders', { params })
}

const stats = async () => {
  return await intermediadorAPI.get('/orders/stats')
}

const show = async ({ orderId = '' }) => {
  return await intermediadorAPI.get(`/payments/${orderId}`)
}

const orders = {
  get,
  stats,
  show,
}

export default orders
