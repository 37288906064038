import React from 'react'

import {
  Box,
  makeStyles,
  Typography,
  Grid,
  Tooltip,
  withStyles,
} from '@material-ui/core'
import styles from './styles'
import helpers from 'helpers'
import LogoMastercard from 'images/image-mastercard.svg'
import LogoVisa from 'images/image-visa.svg'
import LogoAmec from 'images/image-amec.svg'
import LogoElo from 'images/image-elo.svg'
import LogoHipercard from 'images/image-hipercard.svg'
import LogoBoleto from 'images/image-boleto.svg'
import LogoPix from 'images/image-pix.svg'
import LogoDiners from 'images/image-diners.svg'
import LogoBanes from 'images/image-banes.svg'
import LogoCabal from 'images/image-cabal.svg'
import LogoJcb from 'images/image-jcb.svg'

import checkoutTheme from 'theme/checkoutTheme'

const useStyles = makeStyles(styles)

const ProductDetails = ({ order }) => {
  const classes = useStyles()

  const images = [
    { name: 'mastercard', src: LogoMastercard },
    { name: 'visa', src: LogoVisa },
    { name: 'amec', src: LogoAmec },
    { name: 'elo', src: LogoElo },
    { name: 'hipercard', src: LogoHipercard },
    { name: 'boleto', src: LogoBoleto },
    { name: 'pix', src: LogoPix },
    { name: 'diners', src: LogoDiners },
    { name: 'banes', src: LogoBanes },
    { name: 'cabal', src: LogoCabal },
    { name: 'jcb', src: LogoJcb },
  ]

  const truncateText = (text, lenghtText) => {
    let result = null

    if (text.length > lenghtText) {
      result = text.substring(0, lenghtText) + '...'
    }
    return result ? result : text
  }

  const LightTooltip = withStyles((checkoutTheme) => ({
    tooltip: {
      backgroundColor: checkoutTheme.palette.white,
      fontSize: 14,
    },
  }))(Tooltip)

  return (
    <Grid container spacing={2}>
      <Grid item lg={12} sm={12} xs={12}>
        <Typography variant="body1" className={classes.lightBlueText}>
          Você está comprando
        </Typography>
      </Grid>
      <Grid item lg={12} sm={12} xs={12}>
        {order?.products.map((product, index) => (
          <Grid container spacing={2} key={index}>
            {product?.name !== 'Desconto' && (
              <Grid item lg={12} sm={12} xs={12}>
                <Grid item lg={12} sm={12} xs={12}>
                  <Box display="flex" flexDirection="row" alignItems="start">
                    <LightTooltip title={product?.name} enterTouchDelay={0}>
                      <Typography
                        variant="body1"
                        style={{ maxWidth: '90%' }}
                        className={classes.blueText}
                      >
                        {product?.name}
                      </Typography>
                    </LightTooltip>
                    <Typography variant="body1" className={classes.blueText}>
                      ({product?.quantity})
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                  <LightTooltip
                    title={product?.description}
                    enterTouchDelay={0}
                  >
                    <Typography
                      className={classes.blueDescription}
                      variant="body1"
                    >
                      {truncateText(product?.description, 40)}
                    </Typography>
                  </LightTooltip>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography variant="body1" className={classes.price}>
                    R${' '}
                    {helpers.formatters.floatToMoney(
                      parseFloat(product?.price) * product?.quantity,
                    )}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        ))}
      </Grid>
      <Grid item lg={12} sm={12} xs={12}>
        <Box mt={checkoutTheme.spacing(4)}>
          <Typography variant="body1" className={classes.blueText}>
            Total
          </Typography>
        </Box>
        <Box display="flex" direction="row" alignItems="center">
          <Typography variant="body2" className={classes.blueTextSecondary}>
            R$
          </Typography>
          <Typography variant="h6" className={classes.blueTextMain}>
            {helpers.formatters.floatToMoney(Number(order?.value))}
          </Typography>
        </Box>
        <Box style={{ marginTop: '20px' }}>
          <Typography variant="body2" color="textSecondary">
            Aceitamos:
            <br />
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="flex-start"
          flexWrap="wrap"
          justifyContent="space-around"
        >
          {images?.map((image, index) => (
            <img
              key={index}
              alt={image.name}
              src={image.src}
              style={{
                flexBasis: 'calc(18% - 10px)',
                marginBottom: checkoutTheme.spacing(6),
              }}
              height="28px"
            />
          ))}
        </Box>
      </Grid>
    </Grid>
  )
}

export default ProductDetails
