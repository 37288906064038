const styles = (theme) => ({
  root: {
    backgroundColor: 'transparent',
  },
  logo: {
    width: '140px',
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    minHeight: 68,
  },
  button: {
    borderRadius: theme.spacing(20),
    paddingRight: theme.spacing(8),
    paddingLeft: theme.spacing(8),
    backgroundColor: '#a804bc',
    color: 'white',
    '&:hover': {
      backgroundColor: '#a804bc',
    },
  },
})

export default styles
