import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from '@material-ui/core'

import helpers from 'helpers'

const DetailsTable = ({ children, header = [], rows = [], lines = 0 }) => {
  return (
    <Box
      style={{ backgroundColor: '#FFF', borderRadius: '20px', padding: '20px' }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="body1"
          color="textPrimary"
          style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '20px' }}
        >
          Pré-visualização dos dados
        </Typography>
        <Box
        // style={{
        //   display: 'flex',
        //   flexDirection: 'row',
        //   justifyContent: 'space-between',
        //   alignItems: 'center',
        //   width: '100%',
        // }}
        >
          {children}
        </Box>
      </Box>

      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {header &&
                header.map((item, index) => (
                  <TableCell key={index}>
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      style={{ fontSize: '12px' }}
                    >
                      {item}
                    </Typography>
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows &&
              rows.map((row) => (
                <TableRow
                  key={row.name}
                  style={{
                    backgroundColor: 'transparent',
                    borderBottom: '1px solid #b8d1dd',
                  }}
                >
                  <TableCell component="th" scope="row">
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      style={{ fontSize: '12px' }}
                    >
                      {row[0]}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      style={{ fontSize: '12px' }}
                    >
                      {row[1]}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      style={{ fontSize: '12px' }}
                    >
                      {row[2]}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      style={{ fontSize: '12px' }}
                    >
                      {helpers.formatters.cnpj(row[3])}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      style={{ fontSize: '12px' }}
                    >
                      {row[4]}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      style={{ fontSize: '12px' }}
                    >
                      {row[5]}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      style={{ fontSize: '12px' }}
                    >
                      {row[6]}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      style={{ fontSize: '12px' }}
                    >
                      {row[7]}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      style={{ fontSize: '12px' }}
                    >
                      {row[8]}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      style={{ fontSize: '12px' }}
                    >
                      {row[9]}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      style={{ fontSize: '12px' }}
                    >
                      {row[10]}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      style={{ fontSize: '12px' }}
                    >
                      {helpers.formatters.date(row[11])}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography
        variant="body2"
        color="textSecondary"
        style={{ marginTop: '20px' }}
      >
        Total de linhas: {lines === 0 ? lines : lines - 1}
      </Typography>
    </Box>
  )
}

export default DetailsTable
