import { Typography } from '@material-ui/core'
import helpers from 'helpers'

const valueLabel = (amount, status) => {
  switch (status) {
    case 'PAID':
      return (
        <Typography
          variant="body1"
          style={{
            fontWeight: 'bold',
          }}
        >
          +{helpers.formatters.floatToMoney(amount, false)} R$
        </Typography>
      )
    default:
      return (
        <Typography
          variant="body1"
          style={{
            fontWeight: 'bold',
          }}
        >
          {helpers.formatters.floatToMoney(amount, false)} R$
        </Typography>
      )
  }
}

export default valueLabel
