import {
  User as UserIcon,
  CreditCard as CreditCardIcon,
  Cpu as CpuIcon,
  Bell as BellIcon,
  Edit as EditIcon,
  FileText as FileTextIcon,
} from 'react-feather'

const ITEMS = [
  {
    title: 'Perfil',
    icon: <UserIcon size={16} color="#235078" />,
    subtitle: 'Consulte os dados básicos da sua conta.',
    href: '/settings/profile',
  },
  {
    title: 'Relatório de transações',
    icon: <FileTextIcon size={16} color="#235078" />,
    subtitle: 'Veja mais detalhes sobre as transações.',
    href: '/settings/generateReport',
  },
  {
    title: 'Conta bancária',
    icon: <CreditCardIcon size={16} color="#235078" />,
    subtitle: 'Resgistre sua conta bancária para realizar as transações.',
    href: '/settings/bank-accounts',
  },
  {
    title: 'Notificações',
    icon: <BellIcon size={16} color="#235078" />,
    subtitle: 'Adicione um "end-point" e receba as notificações de pagamento!',
    href: '/settings/notifications',
  },
  {
    title: 'Implementação',
    icon: <CpuIcon size={16} color="#235078" />,
    subtitle:
      'Integre a API em sua aplicação para realizar cobranças e muito mais.',
    href: '/settings/implementation',
  },
  {
    title: 'Personalizar carrinho de compra',
    icon: <EditIcon size={16} color="#235078" />,
    subtitle: 'Personalize o carrinho para seus clientes',
    href: '/previewcheckout/order/373ae7252ce2c9e59105ba85b62d2365',
  },
  // {
  //   title: 'Split de pagamentos',
  //   icon: <DollarSignIcon size={16} color="#235078" />,
  //   subtitle: 'Gerencie os repasses com split de pagamentos',
  //   href: '/split',
  // },
]

const settingOptions = {
  ITEMS,
}

export default settingOptions
