import { useMemo } from 'react'
import { makeStyles, Box, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'

import { ListCard } from 'components'

import helpers from 'helpers'
import { StepperOrder } from '../index'
import { routes } from 'Routes'
import { reverse } from 'named-urls'

import styles from './styles'

const useStyles = makeStyles(styles)

const Main = ({ orders }) => {
  const classes = useStyles()

  const transactionsByDate = useMemo(() => {
    const transactionsMap = new Map()
    orders.forEach((order) => {
      const dataKey = helpers.formatters.date(order?.createdAt)
      if (!transactionsMap.has(dataKey)) {
        transactionsMap.set(dataKey, [])
      }
      transactionsMap.get(dataKey).push(order)
    })
    return transactionsMap
  }, [orders])

  return (
    <>
      {Array.from(transactionsByDate).map(([data, transactions]) => (
        <Box key={data} className={classes.container}>
          <Box mt={2} mb={2} className={classes.center}>
            <Typography variant="body1" color="textPrimary">
              {data}
            </Typography>
          </Box>
          {transactions.map((order) => (
            <ListCard
              key={order?.token}
              iconByStatus={helpers.orders.iconByStatus(order?.status)}
              stepper={<StepperOrder orderStatus={order?.status} />}
              title={helpers.orders.operationLabel(order?.status)}
              subtitle={order?.buyer?.name || 'A definir'}
              hour={helpers.formatters.dateTime(order?.createdAt).split('-')[1]}
              value={helpers.orders.valueLabel(order?.amount, order?.status)}
              route={reverse(routes?.orders?.show, {
                orderId: order.token,
              })}
              moreDetails={helpers.orders.moreDetails(order, classes)}
              data={order}
            />
          ))}
        </Box>
      ))}
    </>
  )
}

Main.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.object),
}

Main.defaultProps = {
  orders: [],
  setRefresh: () => {},
}

export default Main
