import {
  Box,
  makeStyles,
  Typography,
  Card,
  Divider,
  Button,
} from '@material-ui/core'
import styles from './styles'

const useStyles = makeStyles(styles)

const BilletInfo = ({ billet }) => {
  const classes = useStyles()

  return (
    <Box mt={4}>
      <Card>
        <Box p={2}>
          <Typography variant="h5">Código de barras</Typography>
        </Box>
        <Divider />
        <Box maxWidth p={3}>
          <Box
            display="flex"
            justifyContent="center"
            className={classes.barCode}
            p={3}
          >
            <Typography variant="h5">{billet?.digitableLine}</Typography>
          </Box>
        </Box>
        <Box alignSelf="stretch">
          <Divider />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          p={1}
          width="100%"
        >
          <Button
            className={classes.button}
            onClick={() => {
              navigator.clipboard.writeText(billet?.digitableLine)
            }}
          >
            Copiar código de barras
          </Button>
          <Box ml={4}>
            <Button
              className={classes.button}
              onClick={() => {
                window.location.href = billet.url
              }}
            >
              Baixar Boleto
            </Button>
          </Box>
        </Box>
      </Card>
    </Box>
  )
}

export default BilletInfo
