import {
  makeStyles,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import styles from './styles'

const useStyles = makeStyles(styles)

const AccordionCard = ({ title, description }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Accordion elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
        >
          <Typography
            color="textPrimary"
            variant="body1"
            className={classes.heading}
          >
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography color="textPrimary" variant="body2">
            {description}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default AccordionCard
