const styles = (theme) => ({
  barCode: {
    backgroundColor: '#fdeeda',
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.black,
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
})

export default styles
