import { Typography, Box, ButtonBase, Divider } from '@material-ui/core'

import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from 'react-feather'

import { useHistory } from 'react-router-dom'

const ButtonBaseComponent = ({
  previousName = null,
  nextName = null,
  previousRoute = null,
  nextRoute = null,
}) => {
  const history = useHistory()

  return (
    <>
      <Divider />
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: '40px',
          marginBottom: '40px',
        }}
      >
        {previousName && (
          <ButtonBase
            onClick={() => {
              history.push(previousRoute)
            }}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-end',
            }}
          >
            <ChevronLeftIcon
              size={20}
              color="#235078"
              style={{ marginRight: '12px' }}
            />
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <Typography variant="body2" color="textSecondary">
                Anterior
              </Typography>
              <Typography
                variant="body1"
                color="textPrimary"
                style={{ fontWeight: 'bold' }}
              >
                {previousName}
              </Typography>
            </Box>
          </ButtonBase>
        )}

        {nextName && (
          <ButtonBase
            onClick={() => {
              history.push(nextRoute)
            }}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-end',
            }}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <Typography variant="body2" color="textSecondary">
                Próximo
              </Typography>
              <Typography
                variant="body1"
                color="textPrimary"
                style={{ fontWeight: 'bold' }}
              >
                {nextName}
              </Typography>
            </Box>
            <ChevronRightIcon
              size={20}
              color="#235078"
              style={{ marginLeft: '12px' }}
            />
          </ButtonBase>
        )}
      </Box>
    </>
  )
}

export default ButtonBaseComponent
