const operationLabel = (status) => {
  switch (status) {
    case 'waiting_payment':
      return 'Aguardando Pagamento'
    case 'waiting_confirm':
      return 'Aguardando confirmação'
    case 'paid':
      return 'Você recebeu'
    default:
      return 'Fatura cancelada'
  }
}

export default operationLabel
