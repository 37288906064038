import { Grid, Box, Divider } from '@material-ui/core'

import { Page, Container } from 'components'

import {
  Menu,
  OnPage,
  ButtonBaseComponent,
  Title,
  Paragraph,
  ParamsTable,
  UrlBox,
  Section,
  CodeBox,
} from '../components'

import { routes } from 'Routes'

const Plans = () => {
  const onPage = [
    ['#d-planos', 'Planos'],
    ['#d-listar', 'Listar'],
    ['#d-consultar', 'Consultar'],
    ['#d-criar', 'Criar'],
    ['#d-atualizar', 'Atualizar'],
  ]

  const createPlan = `
  {
    "name": "test",
    "description": "teste",
    "code": "abc",
    "price": "12.5",
    "periodicity": 1,
    "public": true
}
  `

  return (
    <Page title="Planos">
      <Container maxWidth="md">
        <Box style={{ marginBottom: '40px' }}>
          <Grid container spacing={8}>
            <Grid item xs={12} sm={12} md={12} lg={2}>
              <Menu />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8}>
              <Box style={{ marginBottom: '40px' }}>
                <Title variant="h5" id="d-planos" subtitle="Recorrência">
                  Planos
                </Title>
                <Paragraph>
                  Este endpoint registra os detalhes de um plano de assinatura,
                  incluindo nome, descrição, preço e periodicidade. Esses dados
                  são obrigatórios para a criação de uma assinatura, funcionando
                  como a configuração da cobrança recorrente. Utilizando este
                  serviço, você pode definir e gerenciar os planos de assinatura
                  oferecidos aos seus clientes.
                </Paragraph>
              </Box>
              <Divider />
              <Section>
                <Title variant="h6" id="d-listar">
                  Listar
                </Title>
                <UrlBox
                  type="GET"
                  url="https://api.sandbox.superfin.com.br/subscription/plans"
                  link="https://documenter.getpostman.com/view/11000226/TzRPhoDe#040f92ab-ada5-43b6-8213-a639d3db70a3"
                />
                <Paragraph>
                  Aqui você encontrará a lista de planos cadastrados. Os
                  parâmetros fornecidos servem como filtros aplicáveis à
                  listagem. Adicionalmente, é possível usar os parâmetros
                  'per_page' e 'current_page' para paginar os resultados. Não se
                  esqueça de enviar os parâmetros de autenticação.
                </Paragraph>
                <ParamsTable
                  rows={[
                    [
                      'Authorization',
                      'Token de identificação referente a autenticação',
                      'String',
                      'Headers',
                      'Sim',
                    ],
                  ]}
                />
              </Section>
              <Divider />
              <Section>
                <Title variant="h6" id="d-consultar">
                  Consultar
                </Title>
                <UrlBox
                  type="GET"
                  url="https://api.sandbox.superfin.com.br/subscription/plans/:id"
                  link="https://documenter.getpostman.com/view/11000226/TzRPhoDe#f08edd2d-d90d-4449-a22f-2c085011db83"
                />
                <Paragraph>
                  Este endpoint permite a consulta detalhada de um plano
                  específico. Para utilizá-lo, é necessário enviar o parâmetro
                  'id' (ID do plano, que deve ser incluído na URL), além dos
                  parâmetros de autenticação. A resposta da API fornecerá
                  informações completas sobre o plano, incluindo nome,
                  descrição, preço, periodicidade e outras configurações
                  relevantes. Esse serviço é essencial para verificar os
                  detalhes de um plano antes de realizar qualquer operação de
                  assinatura ou cobrança.
                </Paragraph>
                <ParamsTable
                  rows={[
                    [
                      'Authorization',
                      'Token de identificação referente a autenticação',
                      'String',
                      'Headers',
                      'Sim',
                    ],
                  ]}
                />
              </Section>
              <Divider />
              <Section>
                <Title variant="h6" id="d-criar">
                  Criar
                </Title>
                <UrlBox
                  type="POST"
                  url="https://api.sandbox.superfin.com.br/subscription/plans"
                  link="https://documenter.getpostman.com/view/11000226/TzRPhoDe#81863d9c-0cdd-4c1c-bb4a-503573b6397d"
                />
                <Paragraph>
                  Para efetuar uma assinatura, é fundamental criar um plano,
                  pois este poderá ser associado ao usuário durante o processo
                  de cobrança. <br />
                  <br /> Agora, é essencial criar um novo plano para associá-lo
                  a qualquer cobrança. Para fazer isso, simplesmente envie uma
                  solicitação POST para a rota 'subscription/plans' com os
                  seguintes campos. <br />
                  <br />
                  Parâmetros obrigatórios: 'name' e os parâmetros de
                  autenticação. Se o campo 'public' não for enviado, será por
                  padrão igual a falso.
                </Paragraph>
                <CodeBox
                  title="Body"
                  code={createPlan}
                  link="https://documenter.getpostman.com/view/11000226/TzRPhoDe#81863d9c-0cdd-4c1c-bb4a-503573b6397d"
                />
                <ParamsTable
                  rows={[
                    [
                      'Authorization',
                      'Token de identificação referente a autenticação',
                      'String',
                      'Headers',
                      'Sim',
                    ],
                    ['name', 'Nome do plano', 'String', 'Body', 'Sim'],
                    [
                      'description',
                      'Descrição do plano',
                      'String',
                      'Body',
                      'Sim',
                    ],
                    ['code', 'Código do plano', 'String', 'Body', 'Sim'],
                    [
                      'price',
                      'Preço da periodicidade',
                      'String',
                      'Body',
                      'Sim',
                    ],
                    [
                      'public',
                      'Visibilidade  da página de planos',
                      'Boolean',
                      'Body',
                      'Sim',
                    ],
                  ]}
                />
              </Section>
              <Divider />
              <Section>
                <Title variant="h6" id="d-atualizar">
                  Atualizar
                </Title>
                <UrlBox
                  type="PUT"
                  url="https://api.sandbox.superfin.com.br/subscription/plans/:id"
                  link="https://documenter.getpostman.com/view/11000226/TzRPhoDe#bf8a6f8c-2fe4-400c-b99f-d0e18ade3b1f"
                />
                <Paragraph>
                  Este endpoint permite a atualização dos dados de um plano
                  existente. Para utilizá-lo, é obrigatório enviar o ID do plano
                  na URL, juntamente com os parâmetros de autenticação (Auth-key
                  e Account-code). Todos os parâmetros fornecidos no corpo da
                  solicitação (body) são os campos que podem ser modificados. A
                  resposta da API confirmará as alterações realizadas,
                  garantindo que os dados do plano estejam atualizados conforme
                  necessário.
                </Paragraph>
                <CodeBox
                  title="Body"
                  code={createPlan}
                  link="https://documenter.getpostman.com/view/11000226/TzRPhoDe#81863d9c-0cdd-4c1c-bb4a-503573b6397d"
                />
                <ParamsTable
                  rows={[
                    [
                      'Authorization',
                      'Token de identificação referente a autenticação',
                      'String',
                      'Headers',
                      'Sim',
                    ],
                    ['name', 'Nome do plano', 'String', 'Body', 'Sim'],
                    [
                      'description',
                      'Descrição do plano',
                      'String',
                      'Body',
                      'Sim',
                    ],
                    ['code', 'Código do plano', 'String', 'Body', 'Sim'],
                    [
                      'price',
                      'Preço da periodicidade',
                      'String',
                      'Body',
                      'Sim',
                    ],
                    [
                      'public',
                      'Visibilidade  da página de planos',
                      'Boolean',
                      'Body',
                      'Sim',
                    ],
                  ]}
                />
              </Section>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ButtonBaseComponent
                    previousName="Minhas informações"
                    previousRoute={routes.documentation.myInformation}
                    nextName="Assinaturas"
                    nextRoute={routes.documentation.subscriptions}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={2}>
              <OnPage page={onPage} />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  )
}

export default Plans
