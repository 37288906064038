import { Grid, Box, Divider } from '@material-ui/core'

import { Page, Container } from 'components'

import {
  Menu,
  OnPage,
  ButtonBaseComponent,
  CodeBox,
  Title,
  Paragraph,
  UrlBox,
  Section,
  ParamsTable,
} from '../components'

import { routes } from 'Routes'

const Sandbox = () => {
  const jsonCode = `{
    "user_email": "rafael.couto@gmail.com",
    "seller_key": "2e3ee2089c77a6acd5417ddc79a63176",
    "seller_token": "9a9686926364ce90a8808d8d6177c1cb",
    "expire_last_token": false
}`

  const onPage = [
    ['#d-sandbox', 'Sandbox'],
    ['#d-endereco', 'Endereço'],
  ]

  return (
    <Page title="Sandbox">
      <Container maxWidth="md">
        <Box style={{ marginBottom: '40px' }}>
          <Grid container spacing={8}>
            <Grid item xs={12} sm={12} md={12} lg={2}>
              <Menu />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8}>
              <Box style={{ marginBottom: '20px' }}>
                <Title variant="h5" id="d-sandbox" subtitle="Começando">
                  Sandbox
                </Title>
                <Paragraph>
                  O ambiente sandbox é ideal para a integração com nossas APIs,
                  permitindo realizar testes e integrações sem custos
                  adicionais.
                </Paragraph>
              </Box>
              <Divider />
              <Section>
                <Title variant="h6" id="d-endereco">
                  Endereço
                </Title>
                <Paragraph>
                  O endpoint abaixo é essencial para realizar todas as
                  requisições à API. No ambiente sandbox, todas as requisições
                  devem ser direcionadas a este endpoint, enquanto o ambiente de
                  produção utiliza um endpoint distinto.
                </Paragraph>
                <UrlBox
                  url="https://api.sandbox.superfin.com.br"
                  link="https://documenter.getpostman.com/view/11000226/TzRPhoDe#intro"
                />
                <Paragraph>
                  Agora você pode autenticar-se na Superfin para obter o token
                  que será utilizado em todas as requisições subsequentes.
                </Paragraph>
              </Section>
              <Section>
                <CodeBox
                  type="POST"
                  title="/authentication_api"
                  code={jsonCode}
                  link="https://documenter.getpostman.com/view/11000226/TzRPhoDe#e4f50aed-3497-4ddf-9840-086999f605c0"
                />
                <ParamsTable
                  rows={[
                    [
                      'user_email',
                      'E-mail do usuário cadastrado na nossa plataforma',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'seller_key',
                      'Token criptografado',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'seller_token',
                      'Id criptografado',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'expire_last_token',
                      'Caso seja enviado true, o antigo token de autenticação gerado será inválidado e será gerado um novo token de autentição.',
                      'booleano',
                      'Body',
                      'Não',
                    ],
                  ]}
                />
              </Section>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ButtonBaseComponent
                    nextName="Produção"
                    previousName="Introdução"
                    previousRoute={routes.documentation.introduction}
                    nextRoute={routes.documentation.production}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={2}>
              <OnPage page={onPage} />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  )
}

export default Sandbox
