const styles = () => ({
  table: {
    borderCollapse: 'separate',
    borderSpacing: '0px 8px',
  },
  cell: {
    height: '50px',
  },
})

export default styles
