import operationLabel from './operationLabel'
import valueLabel from './valueLabel'
import moreDetails from './moreDetails'
import iconByStatus from './iconByStatus'
import translateStatus from './translateStatus'

const invoices = {
  operationLabel,
  valueLabel,
  moreDetails,
  iconByStatus,
  translateStatus,
}

export default invoices
