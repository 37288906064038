import PropTypes from 'prop-types'

import SnackbarProvider from 'providers/SnackbarProvider'

const Payment = ({ children }) => {
  return <SnackbarProvider>{children}</SnackbarProvider>
}

Payment.propTypes = {
  children: PropTypes.any,
}

export default Payment
