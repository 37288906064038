import { KeyboardDatePicker } from '@material-ui/pickers'

import styled from 'styled-components'

const StyledDatePicker = styled(KeyboardDatePicker)({
  borderRadius: 20,
  // color: palette.text.primary,
})

const DatePicker = ({ ...rest }) => {
  return <StyledDatePicker {...rest} />
}

export default DatePicker
