import palette from 'theme/palette'

const MuiSelect = {
  select: {
    borderRadius: 20,
    // backgroundColor: palette.background.paper,
    color: palette.text.primary,
    // '&:hover': {
    //   borderRadius: 20,
    //   color: palette.text.primary,
    // },
    // '&$focused': {
    //   borderRadius: 20,
    //   backgroundColor: palette.background.paper,
    //   borderColor: palette.text.primary,
    //   color: palette.text.primary,
    // },
    // '& label.Mui-focused': {
    //   borderRadius: 20,
    //   color: palette.text.primary,
    // },
    // '& .MuiInput-underline:after': {
    //   borderRadius: 20,
    //   borderBottomColor: palette.text.primary,
    //   color: palette.text.primary,
    // },
    // '& .MuiOutlinedInput-root': {
    //   '& fieldset': {
    //     borderRadius: 20,
    //     color: palette.text.primary,
    //   },
    //   '&:hover fieldset': {
    //     borderRadius: 20,
    //     color: palette.text.primary,
    //   },
    //   '&.Mui-focused fieldset': {
    //     borderRadius: 20,
    //     borderColor: palette.primary.main,
    //     color: palette.text.primary,
    //   },
    // },
  },
}
export default MuiSelect
