import { Grid, Box, Divider } from '@material-ui/core'

import { Page, Container } from 'components'

import {
  Menu,
  OnPage,
  ButtonBaseComponent,
  Title,
  Paragraph,
  DetailsTable,
  Section,
} from '../components'

import { routes } from 'Routes'

const HttpCode = () => {
  const onPage = [
    ['#d-respostahttp', 'Códigos de resposta HTTP'],
    ['#d-sucesso', 'Sucesso'],
    ['#d-erronarequisicao', 'Erro na Requisição'],
    ['#d-erronoservidor', 'Erro no Servidor'],
  ]

  const header = ['Código', 'Descrição']
  const rowsSucess = [
    ['200 OK', 'Requisição realizada com sucesso.'],
    ['201 Created', 'Recurso criado com sucesso.'],
    ['202 Accepted', 'Ação iniciada com sucesso.'],
    [
      '204 No Content',
      'Requisição bem-sucedida, mas sem conteúdo para retornar.',
    ],
    [
      '207 Multi-Status',
      ' A requisição de vários itens foi bem-sucedida; verifique o corpo da resposta para possíveis restrições em itens específicos.',
    ],
  ]
  const rowsError = [
    [
      '400 Bad Request',
      'Erro na requisição devido a um problema com os dados enviados.',
    ],
    ['401 Unauthorized', 'Cabeçalho de autenticação inválido.'],
    ['403 Forbidden', 'Token incorreto ou segurança violada.'],
    ['404 Not Found', 'O recurso solicitado não existe.'],
    ['405 Not Allowed', 'Método HTTP não suportado pelo recurso solicitado.'],
    ['406 Not Accepted', 'Tipo de mídia não permitido na requisição.'],
    ['415 Unsupported Media Type', 'Payload enviado não é suportado.'],
    ['429 Too Many Requests', 'Muitas solicitações em um curto período.'],
  ]
  const rowsServerError = [
    ['500 Internal Server Error', 'Erro interno no servidor.'],
    ['503 Service Unavailable', 'Serviço temporariamente indisponível.'],
    ['504 Gateway Timeout', 'O servidor não respondeu a tempo.'],
  ]

  return (
    <Page title="Códigos de resposta HTTP">
      <Container maxWidth="md">
        <Box style={{ marginBottom: '40px' }}>
          <Grid container spacing={8}>
            <Grid item xs={12} sm={12} md={12} lg={2}>
              <Menu />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8}>
              <Box style={{ marginBottom: '40px' }}>
                <Title variant="h5" id="d-respostahttp" subtitle="Começando">
                  Códigos de resposta HTTP
                </Title>
                <Paragraph>
                  Os códigos de status HTTP são códigos numéricos retornados
                  pelo servidor da web para indicar o resultado de uma
                  solicitação HTTP. Esses códigos ajudam a identificar o sucesso
                  ou falha das solicitações e fornecem informações adicionais
                  sobre o que ocorreu com a solicitação.
                </Paragraph>
              </Box>
              <Divider />
              <Section>
                <Title variant="h6" id="d-sucesso">
                  Sucesso
                </Title>
                <DetailsTable rows={rowsSucess} header={header} />
              </Section>
              <Divider />
              <Section>
                <Title variant="h6" id="d-erronarequisicao">
                  Erro na Requisição
                </Title>
                <DetailsTable rows={rowsError} header={header} />
              </Section>
              <Divider />
              <Section>
                <Title variant="h6" id="d-erronoservidor">
                  Erro no Servidor
                </Title>
                <DetailsTable rows={rowsServerError} header={header} />
              </Section>

              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ButtonBaseComponent
                    nextName="Autenticação"
                    previousName="Produção"
                    previousRoute={routes.documentation.production}
                    nextRoute={routes.documentation.autentication}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={2}>
              <OnPage page={onPage} />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  )
}

export default HttpCode
