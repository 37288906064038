import { Box, Typography, Card } from '@material-ui/core'
import { CheckCircle as CheckCircleIcon } from 'react-feather'

const PixPaid = () => {
  return (
    <Box>
      <Card>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          mt={5}
          mb={5}
        >
          <CheckCircleIcon size="50px" color="green" />
          <Box mt={3}>
            <Typography variant="h5">
              Pagamento realizado com sucesso via pix.
            </Typography>
          </Box>
        </Box>
      </Card>
    </Box>
  )
}

export default PixPaid
