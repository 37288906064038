import { Grid, Box, Divider } from '@material-ui/core'

import { Page, Container } from 'components'

import {
  Menu,
  OnPage,
  ButtonBaseComponent,
  Title,
  Paragraph,
  ParamsTable,
  UrlBox,
  Section,
} from '../components'

import { routes } from 'Routes'

const Invoice = () => {
  const onPage = [
    ['#d-planos', 'Fatura'],
    ['#d-listar', 'Listar'],
    ['#d-consultar', 'Consultar'],
  ]

  return (
    <Page title="Fatura">
      <Container maxWidth="md">
        <Box style={{ marginBottom: '40px' }}>
          <Grid container spacing={8}>
            <Grid item xs={12} sm={12} md={12} lg={2}>
              <Menu />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8}>
              <Box style={{ marginBottom: '40px' }}>
                <Title variant="h5" id="d-planos" subtitle="Recorrência">
                  Fatura
                </Title>
                <Paragraph>
                  A API de faturamento é um componente crucial para a gestão
                  financeira em sistemas de assinaturas e serviços recorrentes.
                  Ela permite a geração, consulta e atualização de faturas,
                  facilitando o controle das cobranças e pagamentos dos
                  clientes.
                </Paragraph>
              </Box>
              <Divider />
              <Section>
                <Title variant="h6" id="d-listar">
                  Listar
                </Title>
                <UrlBox
                  type="GET"
                  url="https://api.sandbox.superfin.com.br/subscription/invoices"
                  link="https://documenter.getpostman.com/view/11000226/TzRPhoDe#cef255eb-6204-4eee-8458-8d5492e3dba5"
                />
                <Paragraph>
                  API para realizar a listagem de faturas, nesse exemplo
                  coloquei todos os filtros opcionais. Obrigatorio o envio dos
                  parametros de autenticação.
                </Paragraph>
                <ParamsTable
                  rows={[
                    [
                      'Authorization',
                      'Token de identificação referente a autenticação',
                      'String',
                      'Headers',
                      'Sim',
                    ],
                  ]}
                />
              </Section>
              <Divider />
              <Section>
                <Title variant="h6" id="d-consultar">
                  Consultar
                </Title>
                <UrlBox
                  type="GET"
                  url="https://api.sandbox.superfin.com.br/subscription/invoices/:id"
                  link="https://documenter.getpostman.com/view/11000226/TzRPhoDe#a75aa1b3-3e0a-40bd-a5db-d0d560842a96"
                />
                <Paragraph>
                  Realiza a consulta de assinaturas. É obrigatório enviar um ID
                  para retornar a assinatura correspondente, desde que ela
                  pertença à conta autenticada.
                </Paragraph>
                <ParamsTable
                  rows={[
                    [
                      'Authorization',
                      'Token de identificação referente a autenticação',
                      'String',
                      'Headers',
                      'Sim',
                    ],
                    [
                      'id',
                      'Identificação da fatura',
                      'String',
                      'Headers',
                      'Sim',
                    ],
                  ]}
                />
              </Section>
              <Divider />
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ButtonBaseComponent
                    previousName="Planos"
                    previousRoute={routes.documentation.plans}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={2}>
              <OnPage page={onPage} />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  )
}

export default Invoice
