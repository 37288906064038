import { Box, makeStyles, Typography } from '@material-ui/core'

import styles from './styles'

const useStyles = makeStyles(styles)

const SellerDetails = ({ seller }) => {
  const classes = useStyles()

  return (
    <Box mt={12} className={classes.sellerText}>
      <Typography>Vendedor - {seller?.name}</Typography>
      <Typography>{seller?.email}</Typography>
      <Typography>{seller?.phone}</Typography>
    </Box>
  )
}

export default SellerDetails
