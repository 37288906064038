import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  Switch,
} from '@material-ui/core'
import { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { MoneyInput } from 'components'

import { routes } from 'Routes'
import * as service from 'service'
import schema from './schema'

import { useHistory } from 'react-router-dom'
import useSnackbar from 'hooks/useSnackbar'

const PlansForm = ({ plan, isEdit }) => {
  const history = useHistory()
  const snackbar = useSnackbar()
  const [isPublic, setIsPublic] = useState(() => {
    if (typeof plan?.public == 'boolean') {
      return plan?.public
    }
    return true
  })

  const defineIsPublic = (data) => {
    if (typeof data == 'boolean') {
      return data
    }
    return true
  }

  const { control, handleSubmit, errors } = useForm({
    validationSchema: schema,
    defaultValues: {
      name: plan?.name || '',
      description: plan?.description || '',
      code: plan?.code || '',
      price: plan?.price || 0,
      periodicity: plan?.periodicity || 1,
      public: defineIsPublic(plan?.public),
    },
  })

  const onSubmit = async (data) => {
    try {
      if (isEdit) {
        await service.intermediador.plans.update({
          planId: plan.id,
          plan: {
            name: data.name,
            description: data.description,
            code: data.code,
            price: data.price,
            periodicity: data.periodicity,
            public: data.public,
          },
        })
      } else {
        await service.intermediador.plans.create({
          plan: {
            name: data.name,
            description: data.description,
            code: data.code,
            price: data.price,
            periodicity: data.periodicity,
            public: data.public,
          },
        })
      }

      snackbar.open({
        message: `Plano ${isEdit ? 'atualizado' : 'criado'} com sucesso`,
        variant: 'success',
      })

      service.history.push(routes.plans.main)
    } catch (error) {
      snackbar.open({
        message: `Erro ao ${isEdit ? 'atualizar' : 'criar'} plano`,
        variant: 'error',
      })
    }
  }

  return (
    <form id="planForm" onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={8}>
        <Grid item lg={12} xs={12}>
          <Box display="flex" alignItems="center" width="100%">
            <Typography color="textPrimary" variant="h6">
              Informações do plano
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2}>
          <Controller
            as={
              <TextField
                label="Código"
                type="text"
                color="primary"
                error={!!errors.code}
                helperText={errors?.code?.message}
                fullWidth
              />
            }
            control={control}
            name="code"
            mode="onBlur"
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={10} lg={10}>
          <Controller
            as={
              <TextField
                label="Nome do plano"
                type="text"
                color="primary"
                error={!!errors.name}
                helperText={errors?.name?.message}
                fullWidth
              />
            }
            control={control}
            name="name"
            mode="onBlur"
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Controller
            as={
              <TextField
                label="Descrição"
                type="text"
                color="primary"
                error={!!errors.description}
                helperText={errors?.description?.message}
                fullWidth
              />
            }
            control={control}
            name="description"
            mode="onBlur"
            size="small"
          />
        </Grid>

        <Grid item xs={12} sm={12} md={8} lg={8}>
          <Controller
            as={
              <TextField
                label="Preço"
                type="text"
                color="primary"
                error={!!errors.price}
                helperText={errors?.price?.message}
                fullWidth
                InputProps={{
                  inputComponent: MoneyInput,
                }}
              />
            }
            control={control}
            name="price"
            mode="onBlur"
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Controller
            as={
              <TextField
                label="Periodicidade"
                type="number"
                color="primary"
                error={!!errors.periodicity}
                helperText={errors?.periodicity?.message}
                fullWidth
                InputProps={{
                  inputProps: { min: 1 },
                }}
              />
            }
            control={control}
            name="periodicity"
            mode="onBlur"
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box pl={2} display="flex" flexDirection="row" alignItems="center">
            <Controller
              as={
                <Switch
                  color="primary"
                  onClick={(event) => {
                    setIsPublic(event.target.checked)
                  }}
                />
              }
              control={control}
              name="public"
              mode="onBlur"
              size="small"
            />
            <Typography variant="body1" color="textPrimary">
              {isPublic ? 'Público' : 'Privado'}
            </Typography>
          </Box>
          <Box pl={2}>
            <Box display="flex" flexDirection="row">
              <Box mr={1}>
                <Typography variant="body1" color="textPrimary">
                  <b>Público:</b>
                </Typography>
              </Box>
              <Typography variant="body1" color="textPrimary">
                O plano será exibido no checkout de planos.
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row">
              <Box mr={1}>
                <Typography variant="body1" color="textPrimary">
                  <b>Privado:</b>
                </Typography>
              </Box>
              <Typography variant="body1" color="textPrimary">
                O plano não será exibido no checkout de planos.
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item sm={12} xs={12}>
          <Box pl={2} mt={3} display="flex" justifyContent="flex-start">
            <Box pr={1}>
              <Button type="button" onClick={() => history.goBack()}>
                Voltar
              </Button>
            </Box>
            <Box>
              <Button
                form="planForm"
                color="primary"
                variant="contained"
                type="submit"
              >
                Salvar
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </form>
  )
}

export default PlansForm
