const styles = (checkoutTheme) => ({
  blueText: {
    color: checkoutTheme.palette.text.primary,
  },
  bold: {
    fontWeight: 600,
  },
})

export default styles
