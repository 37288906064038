import {
  Typography,
  makeStyles,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
} from '@material-ui/core'

import styles from './styles'

const useStyles = makeStyles(styles)

const ContentCard = ({ imageUrl, title, description, link }) => {
  const classes = useStyles()

  return (
    <Card className={classes.root} elevation={0}>
      <CardActionArea>
        <CardMedia className={classes.media} image={imageUrl} />
        <CardContent>
          <Typography gutterBottom variant="body1">
            {title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button
          size="small"
          color="primary"
          onClick={() => window.open(link, '_blank')}
        >
          Baixar
        </Button>
      </CardActions>
    </Card>
  )
}

export default ContentCard
