import PropTypes from 'prop-types'

import { makeStyles, Box } from '@material-ui/core'

import { TopBar } from './components'

import styles from './styles'

import SnackbarProvider from 'providers/SnackbarProvider'

const useStyles = makeStyles(styles)

const Payment = ({ children }) => {
  const classes = useStyles()

  return (
    <SnackbarProvider>
      <Box className={classes.root}>
        <TopBar />
        <Box className={classes.wrapper}>
          <Box className={classes.contentContainer}>
            <Box className={classes.content}>{children}</Box>
          </Box>
        </Box>
      </Box>
    </SnackbarProvider>
  )
}

Payment.propTypes = {
  children: PropTypes.any,
}

export default Payment
