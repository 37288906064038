import { colors } from '@material-ui/core'

const MuiTableCell = {
  head: {
    color: '#263238',
    fontWeight: 500,
    borderColor: colors.blueGrey[300],
    fontSize: 16,
  },
  root: {
    padding: '4px',
  },
  body: {
    borderBottom: 'none',
    '&:first-child': {
      borderRadius: '20px 0  0 20px',
    },
    '&:last-child': {
      borderRadius: '0 20px 20px 0',
    },
  },
}

export default MuiTableCell
