import PropTypes from 'prop-types'
import React from 'react'

import {
  Box,
  Grid,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  Divider,
  makeStyles,
  IconButton,
} from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import { LoadingButton } from 'components'
import { X as XIcon } from 'react-feather'

import schema from './schema'
import styles from './styles'
import * as service from 'service'
import helpers from 'helpers'

import useSnackbar from 'hooks/useSnackbar'
import { useForm, Controller } from 'react-hook-form'
import { routes } from 'Routes'
import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'

const useStyles = makeStyles(styles)

const DialogChargesBillet = ({
  open,
  setOpen = () => {},
  refresh,
  chargesData,
}) => {
  const classes = useStyles()
  const history = useHistory()
  const snackbar = useSnackbar()

  const { control, handleSubmit, errors, setValue } = useForm({
    validationSchema: schema,
    defaultValues: {
      document: '',
      documentType: '',
      birthDate: null,
      address: {
        street: '',
        number: '',
        neighborhood: '',
        city: '',
        state: '',
        postalCode: '',
      },
    },
  })

  const handleClose = () => {
    setOpen(false)
  }

  const onSubmit = async (data) => {
    try {
      const response = await service.intermediador.charges.createFull({
        charge: {
          payerName: chargesData.payerName,
          payerEmail: chargesData.payerEmail,
          expirationDate: helpers.formatters.date(chargesData.expirationDate),
          code: chargesData.code,
          description: chargesData.description,
          products: chargesData.products,
          customer: {
            document: data.document
              .split('-')
              .join('')
              .split('.')
              .join('')
              .split('/')
              .join(''),
            documentType: data.documentType,
            birthDate: data.birthDate.toString(),
            address: {
              street: data.street,
              number: data.number,
              neighborhood: data.neighborhood,
              city: data.city,
              state: data.state,
              postalCode: data.postalCode.replace('-', ''),
            },
          },
        },
      })

      snackbar.open({
        message: `Criada cobrança com boleto vinculado`,
        variant: 'success',
      })
      setOpen(false)

      history.push(reverse(routes.charges.billetInfo), {
        billet: response?.data?.charge?.billet,
      })
    } catch (error) {
      snackbar.open({
        message: 'Falha ao salvar cobrança com boleto',
        variant: 'error',
      })
    }
  }

  return (
    <Dialog
      open={open}
      PaperProps={{ classes: { root: classes.root } }}
      onClose={handleClose}
      maxWidth="lg"
    >
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h5">
            Informações faltantes para o boleto
          </Typography>
          <IconButton onClick={handleClose}>
            <XIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Box p={3}>
            <Grid container spacing={3}>
              <Grid item sm={6} xs={12}>
                <Controller
                  name="document"
                  as={
                    <TextField
                      label="Documento(CPF/CNPJ)"
                      type="text"
                      color="primary"
                      variant="outlined"
                      fullWidth
                      error={!!errors.document}
                      helperText={errors?.document?.message}
                    />
                  }
                  onChange={([event]) => {
                    let doc = helpers.formatters.document(event.target.value)
                    if (doc.length === 14) {
                      setValue('documentType', 'CPF')
                    } else if (doc.length > 14) {
                      setValue('documentType', 'CNPJ')
                    }
                    return doc
                  }}
                  control={control}
                  mode="onBlur"
                />
                <Controller
                  name="documentType"
                  as={<input type="hidden" />}
                  control={control}
                  mode="onBlur"
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Controller
                  name="birthDate"
                  as={
                    <DatePicker
                      fullWidth
                      label="Data de nascimento"
                      format="DD/MM/yyyy"
                      helperText={errors?.birthDate?.message}
                      error={!!errors.birthDate}
                      inputVariant="outlined"
                      minDate={`${
                        new Date(Date.now()).getFullYear() - 100
                      }-01-01`}
                      maxDate={`${new Date(Date.now()).getFullYear() - 18}-${
                        new Date(Date.now()).getMonth() + 1
                      }-${new Date(Date.now()).getDate()}`}
                    />
                  }
                  control={control}
                  mode="onBlur"
                />
              </Grid>
            </Grid>
          </Box>
          <Box alignSelf="stretch">
            <Divider />
          </Box>
          <Box p={2} alignSelf="start">
            <Typography variant="h5">Endereço de cobrança</Typography>
          </Box>
          <Box alignSelf="stretch">
            <Divider />
          </Box>
          <Box p={3}>
            <Grid container spacing={3}>
              <Grid item sm={6} xs={12}>
                <Controller
                  name="street"
                  as={
                    <TextField
                      label="Rua"
                      type="tel"
                      color="primary"
                      variant="outlined"
                      fullWidth
                      error={!!errors.street}
                      helperText={errors?.street?.message}
                    />
                  }
                  control={control}
                  mode="onBlur"
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Controller
                  name="number"
                  as={
                    <TextField
                      label="Número"
                      type="text"
                      color="primary"
                      variant="outlined"
                      fullWidth
                      error={!!errors.number}
                      helperText={errors?.number?.message}
                    />
                  }
                  control={control}
                  mode="onBlur"
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Controller
                  name="neighborhood"
                  as={
                    <TextField
                      label="Bairro"
                      type="text"
                      color="primary"
                      variant="outlined"
                      fullWidth
                      error={!!errors.neighborhood}
                      helperText={errors?.neighborhood?.message}
                    />
                  }
                  control={control}
                  mode="onBlur"
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Controller
                  name="city"
                  as={
                    <TextField
                      label="Cidade"
                      type="text"
                      color="primary"
                      variant="outlined"
                      fullWidth
                      error={!!errors.city}
                      helperText={errors?.city?.message}
                    />
                  }
                  control={control}
                  mode="onBlur"
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Controller
                  name="state"
                  as={
                    <TextField
                      label="Estado"
                      type="text"
                      color="primary"
                      variant="outlined"
                      fullWidth
                      error={!!errors.state}
                      helperText={errors?.state?.message}
                      inputProps={{ maxLength: 2 }}
                    />
                  }
                  control={control}
                  mode="onBlur"
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Controller
                  name="postalCode"
                  as={
                    <TextField
                      label="CEP"
                      type="text"
                      color="primary"
                      variant="outlined"
                      fullWidth
                      error={!!errors.postalCode}
                      helperText={errors?.postalCode?.message}
                      inputProps={{ maxLength: 9 }}
                    />
                  }
                  onChange={([event]) => {
                    return event.target.value.replace(
                      /^(\d{5})(\d{1,4}).*/,
                      '$1-$2',
                    )
                  }}
                  control={control}
                  mode="onBlur"
                />
              </Grid>
            </Grid>
          </Box>
          <Box alignSelf="stretch">
            <Divider />
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            p={1}
            width="100%"
          >
            <LoadingButton text="Enviar" type="submit" />
          </Box>
        </Box>
      </form>
    </Dialog>
  )
}

DialogChargesBillet.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  refresh: PropTypes.func,
}

export default DialogChargesBillet
