const WAITING_PAYMENT_STATUS = 'WAITING_PAYMENT'
const PAID_STATUS = 'PAID'

const WAITING_PAYMENT_LABEL = 'AGUARDANDO PAGAMENTO'
const PAID_LABEL = 'PAGO'

const STATUSES = {
  WAITING_PAYMENT_STATUS,
  PAID_STATUS,
}

const STATUSES_LABELS = {
  WAITING_PAYMENT_LABEL,
  PAID_LABEL,
}

const balanceControls = {
  STATUSES,
  STATUSES_LABELS,
}

export default balanceControls
