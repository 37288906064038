import { useState } from 'react'

import {
  Typography,
  Button,
  Box,
  Select,
  MenuItem,
  FormControl,
  Grid,
} from '@material-ui/core'

const TypographyTheme = ({ changeColor, customTypography }) => {
  const fontOptions = ['Arial', 'Comfortaa', 'Roboto', 'Poppins']
  const [formValues, setFormValues] = useState({
    palette: {
      text: {
        primary: customTypography.palette.text.primary,
        secondary: customTypography.palette.text.secondary,
      },
    },
    typography: {
      body1: {
        ...customTypography.typography.body1,
        fontFamily: customTypography.typography.body1.fontFamily,
        color: customTypography.typography.body1.color,
      },
      h6: {
        ...customTypography.typography.h6,
        fontFamily: customTypography.typography.h6.fontFamily,
        color: customTypography.typography.h6.color,
      },
      subtitle1: {
        ...customTypography.typography.subtitle1,
        fontFamily: customTypography.typography.subtitle1.fontFamily,
        color: customTypography.typography.subtitle1.color,
      },
      body2: {
        ...customTypography.typography.body2,
        fontFamily: customTypography.typography.body2.fontFamily,
        color: customTypography.typography.body2.color,
      },
      overline: {
        ...customTypography.typography.overline,
        fontFamily: customTypography.typography.overline.fontFamily,
        color: customTypography.typography.overline.color,
      },
    },
  })

  const [fontFamilyH6, setFontFamilyH6] = useState(
    fontOptions.includes(formValues.typography.h6.fontFamily)
      ? formValues.typography.h6.fontFamily
      : 'Comfortaa',
  )

  const [fontFamilySubtitle1, setFontFamilySubtitle1] = useState(
    fontOptions.includes(formValues.typography.subtitle1.fontFamily)
      ? formValues.typography.subtitle1.fontFamily
      : 'Arial',
  )

  const [fontFamilyBody1, setFontFamilyBody1] = useState(
    fontOptions.includes(formValues.typography.body1.fontFamily)
      ? formValues.typography.body1.fontFamily
      : 'Arial',
  )

  const [fontFamilyOverline, setFontFamilyOverline] = useState(
    fontOptions.includes(formValues.typography.overline.fontFamily)
      ? formValues.typography.overline.fontFamily
      : 'Arial',
  )

  const [fontFamilyBody2, setFontFamilyBody2] = useState(
    fontOptions.includes(formValues.typography.body2.fontFamily)
      ? formValues.typography.body2.fontFamily
      : 'Arial',
  )

  const handleInputChange = (event, category, field, key) => {
    const newValue = `${event.target.value}`
    setFormValues((prevValues) => {
      if (typeof prevValues[category][field] === 'object') {
        return {
          ...prevValues,
          [category]: {
            ...prevValues[category],
            [field]: {
              ...prevValues[category][field],
              [key]: newValue,
            },
          },
        }
      } else {
        return {
          ...prevValues,
          [category]: {
            ...prevValues[category],
            [field]: newValue,
          },
        }
      }
    })
  }

  const handleFontChange = (event, field) => {
    const selectedFont = event.target.value
    switch (field) {
      case 'h6':
        setFontFamilyH6(selectedFont)
        break
      case 'subtitle1':
        setFontFamilySubtitle1(selectedFont)
        break
      case 'body1':
        setFontFamilyBody1(selectedFont)
        break
      case 'body2':
        setFontFamilyBody2(selectedFont)
        break
      case 'overline':
        setFontFamilyOverline(selectedFont)
        break
      default:
        break
    }
    handleInputChange(event, 'typography', field, 'fontFamily')
  }

  const renderSelect = (label, field, selectedFont, handleFontChange) => (
    <Box>
      <Typography color="textPrimary" variant="body1">
        {label}
      </Typography>
      <FormControl variant="outlined" size="small" fullWidth>
        <Select
          labelId={`${field}-select-label`}
          id={`${field}-select`}
          value={selectedFont}
          onChange={(event) => handleFontChange(event, field)}
          style={{ backgroundColor: 'transparent!important' }}
        >
          {fontOptions.map((font) => (
            <MenuItem key={font} value={font}>
              <Box fontFamily={font}>{font}</Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )

  const handleSaveButtonClick = () => {
    changeColor(formValues)
  }

  return (
    <form noValidate autoComplete="off" style={{ width: '300px' }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography color="textPrimary" variant="h6">
            Tipografia
          </Typography>
          <Typography color="textPrimary" variant="body2">
            Defina os padrões de Tipografia para todos os elementos do carrinho
            de pagamentos
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {renderSelect('Títulos', 'h6', fontFamilyH6, handleFontChange)}
          {renderSelect(
            'Subtítulos',
            'subtitle1',
            fontFamilySubtitle1,
            handleFontChange,
          )}
          {renderSelect(
            'Links',
            'overline',
            fontFamilyOverline,
            handleFontChange,
          )}
          {renderSelect(
            'Corpo do texto',
            'body1',
            fontFamilyBody1,
            handleFontChange,
          )}
          {renderSelect(
            'Texto secundário',
            'body2',
            fontFamilyBody2,
            handleFontChange,
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveButtonClick}
            style={{ width: '100%', marginTop: '20px' }}
          >
            Aplicar
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default TypographyTheme
