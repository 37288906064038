import hexToGrayScale from './hexToGrayScale'
import invertHSVValue from './invertHSVValue'
import hexToHSV from './hexToHSV'

const colors = {
  hexToGrayScale,
  invertHSVValue,
  hexToHSV,
}

export default colors
