import { useEffect } from 'react'
import theme from 'theme'
import { useLocation, Router } from 'react-router-dom'
import { withStyles } from '@material-ui/styles'

import 'configs/yup/locale'

import 'react-perfect-scrollbar/dist/css/styles.css'

import AuthProvider from 'providers/AuthProvider'

import Routes from './Routes'
import history from 'service/history'

import { getBaseURL } from 'service/env'

import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { Helmet } from 'react-helmet'
import MomentUtils from '@date-io/moment'
import 'moment/locale/pt-br'

import 'react-perfect-scrollbar/dist/css/styles.css'

const App = () => {
  const isTestEnv = ['development', 'sandbox', 'test'].includes(
    process.env.REACT_APP_API,
  )

  const injectGA = () => {
    if (typeof window == 'undefined') {
      return
    }
    window.dataLayer = window.dataLayer || []
    function gtag() {
      window.dataLayer.push(arguments)
    }
    gtag('js', new Date())

    gtag('config', 'GT-5M8LTVT')
  }

  return (
    <>
      <Helmet
        script={[
          {
            type: 'text/javascript',
            src: getBaseURL('auth'),
            name: 'leavening_login_with',
            'data-name': 'env',
            env: isTestEnv ? 'sandbox' : process.env.REACT_APP_API,
          },
        ]}
      >
        {process.env.REACT_APP_API === 'production' && (
          <>
            <script
              async
              src="https://www.googletagmanager.com/gtag/js?id=GT-5M8LTVT"
            ></script>
            <script>{injectGA()}</script>
            <script>
              {(function (w, d, s, l, i) {
                w[l] = w[l] || []
                w[l].push({
                  'gtm.start': new Date().getTime(),
                  event: 'gtm.js',
                })
                var f = d.getElementsByTagName(s)[0],
                  j = d.createElement(s),
                  dl = l !== 'dataLayer' ? '&l=' + l : ''
                j.async = true
                j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
                f.parentNode.insertBefore(j, f)
              })(window, document, 'script', 'dataLayer', 'GTM-KW88PJL')}
            </script>
            <script>
              {(function (h, o, t, j, a, r) {
                h.hj =
                  h.hj ||
                  function () {
                    ;(h.hj.q = h.hj.q || []).push(arguments)
                  }
                h._hjSettings = { hjid: 3559814, hjsv: 6 }
                a = o.getElementsByTagName('head')[0]
                r = o.createElement('script')
                r.async = 1
                r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
                a.appendChild(r)
              })(
                window,
                document,
                'https://static.hotjar.com/c/hotjar-',
                '.js?sv=',
              )}
            </script>
          </>
        )}
      </Helmet>
      <MuiPickersUtilsProvider locale="pt-br" utils={MomentUtils}>
        <Router history={history}>
          <ScrollToTop />
          <AuthProvider>
            <Routes />
          </AuthProvider>
        </Router>
      </MuiPickersUtilsProvider>
    </>
  )
}

const styles = {
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    html: {
      height: '100%',
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
    },
    body: {
      backgroundColor: theme.palette.background.default,
      height: '100%',
    },
    a: {
      textDecoration: 'none',
    },
    '#root': {
      height: '100%',
    },
  },
}

function ScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

export default withStyles(styles)(App)
