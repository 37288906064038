import New from './ChargesNew'
import Main from './ChargesMain'
import BilletInfo from './ChargesBilletInfo'
import ChargesShow from './ChargesShow'

const Charges = {
  New,
  Main,
  BilletInfo,
  ChargesShow,
}

export default Charges
