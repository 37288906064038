const convertStringToCpfOrCnpj = (document) => {
  if (document === null || document === undefined) return ''

  let l = document.length
  if (l <= 3) return document

  if (l > 18) return document.slice(0, 18)

  document = document
    .split('-')
    .join('')
    .split('.')
    .join('')
    .split('/')
    .join('')

  l = document.length

  if (l <= 6) return document.replace(/^(\d{3})(\d{1,3})*/, '$1.$2')
  if (l <= 9) return document.replace(/^(\d{3})(\d{3})(\d{1,3}).*/, '$1.$2.$3')
  if (l < 11)
    return document.replace(/^(\d{3})(\d{3})(\d{3})(\d{1}).*/, '$1.$2.$3-$4')
  if (l === 11)
    return document.replace(/^(\d{3})(\d{3})(\d{3})(\d{2}).*/, '$1.$2.$3-$4')
  if (l <= 12)
    return document.replace(/^(\d{2})(\d{3})(\d{3})(\d{1,4}).*/, '$1.$2.$3/$4')
  if (l < 14)
    return document.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{1,2}).*/,
      '$1.$2.$3/$4-$5',
    )
  if (l === 14)
    return document.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/,
      '$1.$2.$3/$4-$5',
    )

  return document
}

export default convertStringToCpfOrCnpj
