import { Box, Typography } from '@material-ui/core'
import checkoutTheme from 'theme/checkoutTheme'

const ComplianceText = ({ buttonTitle }) => {
  return (
    <Box mt={checkoutTheme.spacing(10)} display="flex" flexDirection="column">
      <Box>
        <Typography variant="overline" color="textPrimary">
          Ao clicar em “{buttonTitle}”, você confirma ter autorização de uso
          deste método de pagamento e concorda com o Contrato de Licença de
          Usuário Final da Superfin e Termos de Venda da Superfin.
        </Typography>
      </Box>
      <Box my={checkoutTheme.spacing(4)}>
        <Typography variant="overline" color="textPrimary">
          PAGAMENTO PROCESSADO POR SUPERFIN
        </Typography>
      </Box>
      <Box>
        <Typography variant="overline" color="textPrimary">
          Seus dados de pagamento serão coletados e processados por Superfin.
          Consulte a Política de Privacidade da empresa aqui.
        </Typography>
      </Box>
    </Box>
  )
}

export default ComplianceText
