const styles = (checkoutTheme) => ({
  successBox: {
    border: '1px solid gray',
    borderRadius: '3px',
    fontSize: '18px',
    borderColor: '#87c940',
    color: '#FFF',
    backgroundColor: '#a0d468',
    display: 'flex',
    alignItems: 'center',
  },
  image: {
    color: '#FFF',
  },
})

export default styles
