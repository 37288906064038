import { Grid, Box, Divider } from '@material-ui/core'

import { Page, Container } from 'components'

import {
  Menu,
  OnPage,
  ButtonBaseComponent,
  CodeBox,
  Title,
  Paragraph,
  ParamsTable,
  UrlBox,
  Section,
} from '../components'

import { routes } from 'Routes'

const Orders = () => {
  const onPage = [
    ['#d-transacao', 'Transações'],
    ['#d-listar', 'Listar'],
    ['#d-consultar', 'Consultar'],
    ['#d-criar', 'Criar'],
    ['#d-aprovar', 'Aprovar Pagamento (Sandbox)'],
  ]

  const createOrder = `
    {   
    "seller_auth_token": "ad3d9e7cd5f08c24adcf220089923783",
    "order": {
        "code": "123111133333",
        "customer": {
            "name": "Teste jucelino",
            "email": "cliente@testes.com",
            "document_type": "CPF",
            "document": "71475176015",
            "birth_date": "01/12/2000",
            "address": {
                "street": "Rua teste",
                "number": "777",
                "neighborhood": "Bairro teste",
                "city": "Testelândia",
                "state": "SP",
                "postal_code": "17580000"
            }
        },
        "products": [
            {
                "name": "Produto A",
                "description": "Para testes",
                "price": "70,00",
                "quantity": "2"
            },
            {
                "name": "Produto B",
                "description": "Para testes",
                "price": "30,00",
                "quantity": "3"
            }
        ]
    }
}
`

  return (
    <Page title="Transações">
      <Container maxWidth="md">
        <Box style={{ marginBottom: '40px' }}>
          <Grid container spacing={8}>
            <Grid item xs={12} sm={12} md={12} lg={2}>
              <Menu />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8}>
              <Box style={{ marginBottom: '40px' }}>
                <Title variant="h5" id="d-transacao" subtitle="Começando">
                  Transações
                </Title>
                <Paragraph>
                  O endpoint de transação é uma parte crucial de nossa API,
                  permitindo a criação, consulta e gerenciamento de transações
                  financeiras.
                </Paragraph>
              </Box>
              <Divider />
              <Section>
                <Title variant="h6" id="d-listar">
                  Listar
                </Title>
                <UrlBox
                  type="GET"
                  url="https://api.sandbox.superfin.com.br/orders"
                  link="https://documenter.getpostman.com/view/11000226/TzRPhoDe#ebf33f5b-297a-483a-a863-d0463db7b744"
                />
                <Paragraph>
                  O endpoint de listagem de transações é essencial para obter
                  uma visão geral das transações realizadas. Ele permite
                  recuperar uma lista de transações com diversos parâmetros de
                  filtragem para facilitar a busca e análise.
                </Paragraph>

                <ParamsTable
                  rows={[
                    [
                      'Authorization',
                      'Token de identificação referente a autenticação',
                      'String',
                      'Headers',
                      'Sim',
                    ],
                  ]}
                />
              </Section>
              <Divider />
              <Section>
                <Title variant="h6" id="d-consultar">
                  Consultar
                </Title>
                <UrlBox
                  type="GET"
                  url="https://api.sandbox.superfin.com.br/payments/:token"
                  link="https://documenter.getpostman.com/view/11000226/TzRPhoDe#a5a17830-57eb-4f17-8c41-0d478f34d45a"
                />
                <Paragraph>
                  Para consultar uma transação em nosso sistema, o usuário deve
                  fornecer apenas o token referente à transação. A tabela abaixo
                  detalha todos os parâmetros aceitos pelo sistema, juntamente
                  com suas respectivas descrições.
                </Paragraph>

                <ParamsTable
                  rows={[
                    [
                      'Authorization',
                      'Token de identificação referente a autenticação',
                      'String',
                      'Headers',
                      'Sim',
                    ],
                    [
                      'token',
                      'Token de identificação referente a transação',
                      'String',
                      'Route',
                      'Sim',
                    ],
                  ]}
                />
              </Section>
              <Divider />
              <Section>
                <Title variant="h6" id="d-criar">
                  Criar
                </Title>
                <UrlBox
                  type="POST"
                  url="https://api.sandbox.superfin.com.br/orders"
                  link="https://documenter.getpostman.com/view/11000226/TzRPhoDe#4be6cb97-3986-4c12-909e-1881b70c1d7c"
                />
                <Paragraph>
                  Solicitação para criar uma transação com os seguintes dados:
                </Paragraph>
                <CodeBox
                  title="Body"
                  code={createOrder}
                  link="https://documenter.getpostman.com/view/11000226/TzRPhoDe#4be6cb97-3986-4c12-909e-1881b70c1d7c"
                />
                <ParamsTable
                  rows={[
                    [
                      'seller_auth_token',
                      'Token do vendedor',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[code]',
                      'Identificador externo do pedido',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[customer]',
                      'Cliente que está realizando o pagamento',
                      'object',
                      'Body',
                      'Não',
                    ],
                    [
                      'order[customer][name]',
                      'Nome do cliente que está realizando o pagamento',
                      'string',
                      'Body',
                      'Não',
                    ],
                    [
                      'order[customer][email]',
                      'E-mail do cliente que está realizando o pagamento',
                      'string',
                      'Body',
                      'Não',
                    ],
                    [
                      'order[customer][document]',
                      'Documento do cliente que está realizando o pagamento(Sem pontuação)',
                      'string',
                      'Body',
                      'Não',
                    ],
                    [
                      'order[customer][document_type]',
                      'Tipo de documento do cliente que está realizando o pagamento(CPF ou CNPJ)',
                      'string',
                      'Body',
                      'Não',
                    ],
                    [
                      'order[customer][birth_date]',
                      'Data de nascimento do cliente que está realizando o pagamento (ex: 01/01/2000)',
                      'string',
                      'Body',
                      'Não',
                    ],
                    [
                      'order[customer][one_id_token]',
                      'Token do cliente no OneId',
                      'string',
                      'Body',
                      'Não',
                    ],
                    [
                      'order[customer][address]',
                      'Endereço do cliente',
                      'object',
                      'Body',
                      'Não',
                    ],
                    [
                      'order[customer][address][street]',
                      'Rua onde o cliente reside',
                      'string',
                      'Body',
                      'Não',
                    ],
                    [
                      'order[customer][address][number]',
                      'Número da residência onde o cliente reside',
                      'string',
                      'Body',
                      'Não',
                    ],
                    [
                      'order[customer][address][neighborhood]',
                      'Bairro onde o cliente reside',
                      'string',
                      'Body',
                      'Não',
                    ],
                    [
                      'order[customer][address][city]',
                      'Cidade onde o cliente reside',
                      'string',
                      'Body',
                      'Não',
                    ],
                    [
                      'order[customer][address][state]',
                      'Estado onde o cliente reside (ex: SP)',
                      'string',
                      'Body',
                      'Não',
                    ],
                    [
                      'order[customer][address][postal_code]',
                      'CEP do cliente ex(17580000)',
                      'string',
                      'Body',
                      'Não',
                    ],
                    [
                      'order[products]',
                      'Produtos a serem pagos',
                      'array',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[products][name]',
                      'Nome do produto',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[products][description]',
                      'Descrição do produto',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[products][price]',
                      'Preço unitário do produto',
                      'float',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[products][quantity]',
                      'Quantidade do produto',
                      'integer',
                      'Body',
                      'Sim',
                    ],
                  ]}
                />
              </Section>
              <Divider />
              <Section>
                <Title variant="h6" id="d-aprovar">
                  Aprovar Pagamento (Sandbox)
                </Title>
                <UrlBox
                  type="POST"
                  url="https://api.sandbox.superfin.com.br/orders/:token/approve"
                  link="https://documenter.getpostman.com/view/11000226/TzRPhoDe#7de28bed-94c2-4866-8af0-d69a2586d83b"
                />
                <Paragraph>
                  Utilize este recurso para alterar o status de uma transação
                  para "Pago", simulando atualizações em pagamentos. Esta
                  funcionalidade está disponível apenas no ambiente sandbox para
                  testes e validações de integração.
                </Paragraph>
                <ParamsTable
                  rows={[
                    [
                      'Authorization',
                      'Token de identificação referente a autenticação',
                      'String',
                      'Headers',
                      'Sim',
                    ],
                    [
                      'token',
                      'Token de identificação referente a transação',
                      'String',
                      'Route',
                      'Sim',
                    ],
                  ]}
                />
              </Section>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ButtonBaseComponent
                    previousName="Autenticação"
                    previousRoute={routes.documentation.autentication}
                    nextName="Cobrança avulsa"
                    nextRoute={routes.documentation.oneTimeCharge}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={2}>
              <OnPage page={onPage} />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  )
}

export default Orders
