import useFetch from 'hooks/useFetch'
import usePagination from 'hooks/usePagination'
import { Container, Page, Header } from 'components'
import { PlansList } from './components'
import {
  Box,
  makeStyles,
  Grid,
  Button,
  useMediaQuery,
  TablePagination,
  Typography,
} from '@material-ui/core'

import styles from './styles'
import * as service from 'service'
import theme from 'theme'
import { routes } from 'Routes'

import useAuth from 'hooks/useAuth'

const useStyles = makeStyles(styles)

const PlansMain = () => {
  const classes = useStyles()
  const { user } = useAuth()

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const { page, perPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination(9)

  const { response, refresh } = useFetch(
    service.intermediador.plans.get,
    { page, perPage },
    [page, perPage],
  )

  return (
    <Page className={classes.root} title="Planos Recorrentes">
      <Container maxWidth="md">
        <Grid container spacing={8}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Header name="Planos recorrentes" route={routes.home} />
          </Grid>

          <Grid item lg={12} xs={12}>
            <PlansList plans={response?.data?.plans} refresh={refresh} />
          </Grid>

          <Grid item lg={12} xs={12}>
            <Box
              mt={2}
              display="flex"
              justifyContent="flex-end"
              className={classes.pagination}
            >
              <TablePagination
                component="div"
                count={response?.data?.meta?.totalCount || 0}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={page - 1}
                rowsPerPage={perPage}
                rowsPerPageOptions={[9, 12, 15, 18]}
                labelRowsPerPage={isDesktop ? 'Por página' : ''}
                nextIconButtonProps={{ size: 'small' }}
                backIconButtonProps={{ size: 'small' }}
              />
            </Box>
          </Grid>
          <Grid item lg={12} xs={12}>
            <Box className={classes.plansBanner}>
              <Box className={classes.boxDescription}>
                <Typography variant="h6" className={classes.text}>
                  Plano de pagamento
                </Typography>
                <Typography variant="body2" className={classes.text}>
                  Veja a página com os planos criados para compartilhar com seus
                  clientes.
                </Typography>
                <Box mt={2}>
                  <Button
                    style={{ borderRadius: 40 }}
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      window.open(
                        `${window.location.origin}/checkout/plans/${user?.account?.id}`,
                        '_blank',
                      )
                    }}
                  >
                    Acessar página
                  </Button>
                </Box>
              </Box>
              <Box className={classes.bgColor}></Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}

export default PlansMain
