import { Box, makeStyles, Typography, Button, Grid } from '@material-ui/core'
import { Container } from 'components'
import { useHistory } from 'react-router-dom'
import { routes } from 'Routes'
import { reverse } from 'named-urls'
import styles from './styles'
import { CreditCard, File, DollarSign } from 'react-feather'
import DialogBillet from '../DialogBillet'
import DialogPix from '../DialogPix'
import { useState } from 'react'

const useStyles = makeStyles(styles)

const PaymentHeader = ({
  token,
  type,
  home = false,
  credit = false,
  order,
  seller,
  customerPage = false,
  customer,
  creditPaid = false,
  paidPayment = false,
  pix = false,
}) => {
  const history = useHistory()
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [openPix, setOpenPix] = useState(false)
  const name = customer?.name || 'Usuário'

  return (
    <Box pt={8} pb={6} className={classes.header} maxWidth={true}>
      <Container
        maxWidth={false}
        spacingXl={60}
        spacingLg={45}
        spacingMd={30}
        spacingSm={15}
      >
        <Box
          pb={5}
          px={3}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          maxWidth={true}
        >
          {paidPayment ? (
            <>
              <Typography variant="h2" className={classes.title}>
                Pagamento realizado.
              </Typography>
              <Typography variant="h3" className={classes.subtitle}>
                Já recebemos e confirmamos o seu pagamento.
              </Typography>
            </>
          ) : customerPage ? (
            <>
              <Typography variant="h2" className={classes.title}>
                Olá {name}.
              </Typography>
              <Typography variant="h3" className={classes.subtitle}>
                Precisamos confirmar os seus dados pessoais.
              </Typography>
            </>
          ) : home ? (
            <>
              <Typography variant="h2" className={classes.title}>
                {name}, qual a forma de pagamento ?
              </Typography>
              <Typography variant="h3" className={classes.subtitle}>
                Escolha abaixo a melhor forma de pagamento para você.
              </Typography>
            </>
          ) : credit ? (
            <>
              <Typography variant="h2" className={classes.title}>
                Olá, {name}!
              </Typography>
              <Typography variant="h3" className={classes.subtitle}>
                Agora você pode adicionar os dados do seu cartão para finalizar
                o pagamento
              </Typography>
            </>
          ) : creditPaid ? (
            <>
              <Typography variant="h2" className={classes.title}>
                {name}, seu pagamento foi efetuado com sucesso!
              </Typography>
            </>
          ) : pix ? (
            <>
              <Typography variant="h2" className={classes.title}>
                Revise os dados para pagamento no pix
              </Typography>
              <Typography variant="h3" className={classes.subtitle}>
                Abra o aplicativo do seu banco e utilize o código do pix para
                efetuar o pagamento ou aponte a câmera e leia o qr code.
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="h2" className={classes.title}>
                Revise os dados para pagamento no boleto
              </Typography>
              <Typography variant="h3" className={classes.subtitle}>
                Utilize o código do boleto para efetuar o pagamento ou aponte a
                câmera e leia o código de barras.
              </Typography>
            </>
          )}
        </Box>
        {home ? (
          <Grid container>
            <Grid lg={1}></Grid>
            <Grid lg={3} sm={12} xs={12}>
              <Box m={2}>
                <Button
                  onClick={() =>
                    history.push(
                      reverse(routes.payments.credit, {
                        type: type,
                        token: token,
                        order: order,
                      }),
                      {
                        customer: customer,
                      },
                    )
                  }
                  variant="contained"
                  className={classes.button}
                >
                  <CreditCard className={classes.image} />
                  <Typography variant="body2">Cartão de Crédito</Typography>
                </Button>
              </Box>
            </Grid>
            <Grid lg={3} sm={12} xs={12}>
              <Box m={2}>
                <Button
                  onClick={() => setOpen(true)}
                  variant="contained"
                  className={classes.button}
                >
                  <File className={classes.image} />
                  <Typography variant="body2">Boleto Bancário</Typography>
                </Button>
              </Box>
            </Grid>
            <Grid lg={3} sm={12} xs={12}>
              <Box m={2}>
                <Button
                  onClick={() => setOpenPix(true)}
                  variant="contained"
                  className={classes.button}
                >
                  <DollarSign className={classes.image} />
                  <Typography variant="body2">Pix</Typography>
                </Button>
              </Box>
            </Grid>
            <Grid lg={1}></Grid>
            <DialogBillet
              open={open}
              setOpen={setOpen}
              type={type}
              token={token}
              order={order}
              seller={seller}
              customer={customer}
              {...open}
            />
            <DialogPix
              open={openPix}
              setOpen={setOpenPix}
              type={type}
              token={token}
              order={order}
              seller={seller}
              customer={customer}
              {...openPix}
            />
          </Grid>
        ) : null}
      </Container>
    </Box>
  )
}

export default PaymentHeader
