import { Box } from '@material-ui/core'
import { LoadingButton } from 'theme/checkoutTheme/components'
import { BilletInfo, DialogBillet } from './components'

import { useState } from 'react'
import PaymentMethodSelect from '../PaymentMethodSelect'
import ComplianceText from '../ComplianceText/ComplianceText'

import checkoutTheme from 'theme/checkoutTheme'

const CheckoutBillet = ({ type, token, setStep, customer, order }) => {
  const [billet, setBillet] = useState({})
  const [open, setOpen] = useState(false)
  const [openBillet, setOpenBillet] = useState(false)

  return (
    <>
      <PaymentMethodSelect paymentMethodId={0} setStep={setStep} />
      {openBillet ? (
        <>
          <BilletInfo billet={billet} />
        </>
      ) : (
        <>
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            mt={checkoutTheme.spacing(10)}
          >
            <LoadingButton
              text="Visualizar o Boleto"
              onClick={() => {
                setOpen(true)
              }}
            />
          </Box>
        </>
      )}
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        my={checkoutTheme.spacing(10)}
      >
        <LoadingButton
          text="VOLTAR"
          onClick={() => {
            setStep(1)
          }}
          variant="outlined"
        />
      </Box>
      <ComplianceText buttonTitle="Gerar Boleto" />
      <DialogBillet
        open={open}
        setOpen={setOpen}
        type={type}
        token={token}
        order={order}
        customer={customer}
        setBillet={setBillet}
        setOpenBillet={setOpenBillet}
      />
    </>
  )
}

export default CheckoutBillet
