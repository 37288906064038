import { Container, Grid, Typography, Box } from '@material-ui/core'

import { Page, Header, AccordionCard } from 'components'
import ChargesForm from './components/ChargesForm'

import { routes } from 'Routes'

import constants from 'constants/index'

const ChargesNew = () => {
  return (
    <Page title="Nova cobrança">
      <Container maxWidth="sm">
        <Grid container spacing={8}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Header name="Nova cobrança avulsa" route={routes.charges.main} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <ChargesForm />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box mt={10}>
              <Typography variant="h6" color="textPrimary">
                Perguntas frequentes
              </Typography>
            </Box>
          </Grid>
          {constants?.faqs?.ITEMS?.charges?.chargesNew?.content?.map(
            (value) => (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <AccordionCard
                  title={value?.title}
                  description={value?.description}
                />
              </Grid>
            ),
          )}
        </Grid>
      </Container>
    </Page>
  )
}

export default ChargesNew
