import { useState, useEffect } from 'react'

import AuthContext from 'contexts/AuthContext'
import * as service from 'service'

import {
  Home as HomeIcon,
  DollarSign as DollarSignIcon,
  Settings as SettingIcon,
  FileText as FileTextIcon,
  RefreshCcw as RefreshIcon,
} from 'react-feather'

const AuthProvider = ({ children }) => {
  const [loaded, setLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [user, setUser] = useState(null)
  const [account, setAccount] = useState(null)
  const [accounts, setAccounts] = useState(null)
  const [menuItems, setMenuItems] = useState([])
  const [auth, setAuth] = useState(false)

  useEffect(() => {
    if (service.intermediador.auth.isAuthenticated()) {
      loadDataWithAnimation()
    }

    // eslint-disable-next-line
  }, [])

  const loadData = async () => {
    const response = await service.intermediador.users.me()
    const responseUser = response.data.user
    const allAccounts = [
      responseUser.account,
      ...responseUser.accounts.map((account) => account),
    ]
    setUser(responseUser)
    setAccounts(allAccounts)
    setCurrentAccount(responseUser, allAccounts)

    const menuItems = [
      {
        items: [
          {
            title: 'Inicio',
            icon: HomeIcon,
            href: '/home',
          },
          {
            title: 'Transações',
            icon: FileTextIcon,
            href: '/orders',
          },
          {
            title: 'Cobranças',
            icon: DollarSignIcon,
            href: '/charges',
          },
          {
            title: 'Planos & Assinaturas',
            icon: RefreshIcon,
            href: '/subscriptions',
          },
          {
            title: 'Configurações',
            icon: SettingIcon,
            href: '/account',
          },
        ],
      },
    ]

    setMenuItems(menuItems)
  }

  const setCurrentAccount = (user, accounts = []) => {
    const accountToken = service.intermediador.auth.getAccountToken()
    var currentAccount = ''

    if (accountToken) {
      currentAccount = accounts.find((account) => account.id === accountToken)
    }

    setAccount(currentAccount || user?.account)
  }

  const loadDataWithAnimation = async () => {
    setIsLoading(true)
    setLoaded(false)

    try {
      await loadData()
    } finally {
      setLoaded(true)
      setIsLoading(false)
    }
  }

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        user,
        setUser,
        loaded,
        isLoading,
        menuItems,
        loadDataWithAnimation,
        setCurrentAccount,
        loadData,
        account,
        setAccount,
        accounts,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
