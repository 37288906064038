import api from 'service/api'

const intermediadorAPI = api.create('intermediador')

const resume = async () => {
  return await intermediadorAPI.get('/accounts/resume')
}

const resetSellerKey = async () => {
  return await intermediadorAPI.get('/accounts/reset_seller_key')
}

const showSellerKey = async () => {
  return await intermediadorAPI.get('/accounts/show_seller_key')
}

const accounts = {
  resume,
  resetSellerKey,
  showSellerKey,
}

export default accounts
