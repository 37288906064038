import { Page, Container } from 'components'
import { Grid, Typography, makeStyles } from '@material-ui/core'
import { CheckoutPlansList } from './components'
import { useState, useEffect, useCallback } from 'react'
import * as service from 'service'
import checkoutTheme from 'theme/checkoutTheme'
import Logo from 'images/logo-horizontal-y.png'

import styles from './styles'

import { ThemeProvider, createTheme } from '@material-ui/core'

const useStyles = makeStyles(styles)

const CheckoutPlans = ({ match }) => {
  const [theme, setTheme] = useState(null)
  const [plans, setPlans] = useState([])
  const [accountName, setAccountName] = useState('')
  const { accountToken } = match.params
  const classes = useStyles()

  const getTheme = useCallback(async () => {
    try {
      const response = await service.intermediador.accountCustomizations.show(
        'theme',
        accountToken,
      )
      const themeData = JSON.parse(response?.data.accountCustomization?.data)
      setTheme(createTheme(themeData))
    } catch (error) {
      setTheme(createTheme(checkoutTheme))
    }
  }, [accountToken])

  useEffect(() => {
    const loadPlans = async () => {
      const response = await service.intermediador.plans.getPublic({
        accountToken: accountToken,
      })
      getTheme()
      setPlans(response?.data?.plans)
      setAccountName(response?.data?.account?.name)
    }
    loadPlans()
  }, [accountToken, getTheme])

  return (
    <>
      {theme && (
        <ThemeProvider theme={theme}>
          <Page
            title="Planos"
            style={{
              padding: '0px!important',
              paddingRight: '0px!important',
              minHeight: '104vh',
            }}
          >
            <Container maxWidth="md">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <img
                    alt="Logo"
                    src={Logo}
                    className={classes.logo}
                    width="100%"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography
                    color="textPrimary"
                    variant="h6"
                    align="center"
                    style={{
                      marginTop: checkoutTheme.spacing(20),
                      marginBottom: checkoutTheme.spacing(20),
                    }}
                  >
                    Escolha o melhor para você
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <CheckoutPlansList
                    plans={plans}
                    accountToken={accountToken}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography
                    color="textPrimary"
                    variant="body1"
                    align="center"
                    style={{
                      marginTop: checkoutTheme.spacing(20),
                    }}
                  >
                    Vendedor - {accountName}
                  </Typography>
                </Grid>
              </Grid>
            </Container>
          </Page>
        </ThemeProvider>
      )}
    </>
  )
}

export default CheckoutPlans
