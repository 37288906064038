import { Grid, Box, Divider } from '@material-ui/core'

import { Page, Container } from 'components'

import {
  Menu,
  OnPage,
  ButtonBaseComponent,
  StepperComponent,
  Title,
  Paragraph,
  Section,
} from '../components'

import { routes } from 'Routes'

function getSteps() {
  return [
    'Token de autenticação e o ambiente',
    'Criação do Plano',
    'Associação do Cliente',
    'Ativação',
  ]
}

function getStepContent(step) {
  return [
    `Obtenha o token de autenticação e defina o .`,
    `Primeiramente, é necessário gerar um plano, incluindo nome, descrição e periodicidade.`,
    'Em seguida, vincule o plano à assinatura, inserindo os dados do cliente que será cobrado.',
    `Ative o plano para iniciar a cobrança recorrente.`,
  ]
}

const IntroductionSubscriptions = () => {
  const onPage = [
    ['#d-cobrançarecorrente', 'Cobrança recorrente'],
    ['#d-requisitos', 'Requisitos'],
  ]
  const steps = getSteps()
  const stepContents = getStepContent()

  return (
    <Page title="Cobrança Recorrente">
      <Container maxWidth="md">
        <Box style={{ marginBottom: '40px' }}>
          <Grid container spacing={8}>
            <Grid item xs={12} sm={12} md={12} lg={2}>
              <Menu />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8}>
              <Box style={{ marginBottom: '40px' }}>
                <Title
                  variant="h5"
                  id="d-cobrançarecorrente"
                  subtitle="Recorrência"
                >
                  Introdução
                </Title>
                <Paragraph>
                  A cobrança recorrente é um método de pagamento em que os
                  valores são debitados automaticamente em intervalos regulares,
                  como mensal, trimestral ou anual, sem a necessidade de uma
                  ação manual do cliente em cada ciclo.{' '}
                </Paragraph>
              </Box>
              <Divider />
              <Section>
                <Title variant="h6" id="d-requisitos">
                  Requisitos
                </Title>
                <Paragraph>
                  Com apenas três etapas, você pode configurar sua cobrança
                  recorrente de forma rápida e fácil.
                  <br />
                  es
                  <br />
                  <StepperComponent steps={steps} stepContents={stepContents} />
                  {/* <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((label, index) => (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                        <StepContent>
                          <Typography variant="body2" color="textSecondary">
                            {getStepContent(index)}
                          </Typography>
                          <div>
                            <div>
                              <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                              >
                                Voltar
                              </Button>
                              {activeStep !== steps.length - 1 && (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={handleNext}
                                >
                                  Próximo
                                </Button>
                              )}
                            </div>
                          </div>
                        </StepContent>
                      </Step>
                    ))}
                  </Stepper> */}
                </Paragraph>
              </Section>
              <Section>
                <Title variant="h6">Mais detalh</Title>
                <Paragraph>
                  Com apenas três etapas, você pode configurar sua cobrança
                  recorrente de forma rápida e fácil.
                  <br />
                  <br />
                </Paragraph>
              </Section>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ButtonBaseComponent
                    previousName="Minhas informações"
                    previousRoute={routes.documentation.myInformation}
                    nextName="Planos"
                    nextRoute={routes.documentation.plans}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={2}>
              <OnPage page={onPage} />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  )
}

export default IntroductionSubscriptions
