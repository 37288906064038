import * as yup from 'yup'

export const schema = yup.object().shape({
  payerName: yup.string().required(),
  payerEmail: yup.string().email().required(),
  expirationDate: yup.date().nullable(),
  code: yup.string().required(),
  description: yup.string().required(),
  products: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required(),
        price: yup.string().required(),
        quantity: yup
          .number()
          .min(1, 'Digite um número maior ou igual a 1')
          .required(),
      }),
    )
    .required(),
})

export default schema
