const styles = (theme) => ({
  pixCodeBox: {
    backgroundColor: '#fdeeda',
  },
  pixCode: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '95%',
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.black,
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  copyIcon: {
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.8,
    },
  },
  qrCodeBox: {
    backgroundColor: '#e9ecef',
    borderRadius: '50%',
    padding: theme.spacing(6),
  },
  numberIcon: {
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    paddingRight: '12px',
    paddingLeft: '12px',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
})

export default styles
