import { Grid, Box, Divider } from '@material-ui/core'

import { Page, Container } from 'components'

import {
  Menu,
  OnPage,
  ButtonBaseComponent,
  Title,
  Paragraph,
  ParamsTable,
  UrlBox,
  Section,
} from '../components'

import { routes } from 'Routes'

const MyInformation = () => {
  const onPage = [
    ['#d-minhauinformacao', 'Minhas informações'],
    ['#d-usuário', 'Informações do usuário'],
  ]

  return (
    <Page title="Superfin">
      <Container maxWidth="md">
        <Box style={{ marginBottom: '40px' }}>
          <Grid container spacing={8}>
            <Grid item xs={12} sm={12} md={12} lg={2}>
              <Menu />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8}>
              <Box style={{ marginBottom: '40px' }}>
                <Title
                  variant="h5"
                  id="d-minhauinformacao"
                  subtitle="Começando"
                >
                  Minhas informações
                </Title>
                <Paragraph>
                  Requisição para o endpoint userme, que retorna todos os dados
                  do usuário autenticado.
                </Paragraph>
              </Box>
              <Divider />
              <Section>
                <Title variant="h6" id="d-usuário">
                  Informações do usuário
                </Title>
                <UrlBox
                  type="GET"
                  url="https://api.sandbox.superfin.com.br/users/me"
                  link="https://documenter.getpostman.com/view/11000226/TzRPhoDe#97795549-6e04-48be-b8fe-edd16c887556"
                />
                <Paragraph>
                  Para consultar uma cobrança em nosso sistema, o usuário deve
                  fornecer apenas o token de autorização.
                </Paragraph>
                <ParamsTable
                  rows={[
                    [
                      'Authorization',
                      'Token de identificação referente ao usuario',
                      'string',
                      'Headers',
                      'Sim',
                    ],
                  ]}
                />
              </Section>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ButtonBaseComponent
                    previousName="Pagamentos"
                    previousRoute={routes.documentation.payments}
                    nextName="Cobrança recorrente"
                    nextRoute={routes.documentation.recurringBillingSummary}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={2}>
              <OnPage page={onPage} />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  )
}

export default MyInformation
