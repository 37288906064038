import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from '@material-ui/core'

const DetailsTable = ({ header = [], rows = [] }) => {
  return (
    <Box style={{ marginTop: '20px', marginBottom: '20px' }}>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {header &&
                header.map((item, index) => (
                  <TableCell key={index}>
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      style={{ fontSize: '12px' }}
                    >
                      {item}
                    </Typography>
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows &&
              rows.map((row) => (
                <TableRow
                  key={row.name}
                  style={{ backgroundColor: 'transparent' }}
                >
                  <TableCell component="th" scope="row">
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      style={{ fontSize: '12px' }}
                    >
                      {row[0]}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      style={{ fontSize: '12px' }}
                    >
                      {row[1]}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      style={{ fontSize: '12px' }}
                    >
                      {row[2]}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      style={{ fontSize: '12px' }}
                    >
                      {row[3]}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      style={{ fontSize: '12px' }}
                    >
                      {row[4]}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default DetailsTable
