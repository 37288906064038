import { Typography, Link, Box } from '@material-ui/core'

import { ExternalLink as ExternalLinkIcon } from 'react-feather'

const CodeBox = ({ type = false, title = '', code, link }) => {
  return (
    <Box style={{ marginTop: '20px' }}>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          backgroundColor: '#FFF',
          borderRadius: '20px 20px 0px 0px',
          padding: '12px',
        }}
      >
        {type === 'GET' && (
          <Box
            style={{
              backgroundColor: '#61affe',
              padding: '4px 12px 4px 12px',
              borderRadius: '4px',
              marginRight: '20px',
            }}
          >
            <Typography
              variant="body2"
              style={{ color: '#FFFFFF', fontWeight: 'bold' }}
            >
              GET
            </Typography>
          </Box>
        )}
        {type === 'POST' && (
          <Box
            style={{
              backgroundColor: '#4AA802',
              padding: '4px 12px 4px 12px',
              borderRadius: '4px',
              marginRight: '20px',
            }}
          >
            <Typography
              variant="body2"
              style={{ color: '#FFFFFF', fontWeight: 'bold' }}
            >
              POST
            </Typography>
          </Box>
        )}
        {type === 'PUT' && (
          <Box
            style={{
              backgroundColor: '#E5A200',
              padding: '4px 12px 4px 12px',
              borderRadius: '4px',
              marginRight: '20px',
            }}
          >
            <Typography
              variant="body2"
              style={{ color: '#FFFFFF', fontWeight: 'bold' }}
            >
              POST
            </Typography>
          </Box>
        )}
        {type === 'DELETE' && (
          <Box
            style={{
              backgroundColor: '#E60042',
              padding: '4px 12px 4px 12px',
              borderRadius: '4px',
              marginRight: '20px',
            }}
          >
            <Typography
              variant="body2"
              style={{ color: '#FFFFFF', fontWeight: 'bold' }}
            >
              POST
            </Typography>
          </Box>
        )}
        <Typography variant="body1" color="textPrimary">
          {title}
        </Typography>
      </Box>

      <Box
        style={{
          boxSizing: 'border-box',
          backgroundColor: '#ecf6ff',
          padding: '20px',
        }}
      >
        <Typography
          variant="body2"
          color="textPrimary"
          style={{
            padding: '4px',
            border: '1px solid #598cb9',
            borderRadius: '2px',
            width: '60px',
            fontSize: '12px',
            textAlign: 'center',
            marginBottom: '20px',
          }}
        >
          json
        </Typography>
        <Typography variant="body2" color="textPrimary">
          <pre style={{ overflowX: 'auto' }}>
            <code
              style={{ fontSize: '12px' }}
              dangerouslySetInnerHTML={{ __html: code }}
            />
          </pre>
        </Typography>
      </Box>
      <Box
        style={{
          backgroundColor: '#FFF',
          padding: '12px',
          borderRadius: '0px 0px 20px 20px',
        }}
      >
        <Link
          href={link}
          color="inherit"
          target="_blank"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="body2"
            color="primary"
            style={{ marginRight: '12px' }}
          >
            Mais detalhes
          </Typography>
          <ExternalLinkIcon size={14} color="#00A900" />
        </Link>
      </Box>
    </Box>
  )
}

export default CodeBox
