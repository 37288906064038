const styles = (theme) => ({
  itemsContainer: {
    display: 'grid',
    justifyItems: 'center',
    padding: theme.spacing(1),
    backgroundColor: 'red',
  },
})

export default styles
