const styles = (themeCheckout) => ({
  creditCard: {
    '& > .rccs': {
      color: '#000',
    },
    '& > .rccs .rccs__card .rccs__card--front': {
      background: '#C7C1B6!important',
      boxShadow: '-6px 0 0 0 #938A7A',
      height: '142.873px',
      position: 'relative',
      width: 250,
    },
    '& > .rccs .rccs__card .rccs__card--front .rccs__number': {
      // textStroke: '1px #FFF',
    },
    '& > .rccs .rccs__card .rccs__card--front .rccs__name': {
      // textStroke: '1px #FFF',
    },
    '& > .rccs .rccs__card .rccs__card--front .rccs__expiry__valid': {
      // textStroke: '1px #FFF',
    },
    '& > .rccs .rccs__card .rccs__card--front .rccs__expiry__value': {
      // textStroke: '1px #FFF',
    },
    '& > .rccs .rccs__card .rccs__card--back': {},
    '& > .rccs .rccs__card ': {
      height: '142.873px',
      width: 250,
    },
  },
})

export default styles
