import api from 'service/api'

const intermediadorAPI = api.create('intermediador', false)

const get = async () => {
  return await intermediadorAPI.get('/account_urls')
}

const create = async ({ ...params }) => {
  return await intermediadorAPI.post('/account_urls', params)
}

const accountUrls = {
  get,
  create,
}

export default accountUrls
