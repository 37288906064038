import {
  Box,
  makeStyles,
  Grid,
  Typography,
  IconButton,
  Tooltip,
  ButtonBase,
} from '@material-ui/core'
import {
  Eye as EyeIcon,
  EyeOff as EyeOffIcon,
  Plus as PlusIcon,
} from 'react-feather'

import styles from './styles'
import clsx from 'clsx'
import { routes } from 'Routes'
import { reverse } from 'named-urls'
import * as service from 'service'

import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(styles)

const PlansList = ({ plans, refresh }) => {
  const history = useHistory()
  const classes = useStyles()

  const updatePlan = async (plan) => {
    await service.intermediador.plans.update({
      planId: plan?.id,
      plan: {
        public: !plan?.public,
      },
    })

    refresh()
  }

  return (
    <Grid container spacing={8}>
      <Grid item lg={4} md={6} sm={6} xs={12}>
        <ButtonBase
          onClick={() => {
            history.push(routes.plans.new)
          }}
          className={classes.planBox}
        >
          <IconButton color="secondary" component="span">
            <PlusIcon size={32} />
          </IconButton>
        </ButtonBase>
      </Grid>
      {/* <Grid item lg={4} md={6} sm={6} xs={12}>
        <ButtonBase className={classes.planBox}>
          <Grid container spacing={8}>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <IconButton
                className={classes.iconButton}
                color="secondary"
                component="span"
              >
                <EyeOffIcon size={22} color="#325c81" />
              </IconButton>
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={9}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                width="100%"
              >
                <Typography
                  variant="body1"
                  color="textPrimary"
                  className={classes.planName}
                >
                  plan.name
                </Typography>
                <Typography variant="body2" className={classes.descriptionText}>
                  plan.descriptionplan.descriptionplan.descriptionplan.descriptionplan.descriptionplan.description
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </ButtonBase>
      </Grid> */}
      {plans?.map((plan) => (
        <Grid item lg={4} md={6} sm={6} xs={12} key={plan?.id}>
          <ButtonBase
            className={clsx(classes.planBox, {
              [classes.notPublicBox]: !plan?.public,
            })}
            onClick={() => {
              history.push(
                reverse(routes.plans.edit, {
                  planId: plan?.id,
                }),
              )
            }}
          >
            <Grid container spacing={8}>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <IconButton
                  className={classes.iconButton}
                  color="secondary"
                  component="span"
                  onClick={() => {
                    updatePlan(plan)
                  }}
                >
                  {plan?.public ? (
                    <EyeIcon size={22} color="#325c81" />
                  ) : (
                    <EyeOffIcon size={22} color="#325c81" />
                  )}
                </IconButton>
              </Grid>
              <Grid item xs={12} sm={12} md={9} lg={9}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-start"
                  width="100%"
                >
                  <Tooltip title={plan.name} placement="top-start">
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      className={classes.planName}
                    >
                      {plan.name}
                    </Typography>
                  </Tooltip>
                  <Typography
                    variant="body2"
                    className={classes.descriptionText}
                  >
                    {plan.description}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </ButtonBase>
        </Grid>
      ))}
    </Grid>
  )
}

export default PlansList
