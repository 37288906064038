import React, { useState } from 'react'
import { Grid, Typography, Box } from '@material-ui/core'

import useFetch from 'hooks/useFetch'

import { Eye as EyeIcon, EyeOff as EyeoffIcon } from 'react-feather'

import helpers from 'helpers'
import * as service from 'service'
import theme from 'theme'

const Balance = () => {
  const { response } = useFetch(service.intermediador.accounts.resume)
  const [isComponentVisible, setComponentVisible] = useState(false)

  const toggleComponent = () => {
    setComponentVisible(!isComponentVisible)
  }

  function balanceData() {
    return helpers.formatters
      .floatToMoney(response?.data?.balance, true)
      .replace('R$ ', '')
  }

  function balanceControlToReceive() {
    return helpers.formatters.floatToMoney(
      response?.data?.balanceControlToReceive,
      true,
    )
  }

  return (
    <Grid item lg={12} sm={12} xs={12}>
      <Box mb={theme.spacing(8)}>
        <Typography variant="h6" color="textSecondary">
          Saldo
        </Typography>
        <Box
          style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h4"
            color="textPrimary"
            style={{
              fontWeight: 100,
              marginRight: theme.spacing(6),
            }}
          >
            R$
          </Typography>
          <Typography
            variant="h2"
            style={{
              color: theme.palette.text.primary,
              filter: isComponentVisible ? 'blur(0px)' : 'blur(20px)',
            }}
          >
            {balanceData()}
          </Typography>
          <Box
            ml={theme.spacing(8)}
            sx={{
              '&:hover': {
                cursor: 'pointer',
              },
            }}
          >
            {isComponentVisible ? (
              <EyeoffIcon
                onClick={() => toggleComponent()}
                color={theme.palette.text.primary}
                style={{ cursor: 'pointer' }}
                size={theme.spacing(10)}
              />
            ) : (
              <EyeIcon
                onClick={() => toggleComponent()}
                color={theme.palette.text.primary}
                style={{ cursor: 'pointer' }}
                size={theme.spacing(10)}
              />
            )}
          </Box>
          <Box
            style={{
              position: 'absolute',
              backgroundColor: '#63bbdb',
              bottom: theme.spacing(4),
              width: 240,
              height: 8,
              zIndex: -1,
            }}
          ></Box>
        </Box>
      </Box>
      <Box mb={theme.spacing(8)}>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="body1"
            color="textSecondary"
            style={{
              marginRight: theme.spacing(8),
            }}
          >
            Recebíveis Futuros: {''}
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            style={{
              filter: isComponentVisible ? 'blur(0px)' : 'blur(20px)',
            }}
          >
            {balanceControlToReceive()}
          </Typography>
        </Box>
      </Box>
    </Grid>
  )
}

export default Balance
