import {
  Box,
  Typography,
  Card,
  Divider,
  Grid,
  TextField,
} from '@material-ui/core'
import { LoadingButton } from 'components'

import { Controller, useForm } from 'react-hook-form'

import { DatePicker } from '@material-ui/pickers'
import { useHistory } from 'react-router-dom'
import { routes } from 'Routes'
import { reverse } from 'named-urls'
import schema from './schema'
import helpers from 'helpers'

const CustomerForm = ({ type, token }) => {
  const history = useHistory()
  const { control, handleSubmit, errors, getValues, setValue } = useForm({
    validationSchema: schema,
    defaultValues: {
      name: '',
      email: '',
      document: '',
      documentType: '',
      birthDate: null,
      address: {
        street: '',
        number: '',
        neighborhood: '',
        city: '',
        state: '',
        postalCode: '',
      },
    },
  })

  const formatCustomerParams = (data) => {
    return {
      name: data.name,
      email: data.email,
      document: data.document,
      documentType: data.documentType,
      birthDate: data.birthDate.toString(),
      address: {
        street: data.street,
        number: data.number,
        neighborhood: data.neighborhood,
        city: data.city,
        state: data.state,
        postalCode: data.postalCode,
      },
    }
  }

  const onSubmit = async (data) => {
    history.push(
      reverse(routes.payments.details, {
        type: type,
        token: token,
      }),
      {
        customer: formatCustomerParams(getValues()),
      },
    )
  }

  return (
    <Box mt={4}>
      <Card>
        <Box p={2}>
          <Typography variant="h5">Dados do cliente</Typography>
        </Box>
        <Divider />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <Box p={3}>
              <Grid container spacing={3}>
                <Grid item sm={6} xs={12}>
                  <Controller
                    name="name"
                    as={
                      <TextField
                        label="Nome"
                        type="tel"
                        color="primary"
                        variant="outlined"
                        fullWidth
                        error={!!errors.name}
                        helperText={errors?.name?.message}
                      />
                    }
                    control={control}
                    mode="onBlur"
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Controller
                    name="email"
                    as={
                      <TextField
                        label="Email"
                        type="email"
                        color="primary"
                        variant="outlined"
                        fullWidth
                        error={!!errors.email}
                        helperText={errors?.email?.message}
                      />
                    }
                    control={control}
                    mode="onBlur"
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Controller
                    name="document"
                    as={
                      <TextField
                        label="Documento(CPF/CNPJ)"
                        type="text"
                        color="primary"
                        variant="outlined"
                        fullWidth
                        error={!!errors.document}
                        helperText={errors?.document?.message}
                      />
                    }
                    onChange={([event]) => {
                      let doc = helpers.formatters.document(event.target.value)
                      if (doc.length === 14) {
                        setValue('documentType', 'CPF')
                      } else if (doc.length > 14) {
                        setValue('documentType', 'CNPJ')
                      }
                      return doc
                    }}
                    control={control}
                    mode="onBlur"
                  />
                  <Controller
                    name="documentType"
                    as={<input type="hidden" />}
                    control={control}
                    mode="onBlur"
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Controller
                    name="birthDate"
                    as={
                      <DatePicker
                        fullWidth
                        label="Data de nascimento"
                        format="DD/MM/yyyy"
                        helperText={errors?.birthDate?.message}
                        error={!!errors.birthDate}
                        inputVariant="outlined"
                        minDate={`${
                          new Date(Date.now()).getFullYear() - 100
                        }-01-01`}
                        maxDate={`${new Date(Date.now()).getFullYear() - 18}-${
                          new Date(Date.now()).getMonth() + 1
                        }-${new Date(Date.now()).getDate()}`}
                      />
                    }
                    control={control}
                    mode="onBlur"
                  />
                </Grid>
              </Grid>
            </Box>
            <Box alignSelf="stretch">
              <Divider />
            </Box>
            <Box p={2} alignSelf="start">
              <Typography variant="h5">Endereço de cobrança</Typography>
            </Box>
            <Box alignSelf="stretch">
              <Divider />
            </Box>
            <Box p={3}>
              <Grid container spacing={3}>
                <Grid item sm={6} xs={12}>
                  <Controller
                    name="street"
                    as={
                      <TextField
                        label="Rua"
                        type="tel"
                        color="primary"
                        variant="outlined"
                        fullWidth
                        error={!!errors.street}
                        helperText={errors?.street?.message}
                      />
                    }
                    control={control}
                    mode="onBlur"
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Controller
                    name="number"
                    as={
                      <TextField
                        label="Número"
                        type="text"
                        color="primary"
                        variant="outlined"
                        fullWidth
                        error={!!errors.number}
                        helperText={errors?.number?.message}
                      />
                    }
                    control={control}
                    mode="onBlur"
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Controller
                    name="neighborhood"
                    as={
                      <TextField
                        label="Bairro"
                        type="text"
                        color="primary"
                        variant="outlined"
                        fullWidth
                        error={!!errors.neighborhood}
                        helperText={errors?.neighborhood?.message}
                      />
                    }
                    control={control}
                    mode="onBlur"
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Controller
                    name="city"
                    as={
                      <TextField
                        label="Cidade"
                        type="text"
                        color="primary"
                        variant="outlined"
                        fullWidth
                        error={!!errors.city}
                        helperText={errors?.city?.message}
                      />
                    }
                    control={control}
                    mode="onBlur"
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Controller
                    name="state"
                    as={
                      <TextField
                        label="Estado"
                        type="text"
                        color="primary"
                        variant="outlined"
                        fullWidth
                        error={!!errors.state}
                        helperText={errors?.state?.message}
                        inputProps={{ maxLength: 2 }}
                      />
                    }
                    control={control}
                    mode="onBlur"
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Controller
                    name="postalCode"
                    as={
                      <TextField
                        label="CEP"
                        type="text"
                        color="primary"
                        variant="outlined"
                        fullWidth
                        error={!!errors.postalCode}
                        helperText={errors?.postalCode?.message}
                        inputProps={{ maxLength: 9 }}
                      />
                    }
                    onChange={([event]) => {
                      return event.target.value.replace(
                        /^(\d{5})(\d{1,4}).*/,
                        '$1-$2',
                      )
                    }}
                    control={control}
                    mode="onBlur"
                  />
                </Grid>
              </Grid>
            </Box>
            <Box alignSelf="stretch">
              <Divider />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              p={1}
              width="100%"
            >
              <LoadingButton text="Enviar" type="submit" />
            </Box>
          </Box>
        </form>
      </Card>
    </Box>
  )
}

export default CustomerForm
