import { TableContainer, makeStyles } from '@material-ui/core'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'

import PerfectScrollbar from 'react-perfect-scrollbar'
import helpers from 'helpers'
import styles from './styles'

const useStyles = makeStyles(styles)

const BalanceControlsTable = ({ isLoading, balanceControls = [] }) => {
  const classes = useStyles()

  return (
    <>
      <PerfectScrollbar>
        <TableContainer>
          <Table
            isLoading={isLoading}
            emptyMessage="Nenhuma transação encontrada"
            size="medium"
            noWrap
            className={classes.table}
          >
            <TableHead>
              <TableRow>
                <TableCell width="25%">Valor (R$)</TableCell>
                <TableCell width="15%">Data do pagamento</TableCell>
                <TableCell width="20%">Status</TableCell>
                <TableCell width="10%">Parcela</TableCell>
                <TableCell width="20%">Token da transação</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {balanceControls?.map((balanceControl) => (
                <TableRow key={balanceControl.token}>
                  <TableCell className={classes.cell}>
                    {helpers.formatters.floatToMoney(balanceControl?.amount)}
                  </TableCell>
                  <TableCell>
                    {helpers.formatters.date(balanceControl?.paymentDate)}
                  </TableCell>
                  <TableCell>
                    {helpers.formatters.balanceControlStatus(
                      balanceControl?.status,
                    )}
                  </TableCell>
                  <TableCell>{balanceControl?.split}</TableCell>
                  <TableCell>{balanceControl?.orderToken}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </PerfectScrollbar>
    </>
  )
}

export default BalanceControlsTable
