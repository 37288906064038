import React, { useState, useEffect } from 'react'
import { makeStyles, Box } from '@material-ui/core'

import styled, { keyframes } from 'styled-components'
import {
  X as XIcon,
  Check as CheckIcon,
  Loader as LoaderIcon,
  Clock as ClockIcon,
} from 'react-feather'
import styles from './styles'
import theme from 'theme'
import moment from 'moment'

const useStyles = makeStyles(styles)

const pulseAnimation = keyframes`
0% {
  transform: scale(0.5);
  opacity: 0;
}
50% {
  transform: scale(1);
  opacity: 1;
}
100% {
  transform: scale(1.3);
  opacity: 0;
}
`

const PulseComponent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${(props) => props.color || theme.palette.info.main};
  &::before,
  &::after {
    content: '';
    position: absolute;
    width: calc(100% + 10px);
    border: 1px solid ${(props) => props.color || theme.palette.info.main};
    height: calc(100% + 10px);
    border-radius: 50%;
  }
  &::before {
    animation: ${pulseAnimation} 1s linear infinite;
  }
  &::after {
    border: 1px solid ${(props) => props.color || theme.palette.info.main};
    animation: ${pulseAnimation} 1s linear infinite 0.3s;
    animation-delay: '0.3s';
  }
`

const Pulse = ({ status, effect }) => {
  const classes = useStyles()

  const [isWithin5MinutesClasses, setIsWithin5MinutesClasses] = useState(null)

  useEffect(() => {
    const isWithin5Minutes = () => {
      const updatedAtMoment = moment(effect?.updatedAt)
      const currentDateTime = moment()
      const isSameDate = updatedAtMoment.isSame(currentDateTime, 'day')
      const isWithin5Minutes =
        currentDateTime.diff(updatedAtMoment, 'minutes') <= 5
      setIsWithin5MinutesClasses(isSameDate && isWithin5Minutes)
    }

    isWithin5Minutes()
  }, [effect?.updatedAt])

  const subscriptionStatus = () => {
    let result = ''

    switch (status) {
      case 1:
        result =
          isWithin5MinutesClasses === true ? (
            <PulseComponent color={theme.palette.info.main}>
              <LoaderIcon style={{ color: 'white' }} size={12} />
            </PulseComponent>
          ) : (
            <Box
              className={classes.iconStatus}
              style={{ backgroundColor: theme.palette.info.main }}
            >
              <LoaderIcon style={{ color: 'white' }} size={12} />
            </Box>
          )

        break
      case 2:
        result =
          isWithin5MinutesClasses === true ? (
            <PulseComponent color={theme.palette.success}>
              <CheckIcon style={{ color: 'white' }} size={12} />
            </PulseComponent>
          ) : (
            <Box
              className={classes.iconStatus}
              style={{ backgroundColor: theme.palette.success }}
            >
              <CheckIcon style={{ color: 'white' }} size={12} />
            </Box>
          )
        break
      case 3:
        result =
          isWithin5MinutesClasses === true ? (
            <PulseComponent color="#FFAC2F">
              <ClockIcon style={{ color: 'white' }} size={12} />
            </PulseComponent>
          ) : (
            <Box className={classes.iconStatus}>
              <ClockIcon style={{ color: 'white' }} size={12} />
            </Box>
          )
        break
      case 4:
        result =
          isWithin5MinutesClasses === true ? (
            <PulseComponent color={theme.palette.newWarning.warningOne}>
              <XIcon style={{ color: 'white' }} size={12} />
            </PulseComponent>
          ) : (
            <Box
              className={classes.iconStatus}
              style={{ backgroundColor: theme.palette.newWarning.warningOne }}
            >
              <XIcon style={{ color: 'white' }} size={12} />
            </Box>
          )
        break
      default:
        break
    }
    return result
  }

  return <>{subscriptionStatus(status)}</>
}

export default Pulse
