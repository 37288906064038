import { LabelView } from 'components'
import { Box, Typography } from '@material-ui/core'

import helpers from 'helpers'

const BankAccountsDetail = ({ bankAccount }) => {
  return (
    <>
      <Box mb={3}>
        <Typography variant="h6" color="textPrimary">
          Conta principal selecionada
        </Typography>
      </Box>
      <LabelView label="Banco" value={bankAccount?.bank} />
      <LabelView label="Agência" value={bankAccount?.agency} />
      <LabelView label="Número" value={bankAccount?.number} />
      <LabelView
        label="Data de criação"
        value={helpers.formatters.date(bankAccount?.createdAt)}
      />
    </>
  )
}

export default BankAccountsDetail
