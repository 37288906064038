import { DialogCard, CreditCardForm } from './components'

import { useState } from 'react'
import { PaymentMethodSelect, ComplianceText } from '../'

const CheckoutCard = ({ type, token, setStep, customer, order }) => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  return (
    <>
      <PaymentMethodSelect paymentMethodId={1} setStep={setStep} />
      <CreditCardForm
        type={type}
        token={token}
        customer={customer}
        order={order}
        setStep={setStep}
        loading={loading}
        setLoading={setLoading}
      />
      <ComplianceText buttonTitle="Pagar Agora" />
      <DialogCard
        open={open}
        setOpen={setOpen}
        type={type}
        token={token}
        order={order}
        customer={customer}
        setLoading={setLoading}
      />
    </>
  )
}

export default CheckoutCard
