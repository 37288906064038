import { Box, makeStyles, Typography } from '@material-ui/core'

import { Check } from 'react-feather'
import styles from './styles'

const useStyles = makeStyles(styles)

const CheckoutPaidPayment = () => {
  const classes = useStyles()

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={classes.successBox}
      >
        <Check size="40px" />
      </Box>
      <Typography variant="h6" color="textPrimary">
        Pagamento efetuado com sucesso!
      </Typography>
    </>
  )
}

export default CheckoutPaidPayment
