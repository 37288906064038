const ITEMS = {
  charges: {
    chargesMain: {
      content: [
        {
          title: 'Qual é o significado de cada status da transação?',
          description:
            'Enviado: Nesta etapa, o e-mail de cobrança foi enviado ao destinatário. Em andamento: O destinatário preencheu algum campo na cobrança. Aguardando confirmação: Nesta etapa, o usuário já fez o pagamento, mas ele está sendo validado. Você recebeu: O pagamento foi aprovado e você recebeu o dinheiro.',
        },
      ],
    },
    chargesNew: {
      content: [
        {
          title: 'Como funciona a cobrança avulsa?',
          description:
            "O cliente receberá um link de pagamento por e-mail, conforme especificado no campo 'e-mail' da cobrança. Neste link, ele terá acesso ao carrinho e aos métodos de pagamento selecionáveis. Se você marcar a opção 'Gerar cobrança com boleto', o destinatário receberá o boleto sem a necessidade de um link de pagamento.",
        },
        {
          title: 'Descrição de cada campo',
          description:
            'Informações do cliente: Aqui você colocará o nome e o e-mail do cliente. O cliente receberá o link de pagamento no e-mail especificado.  Informações de cobrança: Aqui você colocará o código da cobrança, a data de vencimento e a descrição da cobrança. Lembre-se de que a descrição ficará visível apenas para você.     Carrinho: Aqui serão exibidos os produtos ou serviços para seu cliente na página do link de pagamento. Inclui o nome, a descrição, o preço unitário e a quantidade disponível de cada item.Gerar cobrança com boleto: Se esta opção estiver selecionada, o cliente receberá um e-mail com o boleto.',
        },
      ],
    },
    chargesEdit: {
      content: [
        {
          title: 'Como funciona a cobrança avulsa?',
          description:
            "O cliente receberá um link de pagamento por e-mail, conforme especificado no campo 'e-mail' da cobrança. Neste link, ele terá acesso ao carrinho e aos métodos de pagamento selecionáveis. Se você marcar a opção 'Gerar cobrança com boleto', o destinatário receberá o boleto sem a necessidade de um link de pagamento.",
        },
      ],
    },
    subscriptionNew: {
      content: [
        {
          title: 'Como funciona a cobrança recorrente?',
          description:
            'Neste modelo de cobrança, cobrará recorrentemente por meio de assinatura.',
        },
        {
          title: 'O que são os planos?',
          description:
            'Os planos possuem informações como código, nome, descrição, valor e periodicidade de cobrança. Eles são fundamentais para criar as assinaturas.',
        },
      ],
    },
    plansNew: {
      content: [
        {
          title: 'Como funciona a periodicidade?',
          description:
            'A periodicidade é o intervalo da recorrência da cobrança. Por exemplo, se colocar mensal, será cobrado a cada mês; bimestral, a cada 2 meses; e assim sucessivamente.',
        },
        {
          title: 'O que significam os campos "privado" e "público"?',
          description:
            'Este campo é responsável por deixar visíveis os planos na página de seleção de planos no link de pagamento.',
        },
      ],
    },
  },
}

const faqs = {
  ITEMS,
}

export default faqs
