const periodicity = (periodicity) => {
  switch (periodicity) {
    case 12:
      return '/ano'
    case 1:
      return '/mês'
    default:
      return `/${periodicity} meses`
  }
}

export default periodicity
