import PropTypes from 'prop-types'

import { ChevronRight as ChevronRightIcon } from 'react-feather'
import { Box, Typography, makeStyles, Button, Grid } from '@material-ui/core'

import { useHistory } from 'react-router-dom'

import styles from './styles'

import theme from 'theme'

const useStyles = makeStyles(styles)

const SettingsIcon = ({ icon, title, subtitle, href }) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Button className={classes.boxButton} onClick={() => history.push(href)}>
      <Grid container>
        <Grid item xs={12} sm={2} md={2} lg={2}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            style={{ height: '100%' }}
          >
            <Box
              mr={theme.spacing(4)}
              display="flex"
              alignItems="center"
              justifyContent="center"
              className={classes.iconBox}
            >
              {icon}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={8} md={8} lg={8}>
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Typography
              variant="body1"
              style={{ fontWeight: 'bold', textTransform: 'capitalize' }}
              color="textPrimary"
            >
              {title}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{
                textAlign: 'left',
                textTransform: 'capitalize',
              }}
            >
              {subtitle}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={2} md={2} lg={2}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            style={{ height: '100%' }}
          >
            <ChevronRightIcon size={16} color="black" />
          </Box>
        </Grid>
      </Grid>
    </Button>
  )
}

SettingsIcon.propTypes = {
  icon: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
}

export default SettingsIcon
