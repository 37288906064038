import {
  Box,
  TablePagination,
  makeStyles,
  useMediaQuery,
  Grid,
  Typography,
} from '@material-ui/core'

import {
  Container,
  Page,
  Filters,
  LoadingFeedback,
  FilterRow,
  Header,
  AccordionCard,
  NoData,
} from 'components'
import { Main } from './components'
import { useState } from 'react'

import useFilter from 'hooks/useFilter'
import useFetch from 'hooks/useFetch'
import usePagination from 'hooks/usePagination'

import theme from 'theme'
import styles from './styles'
import { routes } from 'Routes'

import * as service from 'service'
import constants from 'constants/index'

const useStyles = makeStyles(styles)

const OrdersMain = () => {
  const filter = useFilter()
  const classes = useStyles()
  const [listingType, setListingType] = useState('table')
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const { page, perPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination(5)

  const { response, isLoading } = useFetch(
    service.intermediador.orders.get,
    { ...{ ...filter.filters }, page, perPage },
    [page, perPage, filter.filters],
  )

  return (
    <Page className={classes.root} title="Transações">
      <LoadingFeedback open={isLoading} />
      <Container maxWidth="md">
        <Grid container spacing={8}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Header name="Transações" route={routes.home} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FilterRow
              filter={filter}
              listingType={listingType}
              setListingType={setListingType}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {!response?.data?.orders && (
              <NoData
                title="Nenhuma transação encontrada."
                description="Você pode criar uma nova cobrança ou, caso tenha alguma dúvida, consulte nossos tutoriais e documentos para te auxiliar."
                route={routes.charges.new}
                buttonName="Nova cobrança"
              />
            )}
            <Main orders={response?.data?.orders} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box
              mt={2}
              display="flex"
              justifyContent="flex-end"
              className={classes.pagination}
            >
              <TablePagination
                component="div"
                count={response?.data?.meta?.totalCount || 0}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={page - 1}
                rowsPerPage={perPage}
                rowsPerPageOptions={[5, 10, 25, 100]}
                labelRowsPerPage={isDesktop ? 'Por página' : ''}
                nextIconButtonProps={{ size: 'small' }}
                backIconButtonProps={{ size: 'small' }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box mt={10}>
              <Typography variant="h6" color="textPrimary">
                Perguntas frequentes
              </Typography>
            </Box>
          </Grid>
          {constants?.faqs?.ITEMS?.charges?.chargesMain?.content?.map(
            (value) => (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <AccordionCard
                  title={value?.title}
                  description={value?.description}
                />
              </Grid>
            ),
          )}

          <Filters filter={filter}>
            <select textfieldinput="true" label="Status" name="status">
              <option value=""></option>
              <option value={constants.payments.STATUSES.NOT_PAID_STATUS}>
                {constants.payments.STATUSES_LABELS.NOT_PAID_LABEL}
              </option>
              <option
                value={constants.payments.STATUSES.WAITING_PAYMENT_STATUS}
              >
                {constants.payments.STATUSES_LABELS.WAITING_PAYMENT_LABEL}
              </option>
              <option
                value={constants.payments.STATUSES.WAITING_CONFIRM_STATUS}
              >
                {constants.payments.STATUSES_LABELS.WAITING_CONFIRM_LABEL}
              </option>
              <option value={constants.payments.STATUSES.PAID_STATUS}>
                {constants.payments.STATUSES_LABELS.PAID_LABEL}
              </option>
              <option value={constants.payments.STATUSES.CANCELED_STATUS}>
                {constants.payments.STATUSES_LABELS.CANCELED_LABEL}
              </option>
              <option value={constants.payments.STATUSES.FAILURE_STATUS}>
                {constants.payments.STATUSES_LABELS.FAILURE_LABEL}
              </option>
            </select>
            <input
              datepickerinput="true"
              label="Data de criação inicial"
              name="createdAtGt"
              disableFuture
              data-target="createdAtLt"
              data-min
            />
            <input
              datepickerinput="true"
              label="Data de criação final"
              name="createdAtLt"
              disableFuture
              data-target="createdAtGt"
              data-max
            />
          </Filters>
        </Grid>
      </Container>
    </Page>
  )
}

export default OrdersMain
