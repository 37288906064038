import {
  Box,
  makeStyles,
  Typography,
  Card,
  Divider,
  Button,
} from '@material-ui/core'
import { Copy as CopyIcon } from 'react-feather'
import styles from './styles'
import { QRCodeSVG } from 'qrcode.react'

const useStyles = makeStyles(styles)

const PixCode = ({ pix }) => {
  const classes = useStyles()

  return (
    <Box>
      <Card>
        <Box p={2}>
          <Typography variant="h5">Escaneie o QR Code</Typography>
        </Box>
        <Divider />
        <Box maxWidth p={3} display="flex" justifyContent="center">
          <Box className={classes.qrCodeBox}>
            <QRCodeSVG value={pix?.qrCode} size="180" />
          </Box>
        </Box>
        <Box p={5}>
          <Box display="flex" alignItems="center">
            <Typography className={classes.numberIcon}>1. </Typography>
            <Typography>
              Abra o app do seu banco ou instituição financeira e entre no
              ambiente <strong>Pix</strong>.
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Typography className={classes.numberIcon}>2. </Typography>
            <Typography>
              Escolha a opção <strong>Pagar com QR Code</strong> e escaneie o
              código ao lado, ou copie o código abaixo e pague na opção{' '}
              <strong>Pix Copia e Cola</strong>.
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Typography className={classes.numberIcon}>3. </Typography>
            <Typography>
              Confirme as informações e finalize o pagamento.
            </Typography>
          </Box>
        </Box>
      </Card>
      <Box mt={3}>
        <Card>
          <Box p={2}>
            <Typography variant="h5">Código pix</Typography>
          </Box>
          <Divider />
          <Box maxWidth p={3}>
            <Box display="flex" className={classes.pixCodeBox} py={3}>
              <Typography variant="h5" className={classes.pixCode}>
                {pix?.qrCode}
              </Typography>
              <CopyIcon
                size="16px"
                className={classes.copyIcon}
                onClick={() => {
                  navigator.clipboard.writeText(pix?.qrCode)
                }}
              />
            </Box>
          </Box>
          <Box alignSelf="stretch">
            <Divider />
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            p={1}
            width="100%"
          >
            <Button
              className={classes.button}
              onClick={() => {
                navigator.clipboard.writeText(pix?.qrCode)
              }}
            >
              Copiar código pix
            </Button>
          </Box>
        </Card>
      </Box>
    </Box>
  )
}

export default PixCode
