const styles = (checkoutTheme) => ({
  infoBox: {
    backgroundColor: checkoutTheme.palette.background.paper,
    borderRadius: checkoutTheme.spacing(2),
    border: '2px solid #cdcecf',
    paddingTop: checkoutTheme.spacing(10),
    paddingBottom: checkoutTheme.spacing(10),
  },
  overflow: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
})

export default styles
