import api from 'service/api'

const intermediadorAPI = api.create('intermediador')

const get = async ({ ...params }) => {
  return await intermediadorAPI.get('/subscription/subscriptions', { params })
}

const show = async ({ subscriptionId = '' }) => {
  return await intermediadorAPI.get(
    `/subscription/subscriptions/${subscriptionId}`,
  )
}

const create = async ({ ...data }) => {
  return await intermediadorAPI.post('/subscription/subscriptions', data)
}

const subscriptions = {
  get,
  show,
  create,
}

export default subscriptions
