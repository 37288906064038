import {
  BottomNavigationAction,
  BottomNavigation,
  makeStyles,
} from '@material-ui/core'

import styles from './styles'
import { useState } from 'react'
import { useLocation } from 'react-router'
import { useHistory } from 'react-router-dom'
import constants from 'constants/index'

const useStyles = makeStyles(styles)

const MenuBar = ({ className, onMobileNavOpen, ...rest }) => {
  const location = useLocation()
  const classes = useStyles()
  const history = useHistory()
  const [value, setValue] = useState(location.pathname)

  const handleChange = (event, newValue) => {
    setValue(newValue)
    history.push(newValue)
  }

  return (
    <BottomNavigation
      showLabels
      value={value}
      onChange={handleChange}
      className={classes.root}
    >
      {constants.menu.ITEMS &&
        constants.menu.ITEMS.map((item) => (
          <BottomNavigationAction
            key={item.title}
            label={item.title}
            value={item.href}
            icon={item.icon}
          />
        ))}
    </BottomNavigation>
  )
}

export default MenuBar
