import { useState, useRef, useEffect } from 'react'

import { Button, CircularProgress } from '@material-ui/core'

const LoadingButton = ({ loading, text, disabled, ...rest }) => {
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  })
  const targetRef = useRef()

  useEffect(() => {
    if (targetRef.current.offsetWidth >= dimensions.width) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight,
      })
    }
    //eslint-disable-next-line
  }, [loading])

  return (
    <Button
      color="primary"
      type="submit"
      variant="contained"
      ref={targetRef}
      style={{ width: !loading ? '100%' : dimensions.width }}
      disabled={disabled}
      {...rest}
    >
      {loading ? <CircularProgress color="inherit" size={25} /> : `${text}`}
    </Button>
  )
}
export default LoadingButton
