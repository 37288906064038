import React, { useState, useEffect, useCallback } from 'react'
import { Container, Page } from 'components'
import Logo from 'images/logo-horizontal-y.png'
import LogoLeadLovers from 'images/leadlovers-marca.svg'
import { ThemeProvider, createTheme } from '@material-ui/core'
import { Grid, Hidden, makeStyles } from '@material-ui/core'
import useAuth from 'hooks/useAuth'
import checkoutTheme from 'theme/checkoutTheme'
import { CheckoutPayment, ProductDetails, EditPage } from './components'
import themeLeadLovers from 'theme/themeLeadLovers'
import styles from './styles'

import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'

const useStyles = makeStyles(styles)

const PreviewCheckoutMain = ({ match }) => {
  const type = match.params.type
  const token = match.params.token
  const { user } = useAuth()
  const order = {
    payment: {
      code: '2',
      value: 22,
      products: [
        {
          name: 'Produto A',
          description: 'O produto tem determinada características',
          price: '22.00',
          quantity: 1,
        },
      ],
      status: 'NOT_PAID',
      description: 'Produto Avulso 111',
      expirationDate: '2024-03-01',
    },
    seller: {
      email: null,
      name: 'Eduardo Batista',
      phone: null,
      token: 'ad3d9e7cd5f08c24adcf220089923783',
    },
  }
  const classes = useStyles()
  const [step, setStep] = useState(1)
  const [customer, setCustomer] = useState({})
  const [theme, setTheme] = useState(null)
  const snackbar = useSnackbar()

  const changeColor = (value) => {
    setTheme((prevTheme) => {
      const newTheme = { ...prevTheme }
      for (const category in value) {
        if (!newTheme[category]) {
          newTheme[category] = {}
        }
        for (const field in value[category]) {
          newTheme[category][field] = value[category][field]
        }
      }
      postTheme(newTheme)
      return newTheme
    })
  }

  const postTheme = async (data) => {
    try {
      await service.intermediador.accountCustomizations.create({
        tag: 'theme',
        data: JSON.stringify(data),
      })
      snackbar.open({
        variant: 'success',
        message: 'Personalização aplicada com sucesso!',
      })
    } catch (error) {
      snackbar.open({
        variant: 'error',
        message: 'Erro ao aplicar a personalização',
      })
      return null
    }
  }

  const getTheme = useCallback(async () => {
    try {
      const response = await service.intermediador.accountCustomizations.show(
        'theme',
        user?.account?.id,
      )
      const themeData = JSON.parse(response?.data.accountCustomization?.data)
      if (
        user?.account?.partnerGroup === 'leadlovers' &&
        response?.data.accountCustomization?.data === 'f'
      ) {
        setTheme(createTheme(themeLeadLovers))
      } else {
        setTheme(createTheme(themeData))
      }
    } catch (error) {
      if (user?.account?.partnerGroup === 'leadlovers') {
        setTheme(createTheme(themeLeadLovers))
      } else {
        setTheme(createTheme(checkoutTheme))
      }
      return null
    }
  }, [user])

  useEffect(() => {
    const fetchData = async () => {
      if (user && theme === null) {
        await getTheme()
      }
    }
    fetchData()
  }, [theme, user, getTheme])

  return (
    <div style={{ backgroundColor: theme?.palette?.background?.default }}>
      <EditPage changeColor={changeColor} customPalette={theme} />
      {user && (
        <Container maxWidth="md">
          <>
            {theme && (
              <ThemeProvider theme={theme}>
                <Page title="Pagamento" style={{ minHeight: '104vh' }}>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <img
                        alt="Logo"
                        src={
                          user?.account?.partnerGroup === 'leadlovers'
                            ? LogoLeadLovers
                            : Logo
                        }
                        className={classes.logo}
                        width="100%"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={7}>
                      <CheckoutPayment
                        type={type}
                        token={token}
                        order={order}
                        customer={customer}
                        setCustomer={setCustomer}
                        step={step}
                        setStep={setStep}
                        loading={false}
                      />
                    </Grid>
                    <Hidden smDown>
                      <Grid item xs={6} md={1} sm={12} lg={1}></Grid>
                    </Hidden>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <ProductDetails order={order?.payment} />
                    </Grid>
                  </Grid>
                </Page>
              </ThemeProvider>
            )}
          </>
        </Container>
      )}
    </div>
  )
}

export default PreviewCheckoutMain
