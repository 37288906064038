import { Typography, Box, Link } from '@material-ui/core'

import { ExternalLink as ExternalLinkIcon } from 'react-feather'

const UrlBox = ({ type, url, link }) => {
  return (
    <>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          backgroundColor: '#FFFFFF',
          padding: '12px',
          borderRadius: '20px',
          marginTop: '12px',
          marginBottom: '12px',
        }}
      >
        {type === 'GET' && (
          <Box
            style={{
              backgroundColor: '#61affe',
              padding: '4px 12px 4px 12px',
              borderRadius: '4px',
              marginRight: '20px',
            }}
          >
            <Typography
              variant="body2"
              style={{ color: '#FFFFFF', fontWeight: 'bold' }}
            >
              GET
            </Typography>
          </Box>
        )}
        {type === 'POST' && (
          <Box
            style={{
              backgroundColor: '#4AA802',
              padding: '4px 12px 4px 12px',
              borderRadius: '4px',
              marginRight: '20px',
            }}
          >
            <Typography
              variant="body2"
              style={{ color: '#FFFFFF', fontWeight: 'bold' }}
            >
              POST
            </Typography>
          </Box>
        )}
        {type === 'PUT' && (
          <Box
            style={{
              backgroundColor: '#E5A200',
              padding: '4px 12px 4px 12px',
              borderRadius: '4px',
              marginRight: '20px',
            }}
          >
            <Typography
              variant="body2"
              style={{ color: '#FFFFFF', fontWeight: 'bold' }}
            >
              PUT
            </Typography>
          </Box>
        )}
        {type === 'DELETE' && (
          <Box
            style={{
              backgroundColor: '#E60042',
              padding: '4px 12px 4px 12px',
              borderRadius: '4px',
              marginRight: '20px',
            }}
          >
            <Typography
              variant="body2"
              style={{ color: '#FFFFFF', fontWeight: 'bold' }}
            >
              POST
            </Typography>
          </Box>
        )}
        <Typography variant="body2" color="textPrimary">
          {url}
        </Typography>
      </Box>
      <Link
        href={link}
        color="inherit"
        target="_blank"
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="body2"
          color="primary"
          style={{ marginRight: '12px' }}
        >
          Mais detalhes
        </Typography>
        <ExternalLinkIcon size={14} color="#00A900" />
      </Link>
    </>
  )
}

export default UrlBox
