const statusColor = (status, classes) => {
  switch (status) {
    case 'NOT_PAID':
      return classes.textNotPaid
    case 'WAITING_PAYMENT':
      return classes.textWaitingPayment
    case 'WAITING_CONFIRM':
      return classes.textWaitingConfirm
    case 'PAID':
      return classes.textPaid
    default:
      return classes.textCanceled
  }
}

export default statusColor
