import { Container, Page } from 'components'
import {
  PaymentHeader,
  CustomerForm,
  PaidPayment,
  SellerDetails,
  ProductDetails,
} from './components'
import useOrder from 'hooks/useOrder'
import { useState, useEffect } from 'react'

import * as service from 'service'
import constants from 'constants/index'

const PaymentCustomerInfo = ({ match }) => {
  const type = match.params.type
  const token = match.params.token
  const [isLoading, setIsLoading] = useState(true)
  const [order, setOrder] = useState([])
  const { loadData } = useOrder()

  useEffect(() => {
    const getResponse = async () => {
      const response = await service.intermediador.payments.show(type, token)
      loadData(response?.data)
      setOrder(response?.data)
      setIsLoading(false)
    }
    getResponse()
  }, [loadData, type, token])

  return (
    <>
      {!isLoading &&
        (order?.payment?.status === constants.payments.STATUSES.PAID_STATUS ? (
          <Page title="Pagamento" paddingTop={0}>
            <PaymentHeader token={token} type={type} paidPayment />
            <Container
              maxWidth={false}
              spacingXl={50}
              spacingLg={35}
              spacingMd={20}
              spacingSm={10}
            >
              <PaidPayment />
              <ProductDetails order={order?.payment} />
              <SellerDetails seller={order?.seller} />
            </Container>
          </Page>
        ) : (
          <Page title="Pagar" paddingTop={0}>
            <PaymentHeader token={token} type={type} customerPage />
            <Container
              maxWidth={false}
              spacingXl={50}
              spacingLg={35}
              spacingMd={20}
              spacingSm={10}
            >
              <CustomerForm type={type} token={token} />
            </Container>
          </Page>
        ))}
    </>
  )
}

export default PaymentCustomerInfo
