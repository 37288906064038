import constants from 'constants/index'

const statusTooltip = (status) => {
  switch (status) {
    case constants.withdraws.PENDING_LABEL_EN:
      return constants.withdraws.PENDING_LABEL_PT
    case constants.withdraws.CANCELED_LABEL_EN:
      return constants.withdraws.CANCELED_LABEL_PT
    case constants.withdraws.PROCESSED_LABEL_EN:
      return constants.withdraws.PROCESSED_LABEL_PT
    default:
      return constants.withdraws.PROCESSING_LABEL_PT
  }
}

export default statusTooltip
