import {
  Box,
  Typography,
  CircularProgress,
  Button,
  makeStyles,
} from '@material-ui/core'

import { Play } from 'react-feather'

import PropTypes from 'prop-types'

import styles from './styles'

const useStyles = makeStyles(styles)

function CircularProgressWithLabel(props) {
  return (
    <Box
      position="relative"
      display="inline-flex"
      style={{ position: 'absolute', top: '0px', right: '0px' }}
    >
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  )
}

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
}

const AchievementCard = ({ title, progress, urlImage }) => {
  const classes = useStyles()

  return (
    <Button className={classes.root}>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '12px',
        }}
      >
        <Box
          style={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '80px',
            height: '80px',
            marginBottom: '12px',
          }}
        >
          <Play />
          <img src={urlImage} alt="achievement" />
          <CircularProgressWithLabel value={progress} />
        </Box>
        <Typography variant="body2" color="textSecondary">
          {title}
        </Typography>
      </Box>
    </Button>
  )
}

export default AchievementCard
