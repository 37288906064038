import { Box, Grid, Typography, TextField, Button } from '@material-ui/core'
import { Select } from 'components'
import useFetch from 'hooks/useFetch'
import { useForm, Controller } from 'react-hook-form'

import * as service from 'service'
import helpers from 'helpers'
import schema from './schema'

import { useHistory } from 'react-router-dom'
import { routes } from 'Routes'
import useSnackbar from 'hooks/useSnackbar'

const SubscriptionsForm = ({ plan, isEdit }) => {
  const history = useHistory()
  const snackbar = useSnackbar()

  const { response } = useFetch(service.intermediador.plans.get, {})

  const { control, handleSubmit, errors } = useForm({
    validationSchema: schema,
    defaultValues: {
      name: '',
      document: '',
      email: '',
      code: '',
      planId: '',
      subscriptionCode: '',
    },
  })

  const setPlanName = (plan) => {
    return `${plan.name} - ${helpers.formatters.floatToMoney(
      plan.price,
    )}${helpers.plans.periodicity(plan.periodicity)}`
  }

  const onSubmit = async (data) => {
    try {
      await service.intermediador.subscriptions.create({
        subscription: {
          customer: {
            name: data.name,
            document: data.document,
            email: data.email,
            code: data.code,
          },
          plans: [{ id: data.planId }],
          code: data.subscriptionCode,
        },
      })

      snackbar.open({
        message: 'Assinatura criada com sucesso',
        variant: 'success',
      })

      service.history.push(routes.subscriptions.main)
    } catch (error) {
      snackbar.open({
        message: 'Erro ao criar assinatura',
        variant: 'error',
      })
    }
  }

  return (
    <form id="planForm" onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={8}>
        <Grid item lg={12} xs={12}>
          <Typography color="textPrimary" variant="h6">
            Informações pessoais do cliente
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Controller
            as={
              <TextField
                label="Nome Completo*"
                type="text"
                color="primary"
                error={!!errors.name}
                helperText={errors?.name?.message}
                fullWidth
              />
            }
            control={control}
            name="name"
            mode="onBlur"
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Controller
            as={
              <TextField
                label="Documento(CPF/CNPJ)*"
                type="text"
                color="primary"
                error={!!errors.document}
                helperText={errors?.document?.message}
                fullWidth
              />
            }
            onChange={([event]) => {
              return helpers.formatters.document(event.target.value)
            }}
            control={control}
            name="document"
            mode="onBlur"
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Controller
            as={
              <TextField
                label="E-mail*"
                type="text"
                color="primary"
                error={!!errors.email}
                helperText={errors?.email?.message}
                fullWidth
              />
            }
            control={control}
            name="email"
            mode="onBlur"
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Controller
            as={
              <TextField
                label="Código"
                type="text"
                color="primary"
                error={!!errors.code}
                helperText={errors?.code?.message}
                fullWidth
              />
            }
            control={control}
            name="code"
            mode="onBlur"
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography color="textPrimary" variant="h6">
            Informações de pagamento/assinatura
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4}>
          <Controller
            as={
              <TextField
                label="Código da assinatura*"
                type="text"
                color="primary"
                error={!!errors.subscriptionCode}
                helperText={errors?.subscriptionCode?.message}
                fullWidth
              />
            }
            control={control}
            name="subscriptionCode"
            mode="onBlur"
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={8}>
          <Controller
            as={
              <Select
                label="Planos*"
                defaultValue={''}
                items={
                  response?.data?.plans?.map((plan) => {
                    return { id: plan.id, name: setPlanName(plan) }
                  }) || []
                }
                error={!!errors.planId}
                helperText={errors?.planId?.message}
              />
            }
            control={control}
            name="planId"
            mode="onBlur"
            size="small"
          />
          <Typography
            variant="body2"
            style={{ textDecoration: 'underline' }}
            color="textPrimary"
            onClick={() => {
              history.push(routes.plans.new)
            }}
          >
            Não encontrou o plano? Clique aqui e registre!
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box pl={2} mt={3} display="flex" justifyContent="flex-start">
            <Button onClick={() => history.push(routes.subscriptions.main)}>
              Voltar
            </Button>

            <Button
              form="planForm"
              color="primary"
              variant="contained"
              type="submit"
            >
              Salvar
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  )
}

export default SubscriptionsForm
