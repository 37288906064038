import { Typography, Grid, Box, Button } from '@material-ui/core'

import { Page, Container } from 'components'

import { routes } from 'Routes'

import { useHistory } from 'react-router-dom'

import Ccobrancarecorrente from './images/c-cobrancarecorrente.png'
import Ccobrancassinatura from './images/c-cobrancassinatura.png'
import Cdashboardgestao from './images/c-dashboardgestao.png'
import Clinkspagamento from './images/c-linkspagamento.png'
import Ccheckoutpagamentos from './images/c-checkoutpagamentos.png'
import Cformaspagamento from './images/c-formaspagamento.png'

const Documentation = () => {
  const history = useHistory()

  return (
    <Page title="Superfin">
      <Container maxWidth="md">
        <Grid container spacing={8}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box
              my={10}
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                paddingTop: '120px',
                paddingBottom: '120px',
              }}
            >
              <Typography variant="h6" color="textPrimary">
                Documentação do Superfin para Desenvolvedores
              </Typography>
              <Typography variant="body1" color="textPrimary">
                Os desenvolvedores poderão integrar APIs da Superfin
              </Typography>
              <Button
                color="primary"
                variant="contained"
                onClick={() => history.push(routes.documentation.introduction)}
              >
                Iniciar
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography variant="h6" color="textPrimary">
              Como utilizar?
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <Typography variant="h6" color="textPrimary">
              1
            </Typography>
            <Typography variant="body1" color="textPrimary">
              Realize o seu cadastro nessa plataforma
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Faça seu cadastro como desenvolvedor na aplicação da Superfin para
              obter a chave.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <Typography variant="h6" color="textPrimary">
              2
            </Typography>
            <Typography variant="body1" color="textPrimary">
              Obtenha o token de autenticação
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Com acesso, obtenha a chave na aplicação da superfin.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <Typography variant="h6" color="textPrimary">
              3
            </Typography>
            <Typography variant="body1" color="textPrimary">
              Realize as requisições
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Pronto. Agora você pode utilizar as nossas documentações para
              realizar as requisições
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box
              style={{
                paddingTop: '60px',
              }}
            >
              <Typography
                variant="h6"
                color="textPrimary"
                style={{ textAlign: 'center' }}
              >
                Recursos
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Box
              style={{
                border: '2px solid #ecf6ff',
                borderRadius: '20px',
                padding: '20px',
              }}
            >
              <img
                src={Ccobrancarecorrente}
                alt="Cobranças recorrentes"
                width="60px"
              />
              <Typography variant="body1" color="textPrimary">
                Cobranças recorrentes
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Box
              style={{
                border: '2px solid #ecf6ff',
                borderRadius: '20px',
                padding: '20px',
              }}
            >
              <img
                src={Ccobrancassinatura}
                alt="Gestão de assinaturas"
                width="60px"
              />
              <Typography variant="body1" style={{ color: '#00a900' }}>
                Gestão de assinaturas
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Box
              style={{
                border: '2px solid #ecf6ff',
                borderRadius: '20px',
                padding: '20px',
              }}
            >
              <img
                src={Cdashboardgestao}
                alt="Dashboard de gestão"
                width="60px"
              />
              <Typography variant="body1" style={{ color: '#ffac2f' }}>
                Dashboard de gestão
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Box
              style={{
                border: '2px solid #ecf6ff',
                borderRadius: '20px',
                padding: '20px',
              }}
            >
              <img
                src={Clinkspagamento}
                alt="Links de pagamento"
                width="60px"
              />
              <Typography variant="body1" style={{ color: '#9651b8' }}>
                Links de pagamento
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Box
              style={{
                border: '2px solid #ecf6ff',
                borderRadius: '20px',
                padding: '20px',
              }}
            >
              <img
                src={Ccheckoutpagamentos}
                alt="Checkout de pagamentos"
                width="60px"
              />
              <Typography variant="body1" style={{ color: '#44be9e' }}>
                Checkout de pagamentos
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Box
              style={{
                border: '2px solid #ecf6ff',
                borderRadius: '20px',
                padding: '20px',
              }}
            >
              <img
                src={Cformaspagamento}
                alt="Múltiplas formas de pagamento"
                width="60px"
              />
              <Typography variant="body1" style={{ color: '#b71c1c' }}>
                Múltiplas formas de pagamento
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box
              style={{
                marginBottom: '120px',
              }}
            ></Box>
          </Grid>
        </Grid>
      </Container>
      {/* <Box
        style={{
          backgroundColor: '#ecf6ff',
          paddingTop: '60px',
          paddingBottom: '60px',
        }}
      >
        <Container maxWidth="md">
          <Grid container spacing={8}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box
                style={{
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <Typography variant="h6" color="textPrimary">
                  Acesse a documentação
                </Typography>
                <Typography variant="body1" color="textPrimary">
                  Desfrute de nossa plataforma de intermediação de pagamentos.{' '}
                </Typography>
                <Button color="primary" variant="contained">
                  Iniciar
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box> */}
    </Page>
  )
}

export default Documentation
