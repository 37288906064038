import api from 'service/api'

const intermediadorAPI = api.create('intermediador')

const get = async ({ ...params }) => {
  return await intermediadorAPI.get('/bank_accounts', { params })
}

const update = async ({ bankAccountId = '', ...params }) => {
  return await intermediadorAPI.put(`/bank_accounts/${bankAccountId}`, params)
}

const create = async ({ ...data }) => {
  return await intermediadorAPI.post('/bank_accounts', data)
}

const listBanks = async () => {
  return await intermediadorAPI.get('/banks.json')
}

const bankAccounts = {
  get,
  update,
  create,
  listBanks,
}

export default bankAccounts
