import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from '@material-ui/core'

const ParamsTable = ({ rows }) => {
  return (
    <Box style={{ marginTop: '40px' }}>
      <Typography variant="h6" color="textPrimary" style={{ fontSize: '16px' }}>
        Tabela de Parâmetros
      </Typography>
      <br />
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography
                  variant="body2"
                  color="textPrimary"
                  style={{ fontSize: '12px' }}
                >
                  Nome
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography
                  variant="body2"
                  color="textPrimary"
                  style={{ fontSize: '12px' }}
                >
                  Descrição
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography
                  variant="body2"
                  color="textPrimary"
                  style={{ fontSize: '12px' }}
                >
                  Tipo
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography
                  variant="body2"
                  color="textPrimary"
                  style={{ fontSize: '12px' }}
                >
                  Modo de envio
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography
                  variant="body2"
                  color="textPrimary"
                  style={{ fontSize: '12px' }}
                >
                  Obrigatório
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows &&
              rows.map((row) => {
                if (!row) return null // Ensure row is defined
                return (
                  <TableRow
                    key={row.name}
                    style={{ backgroundColor: 'transparent' }}
                  >
                    <TableCell component="th" scope="row">
                      <Typography
                        variant="body2"
                        color="textPrimary"
                        style={{ fontSize: '12px' }}
                      >
                        {row[0]}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography
                        variant="body2"
                        color="textPrimary"
                        style={{ fontSize: '12px' }}
                      >
                        {row[1]}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography
                        variant="body2"
                        color="textPrimary"
                        style={{ fontSize: '12px' }}
                      >
                        {row[2]}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography
                        variant="body2"
                        color="textPrimary"
                        style={{ fontSize: '12px' }}
                      >
                        {row[3]}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography
                        variant="body2"
                        color="textPrimary"
                        style={{ fontSize: '12px' }}
                      >
                        {row[4]}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default ParamsTable
