import { Box, Typography, Button } from '@material-ui/core'

import { useHistory } from 'react-router-dom'

import { routes } from 'Routes'

const NoData = ({ title, description, buttonName, route }) => {
  const history = useHistory()

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '40px',
      }}
    >
      <Typography variant="h6" color="textPrimary" align="center">
        {title}
      </Typography>
      <Typography variant="body1" color="textPrimary" align="center">
        {description}
      </Typography>
      <Box mt={6}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            history.push(route)
          }}
        >
          {buttonName}
        </Button>
        <Button
          onClick={() => {
            history.push(routes.quickGuide.main)
          }}
        >
          Tutorial
        </Button>
      </Box>
    </Box>
  )
}

export default NoData
