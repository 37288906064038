const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: '100%',
  },
  logo: {
    width: '200px',
    zIndex: 2,
  },
  backImageHome: {
    width: '80%',
    borderRadius: '20px 2px 2px 20px',
  },
  logoOneid: {
    width: '20px',
    marginRight: '20px',
  },
  button: {
    width: '200px',
    backgroundColor: '#ECF6FF',
    borderRadius: '20px',
    zIndex: 2,
  },
  boxButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  box: {
    position: 'relative',
    right: '-80px',
    height: '500px',
    maxHeight: '500px',
    width: '200px',
    border: '4px solid #ECF6FF',
    borderRadius: '20px 0px 0px 20px',
  },
  actionsContainer: {
    marginTop: '20px',
  },
})

export default styles
