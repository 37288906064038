const styles = (theme) => ({
  boxButton: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(10),
    padding: '8px',
    width: '100%',
    transition: 'transform 0.5s',
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.05)',
    },
  },
  iconBox: {
    backgroundColor: '#e2f4f9',
    borderRadius: '50%',
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
})

export default styles
