import {
  Home as HomeIcon,
  DollarSign as DollarSignIcon,
  //FileText as FileTextIcon,
  FileText as FileTextIcon,
  ArrowDownCircle as ArrowDownCircleIcon,
  Settings as SettingIcon,
  Layers as LayersIcon,
  RefreshCcw as RefreshCcwIcon,
} from 'react-feather'

const ITEMS = [
  { title: 'Início', icon: <HomeIcon size={16} />, href: '/home' },
  { title: 'Transações', icon: <FileTextIcon size={16} />, href: '/orders' },
  //{ title: 'Extrato', icon: <FileTextIcon size={22} />, href: '/statements' },
  { title: 'Cobrança', icon: <DollarSignIcon size={16} />, href: '/charges' },
  {
    title: 'Saques',
    icon: <ArrowDownCircleIcon size={16} />,
    href: '/withdraws/new',
  },
  {
    title: 'Planos',
    icon: <LayersIcon size={16} />,
    href: '/plans',
  },
  {
    title: 'Assinaturas',
    icon: <RefreshCcwIcon size={16} />,
    href: '/subscriptions',
  },
  {
    title: 'Configurações',
    icon: <SettingIcon size={16} />,
    href: '/settings',
  },
]

const menu = {
  ITEMS,
}

export default menu
