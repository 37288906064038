import { Grid } from '@material-ui/core'

import { Container, Page } from 'components'
import {
  PaymentHeader,
  ProductDetails,
  SellerDetails,
  CreditCardForm,
} from './components'

import { useHistory, useLocation } from 'react-router-dom'
import { reverse } from 'named-urls'
import { routes } from 'Routes'
import useOrder from 'hooks/useOrder'

const CreditCardPayment = ({ match }) => {
  const type = match.params.type
  const token = match.params.token
  const location = useLocation()
  const history = useHistory()
  const customer = location.state?.customer
  const { data } = useOrder()

  if (customer == null) {
    history.push(
      reverse(routes.payments.main, {
        type: type,
        token: token,
      }),
    )
  }

  return (
    <Page title="Pagar" paddingTop={0}>
      <PaymentHeader credit={true} customer={customer} />
      <Container
        maxWidth={false}
        spacingXl={60}
        spacingLg={40}
        spacingMd={25}
        spacingSm={10}
      >
        <Grid container spacing={3}>
          <Grid item sm={4} xs={12}>
            <ProductDetails order={data?.payment} />
          </Grid>
          <Grid item sm={8} xs={12}>
            <CreditCardForm
              type={type}
              token={token}
              customer={customer}
              order={data}
            />
          </Grid>
        </Grid>
        <SellerDetails seller={data?.seller} />
      </Container>
    </Page>
  )
}

export default CreditCardPayment
