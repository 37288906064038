const styles = (theme) => ({
  root: {
    backgroundColor: '#e2f4f9',
    zIndex: 1,
    // color: theme.palette.text.primary,
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundColor: '#235078',
  },
  completed: {
    backgroundColor: theme.palette.success.main,
  },
})

export default styles
