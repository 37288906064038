import { Grid, Box, Divider } from '@material-ui/core'

import { Page, Container } from 'components'

import {
  Menu,
  OnPage,
  ButtonBaseComponent,
  Title,
  Paragraph,
  ParamsTable,
  UrlBox,
  Section,
  CodeBox,
} from '../components'

import { routes } from 'Routes'

const Customers = () => {
  const onPage = [
    ['#d-customers', 'Clientes'],
    ['#d-listar', 'Listar'],
    ['#d-consultar', 'Consultar'],
    ['#d-criar', 'Criar'],
    ['#d-atualizar', 'Atualizar'],
  ]

  const createCustomer = `
{
    "name": "test",
    "email": "teste",
    "document": "teste",
    "code": "aaaa"
}
  `

  return (
    <Page title="Clientes">
      <Container maxWidth="md">
        <Box style={{ marginBottom: '40px' }}>
          <Grid container spacing={8}>
            <Grid item xs={12} sm={12} md={12} lg={2}>
              <Menu />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8}>
              <Box style={{ marginBottom: '40px' }}>
                <Title variant="h5" id="d-customers" subtitle="Recorrência">
                  Clientes
                </Title>
                <Paragraph>
                  O endpoint de cliente é fundamental para a gestão de
                  informações dos clientes em um sistema de assinaturas. Ele
                  permite a criação, consulta, atualização e exclusão dos dados
                  dos clientes, facilitando a administração eficiente e
                  personalizada das interações com os usuários.
                </Paragraph>
              </Box>
              <Divider />
              <Section>
                <Title variant="h6" id="d-listar">
                  Listar
                </Title>
                <UrlBox
                  type="GET"
                  url="https://api.sandbox.superfin.com.br/subscription/customers"
                  link="https://documenter.getpostman.com/view/11000226/TzRPhoDe#29b559ca-9b0e-4b92-aa2b-b11f6026a93a"
                />
                <Paragraph>
                  API para realizar a listagem dos clientes existentes na base
                  de dados. Obrigatório o envio dos parametros de autenticação.
                  Filtros opcionais: name, email, document, status, code,
                  created_at_gt, create_at_lt.
                </Paragraph>
                <ParamsTable
                  rows={[
                    [
                      'Authorization',
                      'Token de identificação referente a autenticação',
                      'String',
                      'Headers',
                      'Sim',
                    ],
                  ]}
                />
              </Section>
              <Divider />
              <Section>
                <Title variant="h6" id="d-consultar">
                  Consultar
                </Title>
                <UrlBox
                  type="GET"
                  url="https://api.sandbox.superfin.com.br/subscription/customers/:id"
                  link="https://documenter.getpostman.com/view/11000226/TzRPhoDe#aef6287b-81b0-4e90-8751-41db72b14bc6"
                />
                <Paragraph>
                  API para realizar a consulta dos dados de um cliente.
                  Obrigatório o envio do id do cliente na url e dos parametros
                  de autenticação.
                </Paragraph>
                <ParamsTable
                  rows={[
                    [
                      'Authorization',
                      'Token de identificação referente a autenticação',
                      'String',
                      'Headers',
                      'Sim',
                    ],
                    [
                      'id',
                      'Identificação da assinatura',
                      'String',
                      'Headers',
                      'Sim',
                    ],
                  ]}
                />
              </Section>
              <Divider />
              <Section>
                <Title variant="h6" id="d-criar">
                  Criar
                </Title>
                <UrlBox
                  type="POST"
                  url="https://api.sandbox.superfin.com.br/subscription/customers"
                  link="https://documenter.getpostman.com/view/11000226/TzRPhoDe#387e51cb-48ea-47f9-9cad-f485d31553ac"
                />
                <Paragraph>
                  API para realizar a criação de clientes. Parametros
                  obrigatorios: name, email, documente e parametros de
                  autenticação.
                </Paragraph>
                <CodeBox
                  title="Body"
                  code={createCustomer}
                  link="https://documenter.getpostman.com/view/11000226/TzRPhoDe#34ecf943-026a-455c-b613-3fbc9d3961db"
                />
                <ParamsTable
                  rows={[
                    [
                      'Authorization',
                      'Token de identificação referente a autenticação',
                      'String',
                      'Headers',
                      'Sim',
                    ],
                    ['name', 'Nome do cliente', 'String', 'Body', 'Sim'],
                    ['email', 'E-mail do cliente', 'String', 'Body', 'Sim'],
                    [
                      'document',
                      'Documento de indetificação do cliente',
                      'String',
                      'Body',
                      'Sim',
                    ],
                    ['code', 'Código do cliente', 'String', 'Body', 'Sim'],
                  ]}
                />
              </Section>
              <Section>
                <Title variant="h6" id="d-atualizar">
                  Atualizar
                </Title>
                <UrlBox
                  type="PUT"
                  url="https://api.sandbox.superfin.com.br/subscription/customers/:id"
                  link="https://documenter.getpostman.com/view/11000226/TzRPhoDe#59d7e56d-bdb5-4897-9056-c55b05e9264a"
                />
                <Paragraph>
                  A API de atualização de dados de clientes permite modificar as
                  informações registradas de um cliente específico.
                  <br />
                  Para utilizar esta funcionalidade, é necessário fornecer o ID
                  do cliente na URL e os parâmetros de autenticação. Os campos
                  que podem ser modificados incluem: nome, e-mail, documento,
                  código e status. Esta API assegura que todas as alterações
                  sejam realizadas de forma segura e eficiente, garantindo a
                  integridade dos dados dos clientes no sistema.
                </Paragraph>
                <CodeBox
                  title="Body"
                  code={createCustomer}
                  link="https://documenter.getpostman.com/view/11000226/TzRPhoDe#59d7e56d-bdb5-4897-9056-c55b05e9264a"
                />
                <ParamsTable
                  rows={[
                    [
                      'Authorization',
                      'Token de identificação referente a autenticação',
                      'String',
                      'Headers',
                      'Sim',
                    ],
                    [
                      'id',
                      'Identificação da assinatura',
                      'String',
                      'Headers',
                      'Sim',
                    ],
                    ['name', 'Nome do cliente', 'String', 'Body', 'Sim'],
                    ['email', 'E-mail do cliente', 'String', 'Body', 'Sim'],
                    [
                      'document',
                      'Documento de indetificação do cliente',
                      'String',
                      'Body',
                      'Sim',
                    ],
                    ['code', 'Código do cliente', 'String', 'Body', 'Sim'],
                  ]}
                />
              </Section>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ButtonBaseComponent
                    previousName="Assinaturas"
                    previousRoute={routes.documentation.subscriptions}
                    nextName="Fatura"
                    nextRoute={routes.documentation.invoice}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={2}>
              <OnPage page={onPage} />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  )
}

export default Customers
