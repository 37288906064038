const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.card.background,
    height: '100%',
    borderRadius: '20px',
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '20px',
    height: '100%',
    minWidth: '12px',
  },
})

export default styles
