const styles = () => ({
  title: {
    color: '#263238',
  },
  menu: {
    color: '#546E7A',
  },
})

export default styles
