import { Container, Page } from 'components'
import { PaymentHeader, ProductDetails, SellerDetails } from './components'

import { useLocation } from 'react-router-dom'

import { reverse } from 'named-urls'
import { routes } from 'Routes'
import { useHistory } from 'react-router-dom'
import useOrder from 'hooks/useOrder'

const PaymentPage = ({ match }) => {
  const type = match.params.type
  const token = match.params.token
  const location = useLocation()
  const history = useHistory()
  const customer = location.state?.customer
  const { data } = useOrder()

  if (customer == null || data?.payment == null) {
    history.push(
      reverse(routes.payments.main, {
        type: type,
        token: token,
      }),
    )
  }

  return (
    <Page title="Pagar" paddingTop={0}>
      <PaymentHeader
        token={token}
        type={type}
        home={true}
        order={data?.payment}
        seller={data?.seller}
        customer={customer}
      />
      <Container
        maxWidth={false}
        spacingXl={60}
        spacingLg={45}
        spacingMd={30}
        spacingSm={15}
      >
        <ProductDetails order={data?.payment} />
        <SellerDetails seller={data?.seller} />
      </Container>
    </Page>
  )
}

export default PaymentPage
