const styles = (theme) => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  pagination: {
    borderRadius: '20px',
  },
  tabs: {
    backgroundColor: theme.palette.success,
    color: theme.palette.white,
    borderRadius: 200,
  },
  header: {
    backgroundColor: '#235078',
    borderRadius: '20px',
  },
  iconBox: {
    borderRadius: '20px',
    backgroundColor: '#e3e9ec',
    margin: theme.spacing(2),
    padding: theme.spacing(4),
    color: '#235078',
  },
  textWhite: {
    color: theme.palette.white,
  },
  backgroundColorWhite: {
    backgroundColor: theme.palette.white,
    borderRadius: '20px',
  },
  selectedCard: {
    backgroundColor: theme.palette.primary.main,
  },
  statusCard: {
    height: theme.spacing(5),
    width: theme.spacing(5),
    borderRadius: theme.spacing(5),
    marginRight: theme.spacing(1),
  },
  inLabel: {
    color: '#4CAF50',
  },
  outLabel: {
    color: '#B71C1C',
  },
  titleCard: {
    fontSize: '15px',
    fontWeight: '500',
  },
  pointer: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
})

export default styles
