import { Grid, makeStyles, Typography, Box } from '@material-ui/core'
import { Container, Page, LoadingFeedback, Header } from 'components'
import {
  DollarSign as DollarSignIcon,
  ArrowDown as ArrowDownIcon,
  Loader as LoaderIcon,
} from 'react-feather'

import styles from './styles'
import { routes } from 'Routes'

import useFetch from 'hooks/useFetch'
import * as service from 'service'
import clsx from 'clsx'

const useStyles = makeStyles(styles)

const SeparateReport = () => {
  const classes = useStyles()

  const { response, isLoading } = useFetch(service.intermediador.orders.stats)

  return (
    <Container maxWidth="md">
      <LoadingFeedback open={isLoading} />
      <Page className={classes.root} title="Relatório de cobrança avulsa">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Header name="Relatório de cobrança avulsa" route={routes.home} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box mt={2}>
              <Typography variant="h6" color="textPrimary">
                Métricas
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box
              p={2}
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              className={classes.boxRoot}
            >
              <Box
                p={2.5}
                className={clsx(classes.iconBox, classes.backgroundColorBlue)}
                display="flex"
                justifyContent="center"
                mb={2}
              >
                <LoaderIcon size={16} color="#235078" />
              </Box>
              <Typography variant="body1" color="textPrimary">
                Cobranças geradas
              </Typography>
              <Typography variant="h6" color="textPrimary">
                {response?.data?.totalOrders}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4}>
            <Box
              p={2}
              className={clsx(classes.boxRoot, classes.boxFullHeight)}
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
            >
              <Box
                p={2.5}
                mb={2}
                className={clsx(classes.iconBox, classes.backgroundColorBlue)}
                display="flex"
                justifyContent="center"
              >
                <LoaderIcon size={16} color="#235078" />
              </Box>
              <Typography variant="body1" color="textPrimary">
                Pagamentos pendentes
              </Typography>
              <Typography variant="h6" color="textPrimary">
                {response?.data?.totalPending}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4}>
            <Box
              p={2}
              className={clsx(classes.boxRoot, classes.boxFullHeight)}
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
            >
              <Box
                p={2.5}
                className={clsx(classes.iconBox, classes.backgroundColorBlue)}
                display="flex"
                justifyContent="center"
                mb={2}
              >
                <ArrowDownIcon size={16} color="#235078" />
              </Box>
              <Typography variant="body1" color="textPrimary">
                Total de vendas/mês (R$)
              </Typography>
              <Typography variant="h6" color="textPrimary">
                {response?.data?.monthSales}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4}>
            <Box
              p={2}
              className={clsx(classes.boxRoot, classes.boxFullHeight)}
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
            >
              <Box
                p={2.5}
                className={classes.iconBox}
                display="flex"
                justifyContent="center"
                mb={2}
              >
                <DollarSignIcon size={16} color="#235078" />
              </Box>
              <Typography variant="body1" color="textPrimary">
                Ticket médio (R$)
              </Typography>
              <Typography variant="h6" color="textPrimary">
                {response?.data?.averageTicket}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Page>
    </Container>
  )
}

export default SeparateReport
