import { Container, Page, Header, AccordionCard } from 'components'
import { PlansForm } from './components'
import { makeStyles, Grid, Typography, Box } from '@material-ui/core'

import { routes } from 'Routes'

import constants from 'constants/index'

import styles from './styles'

const useStyles = makeStyles(styles)

const PlansNew = () => {
  const classes = useStyles()

  return (
    <Page className={classes.root} title="Planos Recorrentes">
      <Container maxWidth="md">
        <Grid container spacing={8}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Header name="Planos recorrentes" route={routes.plans.main} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <PlansForm isEdit={false} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box mt={20}>
              <Typography variant="h6" color="textPrimary">
                Perguntas frequentes
              </Typography>
            </Box>
          </Grid>
          {constants?.faqs?.ITEMS?.charges?.plansNew?.content?.map((value) => (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <AccordionCard
                title={value?.title}
                description={value?.description}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Page>
  )
}

export default PlansNew
