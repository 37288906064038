import api from 'service/api'

const intermediadorAPI = api.create('intermediador')

const get = async ({ subscriptionId }) => {
  return await intermediadorAPI.get(
    `/subscription/invoices?subscription_id=${subscriptionId}`,
  )
}
const invoices = {
  get,
}

export default invoices
