import { Container, Page } from 'components'
import {
  PaymentHeader,
  ProductDetails,
  SellerDetails,
  SuccessPayment,
} from './components'

import { useLocation } from 'react-router-dom'
import { useHistory } from 'react-router-dom'

import { reverse } from 'named-urls'
import { routes } from 'Routes'

import { useState, useEffect } from 'react'
import * as service from 'service'

const CreditCardPaid = ({ match }) => {
  const type = match.params.type
  const token = match.params.token
  const location = useLocation()
  const history = useHistory()
  const customer = location.state?.customer

  const [order, setOrder] = useState()

  if (customer == null) {
    history.push(
      reverse(routes.payments.main, {
        type: type,
        token: token,
      }),
    )
  }

  useEffect(() => {
    const loadOrder = async () => {
      const response = await service.intermediador.payments.show(type, token)
      setOrder(response?.data)
    }
    loadOrder()
    //eslint-disable-next-line
  }, [])

  return (
    <Page title="Pagar" paddingTop={0}>
      <PaymentHeader creditPaid={true} customer={customer} />
      <Container
        maxWidth={false}
        spacingXl={60}
        spacingLg={40}
        spacingMd={25}
        spacingSm={10}
      >
        <SuccessPayment />
        <ProductDetails order={order?.payment} notPaid={false} />
        <SellerDetails seller={order?.seller} />
      </Container>
    </Page>
  )
}

export default CreditCardPaid
