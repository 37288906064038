const styles = (theme) => ({
  root: {
    paddingTop: theme.spacing(3),
  },
  center: {
    textAlign: 'center',
  },
  pagination: {
    borderRadius: '20px',
  },
  headerBox: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  tabs: {
    backgroundColor: theme.palette.success,
    color: theme.palette.white,
    borderRadius: 200,
  },
  circle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    width: 50,
    height: 50,
    [theme.breakpoints.between('xs', 'sm')]: {
      width: 40,
      height: 40,
    },
    zIndex: 1,
  },
})

export default styles
