import { useContext } from 'react'

import OrderContext from 'contexts/OrderContext'

const useOrder = () => {
  const context = useContext(OrderContext)

  if (context === undefined) {
    throw new Error('useOrder must be used within a OrderContext.Provider')
  }

  return {
    data: context.data,
    loadData: context.loadData,
    setData: context.setData,
    isLoading: context.isLoading,
  }
}

export default useOrder
