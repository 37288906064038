import { Grid, Box, Divider } from '@material-ui/core'

import { Page, Container } from 'components'

import {
  Menu,
  OnPage,
  ButtonBaseComponent,
  CodeBox,
  Title,
  Paragraph,
  ParamsTable,
  UrlBox,
} from '../components'

import { routes } from 'Routes'

const Users = () => {
  const createCharge = `
{
    "charge" : {
        "payer_name": "Rafael",
        "payer_email": "rafael.couto@leavening.com",
        "expiration_date": "08/08/2023",
        "code": "1a2b3c",
        "description": "Teste",
        "products": [
            {
                "name": "Action Figure Luffy",
                "price": "1000",
                "quantity": "1"
            },
            {
                "name": "Garrafinha",
                "price": "20.5",
                "quantity": "3"
            }
        ]
    }
}
`
  const paymentCharge = `
{   
    "seller_auth_token": "ad3d9e7cd5f08c24adcf220089923783",
    "order": {
        "code": "12333333",
        "customer": {
            "name": "Teste jucelino",
            "email": "cliente@testes.com",
            "document_type": "CPF",
            "document": "71475176015",
            "birth_date": "01/01/2000",
            "address": {
                "street": "Rua teste",
                "number": "777",
                "neighborhood": "Bairro teste",
                "city": "Testelândia",
                "state": "SP",
                "postal_code": "17580000"
            }
        },
        "payment": {
            "method": 7
        },
        "products": [
            {
                "name": "Produto Teste",
                "description": "Para testes",
                "price": "70,00",
                "quantity": "2"
            },
            {
                "name": "Produto Teste 2",
                "description": "Para testes",
                "price": "30,00",
                "quantity": "3"
            }
        ]
    }
}
`

  return (
    <Page title="Superfin">
      <Container maxWidth="md">
        <Box style={{ marginBottom: '40px' }}>
          <Grid container spacing={8}>
            <Grid item xs={12} sm={12} md={12} lg={2}>
              <Menu />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8}>
              <Box style={{ marginBottom: '40px' }}>
                <Title variant="h5">Pagamentos</Title>
                <Paragraph>
                  A requisição para a criação de uma cobrança é um pedido que o
                  cliente (geralmente uma aplicação ou serviço) envia ao
                  servidor para gerar uma nova cobrança com base nos produtos
                  especificados.
                </Paragraph>
              </Box>
              <Divider />
              <Box style={{ marginTop: '40px', marginBottom: '40px' }}>
                <Title variant="h6">Dados para tela de pagamento</Title>
                <UrlBox
                  type="GET"
                  url="https://api.sandbox.superfin.com.br/payments/invoice/:token"
                  link="https://documenter.getpostman.com/view/11000226/TzRPhoDe#be7bb634-54c9-47ba-b36f-5045e2b0949c"
                />
                <Paragraph>
                  Para consultar uma cobrança em nosso sistema, o usuário deve
                  fornecer apenas o token de autorização.
                </Paragraph>
              </Box>
              <Divider />
              <Box style={{ marginTop: '40px', marginBottom: '40px' }}>
                <Title variant="h6">Realizar Pagamento</Title>
                <UrlBox
                  type="POST"
                  url="https://api.sandbox.superfin.com.br/payments"
                  link="https://documenter.getpostman.com/view/11000226/TzRPhoDe#106292b3-4aa8-425e-b557-2724a228f312"
                />
                <Paragraph>
                  Para realizar pagamentos em nosso sistema o usuário deverá
                  informar dados do cliente que está realizando pagamento, os
                  produtos que estão sendo pagos com valor e quantidade, e
                  também a forma com que este pagamento é feito. Os detalhes de
                  todos os parâmetros que o sistema aceita, tal como suas
                  respectivas descrições estão presentes na tabela abaixo.
                </Paragraph>
                <CodeBox
                  title="Body"
                  code={createCharge}
                  link="https://documenter.getpostman.com/view/11000226/TzRPhoDe#106292b3-4aa8-425e-b557-2724a228f312"
                />
                <ParamsTable
                  rows={[
                    [
                      'seller_auth_token',
                      'Token do vendedor',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[code]',
                      'Identificador externo do pedido',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[customer]',
                      'Cliente que está realizando o pagamento',
                      'object',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[customer][name]',
                      'Nome do cliente que está realizando o pagamento',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[customer][email]',
                      'E-mail do cliente que está realizando o pagamento',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[customer][document]',
                      'Documento do cliente que está realizando o pagamento(Sem pontuação)',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[customer][document_type]',
                      'Tipo de documento do cliente que está realizando o pagamento(CPF ou CNPJ)',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[customer][birth_date]',
                      'Data de nascimento do cliente que está realizando o pagamento (ex: 01/01/2000)',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[customer][one_id_token]',
                      'Token do cliente no OneId',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[customer][address]',
                      'Endereço do cliente',
                      'object',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[customer][address][street]',
                      'Rua onde o cliente reside',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[customer][address][number]',
                      'Número da residência onde o cliente reside',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[customer][address][neighborhood]',
                      'Bairro onde o cliente reside',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[customer][address][city]',
                      'Cidade onde o cliente reside',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[customer][address][state]',
                      'Estado onde o cliente reside (ex: SP)',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[customer][address][postal_code]',
                      'CEP do cliente ex(17580000)',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[payment]',
                      'Dados sobre o pagamento do pedido',
                      'object',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[payment][method]',
                      'ID do método de pagamento**(Tabela abaixo)**',
                      'integer',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[payment][split_number]',
                      'Número de parcelas(Somente para cartão de cŕedito)',
                      'integer',
                      'Body',
                      'Não',
                    ],
                    [
                      'order[payment][card]',
                      'Dados do cartão de crédito utilizado no pagamento',
                      'object',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[payment][card][name]',
                      'Nome impresso no cartão',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[payment][card][number]',
                      'Número do cartão',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[payment][card][cvv]',
                      'Código de segurança',
                      'integer',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[payment][card][expiration]',
                      'Vencimento do cartão (ex: 12/22)',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[payment][billet]',
                      'Dados do boleto utilizado no pagamento',
                      'object',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[payment][billet][description]',
                      'Texto impresso no corpo do boleto',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[payment][billet][expiration]',
                      'Data de vencimento do boleto',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[products]',
                      'Produtos a serem pagos',
                      'array',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[products][name]',
                      'Nome do produto',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[products][description]',
                      'Descrição do produto',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[products][price]',
                      'Preço unitário do produto',
                      'float',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[products][quantity]',
                      'Quantidade do produto',
                      'integer',
                      'Body',
                      'Sim',
                    ],
                  ]}
                />
                <Title variant="h6">Métodos de pagamento</Title>
                <Paragraph>
                  Nosso sistema permite que pagamentos sejam realizados de
                  diversas formas, é importante ressaltar que ao escolher uma
                  forma especifica, é obrigatório mandar os dados de pagamento
                  da mesma, por exemplo, ao escolher cartão é obrigatório mandar
                  os dados do cartão de crédito, a tabela abaixo especifica as
                  formas de pagamentos aceitas em nosso sistema.
                </Paragraph>
                <ParamsTable
                  rows={[
                    [
                      'seller_auth_token',
                      'Token do vendedor',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[code]',
                      'Identificador externo do pedido',
                      'string',
                      'Body',
                      'Sim',
                    ],
                  ]}
                />
                <Title variant="h6">Informando os produtos </Title>
                <Paragraph>
                  É possível enviar 1 ou mais produtos para o pagamento, é
                  importante ressaltar que mesmo que apenas 1 produto seja
                  enviado nós recebemos um array de produtos, ou seja será
                  necessário enviar um array com um único item.
                </Paragraph>
                <Title variant="h6">Parcelamento de pagamentos</Title>
                <Paragraph>
                  Pagamentos com cartão de crédito permitem o parcelamento do
                  valor, para parcelar o pagamento basta enviar o parâmetro
                  order[payment][split_number] com o número de parcelas que você
                  deseja, caso este parâmetro não seja enviado o sistema
                  entenderá que o pagamento será feita em uma única parcela.
                </Paragraph>
                <Title variant="h6">Recuperação de transação</Title>
                <Paragraph>
                  Caso seja enviado o code de uma transação já existente o
                  sistema irá tentar recuperar esta transação, caso os
                  parâmetros de cliente e produto sejam enviados diferentes um
                  erro será retornado, caso recuperado outro pagamento será
                  criado para a transação pré existente, a menos que está
                  transação esteja Paga, Cancelada ou Aguardando confirmação.{' '}
                </Paragraph>
                <CodeBox
                  title="Body"
                  code={paymentCharge}
                  link="https://documenter.getpostman.com/view/11000226/TzRPhoDe#106292b3-4aa8-425e-b557-2724a228f312"
                />
              </Box>
              <Box style={{ marginTop: '40px', marginBottom: '40px' }}>
                <Title variant="h6">Pagar Fatura</Title>
                <UrlBox
                  type="POST"
                  url="https://api.sandbox.superfin.com.br/payments/pay"
                  link="https://documenter.getpostman.com/view/11000226/TzRPhoDe#06c7099f-732d-4f1b-be07-e8d245240e31"
                />
                <Paragraph>
                  Para realizar pagamentosde fatura em nosso sistema o usuário
                  deverá informar dados do cliente que está realizando
                  pagamento, o tipo da transação(invoice) o token(id da fatura),
                  e também a forma com que este pagamento é feito. Os detalhes
                  de todos os parâmetros que o sistema aceita, tal como suas
                  respectivas descrições estão presentes na tabela abaixo.
                </Paragraph>
                <ParamsTable
                  rows={[
                    [
                      'seller_auth_token',
                      'Token do vendedor',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[code]',
                      'Identificador externo do pedido',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[customer]',
                      'Cliente que está realizando o pagamento',
                      'object',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[customer][name]',
                      'Nome do cliente que está realizando o pagamento',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[customer][email]',
                      'E-mail do cliente que está realizando o pagamento',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[customer][document]',
                      'Documento do cliente que está realizando o pagamento(Sem pontuação)',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[customer][document_type]',
                      'Tipo de documento do cliente que está realizando o pagamento(CPF ou CNPJ)',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[customer][birth_date]',
                      'Data de nascimento do cliente que está realizando o pagamento (ex: 01/01/2000)',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[customer][one_id_token]',
                      'Token do cliente no OneId',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[customer][address]',
                      'Endereço do cliente',
                      'object',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[customer][address][street]',
                      'Rua onde o cliente reside',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[customer][address][number]',
                      'Número da residência onde o cliente reside',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[customer][address][neighborhood]',
                      'Bairro onde o cliente reside',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[customer][address][city]',
                      'Cidade onde o cliente reside',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[customer][address][state]',
                      'Estado onde o cliente reside (ex: SP)',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[customer][address][postal_code]',
                      'CEP do cliente ex(17580000)',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[payment]',
                      'Dados sobre o pagamento do pedido',
                      'object',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[payment][method]',
                      'ID do método de pagamento**(Tabela abaixo)**',
                      'integer',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[payment][split_number]',
                      'Número de parcelas(Somente para cartão de cŕedito)',
                      'integer',
                      'Body',
                      'Não',
                    ],
                    [
                      'order[payment][card]',
                      'Dados do cartão de crédito utilizado no pagamento',
                      'object',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[payment][card][name]',
                      'Nome impresso no cartão',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[payment][card][number]',
                      'Número do cartão',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[payment][card][cvv]',
                      'Código de segurança',
                      'integer',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[payment][card][expiration]',
                      'Vencimento do cartão (ex: 12/22)',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[payment][billet]',
                      'Dados do boleto utilizado no pagamento',
                      'object',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[payment][billet][description]',
                      'Texto impresso no corpo do boleto',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[payment][billet][expiration]',
                      'Data de vencimento do boleto',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[products]',
                      'Produtos a serem pagos',
                      'array',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[products][name]',
                      'Nome do produto',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[products][description]',
                      'Descrição do produto',
                      'string',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[products][price]',
                      'Preço unitário do produto',
                      'float',
                      'Body',
                      'Sim',
                    ],
                    [
                      'order[products][quantity]',
                      'Quantidade do produto',
                      'integer',
                      'Body',
                      'Sim',
                    ],
                  ]}
                />
              </Box>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ButtonBaseComponent
                    nextName="Pagamento"
                    previousName="Cobrança avulsa"
                    previousRoute={routes.documentation.oneTimeCharge}
                    nextRoute={routes.documentation.payment}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={2}>
              <OnPage />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  )
}

export default Users
