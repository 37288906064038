function invertHSVValue(hsvColor) {
  // Extrair os componentes HSV da string
  const match = hsvColor.match(/HSV\((\d+), (\d+)%, (\d+)%\)/)

  if (!match) {
    // Se a string não estiver no formato esperado, retornar null
    return null
  }

  // Extrair os componentes HSV da correspondência
  const [, h, s, v] = match.map(Number)

  // Inverter a luminosidade (Valor, V)
  const invertedV = 100 - v

  // Retornar a nova cor no formato HSV como uma string
  return `HSV(${h}, ${s}%, ${invertedV}%)`
}

export default invertHSVValue
