const styles = (checkoutTheme) => ({
  barCode: {
    backgroundColor: checkoutTheme.palette.background.paper,
    borderRadius: checkoutTheme.spacing(10),
  },
  button: {
    borderRadius: checkoutTheme.spacing(10),
    backgroundColor: checkoutTheme.palette.primary.main,
  },
  blueText: {
    color: checkoutTheme.palette.text.primary,
    fontWeight: 400,
  },
  lightBlueText: {
    color: checkoutTheme.palette.text.secondary,
    fontWeight: 600,
  },
})

export default styles
