const styles = (theme) => ({
  boxButton: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '20px',
    width: '100%',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  iconBox: {
    backgroundColor: '#235078',
    borderRadius: '20px',
  },
  pagination: {
    borderRadius: '20px',
  },
  center: {
    textAlign: 'center',
  },
})

export default styles
