import theme from 'theme'
import {
  Send as SendIcon,
  Check as CheckIcon,
  Loader as LoaderIcon,
  Cpu as CpuIcon,
} from 'react-feather'

const iconByStatus = (status) => {
  switch (status) {
    case 'PAID':
      return {
        icon: <CheckIcon style={{ color: theme.palette.white }} size={16} />,
        color: theme.palette.success.main,
      }
    case 'WAITING_PAYMENT':
      return {
        icon: <LoaderIcon style={{ color: theme.palette.white }} size={16} />,
        color: theme.palette.info.main,
      }
    case 'WAITING_CONFIRM':
      return {
        icon: <CpuIcon style={{ color: theme.palette.white }} size={16} />,
        color: '#44be9d',
      }
    default:
      return {
        icon: <SendIcon style={{ color: theme.palette.white }} size={16} />,
        color: theme.palette.secondary.main,
      }
  }
}

export default iconByStatus
