import { useState, useEffect } from 'react'

import { makeStyles } from '@material-ui/core'

import styles from './styles'

const useStyles = makeStyles(styles)

const AnimatedBox = (div) => {
  const classes = useStyles()
  const [boxes, setBoxes] = useState(div.div)

  useEffect(() => {
    const moveBoxes = () => {
      const newBoxes = boxes.map((box) => ({
        ...box,
        top: Math.random() * (window.innerHeight - 300),
        left: Math.random() * (window.innerWidth - 300),
      }))
      setBoxes(newBoxes)
    }

    const intervalId = setInterval(moveBoxes, 3000)
    return () => {
      clearInterval(intervalId)
    }
  }, [boxes])

  return (
    <>
      {boxes &&
        boxes.map((box) => (
          <div
            key={box.id}
            className={classes.boxStyled}
            style={{
              position: 'absolute',
              transform: `translate(${box.left}px, ${box.top}px)`,
            }}
          ></div>
        ))}
    </>
  )
}

export default AnimatedBox
