import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  makeStyles,
  withStyles,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
} from '@material-ui/core'

import clsx from 'clsx'

import {
  Send as SendIcon,
  Check as CheckIcon,
  Cpu as CpuIcon,
  Loader as LoaderIcon,
} from 'react-feather'

import styles from './styles'
import theme from 'theme'

const useStyles = makeStyles(styles)

const StepperOrder = ({ orderStatus, alternativeLabel = true }) => {
  const [activeStep, setActiveStep] = useState(0)
  const steps = getSteps()

  const ColorlibConnector = withStyles({
    alternativeLabel: {
      top: 22,
    },
    active: {
      '& $line': {
        backgroundColor: theme.palette.success.main,
      },
    },
    completed: {
      '& $line': {
        // backgroundColor: '#235078',
        backgroundColor: theme.palette.success.main,
      },
    },
    line: {
      height: 3,
      border: 0,
      backgroundColor: '#e2f4f9',
      borderRadius: 1,
    },
  })(StepConnector)

  function setColor(active, completed) {
    if (active === true && completed === false) {
      return theme.palette.additional.superLight
    }
    if (active === false && completed === true) {
      return theme.palette.additional.superLight
    } else {
      return theme.palette.additional.light
    }
  }

  function ColorlibStepIcon(props) {
    const classes = useStyles()
    const { active, completed } = props

    const icons = {
      // 1: <SendIcon color="#63bbdb" />,
      // 2: <LoaderIcon color="#63bbdb" />,
      // 3: <CpuIcon color="#63bbdb" />,
      // 4: <CheckIcon color="#63bbdb" />,
      1: <SendIcon color={setColor(active, completed)} />,
      2: <LoaderIcon color={setColor(active, completed)} />,
      3: <CpuIcon color={setColor(active, completed)} />,
      4: <CheckIcon color={setColor(active, completed)} />,
    }
    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        {icons[String(props.icon)]}
      </div>
    )
  }

  ColorlibStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
    icon: PropTypes.node,
  }

  function getSteps() {
    return ['Enviado', 'Em andamento', 'Aguardando Confirmação', 'Você recebeu']
  }

  useEffect(() => {
    switch (orderStatus) {
      case 'NOT_PAID':
        setActiveStep(0)
        break
      case 'WAITING_PAYMENT':
        setActiveStep(1)
        break
      case 'WAITING_CONFIRM':
        setActiveStep(2)
        break
      case 'PAID':
        setActiveStep(4)
        break
      default:
        setActiveStep(0)
    }
  }, [orderStatus])

  return (
    <Stepper
      alternativeLabel
      activeStep={activeStep}
      connector={<ColorlibConnector />}
      style={{ backgroundColor: 'transparent' }}
    >
      {steps.map((label) => (
        <Step key={label}>
          {alternativeLabel ? (
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          ) : (
            <StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
          )}
        </Step>
      ))}
    </Stepper>
  )
}

export default StepperOrder
