import { useEffect, useState } from 'react'

import { Container, Page, Filters, FilterButton, Header } from 'components'
import { Box, makeStyles, Grid, Button } from '@material-ui/core'

import useFetch from 'hooks/useFetch'
import useFilter from 'hooks/useFilter'

import styles from './styles'
import * as service from 'service'
import {
  BankAccountsDetail,
  BankAccountsTable,
  DialogBankAccountCreation,
} from './components'

import { routes } from 'Routes'

const useStyles = makeStyles(styles)

const BankAccountsMain = () => {
  const [bankAccountActive, setBankAccountActive] = useState()
  const [openCreationDialog, setOpenCreationDialog] = useState(false)
  const classes = useStyles()
  const filter = useFilter()

  const { response, isLoading, refresh } = useFetch(
    service.intermediador.bankAccounts.get,
    { ...{ ...filter.filters }, page: 1, perPage: 1000 },
    [1, 1000, filter.filters],
  )

  useEffect(() => {
    setBankAccountActive(response?.data?.bankAccounts?.find(findActive))
  }, [response])

  const findActive = (value) => {
    if (value.status === 'ACTIVE') {
      return true
    }
    return false
  }

  return (
    <Page className={classes.root} title="Conta bancária">
      <Container maxWidth="md">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Header name="Conta bancária" route={routes.settings.main} />
          </Grid>
          <Grid container spacing={3}>
            <Grid item lg={9}>
              <BankAccountsDetail bankAccount={bankAccountActive} />
            </Grid>
            <Grid item lg={12} xs={12}>
              <Box display="flex" justifyContent="end">
                <Box ml={2}>
                  <FilterButton setDrawerOpen={filter.setDrawerOpen} />
                </Box>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setOpenCreationDialog(true)}
                >
                  Nova conta
                </Button>
              </Box>
              <BankAccountsTable
                isLoading={isLoading}
                bankAccounts={response?.data?.bankAccounts}
                bankAccountActive={bankAccountActive}
                setBankAccountActive={setBankAccountActive}
              />
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <DialogBankAccountCreation
        open={openCreationDialog}
        refresh={refresh}
        setOpen={setOpenCreationDialog}
      />
      <Filters filter={filter}>
        <input textfieldinput="true" label="Documento" name="document" />
      </Filters>
    </Page>
  )
}

export default BankAccountsMain
