import { Box, makeStyles, Typography, Button } from '@material-ui/core'
import styles from './styles'
import { Download as DownloadIcon } from 'react-feather'

import checkoutTheme from 'theme/checkoutTheme'

const useStyles = makeStyles(styles)

const BilletInfo = ({ billet }) => {
  const classes = useStyles()

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="center"
        py={checkoutTheme.spacing(4)}
        mb={checkoutTheme.spacing(10)}
        mt={checkoutTheme.spacing(10)}
        className={classes.barCode}
      >
        <Typography variant="body1" color="textPrimary">
          {billet?.digitableLine}
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        width="100%"
        mb={checkoutTheme.spacing(20)}
      >
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={() => {
            window.open(billet.url, '_blank')
          }}
        >
          <DownloadIcon size={checkoutTheme.spacing(10)} />
          <Box>Baixar Boleto</Box>
        </Button>
        <Box ml={checkoutTheme.spacing(10)}>
          <Button
            variant="outlined"
            color="primary"
            style={{ borderRadius: checkoutTheme.spacing(20) }}
            onClick={() => {
              navigator.clipboard.writeText(billet?.digitableLine)
            }}
          >
            Copiar código
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default BilletInfo
