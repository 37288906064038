import { Box, makeStyles, Grid, Typography } from '@material-ui/core'

import { LoadingFeedback, LabelView } from 'components'

import styles from './styles'
import helpers from 'helpers'

const useStyles = makeStyles(styles)

const SubscriptionInfos = ({ isLoading, subscription }) => {
  const classes = useStyles()

  const documentLabel = (document) => {
    if (helpers.formatters.document(document).length === 14) {
      return 'CPF'
    }
    return 'CNPJ'
  }

  return (
    <>
      <LoadingFeedback open={isLoading} />
      <Box mt={4}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box display="flex" flexDirection="column">
              <Typography color="textPrimary" variant="body1">
                Plano
              </Typography>
              <Typography color="textPrimary" variant="body2">
                {subscription?.subscriptionPlans?.[0]?.plan?.name}
              </Typography>
              <Box ml={1}></Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box display="flex" flexDirection="column">
              <Typography color="textPrimary" variant="body1">
                Valor
              </Typography>
              <Typography color="textPrimary" variant="body2">
                {helpers.formatters.floatToMoney(
                  subscription?.subscriptionPlans?.[0]?.plan?.price,
                  true,
                )}
              </Typography>
              <Box ml={1}></Box>
            </Box>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box className={classes.infoBox}>
              <Box mb={3}>
                <Typography color="textPrimary" variant="h6">
                  Informações pessoais do assinante
                </Typography>
              </Box>
              {subscription?.customer && (
                <>
                  <Box>
                    <LabelView
                      label="Nome completo"
                      value={subscription?.customer?.name}
                    />
                    <LabelView
                      label={documentLabel(subscription?.customer?.document)}
                      value={helpers.formatters.document(
                        subscription?.customer?.document,
                      )}
                    />
                    <LabelView
                      label="E-mail"
                      value={subscription?.customer?.email}
                    />
                  </Box>
                </>
              )}
              <Box mb={3} mt={5}>
                <Typography color="textPrimary" variant="h6">
                  Informações da assinatura
                </Typography>
              </Box>

              <Box>
                <LabelView label="Código" value={subscription?.code} />
                <LabelView
                  label="Status"
                  value={helpers.subscriptions.translateStatus(
                    subscription?.status,
                  )}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default SubscriptionInfos
