import {
  TableContainer,
  makeStyles,
  Box,
  useMediaQuery,
  TablePagination,
  Typography,
  Tooltip,
} from '@material-ui/core'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'
import { Filters } from 'components'

import PerfectScrollbar from 'react-perfect-scrollbar'
import {
  Check as CheckIcon,
  Loader as LoaderIcon,
  X as XIcon,
  Cpu as CpuIcon,
} from 'react-feather'
import helpers from 'helpers'
import styles from './styles'
import clsx from 'clsx'
import * as service from 'service'
import theme from 'theme'

import useFilter from 'hooks/useFilter'
import useFetch from 'hooks/useFetch'
import usePagination from 'hooks/usePagination'

const useStyles = makeStyles(styles)

const WithdrawsTable = () => {
  const classes = useStyles()
  const filter = useFilter()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const { page, perPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination(10)

  const { response, isLoading } = useFetch(
    service.intermediador.withdraws.get,
    { ...{ ...filter.filters }, page, perPage },
    [page, perPage, filter.filters],
  )

  return (
    <>
      <Typography
        variant="h6"
        style={{ marginTop: '20px' }}
        className={classes.colorBlue}
      >
        Solicitações de saque
      </Typography>
      <PerfectScrollbar>
        <TableContainer>
          <Table
            isLoading={isLoading}
            emptyMessage="Nenhuma solicitação de saque encontrada"
            size="medium"
            noWrap
            className={classes.table}
          >
            <TableHead>
              <TableRow>
                <TableCell width="5%"></TableCell>
                <TableCell width="45%">Valor (R$)</TableCell>
                <TableCell width="50%">Data</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {response?.data?.withdraws?.map((withdraw) => (
                <TableRow key={withdraw.id}>
                  <TableCell>
                    <Box
                      p={2}
                      mr={2}
                      display="flex"
                      alignItems="center"
                      className={clsx(classes.icon, {
                        [classes.processed]: withdraw.status === 'processed',
                        [classes.pending]: withdraw.status === 'pending',
                        [classes.canceled]: withdraw.status === 'canceled',
                        [classes.processing]: withdraw.status === 'processing',
                      })}
                    >
                      <Tooltip
                        title={helpers.withdraws.statusTooltip(
                          withdraw?.status?.toUpperCase(),
                        )}
                      >
                        {withdraw.status === 'processed' ? (
                          <CheckIcon />
                        ) : withdraw.status === 'pending' ? (
                          <LoaderIcon />
                        ) : withdraw.status === 'canceled' ? (
                          <XIcon />
                        ) : (
                          <CpuIcon />
                        )}
                      </Tooltip>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      {helpers.formatters.floatToMoney(withdraw?.amount, false)}
                    </Box>
                  </TableCell>
                  <TableCell>
                    {helpers.formatters.dateTime(withdraw?.createdAt)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </PerfectScrollbar>
      <Box className={classes.pagination}>
        <TablePagination
          component="div"
          count={response?.data?.meta?.totalCount || 0}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          rowsPerPage={perPage}
          rowsPerPageOptions={[5, 10, 25, 100]}
          labelRowsPerPage={isDesktop ? 'Por página' : ''}
          nextIconButtonProps={{ size: 'small' }}
          backIconButtonProps={{ size: 'small' }}
        />
      </Box>
      <Filters filter={filter}>
        <input textfieldinput="true" label="Valor" name="price" />
        <input
          datepickerinput="true"
          label="Data de criação inicial"
          name="createdAtGt"
          disableFuture
          data-target="createdAtLt"
          data-min
        />
        <input
          datepickerinput="true"
          label="Data de criação final"
          name="createdAtLt"
          disableFuture
          data-target="createdAtGt"
          data-max
        />
      </Filters>
    </>
  )
}

export default WithdrawsTable
