const styles = (theme) => ({
  bg: {
    position: 'fixed',
    height: '20px', // Alteração na altura para 20px
    width: '100%',
    backgroundColor: 'red',
    zIndex: 2000,
    transition: 'height 3s ease', // Adição de uma transição de 3 segundos para a altura
  },
  logo: {
    width: '60px',
    height: 'auto',
    position: 'fixed',
    left: 0,
    top: 0,
    transition: 'all 1s ease',
    transform: 'translate(calc(50vw - 70px), calc(50vh - 36px)) scale(1.5)',
    [theme.breakpoints.down('sm')]: {
      width: 90,
      height: 25,
      transform: 'translate(calc(50vw - 45px), calc(50vh - 12px)) scale(1.5)',
    },
  },
})

export default styles
