import auth from './auth'
import users from './users'
import orders from './orders'
import charges from './charges'
import bankAccounts from './bankAccounts'
import balanceControls from './balanceControls'
import accounts from './accounts'
import accountUrls from './accountUrls'
import accountStatements from './accountStatements'
import payments from './payments'
import withdraws from './withdraws'
import plans from './plans'
import subscriptions from './subscriptions'
import invoices from './invoices'
import accountCustomizations from './accountCustomizations'
import generateReport from './generateReport'

const intermediador = {
  auth,
  users,
  orders,
  charges,
  bankAccounts,
  balanceControls,
  accounts,
  accountUrls,
  payments,
  withdraws,
  plans,
  accountStatements,
  subscriptions,
  invoices,
  accountCustomizations,
  generateReport,
}

export default intermediador
