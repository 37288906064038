import { Typography, Grid, Breadcrumbs, Link, Box } from '@material-ui/core'

import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from 'react-feather'

import { Page, Container, AccordionCard } from 'components'

const Api = () => {
  const Code = ({ children }) => {
    return (
      <span
        style={{
          backgroundColor: '#e2f4f9',
          border: '1px solid #63bbdb',
          textTransform: 'lowercase',
          padding: '1px',
          fontSize: '12px',
          fontWeight: 400,
          width: 'auto',
          borderRadius: '4px',
        }}
      >
        {children}
      </span>
    )
  }

  const menu = {
    menu: [
      '<a href="#d-introduction">Introdução</a>',
      '<a href="#l11">APIs</a>',
      '<a href="#l12">Respostas HTTP</a>',
      '<a href="#l13">Sandbox</a>',
      '<a href="#l14">Produção</a>',
      '<a href="#l15">FAQ</a>',
    ],
    onPage: {
      introduction: [
        '<a href="#d-introduction">Introdução</a>',
        '<a href="#d-start">Primeiros passos</a>',
        '<a href="#d-autentication">Autenticação</a>',
        'Superfin FAQ',
      ],
      apis: ['Autenticação'],
    },
  }

  const data = [
    {
      title: '<div id="d-introduction">Introdução</div>',
      description:
        'Nesta documentação você encontra informações necessárias para intergrar com as nossas APIs. Aqui são disponibilizados APIs para sandbox e produção',
      content: [
        '<code>Olá mundoOlá mundo xxxxxxxxxxxxxxx</code>',
        'dwadwniwwwwwwaadwadwdddddddddadwdadadadadawda',
      ],
    },
    {
      title: '<div id="d-start">Primeiros passos</div>',
      description:
        '<b>Aqui você pode começar a integração selecionando qual APIs que você quer implementar</b>',
      content: [
        'Sandbox',
        'Produção',
        'O ambiente sandbox é para implementações e teste, enquanto em produção para implementação de comercialização.',
      ],
    },
    {
      title: '<div id="d-autentication">Autenticação</div>',
      description:
        '<b>Aqui você pode começar a integração selecionando qual APIs que você quer implementar</b>',
      content: [
        <Code>Olá mundo, olaaaaaaaaaaaa</Code>,
        'Produção',
        'O ambiente sandbox é para implementações e teste, enquanto em produção para implementação de comercialização.',
      ],
    },
  ]

  return (
    <Page title="Superfin">
      <Container maxWidth="md">
        <Box style={{ marginBottom: '40px' }}>
          <Grid container style={{ height: '100%' }} spacing={8}>
            <Grid item xs={12} sm={12} md={12} lg={2}>
              {menu.menu.map((item) => (
                <Typography variant="body2" color="textPrimary">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item,
                    }}
                  />
                </Typography>
              ))}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" href="/">
                  Material-UI
                </Link>
                <Link color="inherit" href="/getting-started/installation/">
                  Core
                </Link>
                <Typography color="textPrimary">Breadcrumb</Typography>
              </Breadcrumbs>
              {/* <Typography variant="body2" color="textPrimary">
              Testtando a tag code com <Code>/Olá mundo</Code>
            </Typography> */}

              {data.map((value, index) => (
                <div
                  key={index}
                  style={{ marginBottom: '40px', marginTop: '40px' }}
                >
                  <Typography variant="h5" color="textPrimary">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: value.title,
                      }}
                    />
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textPrimary"
                    style={{ lineHeight: '1.8em' }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: value.description,
                      }}
                    />
                  </Typography>
                  {value.content.map((item, idx) => (
                    <Typography
                      key={idx}
                      variant="body2"
                      color="textPrimary"
                      style={{ lineHeight: '1.8em' }}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item,
                        }}
                      />
                    </Typography>
                  ))}
                </div>
              ))}
              <Typography variant="h5" color="textPrimary">
                FAQ
              </Typography>
              <AccordionCard title="logico" description="teste" />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={2}>
              <Typography
                variant="body1"
                color="textPrimary"
                style={{ fontWeight: 'bold', marginBottom: '8px' }}
              >
                Nesta página
              </Typography>
              {menu.onPage.introduction.map((item) => (
                <Typography
                  variant="body2"
                  color="textPrimary"
                  style={{ marginBottom: '8px' }}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item,
                    }}
                  />
                </Typography>
              ))}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={2}>
              <Box>
                <Typography variant="body2" color="textPrimary">
                  Anterior
                </Typography>
                <Box>
                  <Typography variant="body2" color="textPrimary">
                    APIs
                  </Typography>
                  <ChevronLeftIcon />
                </Box>
              </Box>
              <Box>
                <Typography variant="body2" color="textPrimary">
                  Próximo
                </Typography>
                <Box>
                  <Typography variant="body2" color="textPrimary">
                    APIs
                  </Typography>
                  <ChevronRightIcon />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  )
}

export default Api
