import { useMemo } from 'react'

import {
  Grid,
  Typography,
  Box,
  makeStyles,
  useMediaQuery,
  TablePagination,
} from '@material-ui/core'
import { LoadingFeedback, FilterRow, Filters, ListCard } from 'components'

import { useState } from 'react'
import useFetch from 'hooks/useFetch'
import useFilter from 'hooks/useFilter'
import usePagination from 'hooks/usePagination'
import helpers from 'helpers'

import styles from './styles'
import theme from 'theme'
import * as service from 'service'

import { routes } from 'Routes'
import { reverse } from 'named-urls'

const useStyles = makeStyles(styles)

const ChargesList = () => {
  const [listingType, setListingType] = useState('cards')
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const filter = useFilter()
  const classes = useStyles()

  const { page, perPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination(10)

  const { response, isLoading } = useFetch(
    service.intermediador.charges.get,
    { ...{ ...filter.filters }, page, perPage },
    [page, perPage, filter.filters],
  )

  const chargesByDate = useMemo(() => {
    const chargesMap = new Map()
    response?.data?.charges.forEach((charge) => {
      const dataKey = helpers.formatters.date(charge?.createdAt)
      if (!chargesMap.has(dataKey)) {
        chargesMap.set(dataKey, [])
      }
      chargesMap.get(dataKey).push(charge)
    })
    return chargesMap
  }, [response?.data?.charges])

  return (
    <>
      <Grid container spacing={3}>
        <LoadingFeedback open={isLoading} />
        <FilterRow
          filter={filter}
          listingType={listingType}
          setListingType={setListingType}
          searchPlaceholder={'Pesquisar pagador'}
          searchField={'payerName'}
        />
        <Grid item lg={12} xs={12}>
          {response &&
            Array.from(chargesByDate).map(([data, charges, index]) => (
              <Box key={charges[0]?.id} className={classes.container}>
                <Box mt={2} mb={2} className={classes.center}>
                  <Typography variant="body1" color="textPrimary">
                    {data}
                  </Typography>
                </Box>
                {charges?.map((charge) => (
                  <>
                    <ListCard
                      key={charge?.id}
                      iconByStatus={helpers.orders.iconByStatus(charge?.status)}
                      title={helpers.orders.operationLabel(charge?.status)}
                      subtitle={charge?.payerName || 'A definir'}
                      value={helpers.orders.valueLabel(charge?.price)}
                      hour={
                        helpers.formatters
                          .dateTime(charge?.createdAt)
                          .split('-')[1]
                      }
                      route={reverse(routes?.orders?.show, {
                        orderId: charge?.orderId,
                      })}
                    />
                  </>
                ))}
              </Box>
            ))}
        </Grid>
        <Grid item lg={12} xs={12}>
          <Box className={classes.pagination}>
            <TablePagination
              component="div"
              count={response?.data?.meta?.totalCount || 0}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page - 1}
              rowsPerPage={perPage}
              rowsPerPageOptions={[5, 10, 25, 100]}
              labelRowsPerPage={isDesktop ? 'Por página' : ''}
              nextIconButtonProps={{ size: 'small' }}
              backIconButtonProps={{ size: 'small' }}
            />
          </Box>
        </Grid>
        <Filters filter={filter}>
          <input textfieldinput="true" label="Valor" name="price" />
          <input textfieldinput="true" label="Pagador" name="payerName" />
          <input
            datepickerinput="true"
            label="Data de criação inicial"
            name="createdAtGt"
            disableFuture
            data-target="createdAtLt"
            data-min
          />
          <input
            datepickerinput="true"
            label="Data de criação final"
            name="createdAtLt"
            disableFuture
            data-target="createdAtGt"
            data-max
          />
          <input
            datepickerinput="true"
            label="Data de expiração inicial"
            name="expirationDateGt"
            data-target="expirationDateLt"
            data-min
          />
          <input
            datepickerinput="true"
            label="Data de expiração final"
            name="expirationDateLt"
            data-target="expirationDateGt"
            data-max
          />
        </Filters>
      </Grid>
    </>
  )
}

export default ChargesList
