import NumberFormat from 'react-number-format'

const MoneyInput = ({ inputRef, onChange, className, mode, value, onBlur }) => {
  return (
    <NumberFormat
      defaultValue={value}
      mode={mode}
      className={className}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange(values.value)
      }}
      onBlur={onBlur}
      prefix="R$ "
      decimalScale={2}
      fixedDecimalScale
      decimalSeparator=","
      allowNegative={false}
      thousandSeparator="."
      isNumericString
    />
  )
}

export default MoneyInput
