const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  iconBox: {
    width: '60px',
    height: '60px',
    backgroundColor: '#235078',
    borderRadius: theme.spacing(2),
    color: theme.palette.white,
  },
  infoText: {
    fontSize: '17px',
    fontWeight: '500',
    color: theme.palette.text.primary,
  },
})

export default styles
