import { Box } from '@material-ui/core'
import { useState, useEffect } from 'react'
import PixCode from '../PixCode'
import PixPaid from '../PixPaid'

const PixInfo = ({ pix, order }) => {
  const [status, setStatus] = useState('notPaid')

  useEffect(() => {
    if (order?.payment?.status === 'PAID') {
      setStatus('paid')
    } else {
      setStatus('notPaid')
    }
  }, [order])

  return (
    <Box mt={4}>
      {status === 'notPaid' && <PixCode pix={pix} />}
      {status === 'paid' && <PixPaid />}
    </Box>
  )
}

export default PixInfo
