import { Container, Page, Header } from 'components'
import { Grid, Box, Button } from '@material-ui/core'

import { ChargesList } from './components/index'
import { routes } from 'Routes'

import { useHistory } from 'react-router-dom'

const ChargesMain = ({ match }) => {
  const history = useHistory()

  return (
    <Page title="Cobrança">
      <Container maxWidth="sm">
        <Grid container spacing={8}>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <Header name="Cobrança avulsa" route={routes.home} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <Box
              display="flex"
              justifyContent="end"
              alignItems="center"
              style={{ height: '100%' }}
            >
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={() => {
                  history.push(routes.charges.new)
                }}
              >
                Nova cobrança avulsa
              </Button>
            </Box>
          </Grid>
          <ChargesList />
        </Grid>
      </Container>
    </Page>
  )
}

export default ChargesMain
