import { Typography } from '@material-ui/core'
import helpers from 'helpers'

const valueLabel = (amount) => {
  return (
    <Typography
      variant="body1"
      style={{
        color: '#235078',
        fontWeight: 'bold',
      }}
    >
      {helpers.formatters.floatToMoney(amount, false)} R$
    </Typography>
  )
}

export default valueLabel
