const styles = (theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  popover: {
    width: '400px',
  },
})

export default styles
