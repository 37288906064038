const styles = (theme) => ({
  root: {
    width: 320,
    padding: '20px',
  },
  filterField: {
    borderRadius: 20,
  },
})

export default styles
