import * as yup from 'yup'

export const schema = yup.object().shape({
  document: yup.string().max(18).min(14).required(),
  birthDate: yup.date().required(),
  street: yup.string().required(),
  number: yup.string().required(),
  neighborhood: yup.string().required(),
  city: yup.string().required(),
  state: yup.string().required(),
  postalCode: yup.string().required(),
})

export default schema
