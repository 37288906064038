import constants from 'constants/index'

const orderStatusColor = (status, classes) => {
  switch (status) {
    case constants.payments.STATUSES.NOT_PAID_STATUS:
      return classes.notPaid
    case constants.payments.STATUSES.CANCELED_STATUS:
      return classes.canceled
    case constants.payments.STATUSES.PAID_STATUS:
      return classes.paid
    case constants.payments.STATUSES.WAITING_CONFIRM_STATUS:
      return classes.waitingConfirmation
    default:
      return classes.waitingPayment
  }
}

export default orderStatusColor
