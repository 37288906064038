const styles = (theme) => ({
  // root: {
  //   backgroundColor: theme.palette.background.dark,
  //   minHeight: '100%',
  //   paddingTop: theme.spacing(5),
  //   paddingBottom: theme.spacing(3),
  // },
  root2: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  media: {
    height: 140,
  },
  root: {
    color: '#eaeaf0',
    display: 'flex',
    alignItems: 'center',
  },
  active: {
    color: '#784af4',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
})

export default styles
