import { Typography, Box, Link, Hidden } from '@material-ui/core'

import React from 'react'

const OnPage = ({ page }) => {
  return (
    <Box style={{ display: 'flex', flexDirection: 'column' }}>
      <Hidden mdDown>
        <Typography
          variant="body2"
          color="textPrimary"
          style={{
            fontWeight: 'bold',
            marginBottom: '8px',
            textTransform: 'uppercase',
          }}
        >
          Nesta página
        </Typography>
        {page &&
          page.map((item, index) => (
            <Link
              variant="body2"
              key={index}
              color="textSecondary"
              style={{ marginBottom: '8px', width: '100%', fontSize: '14px' }}
              href={item[0]}
            >
              {item[1]}
            </Link>
          ))}
      </Hidden>
    </Box>
  )
}

export default OnPage
