import { useState, useEffect } from 'react'
import useFetch from 'hooks/useFetch'

import { Container, Page, LoadingBox, Header } from 'components'
import { Grid } from '@material-ui/core'
import NotificationsForm from './components/NotificationsForm'

import * as service from 'service'

import { routes } from 'Routes'

const NotificationsNew = () => {
  const [accountUrlPaid, setAccountUrlPaid] = useState()
  const { response, isLoading } = useFetch(
    service.intermediador.accountUrls.get,
  )

  useEffect(() => {
    setAccountUrlPaid(
      response?.data?.accountUrls?.filter((accountUrl) => {
        return accountUrl.action === 'PAID'
      })[0],
    )
  }, [response])

  return (
    <Page title="Notificações">
      <Container maxWidth="sm">
        {isLoading ? (
          <LoadingBox />
        ) : (
          <>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Header name="Notificações" route={routes.settings.main} />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <NotificationsForm accountUrlPaid={accountUrlPaid} />
              </Grid>
            </Grid>
          </>
        )}
      </Container>
    </Page>
  )
}

export default NotificationsNew
