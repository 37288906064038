import PropTypes from 'prop-types'
import React from 'react'

import {
  Box,
  Grid,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  IconButton,
  DialogActions,
  Button,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { LoadingButton } from 'components'
import { X as XIcon } from 'react-feather'

import schema from './schema'
import styles from './styles'
import * as service from 'service'
import clsx from 'clsx'
import helpers from 'helpers'

import useFetch from 'hooks/useFetch'
import useSnackbar from 'hooks/useSnackbar'
import { useForm, Controller } from 'react-hook-form'
import theme from 'theme'

const useStyles = makeStyles(styles)

const DialogBankAccountCreation = ({ open, setOpen = () => {}, refresh }) => {
  const classes = useStyles()
  const snackbar = useSnackbar()

  const { response, isLoading } = useFetch(
    service.intermediador.bankAccounts.listBanks,
  )

  const { control, handleSubmit, errors, setValue } = useForm({
    validationSchema: schema,
    defaultValues: {
      bank: '',
      agency: '',
      number: '',
      titularName: '',
      document: '',
      documentType: '',
    },
  })

  const handleClose = () => {
    setOpen(false)
  }

  const onSubmit = async (data) => {
    try {
      await service.intermediador.bankAccounts.create({
        bank: data.bank.id + ' - ' + data.bank.name,
        agency: data.agency,
        number: data.number,
        titularName: data.titularName,
        document: data.document,
        documentType: data.documentType,
        status: 'ACTIVE',
      })

      snackbar.open({
        message: `Conta bancária criada com sucesso`,
        variant: 'success',
      })
      setOpen(false)
      refresh()
    } catch (error) {
      snackbar.open({
        message: 'Falha ao salvar Conta bancária',
        variant: 'error',
      })
    }
  }

  return (
    <Dialog
      open={open}
      PaperProps={{ classes: { root: classes.root } }}
      onClose={handleClose}
      maxWidth="lg"
    >
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h5">Criar nova conta bancária </Typography>
          <IconButton onClick={handleClose}>
            <XIcon color={theme.palette.text.primary} />
          </IconButton>
        </Box>
      </DialogTitle>
      <form id="bankAccountForm" onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item lg={12}>
              <Controller
                as={
                  <Autocomplete
                    options={isLoading ? [] : response?.data?.data}
                    getOptionLabel={(option) => {
                      if (option.name === undefined) {
                        return ''
                      }
                      return option.id + ' - ' + option.name || ''
                    }}
                    disableClearable
                    getOptionSelected={(option, value) => {
                      if (value === '') {
                        return true
                      }
                      return option.name === value.name
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Instituições financeiras"
                        variant="outlined"
                        error={!!errors.bank}
                        helperText={errors?.bank?.message}
                        fullWidth
                        className={classes.roundedTextField}
                      />
                    )}
                  />
                }
                control={control}
                onChange={([, data]) => data}
                name="bank"
                mode="onBlur"
              />
            </Grid>
            <Grid item sm={12} lg={8}>
              <Controller
                as={
                  <TextField
                    fullWidth
                    label="Agência"
                    type="text"
                    color="primary"
                    error={!!errors.agency}
                    helperText={errors?.agency?.message}
                    className={classes.roundedTextField}
                  />
                }
                control={control}
                name="agency"
                mode="onBlur"
              />
            </Grid>
            <Grid item sm={12} lg={4}>
              <Controller
                as={
                  <TextField
                    fullWidth
                    label="Número"
                    type="text"
                    color="primary"
                    error={!!errors.number}
                    helperText={errors?.number?.message}
                    className={classes.roundedTextField}
                  />
                }
                control={control}
                name="number"
                mode="onBlur"
              />
            </Grid>
            <Grid item sm={12} lg={6}>
              <Controller
                as={
                  <TextField
                    fullWidth
                    label="Nome do titular"
                    type="text"
                    color="primary"
                    error={!!errors.titularName}
                    helperText={errors?.titularName?.message}
                    className={classes.roundedTextField}
                  />
                }
                control={control}
                name="titularName"
                mode="onBlur"
              />
            </Grid>
            <Grid item sm={12} lg={6}>
              <Controller
                as={
                  <TextField
                    fullWidth
                    label="CNPJ/CPF do titular"
                    type="text"
                    color="primary"
                    error={!!errors.document}
                    helperText={errors?.document?.message}
                    className={classes.roundedTextField}
                  />
                }
                control={control}
                name="document"
                mode="onBlur"
                onChange={([event]) => {
                  if (event.target.value.length === 14) {
                    setValue('documentType', 'CPF')
                  } else if (event.target.value.length > 14) {
                    setValue('documentType', 'CNPJ')
                  }
                  return helpers.formatters.document(event.target.value)
                }}
              />
              <Controller
                name="documentType"
                as={<input type="hidden" />}
                control={control}
                mode="onBlur"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box pr={1}>
            <Button
              type="button"
              variant="outlined"
              className={clsx(classes.backButton, classes.roundedButton)}
              onClick={handleClose}
            >
              Voltar
            </Button>
          </Box>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            text="Salvar"
            className={classes.roundedButton}
          />
        </DialogActions>
      </form>
    </Dialog>
  )
}

DialogBankAccountCreation.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  refresh: PropTypes.func,
}

export default DialogBankAccountCreation
