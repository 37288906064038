import {
  Typography,
  Box,
  makeStyles,
  Button,
  Grid,
  Hidden,
} from '@material-ui/core'

import { useHistory } from 'react-router-dom'
import { Page, AnimatedBox } from 'components'

import { COMPANY_TOKEN_LOGIN_WITH_ID } from 'service/env'
import { routes } from 'Routes'

import * as service from 'service'

import useAuth from 'hooks/useAuth'
import useSnackbar from 'hooks/useSnackbar'

import Logo from 'images/logo-horizontal-y.png'
import BackgroundImageHome from 'images/backimghome.png'
import LogoOneId from 'images/oneidlg.png'

import theme from 'theme'

import styles from './styles'

const useStyles = makeStyles(styles)

const LandingPage = () => {
  const auth = useAuth()
  const snackbar = useSnackbar()
  const history = useHistory()
  const classes = useStyles()

  const loginWithButton = async () => {
    try {
      const response = await window.getTokenAuth()

      await service.intermediador.auth.login({
        companyToken: COMPANY_TOKEN_LOGIN_WITH_ID,
        token: response?.token,
      })

      history.replace(routes.home)
      auth.loadDataWithAnimation()
    } catch (err) {
      snackbar.open({
        variant: 'error',
        message: 'Erro ao realizar o login! ',
      })
    }
  }

  const signUpButton = async () => {
    const response = await window.signUpOneID()
    snackbar.open({ message: response.message, variant: response.status })
  }

  return (
    <Page
      title="Superfin"
      style={{
        height: '100%',
        zIndex: 1,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <Grid container style={{ height: '100%' }}>
        <Grid item xs={12} sm={12} md={12} lg={3}>
          <Hidden mdDown>
            <AnimatedBox
              div={[
                { id: 1, top: 0, left: 0 },
                { id: 2, top: 67, left: 555 },
                { id: 3, top: 80, left: 20 },
                { id: 4, top: 40, left: 230 },
              ]}
            />
          </Hidden>
          <Box
            ml={6}
            mr={6}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            style={{ height: '100%', zIndex: 2 }}
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              mb={4}
            >
              <img src={Logo} alt="Superfin logo" className={classes.logo} />
            </Box>
            <input
              type="hidden"
              id="leavening_company_token"
              value={COMPANY_TOKEN_LOGIN_WITH_ID}
            />
            <Typography
              variant="h6"
              color="textPrimary"
              style={{ textAlign: 'center', zIndex: 2 }}
            >
              Acesse com o OneID
            </Typography>
            <Box mt={4}>
              <Button onClick={loginWithButton} className={classes.button}>
                <Box className={classes.boxButton}>
                  <img
                    src={LogoOneId}
                    alt="OneID logo"
                    className={classes.logoOneid}
                  />
                  ONEID
                </Box>
              </Button>
            </Box>
            <Box mt={4}>
              <Typography
                variant="body2"
                color="textPrimary"
                style={{ textAlign: 'center', zIndex: 2 }}
              >
                OneID é um software de token que autentica em aplicativos de
                terceiros. Crie sua conta para obter acesso caso ainda não a
                possua.
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              mt={2}
            >
              <Typography
                variant="body2"
                color="textPrimary"
                style={{ zIndex: 2 }}
              >
                Precisa de uma conta?
              </Typography>
              <Box
                sx={{
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
              >
                <Typography
                  onClick={signUpButton}
                  variant="body2"
                  style={{
                    color: theme.palette.primary.main,
                    marginLeft: '12px',
                    textDecoration: 'underline',
                    zIndex: 2,
                  }}
                >
                  Criar conta
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Hidden mdDown>
          <Grid
            item
            xs={9}
            sm={9}
            md={9}
            lg={9}
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row-reverse',
            }}
          >
            <img
              src={BackgroundImageHome}
              alt="Home background"
              className={classes.backImageHome}
              style={{ zIndex: 2 }}
            />
            <div className={classes.box}></div>
          </Grid>
        </Hidden>
      </Grid>
    </Page>
  )
}

export default LandingPage
