import { makeStyles, Grid } from '@material-ui/core'

import { Container, Page, LoadingFeedback, Header } from 'components'
import { OrderInfos, OrderPayments } from './components'
import useFetch from 'hooks/useFetch'

import styles from './styles'
import { routes } from 'Routes'
import * as service from 'service'

const useStyles = makeStyles(styles)

const OrdersShow = ({ match }) => {
  const classes = useStyles()
  const { orderId } = match.params
  const { response, isLoading } = useFetch(service.intermediador.orders.show, {
    orderId,
  })

  return (
    <Page className={classes.root} title="Transações">
      <LoadingFeedback open={isLoading} />
      <Container maxWidth="md">
        <Grid container spacing={8}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Header name="Transações" route={routes.orders.main} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <OrderInfos isLoading={isLoading} order={response?.data?.order} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <OrderPayments
              isLoading={isLoading}
              order={response?.data?.order}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}

export default OrdersShow
