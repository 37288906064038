const subscriptionStatus = (status) => {
  switch (status) {
    case 1:
      return 'Aguardando ativação'
    case 2:
      return 'Ativo'
    case 3:
      return 'Excedido'
    default:
      return 'Cancelado'
  }
}

export default subscriptionStatus
