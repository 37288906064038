import theme from 'theme'
import { Typography } from '@material-ui/core'

const moreDetails = (order) => {
  const data = [
    { label: 'Token de transação', value: order?.token },
    { label: 'Código de cobrança:', value: order?.code },
    {
      label: 'Link de pagamento:',
      value: (
        <Typography
          style={{
            color: theme.palette.primary.main,
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
          onClick={() => {
            window.open(
              `${window.location.origin}/checkout/order/${order?.token}`,
              '_blank',
            )
          }}
        >
          Acessar
        </Typography>
      ),
    },
  ]
  if (order?.status === 'NOT_PAID') {
    return data
  }
  data.push(
    { label: 'Cliente', value: '' },
    { label: 'Nome:', value: order?.buyer?.name ? order.buyer.name : '' },
    { label: 'E-mail:', value: order?.buyer?.email ? order.buyer.email : '' },
    {
      label: order?.buyer?.document?.type
        ? `${order.buyer.document.type}:`
        : 'CPF/CNPJ:',
      value: order?.buyer?.document?.value ? order.buyer.document.value : '',
    },
  )

  return data
}

export default moreDetails
