const styles = (theme) => ({
  rootBox: {
    position: 'relative',
    width: '100%',
    backgroundColor: theme.palette.background.default,
    borderRadius: '20px',
  },
  searchRow: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  tableBox: {
    backgroundColor: '#FFF',
    borderRadius: '20px 0  0 20px',
    height: '100%',
  },
  cardBox: {
    backgroundColor: '#FFF',
    borderRadius: '0 20px  20px 0',
    height: '100%',
  },
  selectListBox: {
    height: '95%',
  },
  selectedList: {
    backgroundColor: '#E3E9EC',
  },
  searchButton: {
    position: 'absolute',
    right: '0px',
    width: '40px',
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '20px',
    marginRight: '8px',
  },
  inputPosition: {
    marginTop: '0px',
    color: theme.palette.text.primary,
  },
  noBorder: {
    border: 'none',
  },
})

export default styles
