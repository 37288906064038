import {
  Box,
  makeStyles,
  Typography,
  Card,
  Divider,
  Grid,
  TextField,
  Button,
} from '@material-ui/core'
import { LoadingButton, LoadingFeedback, Select } from 'components'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import DialogPayment from '../DialogPayment'
import schema from './schema'
import styles from './styles'
import { useHistory } from 'react-router-dom'
import { routes } from 'Routes'
import { reverse } from 'named-urls'
import helpers from 'helpers'
import CreditCard from 'react-credit-cards'
import 'react-credit-cards/es/styles-compiled.css'

const useStyles = makeStyles(styles)

const CreditCardForm = ({ type, token, customer, order }) => {
  const history = useHistory()
  const { control, handleSubmit, errors } = useForm({
    validationSchema: schema,
    defaultValues: {
      number: '',
      name: '',
      expiry: '',
      cvv: '',
      splitNumber: 1,
    },
  })
  const [card, setCard] = useState({
    cvv: '',
    expiry: '',
    focus: '',
    name: '',
    number: '',
  })
  const classes = useStyles()
  const [values, setValues] = useState({})
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const onSubmit = async (data) => {
    setValues(data)
    setOpen(true)
  }

  const handleInputFocus = (e) => {
    setCard((prevState) => ({
      ...prevState,
      focus: e.target.name,
    }))
  }

  const handleInputChange = (e) => {
    const { name, value } = e[0].target

    setCard((prevState) => ({
      ...prevState,
      [name]: value,
    }))
    if (name === 'number') {
      return helpers.formatters.creditCardNumber(value)
    }
    if (name === 'expiry') {
      let expiry = value
      let l = expiry.length

      if (l < 3) return expiry

      if (l > 5) return expiry.slice(0, 5)

      expiry = expiry.replaceAll('/', '')

      l = expiry.length

      if (l <= 5) return expiry.replace(/^(\d{2})(\d{1,2})*/, '$1/$2')
    }
    return value
  }

  return (
    <Box mt={4}>
      <LoadingFeedback open={loading} />
      <Card>
        <Box p={2}>
          <Typography variant="h5">Cartão de Crédito</Typography>
        </Box>
        <Divider />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box display="flex" alignItems="center" flexDirection="column">
            <Box className={classes.border} p={3} mb={5}>
              <Box mb={5}>
                <CreditCard
                  cvv={card.cvv}
                  expiry={card.expiry}
                  focused={card.focus}
                  name={card.name}
                  number={card.number || ''}
                />
              </Box>
              <Grid container spacing={3}>
                <Grid item sm={12} xs={12}>
                  <Controller
                    name="number"
                    as={
                      <TextField
                        label="Número do Cartão"
                        type="tel"
                        color="primary"
                        variant="outlined"
                        fullWidth
                        error={!!errors.number}
                        helperText={errors?.number?.message}
                        onFocus={handleInputFocus}
                        inputProps={{ maxLength: 19 }}
                      />
                    }
                    control={control}
                    mode="onBlur"
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item sm={6} xs={12}>
                  <Controller
                    name="name"
                    as={
                      <TextField
                        label="Nome do Titular"
                        type="text"
                        color="primary"
                        variant="outlined"
                        fullWidth
                        error={!!errors.name}
                        helperText={errors?.name?.message}
                        onFocus={handleInputFocus}
                        inputProps={{ maxLength: 30 }}
                      />
                    }
                    control={control}
                    mode="onBlur"
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Controller
                    name="expiry"
                    as={
                      <TextField
                        label="Data de vencimento"
                        type="text"
                        color="primary"
                        variant="outlined"
                        fullWidth
                        error={!!errors.expiry}
                        helperText={errors?.expiry?.message}
                        onFocus={handleInputFocus}
                        inputProps={{ maxLength: 5 }}
                      />
                    }
                    control={control}
                    mode="onBlur"
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item sm={6} xs={12}>
                  <Controller
                    name="cvv"
                    as={
                      <TextField
                        label="Código de segurança"
                        type="text"
                        color="primary"
                        variant="outlined"
                        fullWidth
                        error={!!errors.cvv}
                        helperText={errors?.cvv?.message}
                        onFocus={handleInputFocus}
                        inputProps={{ maxLength: 3 }}
                      />
                    }
                    control={control}
                    mode="onBlur"
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Controller
                    name="splitNumber"
                    as={
                      <Select
                        label="Parcelas"
                        defaultValue={1}
                        items={[
                          { id: 1, name: 1 },
                          { id: 2, name: 2 },
                          { id: 3, name: 3 },
                          { id: 4, name: 4 },
                          { id: 5, name: 5 },
                          { id: 6, name: 6 },
                          { id: 7, name: 7 },
                          { id: 8, name: 8 },
                          { id: 9, name: 9 },
                          { id: 10, name: 10 },
                          { id: 11, name: 11 },
                          { id: 12, name: 12 },
                        ]}
                      />
                    }
                    control={control}
                    mode="onBlur"
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box alignSelf="stretch">
              <Divider />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              p={1}
              width="100%"
            >
              <Box mr={1}>
                <Button
                  onClick={() =>
                    history.push(
                      reverse(routes.payments.main, {
                        type: type,
                        token: token,
                      }),
                    )
                  }
                >
                  &lt; Voltar
                </Button>
              </Box>
              <LoadingButton text="Pagar" type="submit" />
            </Box>
          </Box>
        </form>
        <DialogPayment
          open={open}
          setOpen={setOpen}
          {...(open && {
            values: values,
            customer: customer,
            order: order,
            token: token,
            type: type,
          })}
          setLoading={setLoading}
        />
      </Card>
    </Box>
  )
}

export default CreditCardForm
