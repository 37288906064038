import translateStatus from './translateStatus'
import valueLabel from './valueLabel'
import subscriptionStatus from './subscriptionStatus'

const subscriptions = {
  translateStatus,
  valueLabel,
  subscriptionStatus,
}

export default subscriptions
