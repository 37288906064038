const styles = (checkoutTheme) => ({
  payText: {
    fontWeight: 300,
  },
  blueText: {
    color: checkoutTheme.palette.text.primary,
    fontWeight: 600,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  blueTextCode: {
    color: checkoutTheme.palette.text.secondary,
    fontWeight: 300,
    fontStyle: 'italic',
    fontSize: '12px',
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
    width: '100%',
  },
  price: {
    color: checkoutTheme.palette.text.primary,
    fontWeight: 600,
  },
  blueDescription: {
    color: checkoutTheme.palette.text.secondary,
    fontWeight: 500,
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
    width: '100%',
    fontSize: 12,
  },
  lightBlueText: {
    fontWeight: 600,
  },
  iconBox: {
    backgroundColor: checkoutTheme.palette.background.paper,
    borderRadius: '50%',
  },
  blueTextMain: {
    color: checkoutTheme.palette.text.primary,
    fontWeight: 600,
  },
})

export default styles
