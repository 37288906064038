import { Link } from '@material-ui/core'

const CustomLink = ({ children, href }) => {
  return (
    <Link
      href={href}
      color="primary"
      style={{
        fontWeight: 'bold',
        boxShadow:
          'inset 0 -.125em 0 0 transparent,inset 0 -.375em 0 0 #AEFF97',
      }}
    >
      {' '}
      {children}{' '}
    </Link>
  )
}

export default CustomLink
