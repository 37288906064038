const styles = (theme) => ({
  rootNew: {
    paddingRight: theme.spacing(30),
    paddingLeft: theme.spacing(30),
    [theme.breakpoints.down('sm')]: {
      paddingRight: '0px',
      paddingLeft: '0px',
    },
  },
  boxButton: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '20px',
    transition: 'transform 0.5s',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
  menuNavigation: {
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.8,
    },
  },
  barCode: {
    backgroundColor: '#fff',
    borderRadius: theme.spacing(4),
  },
  roundedButton: {
    borderRadius: theme.spacing(4),
  },
})

export default styles
